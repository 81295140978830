import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  remark,
  confirmationMessage,
  children,
  disableButton,
  confirmButtonText = "Confirm",
}) => {
  const [inputRemark, setInputRemark] = useState(""); // State to hold the remark input value

  const handleConfirm = () => {
    if (remark) {
      onConfirm(inputRemark); // Pass the remark to the onConfirm function
    } else {
      onConfirm();
    }
    // onClose();
  };

  const shouldShowRemarkInput = !!remark; // Check if the remark prop is provided

  return (
    <Modal show={isOpen} onHide={onClose} backdrop="static" centered fullscreen>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {confirmationMessage}
        {children}
        {shouldShowRemarkInput && (
          <div className="form-group">
            <label htmlFor="remark">Remark:</label>
            <input
              type="text"
              id="remark"
              className="form-control"
              value={inputRemark}
              onChange={(e) => setInputRemark(e.target.value)}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {/*<Button variant="carre-red text-white" onClick={onClose}>*/}
        {/*  Cancel*/}
        {/*</Button>*/}
        <Button
          variant="carre-blue text-white w-100"
          onClick={handleConfirm}
          disabled={disableButton}
        >
          {confirmButtonText}{" "}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
