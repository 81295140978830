import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { useCookies } from "react-cookie";
import { useFieldArray, useForm } from "react-hook-form";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { BsCardImage } from "react-icons/bs";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { TbCash, TbInfoCircle } from "react-icons/tb";
import ImageUploading from "react-images-uploading";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { createProperty } from "../../hooks/useCreateProperty";
import { useGetDao } from "../../hooks/useGetDao";
import { useGetMarketStages } from "../../hooks/useGetMarketStages";
import { useGetPropertyById } from "../../hooks/useGetPropertyById";
import { useGetPropertyType } from "../../hooks/useGetPropertyType";
import { GetRoleNavigationPath } from "../../routeUtils";
import CCdata from "./CitiAndCountry.json";
import { checkPlural } from "../../utils/helper";

function AddProperty() {
  const country = CCdata.map((data) => data.country);
  const params = useParams();
  const id = params.id;
  const roleUrl = GetRoleNavigationPath();
  const { data, refetch } = useGetPropertyById(id);

  const cookies = useCookies();
  const { data: daoList } = useGetDao();
  const { data: marketStages } = useGetMarketStages();
  const { data: typeList } = useGetPropertyType();
  const [indx, setIndx] = useState([]);
  const navigate = useNavigate();
  let hasDefault = false;

  if (indx) {
    indx.forEach((element) => {
      if (element?.is_default === true) {
        hasDefault = true;
      }
    });
  }

  function sendImg(file) {
    const image = file.file;
    const bodyFormData = new FormData();
    bodyFormData.append("file", image);
    axios({
      method: "post",
      url:
        process.env.REACT_APP_API_KEY +
        "/property/upload-photo?is_default=false",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${cookies[0].token}`,
      },
    })
      .then(function (response) {
        //handle success
        // if (response.)
        setIndx((indx) => [...indx.concat(response.data)]);
        append(response.data);
      })
      .catch(function (response) {});
  }

  function setAsDefault(id) {
    indx.forEach((element) => {
      element.image_name === id
        ? (element.is_default = true)
        : (element.is_default = false);
      setIndx((indx) => [...indx.concat()]);
    });
    replace([]);
    append(indx);
  }

  const handleDelete = (id) => {
    const newList = indx.filter((item) => item !== id);
    setIndx(newList);
  };
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    for (let i = indx.length; i < imageList.length; i++) {
      sendImg(imageList[i]);
    }
  };
  const {
    register,
    formState: { errors },
    watch,
    control,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      asset_price: 0,
      doa_llc_fees: 0,
      carre_listing_fees: 0,
      closing_costs: 0,
      operating_reserve: 0,
      other_costs: 0,
      revenues_rent: 0,
      revenues_other: 0,
      cost_property_texes: 0,
      cost_homeowner_insurance: 0,
      cost_utilities_cost: 0,
      cost_other_cost: 0,
      cost_hoa: 0,
      cost_property_management: 0,
      cost_annual_doa_llc: 0,
      net_appreciation: 0,
      token_price: 100,
      allowed_token_to_reserve: 0,
      token_price_up_coming: 97.5,
      vacation_token_exceptions: 0,
    },
  });
  const { append, replace } = useFieldArray({
    control,
    name: "image",
  });

  useEffect(() => {
    if (id) {
      setIndx(data?.image);

      reset(
        {
          property_address_id: data?.property_address_id,
          dao_id: data?.dao_id,
          property_type_id: data?.property_type_id,
          property_name: data?.property_name,
          description: data?.description,
          parking: data?.parking,
          room_count: data?.room_count,
          bedroom_count: data?.bedroom_count,
          property_area: data?.property_area,
          cost_hoa: data?.cost_hoa,
          id: data?.id,
          "address.zipcode": data?.address?.zipcode,
          "address.number_building": data?.address?.number_building,
          "address.number_street": data?.address?.number_street,
          "address.street_name": data?.address?.street_name,
          "address.city_name": data?.address?.city_name,
          "address.country_state": data?.address?.country_state,
          "address.country_name": data?.address?.country_name,
          "amenities.gym": data?.amenities.gym,
          "amenities.air_condition": data?.amenities.air_condition,
          "amenities.barbecue_area": data?.amenities.barbecue_area,
          "amenities.free_wifi": data?.amenities.free_wifi,
          "amenities.games_room": data?.amenities.games_room,
          "amenities.laundry_facilities": data?.amenities.laundry_facilities,
          "amenities.sand_volleyball": data?.amenities.sand_volleyball,
          "amenities.security": data?.amenities.security,
          "amenities.storage": data?.amenities.storage,
          "amenities.swimming_pool": data?.amenities.swimming_pool,
          "amenities.tech_workspace": data?.amenities.tech_workspace,
          asset_price: data?.asset_price,
          doa_llc_fees: data?.doa_llc_fees,
          carre_listing_fees: data?.carre_listing_fees,
          closing_costs: data?.closing_costs,
          operating_reserve: data?.operating_reserve,
          other_costs: data?.other_costs,
          total_costs: data?.total_costs,
          number_of_token: data?.number_of_token,
          bathrooms: data?.bathrooms,
          revenues_rent: data?.revenues_rent,
          revenues_other: data?.revenues_other,
          revenues_total: data?.revenues_total,
          cost_property_texes: data?.cost_property_texes,
          cost_homeowner_insurance: data?.cost_homeowner_insurance,
          cost_utilities_cost: data?.cost_utilities_cost,
          cost_other_cost: data?.cost_other_cost,
          cost_property_management: data?.cost_property_management,
          cost_annual_doa_llc: data?.cost_annual_doa_llc,
          cost_total_cost: data?.cost_total_cost,
          net_expected_annual: data?.net_expected_annual,
          net_expected_monthly: data?.net_expected_monthly,
          net_expected_cash_return: data?.net_expected_cash_return,
          net_expected_coc_cash_token: data?.net_expected_coc_cash_token,
          net_appreciation: data?.net_appreciation,
          net_expected_total_returns: data?.net_expected_total_returns,
          net_actual_cash_return: data?.net_actual_cash_return,
          net_actual_coc_cash_token: data?.net_actual_coc_cash_token,
          market_stage: data?.market_stage,
          cash_dividend_payment: data?.cash_dividend_payment,
          vacation_token_price: data?.vacation_token_price,
          property_management_url: data?.property_management_url,
          property_management_name: data?.property_management_name,
          insurance_name: data?.insurance_name,
          insurance_url: data?.insurance_url,
          // expected_token_offering_date:,
          market_title: data?.market_title,
          market_description: data?.market_description,
          token_price: data?.token_price,
          allowed_token_to_reserve: data?.allowed_token_to_reserve,
          token_price_up_coming: data?.token_price_up_coming,
          vacation_token_exceptions: data?.vacation_token_exceptions,
        },
        { keepDirty: true }
      );
    }
  }, [reset, data, id]);

  useEffect(() => {
    refetch().then();
    replace(data?.image);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data]);
  const countryname = watch("address.country_name");
  const filteredCities =
    countryname !== undefined
      ? CCdata.find((item) => item?.country === countryname)?.city
      : [];

  const notify = () => toast.success("Property Added Succesfully");
  // const [response, setResponse] = useState({});
  const { mutate, error, isLoading } = useMutation(createProperty, {
    onSuccess: (data) => {
      notify();
      // setResponse(data);
      navigate(`/${roleUrl}/listproperty`);
    },
    onError: (error) => {
      notifyError(error?.response.data.error);
    },
  });
  const notifyError = (error) => toast.error(error);

  const marketSelected = watch("market_stage");

  const filterMarket = marketStages?.find((obj) => obj.id === marketSelected);

  const [updateMarketFields, setUpdateMarketFields] = useState(false);
  useEffect(() => {
    if (
      filterMarket?.name === "Primary" ||
      filterMarket?.name === "Secondary"
    ) {
      setUpdateMarketFields(true);
    } else setUpdateMarketFields(false);
  }, [filterMarket]);
  const watchFields = watch([
    "asset_price",
    "doa_llc_fees",
    "carre_listing_fees",
    "closing_costs",
    "operating_reserve",
    "other_costs",
  ]);
  let total = 0;
  for (let i = 0; i < watchFields.length; i++) {
    total += +watchFields[i];
  }
  const isDivided = total % 100 === 0 ? true : false;
  const watchCostFields = watch([
    "cost_property_texes",
    "cost_homeowner_insurance",
    "cost_utilities_cost",
    "cost_other_cost",
    "cost_hoa",
    "cost_property_management",
    "cost_annual_doa_llc",
  ]);
  let totalCost = 0;
  for (let i = 0; i < watchCostFields.length; i++) {
    totalCost += +watchCostFields[i];
  }
  const totalTokens = isDivided ? total / 100 : "The numbers are not divided ";

  function onSubmit(data, event) {
    event.preventDefault();
    mutate(data);
  }

  const watchRevenueFields = watch(["revenues_rent", "revenues_other"]);
  let totalRevenue = 0;
  for (let i = 0; i < watchRevenueFields.length; i++) {
    totalRevenue += +watchRevenueFields[i];
  }

  function percentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
  }

  function submitDisability() {
    if (isDivided === false || (indx?.length >= 0 && hasDefault === false)) {
      return true;
    } else return false;
  }

  let expectedAnnualNetCashFlow = (totalRevenue - totalCost).toFixed(2);
  let expectedMonthlyCashFlow = ((totalRevenue - totalCost) / 12).toFixed(2);
  let expectedCoCReturn = data?.net_expected_cash_return
    ? data?.net_expected_cash_return
    : percentage(expectedAnnualNetCashFlow, total).toFixed(2) > 0
    ? percentage(expectedAnnualNetCashFlow, total).toFixed(2)
    : 0;
  const projectAppreciation = watch("net_appreciation");
  let expectedTotalReturn = (
    (100 * expectedAnnualNetCashFlow) / total +
    projectAppreciation
  ).toFixed(4);
  let ActualCoCCashDividentPerToken = (
    ((totalRevenue - totalCost) * 0.5 * 0.01) /
    365
  ).toFixed(4);
  let ActualCoCReturn12Month = ActualCoCCashDividentPerToken * 365;
  let ExpectedCoCCashDividentPerToken = (
    (totalRevenue - totalCost) /
    100 /
    365
  ).toFixed(4);

  return (
    <div>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="rounded-2 shadow-lg p-3 mb-5 p-3 my-2 ">
          <TbInfoCircle
            style={{ width: "20px", height: "20px" }}
            className="text-carre-purple  d-inline mb-2 mx-1"
          />
          <p className="h4 font-weight-bold d-inline ">Market Stages</p>
          <Form.Group as={Col}>
            <label>Market Stages </label>
            <Form.Select
              // placeholder="Submitted"
              defaultValue="as"
              className={
                errors.market_stage
                  ? "text-carre-purple form-control border-carre-red"
                  : " form-control false"
              }
              {...register("market_stage", {})}
              aria-label="Default select example"
            >
              {/*<option value={"6b114a90-2ee1-40a6-97b4-76feb53355d8"}>*/}
              {/*  Submitted*/}
              {/*</option>*/}
              {marketStages?.map((market) => (
                <option key={market.id} value={market.id}>
                  {market.name}
                </option>
              ))}
            </Form.Select>
            {errors.market_stage && (
              <p className="text-carre-red">This field is required</p>
            )}
          </Form.Group>
        </div>
        <h1 className="mx-3 mb-3">List Property</h1>
        <div className="rounded-2 shadow-lg p-3 mb-5 p-3 my-2 ">
          <TbInfoCircle
            style={{ width: "20px", height: "20px" }}
            className="text-carre-purple  d-inline mb-2 mx-1"
          />
          <p className="h4 font-weight-bold d-inline ">Basic Info</p>
          <Form.Group as={Col}>
            <label>Name</label>
            <input
              placeholder="Property name"
              className={
                errors.property_name
                  ? " form-control border-4 border-carre-red"
                  : " form-control false"
              }
              {...register("property_name", {
                required: true,
              })}
            />
            {errors.property_name && (
              <p className="text-carre-red">This field is required</p>
            )}
          </Form.Group>
          <Row>
            <Form.Group as={Col}>
              <label>Dao Id </label>
              <Form.Select
                className={
                  errors.dao_id
                    ? "text-carre-purple form-control border-carre-red"
                    : " form-control false"
                }
                {...register("dao_id", {
                  required: true,
                })}
                aria-label="Default select example"
              >
                <option value="" disabled selected>
                  Please Select
                </option>
                {daoList?.map((dao) => (
                  <option key={dao.id} value={dao.id}>
                    {dao.dao_name}
                  </option>
                ))}
              </Form.Select>
              {errors.dao_id && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>
          </Row>

          <Form.Group as={Col}>
            <label>About the property </label>
            <textarea
              maxLength={5000}
              style={{ height: "100px" }}
              className={
                errors.description
                  ? "border-carre-red form-control"
                  : " form-control false"
              }
              {...register("description", {
                required: true,
              })}
            />
            {errors.description && (
              <p className="text-carre-red">This field is required</p>
            )}
          </Form.Group>
        </div>
        <div className="rounded-2 shadow-lg p-3 mb-5 p-3 my-2 ">
          <HiOutlineLocationMarker
            style={{ width: "20px", height: "20px" }}
            className="text-carre-purple  d-inline mb-2 mx-1"
          />
          <p className="h4 font-weight-bold d-inline "> Location</p>
          <Row>
            <Form.Group as={Col}>
              <label>Country</label>
              <Form.Select
                className={
                  errors?.address?.country_name
                    ? "text-carre-purple form-control border-carre-red"
                    : " form-control false"
                }
                {...register("address.country_name", {
                  required: true,
                })}
              >
                <option value="" disabled selected>
                  Please Select
                </option>
                {country?.map((count) => (
                  <option key={count.id} value={count.id}>
                    {count}
                  </option>
                ))}
              </Form.Select>
              {errors?.address?.country_name && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>

            <Form.Group as={Col}>
              <label> City*</label>
              <Form.Select
                className={
                  errors?.address?.country_name
                    ? "text-carre-purple form-control border-carre-red"
                    : " form-control false"
                }
                {...register("address.city_name", {
                  required: true,
                })}
              >
                <option value="" disabled selected>
                  Please Select
                </option>
                {filteredCities?.map((count) => (
                  <option key={count.id} value={count.id}>
                    {count}
                  </option>
                ))}
                {errors.address?.city_name && (
                  <p className="text-carre-red">This field is required</p>
                )}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col}>
              <label> District</label>
              <input
                className={
                  errors.address?.country_name
                    ? "border-carre-red form-control"
                    : " form-control false"
                }
                {...register("address.country_state", { required: true })}
              />
              {errors.address?.country_name && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <label> Zip code*</label>
              <input
                className={
                  errors.address?.zipcode
                    ? "border-carre-red form-control"
                    : " form-control false"
                }
                {...register("address.zipcode", {
                  required: true,
                })}
              />
              {errors.address?.zipcode && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <label> Street address*</label>
              <input
                className={
                  errors.address?.street_name
                    ? "border-carre-red form-control"
                    : " form-control false"
                }
                {...register("address.street_name", {
                  required: true,
                })}
              />
              {errors.address?.street_name && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>
          </Row>
        </div>
        <div className="rounded-2 shadow-lg p-3 mb-5 p-3 my-2 ">
          <TbCash
            style={{ width: "20px", height: "20px" }}
            className="text-carre-purple  d-inline mb-2 mx-1"
          />
          <p className="h4 font-weight-bold d-inline "> Price At Lisiting</p>
          <Row>
            <Form.Group as={Col}>
              <label>Underlying Asset Price (price of accepted offer)</label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control
                  {...register("asset_price", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  type="number"
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <label>Closing Cost</label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control
                  {...register("closing_costs", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  type="number"
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <label>Upfront DAO LLC fees</label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control
                  {...register("doa_llc_fees", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  type="number"
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <label> Operating Reserve</label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control
                  {...register("operating_reserve", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  type="number"
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <label>Carre Listing Fees</label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control
                  {...register("carre_listing_fees", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  type="number"
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <label>Construction / Furnishing / other costs</label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control
                  {...register("other_costs", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  type="number"
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <label>Total Investment Value</label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control value={total} disabled="true " type="number" />
              </InputGroup>
              {!isDivided && (
                <p className="text-carre-red">
                  {" "}
                  Total Investment Value must be a multiplier of 100 !
                </p>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <label>Number Of Shares</label>
              <InputGroup className="mb-3">
                <Form.Control value={totalTokens} disabled="true " />
              </InputGroup>
            </Form.Group>
          </Row>
        </div>
        <div className="rounded-2 shadow-lg p-3 mb-5 p-3 my-2 ">
          <TbCash
            style={{ width: "20px", height: "20px" }}
            className="text-carre-purple  d-inline mb-2 mx-1"
          />
          <p className="h4 font-weight-bold d-inline ">
            {" "}
            Annual Cash Flow - Revenues
          </p>
          <Row>
            <Form.Group as={Col}>
              <label>Expected Annual Gross Rents</label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control
                  {...register("revenues_rent", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  type="number"
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <label>Other revenues</label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control
                  {...register("revenues_other", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  type="number"
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <label>Total Revenues</label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control
                  disabled={true}
                  value={totalRevenue}
                  type="number"
                />
              </InputGroup>
            </Form.Group>
          </Row>
        </div>
        <div className="rounded-2 shadow-lg p-3 mb-5 p-3 my-2 ">
          <TbCash
            style={{ width: "20px", height: "20px" }}
            className="text-carre-purple  d-inline mb-2 mx-1"
          />
          <p className="h4 font-weight-bold d-inline ">
            {" "}
            Annual Cash Flow - Cost
          </p>
          <Row>
            <Form.Group as={Col}>
              <label>Property taxes</label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control
                  {...register("cost_property_texes", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  type="number"
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <label>HOA cost</label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control
                  {...register("cost_hoa", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  type="number"
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <label>Homeowners Insurance</label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control
                  {...register("cost_homeowner_insurance", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  type="number"
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <label> Property Management</label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control
                  {...register("cost_property_management", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  type="number"
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <label>Utilities (cost)</label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control
                  {...register("cost_utilities_cost", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  type="number"
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <label>Annual DOA LLC administration and filing fees</label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control
                  {...register("cost_annual_doa_llc", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  type="number"
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <label>Other cost</label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                <Form.Control
                  {...register("cost_other_cost", {
                    required: true,
                    valueAsNumber: true,
                  })}
                  type="number"
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <label>Total Cost</label>
              <InputGroup className="mb-3">
                <Form.Control value={totalCost} disabled="true " />
              </InputGroup>
            </Form.Group>
          </Row>
        </div>
        <div className="rounded-2 shadow-lg p-3 mb-5 p-3 my-2 ">
          <TbCash
            style={{ width: "20px", height: "20px" }}
            className="text-carre-purple  d-inline mb-2 mx-1"
          />
          <p className="h4 font-weight-bold d-inline ">
            {" "}
            Annual Cash Flow - Net
          </p>
          <Row>
            <Form.Group as={Col}>
              <label>Expected Annual Net cash Flows</label>
              <InputGroup className="mb-3">
                {/*<InputGroup.Text id="basic-addon1">$</InputGroup.Text>*/}
                <Form.Control
                  value={expectedAnnualNetCashFlow}
                  disabled="true "
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <label>Expected Monthly cash Flows</label>
              <InputGroup className="mb-3">
                {/*<InputGroup.Text id="basic-addon1">$</InputGroup.Text>*/}
                <Form.Control
                  value={expectedMonthlyCashFlow}
                  disabled="true "
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <label>Expected Cash on Cash Return</label>
              <InputGroup className="mb-3">
                {/*<InputGroup.Text id="basic-addon1">$</InputGroup.Text>*/}
                <Form.Control
                  value={expectedCoCReturn + "%"}
                  disabled="true "
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <label>Expected Coc cash dividend per share</label>
              <InputGroup className="mb-3">
                {/*<InputGroup.Text id="basic-addon1">$</InputGroup.Text>*/}
                <Form.Control
                  value={ExpectedCoCCashDividentPerToken}
                  disabled="true "
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <label>Project Appreciation</label>
              <InputGroup className="mb-3">
                {/*<InputGroup.Text id="basic-addon1">$</InputGroup.Text>*/}
                <Form.Control
                  className={
                    errors.net_appreciation
                      ? "border-carre-red form-control"
                      : " form-control false"
                  }
                  {...register("net_appreciation", {
                    valueAsNumber: true,
                    required: true,
                  })}
                />
              </InputGroup>
              {errors.net_appreciation && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <label>Expected Total Returns</label>
              <InputGroup className="mb-3">
                {/*<InputGroup.Text id="basic-addon1">$</InputGroup.Text>*/}
                <Form.Control
                  value={expectedTotalReturn + "%"}
                  disabled="true "
                />
              </InputGroup>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col}>
              <label>Actual Cash on Cash Return (12-month trailing)</label>
              <InputGroup className="mb-3">
                {/*<InputGroup.Text id="basic-addon1">$</InputGroup.Text>*/}
                <Form.Control value={ActualCoCReturn12Month} disabled="true " />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <label>Actual Coc cash divident per share</label>
              <InputGroup className="mb-3">
                {/*<InputGroup.Text id="basic-addon1">$</InputGroup.Text>*/}
                <Form.Control
                  value={ActualCoCCashDividentPerToken}
                  disabled="true "
                />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <label>Vacation Points Appreciation</label>
              <InputGroup className="mb-3">
                {/*<InputGroup.Text id="basic-addon1">$</InputGroup.Text>*/}
                <Form.Control
                  className={
                    errors.vacation_token_exceptions
                      ? "border-carre-red form-control"
                      : " form-control false"
                  }
                  {...register("vacation_token_exceptions", {
                    valueAsNumber: true,
                    required: true,
                  })}
                />
              </InputGroup>
              {errors.vacation_token_exceptions && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>
          </Row>
        </div>
        <div className="rounded-2 shadow-lg p-3 mb-5 p-3 my-2 ">
          <BiEdit
            style={{ width: "20px", height: "20px" }}
            className="text-carre-purple  d-inline mb-2 mx-1"
          />
          <p className="h4 font-weight-bold d-inline "> Property Details</p>
          <Row>
            <Form.Group as={Col}>
              <label>Property Type </label>
              <Form.Select
                className={
                  errors.property_type_id
                    ? "border-carre-red form-control"
                    : " form-control false"
                }
                {...register("property_type_id", {
                  required: true,
                })}
                placeholder="Property Type"
                aria-label="Default select example"
              >
                <option value="" disabled selected>
                  Please select property type
                </option>
                {typeList?.map((propertyType, i) => (
                  <option key={propertyType.id} value={propertyType.id}>
                    {propertyType.name}
                  </option>
                ))}
              </Form.Select>
              {errors.property_type_id && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <label>Total Area sq.m</label>
              <input
                type="number"
                className={
                  errors.property_area
                    ? "border-carre-red form-control"
                    : " form-control false"
                }
                {...register("property_area", {
                  valueAsNumber: true,
                  required: true,
                })}
              />
              {errors.property_area && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>
          </Row>
          {/*<Row className="mb-3">*/}
          {/*    <Form.Group as={Col}>*/}
          {/*        <label> Nr of building</label>*/}
          {/*        <input*/}
          {/*            type="number"*/}
          {/*            className={errors.address?.number_building ? "border-carre-red form-control" : " form-control false"}*/}
          {/*            {...register("address.number_building", {*/}
          {/*                valueAsNumber: true,*/}
          {/*            })}/>*/}
          {/*        {errors.address?.number_building &&*/}
          {/*            <p className="text-carre-red">This field is required</p>}*/}
          {/*    </Form.Group>*/}
          {/*    <Form.Group as={Col}>*/}
          {/*        /!*we use this to put emty space in the right of number of building*!/*/}
          {/*    </Form.Group>*/}
          {/*</Row>*/}
          <h5>Amenities</h5>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <label>Gym </label>
              <input
                type="checkbox"
                className={
                  errors?.amenities?.gym
                    ? "border-carre-red form-check "
                    : " form-check false"
                }
                {...register("amenities.gym", {})}
              />
              {errors?.amenities?.gym && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <label>Swimming Pool </label>
              <input
                type="checkbox"
                className={
                  errors?.amenities?.swimming_pool
                    ? "border-carre-red form-check "
                    : " form-check false"
                }
                {...register("amenities.swimming_pool", {})}
              />
              {errors?.amenities?.swimming_pool && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <label>Air Conditioning </label>
              <input
                type="checkbox"
                className={
                  errors?.amenities?.air_condition
                    ? "border-carre-red form-check "
                    : " form-check false"
                }
                {...register("amenities.air_condition", {})}
              />
              {errors?.amenities?.air_condition && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <label>Barbecue Area</label>
              <input
                type="checkbox"
                className={
                  errors?.amenities?.barbecue_area
                    ? "border-carre-red form-check "
                    : "form-check false"
                }
                {...register("amenities.barbecue_area", {})}
              />
              {errors?.amenities?.barbecue_area && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>

            <Form.Group as={Col}>
              <label>Free WiFi</label>
              <input
                type="checkbox"
                className={
                  errors?.amenities?.free_wifi
                    ? "border-carre-red form-check "
                    : "form-check false"
                }
                {...register("amenities.free_wifi", {})}
              />
              {errors?.amenities?.free_wifi && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <label>Tech Workspace</label>
              <input
                type="checkbox"
                className={
                  errors?.amenities?.tech_workspace
                    ? "border-carre-red form-check "
                    : "form-check false"
                }
                {...register("amenities.tech_workspace", {})}
              />
              {errors?.amenities?.tech_workspace && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <label>Games Room</label>
              <input
                type="checkbox"
                className={
                  errors?.amenities?.games_room
                    ? "border-carre-red form-check "
                    : "form-check false"
                }
                {...register("amenities.games_room", {})}
              />
              {errors?.amenities?.games_room && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>

            <Form.Group as={Col}>
              <label>Laundry Facilities</label>
              <input
                type="checkbox"
                className={
                  errors?.amenities?.laundry_facilities
                    ? "border-carre-red form-check "
                    : "form-check false"
                }
                {...register("amenities.laundry_facilities", {})}
              />
              {errors?.amenities?.laundry_facilities && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>

            <Form.Group as={Col}>
              <label>Sand Volleyball</label>
              <input
                type="checkbox"
                className={
                  errors?.amenities?.sand_volleyball
                    ? "border-carre-red form-check "
                    : "form-check false"
                }
                {...register("amenities.sand_volleyball", {})}
              />
              {errors?.amenities?.sand_volleyball && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <label>Security</label>
              <input
                type="checkbox"
                className={
                  errors?.amenities?.security
                    ? "border-carre-red form-check "
                    : "form-check false"
                }
                {...register("amenities.security", {})}
              />
              {errors?.amenities?.security && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>

            <Form.Group as={Col}>
              <label>Storage</label>
              <input
                type="checkbox"
                className={
                  errors?.amenities?.storage
                    ? "border-carre-red form-check "
                    : "form-check false"
                }
                {...register("amenities.storage", {})}
              />
              {errors?.amenities?.storage && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>
            <Form.Group as={Col}></Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <label>Parking spaces</label>
              <input
                type="number"
                className={
                  errors.parking
                    ? "border-carre-red form-control "
                    : "form-control false"
                }
                {...register("parking", {
                  valueAsNumber: true,
                  required: true,
                })}
              />
              {errors.parking && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <label>Room Count </label>
              <input
                type="number"
                className={
                  errors.room_count
                    ? "border-carre-red form-control"
                    : " form-control false"
                }
                {...register("room_count", {
                  valueAsNumber: true,
                  required: true,
                })}
              />
              {errors.room_count && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <label>Nr of bedrooms </label>
              <input
                type="number"
                className={
                  errors.bedroom_count
                    ? "border-carre-red form-control"
                    : " form-control false"
                }
                {...register("bedroom_count", {
                  valueAsNumber: true,
                  required: true,
                })}
              />
              {errors.bedroom_count && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <label as={Col}>Nr of bathrooms</label>
              <input
                className={
                  errors.bathrooms
                    ? "border-carre-red form-control"
                    : "form-control false"
                }
                type="number"
                {...register("bathrooms", {
                  valueAsNumber: true,
                  required: true,
                })}
              />
              {errors.bathrooms && (
                <p className="text-carre-red">This field is required</p>
              )}
            </Form.Group>
          </Row>
        </div>
        {/*token*/}
        <div className="rounded-2 shadow-lg p-3 mb-5 p-3 my-2 ">
          <TbInfoCircle
            style={{ width: "20px", height: "20px" }}
            className="text-carre-purple  d-inline mb-2 mx-1"
          />
          <p className="h4 font-weight-bold d-inline ">Share</p>
          <Form.Group as={Col}>
            <label>Share Price</label>
            <InputGroup className="mb-3">
              {/*<InputGroup.Text id="basic-addon1">$</InputGroup.Text>*/}
              <Form.Control
                className={
                  errors.token_price
                    ? "border-carre-red form-control"
                    : " form-control false"
                }
                {...register("token_price", {
                  valueAsNumber: true,
                  required: true,
                })}
              />
            </InputGroup>
            {errors.token_price && (
              <p className="text-carre-red">This field is required</p>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <label>Allowed number of shares to reserve</label>
            <InputGroup className="mb-3">
              {/*<InputGroup.Text id="basic-addon1">$</InputGroup.Text>*/}
              <Form.Control
                className={
                  errors.allowed_token_to_reserve
                    ? "border-carre-red form-control"
                    : " form-control false"
                }
                {...register("allowed_token_to_reserve", {
                  valueAsNumber: true,
                  required: true,
                })}
              />
            </InputGroup>
            {errors.allowed_token_to_reserve && (
              <p className="text-carre-red">This field is required</p>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <label>Share price for upcoming</label>
            <InputGroup className="mb-3">
              {/*<InputGroup.Text id="basic-addon1">$</InputGroup.Text>*/}
              <Form.Control
                className={
                  errors.token_price_up_coming
                    ? "border-carre-red form-control"
                    : " form-control false"
                }
                {...register("token_price_up_coming", {
                  valueAsNumber: true,
                  required: true,
                })}
              />
            </InputGroup>
            {errors.token_price_up_coming && (
              <p className="text-carre-red">This field is required</p>
            )}
          </Form.Group>
        </div>
        {(filterMarket?.name === "Upcoming" || filterMarket) && (
          <div className="rounded-2 shadow-lg p-3 mb-5 p-3 my-2 ">
            <TbCash
              style={{ width: "20px", height: "20px" }}
              className="text-carre-purple  d-inline mb-2 mx-1"
            />
            <p className="h4 font-weight-bold d-inline ">
              Property buying process{" "}
            </p>
            <Row>
              <Form.Group as={Col}>
                <label>Expected Share Offering</label>
                <InputGroup className="mb-3">
                  <Form.Control
                    defaultValue={
                      data?.expected_token_offering_date &&
                      new Date(data?.expected_token_offering_date)
                        .toISOString()
                        .substring(0, 10)
                    }
                    className={
                      errors.expected_token_offering_date
                        ? " form-control border-4 border-carre-red"
                        : " form-control false"
                    }
                    {...register("expected_token_offering_date", {
                      required: true,
                      valueAsDate: true,
                    })}
                    type="date"
                  />
                </InputGroup>
                {errors.expected_token_offering_date && (
                  <p className="text-carre-red">
                    This field is required to move to {filterMarket?.name}{" "}
                    market
                  </p>
                )}
              </Form.Group>
            </Row>
          </div>
        )}
        <div>
          <div className="rounded-2 shadow-lg p-3 mb-5 p-3 my-2 ">
            <BsCardImage
              style={{ width: "20px", height: "20px" }}
              className="text-carre-purple  d-inline mb-2 mx-1"
            />
            <p className="h4 font-weight-bold d-inline "> Photos / videos </p>
            <Form.Group as={Col}>
              <div className="App">
                <ImageUploading
                  multiple
                  value={indx}
                  onChange={onChange}
                  maxNumber={maxNumber}
                  dataURLKey="data_url"
                  acceptType={["jpg", "png"]}
                >
                  {({ onImageUpload, isDragging, dragProps }) => (
                    <div className="upload__image-wrapper">
                      <div
                        className={
                          errors.number_building
                            ? "text-carre-purple bg-carre-purple "
                            : ""
                        }
                        style={isDragging ? { color: "red" } : null}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        <div className="rounded-2 shadow-lg p-3 mb-5 p-3 my-2 bg-carre-purple  text-carre-gray text-center  false">
                          <AiOutlineCloudUpload
                            style={{ width: "20", height: "20" }}
                            className={"m-1"}
                          />
                          Upload photos & videos
                        </div>
                        <div>
                          <p className="text-center">Or drag them in</p>
                        </div>
                      </div>
                      {indx?.length > 0 && !hasDefault ? (
                        <p className="text-carre-red">
                          Please select a picture as default
                        </p>
                      ) : (
                        ""
                      )}
                      <div className={"d-flex gap-3 overflow-scroll"}>
                        {indx?.map((image, index) => (
                          <div
                            onClick={() => setAsDefault(image.image_name)}
                            key={index}
                            className="image-item"
                          >
                            <div
                              className={
                                image.is_default
                                  ? "border border-5 p-2 border-carre-purple"
                                  : ""
                              }
                            >
                              <img
                                className="d-inline"
                                src={image.image_url}
                                alt=""
                                width="200"
                                height="120"
                              />
                            </div>
                            <div
                              onClick={() => handleDelete(image)}
                              className="w-100 d-flex justify-content-center bg-carre-red text-carre-gray"
                            >
                              {" "}
                              Delete
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </ImageUploading>
              </div>
            </Form.Group>
          </div>
        </div>
        {updateMarketFields && (
          <>
            <div className="rounded-2 shadow-lg p-3 mb-5 p-3 my-2 ">
              <TbCash
                style={{ width: "20px", height: "20px" }}
                className="text-carre-purple  d-inline mb-2 mx-1"
              />
              <p className="h4 font-weight-bold d-inline ">
                Property management process{" "}
              </p>
              <Row>
                <Form.Group as={Col}>
                  <label>Property management name</label>
                  <input
                    placeholder="Property management name"
                    className={
                      errors.property_management_name
                        ? " form-control border-4 border-carre-red"
                        : " form-control false"
                    }
                    {...register("property_management_name")}
                  />
                  {errors.property_management_name && (
                    <p className="text-carre-red">
                      This field is required to move to {filterMarket?.name}{" "}
                      market
                    </p>
                  )}
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <label>Property management url</label>
                  <input
                    placeholder="Property management url"
                    className={
                      errors.property_management_url
                        ? " form-control border-4 border-carre-red"
                        : " form-control false"
                    }
                    {...register("property_management_url")}
                  />
                  {errors.property_management_url && (
                    <p className="text-carre-red">
                      This field is required to move to {filterMarket?.name}{" "}
                      market
                    </p>
                  )}
                </Form.Group>
              </Row>
            </div>
            {/*insurance*/}
            <div className="rounded-2 shadow-lg p-3 mb-5 p-3 my-2 ">
              <TbCash
                style={{ width: "20px", height: "20px" }}
                className="text-carre-purple  d-inline mb-2 mx-1"
              />
              <p className="h4 font-weight-bold d-inline ">
                Insurance management process{" "}
              </p>
              <Row>
                <Form.Group as={Col}>
                  <label>Insurance name</label>
                  <input
                    placeholder="Insurance name"
                    className={
                      errors.insurance_name
                        ? " form-control border-4 border-carre-red"
                        : " form-control false"
                    }
                    {...register("insurance_name")}
                  />
                  {errors.insurance_name && (
                    <p className="text-carre-red">
                      This field is required to move to {filterMarket?.name}{" "}
                      market
                    </p>
                  )}
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <label>Insurance url</label>
                  <input
                    placeholder="Insurance url"
                    className={
                      errors.insurance_url
                        ? " form-control border-4 border-carre-red"
                        : " form-control false"
                    }
                    {...register("insurance_url")}
                  />
                  {errors.insurance_url && (
                    <p className="text-carre-red">
                      This field is required to move to {filterMarket?.name}{" "}
                      market
                    </p>
                  )}
                </Form.Group>
              </Row>
            </div>
            {/*market descriptione*/}
            <div className="rounded-2 shadow-lg p-3 mb-5 p-3 my-2 ">
              <TbCash
                style={{ width: "20px", height: "20px" }}
                className="text-carre-purple  d-inline mb-2 mx-1"
              />
              <p className="h4 font-weight-bold d-inline ">
                Update Property Market{" "}
              </p>
              <Row>
                <Form.Group as={Col}>
                  <label>Region name (ex:why Saint Martin?)</label>
                  <input
                    placeholder="Region name"
                    className={
                      errors.market_title
                        ? " form-control border-4 border-carre-red"
                        : " form-control false"
                    }
                    {...register("market_title", { required: true })}
                  />
                </Form.Group>
                {errors.market_title && (
                  <p className="text-carre-red">
                    This field is required to move to {filterMarket?.name}{" "}
                    market
                  </p>
                )}
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <label> Add the Local Market Description Here</label>
                  <textarea
                    maxLength={5000}
                    style={{ height: "100px" }}
                    placeholder="Add the Local Market Description Here"
                    className={
                      errors.market_description
                        ? " form-control border-4 border-carre-red"
                        : " form-control false"
                    }
                    {...register("market_description", { required: true })}
                  />
                </Form.Group>
                {errors.market_description && (
                  <p className="text-carre-red">
                    This field is required to move to {filterMarket?.name}{" "}
                    market
                  </p>
                )}
              </Row>
            </div>
            {/*cash divident change*/}
            <div className="rounded-2 shadow-lg p-3 mb-5 p-3 my-2 ">
              <TbCash
                style={{ width: "20px", height: "20px" }}
                className="text-carre-purple  d-inline mb-2 mx-1"
              />
              <p className="h4 font-weight-bold d-inline ">
                Change Property Finances{" "}
              </p>
              <Row>
                <Form.Group as={Col}>
                  <label>
                    Set or change cash dividend payment (per share /day)
                  </label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      className={
                        errors.cash_dividend_payment
                          ? "border-carre-red form-control"
                          : " form-control false"
                      }
                      {...register("cash_dividend_payment", {
                        valueAsNumber: true,
                        required: true,
                      })}
                    />
                  </InputGroup>
                  {errors.cash_dividend_payment && (
                    <p className="text-carre-red">
                      This field is required to move to {filterMarket?.name}{" "}
                      market
                    </p>
                  )}
                </Form.Group>
                <Form.Group as={Col}>
                  <label>Implied Coc Return</label>
                  <InputGroup className="mb-3">
                    {/*<InputGroup.Text id="basic-addon1">$</InputGroup.Text>*/}
                    <Form.Control
                      value={expectedTotalReturn + "%"}
                      disabled="true "
                    />
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <label>
                    Set or change cash vacation payment (per vacation point
                    /day)
                  </label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      className={
                        errors.vacation_token_price
                          ? "border-carre-red form-control"
                          : " form-control false"
                      }
                      {...register("vacation_token_price", {
                        valueAsNumber: true,
                        required: true,
                      })}
                    />
                  </InputGroup>
                  {errors.vacation_token_price && (
                    <p className="text-carre-red">
                      This field is required to move to {filterMarket?.name}{" "}
                      market
                    </p>
                  )}
                </Form.Group>
              </Row>
            </div>
          </>
        )}
        <div className={"d-flex gap-4"}>
          {/*<button*/}
          {/*  disabled={!isDivided || (indx?.length > 0 && !hasDefault)}*/}
          {/*  size="lg"*/}
          {/*  className="mb-3 d-flex justify-content-center border border-carre-purple rounded-2 bg-carre-gray text-carre-purple align-items-center text-center"*/}
          {/*  style={{ width: "100px", height: "40px" }}*/}
          {/*>*/}
          {/*  {" "}*/}
          {/*  Preview*/}
          {/*</button>*/}
          <Button
            disabled={submitDisability() || isLoading}
            size="lg"
            className="mb-3 d-flex justify-content-center rounded-2 bg-carre-purple text-carre-gray align-items-center text-center"
            // style={{ width: "100px", height: "40px" }}
            type="submit"
          >
            {isLoading ? "Please Wait" : "Submit"}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AddProperty;
