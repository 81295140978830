import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

// import { classNames } from "@react-pdf-viewer/core";

function Info({ text, children, classNames, list }) {
  return (
    <OverlayTrigger
      transition={true}
      placement="top"
      overlay={
        <Tooltip className={`${classNames} `}>
          {text}
          <ul className="text-start">
            {list?.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </Tooltip>
      }
      defaultShow={false}
      delay={100}
      flip={true}
    >
      <div
        className="custom-hover"
        style={{ display: "inline-block", cursor: "help" }}
      >
        {children}
      </div>
    </OverlayTrigger>
  );
}

export default Info;
