import React from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import SideBaIcon from "../../assets/img/SideBarIcon.png";
import { useGetAccount } from "../../hooks/useGetAccount";
import "./WalletCard.css"; // Import your CSS styles
import Footer from "../../components/Footer";
import { useGetUserData } from "../../hooks/useGetUserInformation";
import useCheckUserSteps from "../../hooks/checkKycStep";
import { useTabContext } from "./TabContext";

function Wallet() {
  const { data } = useGetAccount();
  const { setShowOnboarding } = useTabContext();
  const hasCompletedSteps = useCheckUserSteps();
  const handleDivClick = (tokenAddress) => {
    const url = `${process.env.REACT_APP_BLOCKCHAIN_WALLET_LINK}${tokenAddress}`;
    window.open(url, "_blank");
  };
  const truncateText = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <div className="mt-5 mt-sm-0">
      {hasCompletedSteps ? (
        <Container className="text-center mt-5">
          <Row>
            <Col>
              <p className="lead text-center text-lg font-semibold mb-4">
                Your profile verification is pending. To proceed, please
                complete your profile verification.
              </p>
              <Button variant="primary" onClick={() => setShowOnboarding(true)}>
                Complete your profile
              </Button>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          <div className={`mb-4`}>
            <Container className="credit-card_number">
              <Row>
                <Col xs={12} md={6}>
                  <p className="fs-5">Blockchain Wallet Address:</p>
                  <a
                    href={data?.wallet_address_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    Blockchain Link
                  </a>
                </Col>
                <Col xs={12} md={6}>
                  <p className="fs-5">Verification Address:</p>
                  <div
                    className="text-carre-blue"
                    onClick={() => handleDivClick(data.verification_token)}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Verification link
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {data?.blockchain_wallet_balance?.result?.map((item, index) => {
            if (item.symbol === "CarréPropertyToken") {
              return (
                <div
                  key={index}
                  onClick={() => handleDivClick(item.tokenAddress)}
                  style={{
                    cursor: "pointer",
                  }}
                  className="blockchain_info"
                >
                  <Row className="mt-1 mb-1 align-items-center">
                    <Col xs={3} md={2} className="d-none d-md-block mt-2">
                      <img
                        className="mx-2"
                        src={SideBaIcon}
                        alt="Sidebar Icon"
                      />
                    </Col>
                    <Col className="mt-2" xs={4} md={6} style={truncateText}>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-${index}`}>{item.name}</Tooltip>
                        }
                      >
                        <span>{item.name}</span>
                      </OverlayTrigger>
                    </Col>
                    <Col className="mt-2" xs={4} md={2} style={truncateText}>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-${index}`}>
                            {item.balance}
                          </Tooltip>
                        }
                      >
                        <span>{item.balance}</span>
                      </OverlayTrigger>
                    </Col>
                    <Col
                      className="mt-2 text-end"
                      xs={4}
                      md={2}
                      style={truncateText}
                    >
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-${index}`}>{item.type}</Tooltip>
                        }
                      >
                        <span>{item.type}</span>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                  <hr />
                </div>
              );
            } else {
              return (
                <Alert className="text-center fs-sm p-2 fw-semibold">
                  No data!
                </Alert>
              );
            }
          })}
        </Container>
      )}
      <Footer />
    </div>
  );
}

export default Wallet;
