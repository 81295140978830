import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/TokenCreation";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useReferal } from "../hooks/useReferal";

const GoogleAuth = () => {
  const [cookies, setCookies, removeCookie] = useCookies();
  const { setRole } = useAuth();
  const storedRef = localStorage?.getItem("ref");

  const { mutate: referalMutation } = useMutation(useReferal, {
    onSuccess: (data) => {
      localStorage?.removeItem("ref");
    },
  });
  const nav = useNavigate();
  useEffect(() => {
    const currentUrl = window.location.href;
    const queryParams = currentUrl.split("?")[1];

    if (queryParams) {
      const getRequestUrl =
        process.env.REACT_APP_API_KEY + `/google-auth/callback?${queryParams}`;

      // Perform your logic with the GET request data as needed
      fetch(getRequestUrl)
        .then((response) => response.json())
        .then((data) => {
          if (storedRef !== null && storedRef !== undefined) {
            referalMutation({
              referral_name: storedRef,
              email: data?.user?.email,
            });
          }
          if (data.error !== undefined) {
            if (data.error !== "Failed to exchange token") {
              toast.error(data.error);
              nav("/");
            }
          }

          if (data.access_token !== undefined) {
            setCookies("token", data.access_token, {
              expires: new Date(Date.now() + 6000000),
            });
          }
          setCookies("name", data.user.first_name, {
            expires: new Date(Date.now() + 6000000),
          });
          setCookies("last_name", data.user.last_name, {
            expires: new Date(Date.now() + 6000000),
          }); // optional data
          setCookies("email", data.user.email, {
            expires: new Date(Date.now() + 6000000),
          });
          setCookies("verified", data.user.verified, {
            expires: new Date(Date.now() + 6000000),
          });
          setCookies("american_citizen", data.user.american_citizen_int, {
            expires: new Date(Date.now() + 6000000),
          });
          setRole(data?.user?.role);
          localStorage.setItem("role", data?.user?.role);
          // Handle the response data as needed
          nav("/user");
        });
    }
  }, []); // Run the effect only once when the component mounts

  return <div></div>;
};
export default GoogleAuth;
