import * as React from "react"

const DashboardIcon = ({props,color="#A0A0AA"}) => (
    <svg
        width={20}
        height={20}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <rect
            x={1}
            y={1}
            width={6.75}
            height={6.75}
            rx={1}
            stroke={color}
            strokeWidth={2}
        />
        <rect
            x={1}
            y={12.25}
            width={6.75}
            height={6.75}
            rx={1}
            stroke={color}
            strokeWidth={2}
        />
        <rect
            x={12.25}
            y={1}
            width={6.75}
            height={6.75}
            rx={1}
            stroke={color}
            strokeWidth={2}
        />
        <rect
            x={12.25}
            y={12.25}
            width={6.75}
            height={6.75}
            rx={1}
            stroke={color}
            strokeWidth={2}
        />
    </svg>
)

export default DashboardIcon
