// PaymentMethodModal.js
import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { BiCopy } from "react-icons/bi";
import api from "../../api";
import { useGetAccount } from "../../hooks/useGetAccount";
import { useTabContext } from "./TabContext";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { FaFileDownload } from "react-icons/fa";
import { MdCurrencyBitcoin, MdCurrencyExchange, MdEmail } from "react-icons/md";
import { TbMailDown } from "react-icons/tb";
import { IoMdCard } from "react-icons/io";
import { PiPaypalLogo } from "react-icons/pi";
import { CiMoneyBill } from "react-icons/ci";
import { useUpdatedUser } from "../../hooks/useUpdateUser";
import { useSkipDeposit } from "../../hooks/useSkipDeposit";
import { v4 as uuidv4 } from "uuid";
import { useCookies } from "react-cookie";

export function PaymentMethod({ isOnBoarding }) {
  const {
    setDepositModalOpen,
    setPaypalButton,
    paypalAmount,
    setPaypalAmount,
  } = useTabContext();
  const { data } = useGetAccount();

  const sendRequest = async (id, type) => {
    const exportType = "DEPOSIT";
    let exportOption;

    if (type === "EMAIL") {
      exportOption = "EMAIL";
    } else if (type === "DOWNLOAD") {
      exportOption = "DOWNLOAD";
    } else if (type === "BOTH") {
      exportOption = "EMAIL,DOWNLOAD";
    }

    try {
      const response = await api.get(
        `/export/pdf?id=${id}&type=${exportType}&export=${exportOption}`,
        { responseType: "blob" } // Specify response type as blob
      );

      // Extract filename from Content-Disposition header
      const contentDisposition = response.headers["content-disposition"];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      const filename = matches && matches[1] ? matches[1] : `${id}.pdf`;

      // Create a temporary link element
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      // Append the link to the body and trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.success("PDF downloaded successfully!");
    } catch (error) {
      toast.error("There was a problem with the request:", error);
    }
  };

  // Call the function with ID and type parameters based on button click
  function handleButtonClick(id, type) {
    sendRequest(id, type);
  }

  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [accountId, setAccountId] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("USD");

  const [showPaymentMethod, setShowPaymentMethod] = useState("");
  // const [amount, setAmount] = useState(0);
  const [amountWithFees, setAmountWithFees] = useState(0);
  const [sendAmount, setSendAmount] = useState(0);
  const [fees, setFees] = useState(0);
  // const [paypalButton, setPaypalButton] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [showAmountAndFee, setShowAmountAndFee] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showWireModal, setShowWireModal] = useState(false);
  const [cardPaymentModalShow, setCardPaymentModalShow] = useState(false);
  const queryClient = useQueryClient();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [wireRequestResponse, setWireRequestResponse] = useState();
  const [cookies, setCookies, removeCookie] = useCookies();

  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
  };

  const [formData, setFormData] = useState({
    cardNumber: "",
    cardCode: "",
    expDate: "",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear the error when the user starts typing
  };

  useEffect(() => {
    // setDepositModalOpen(false);
    queryClient.invalidateQueries({ queryKey: ["account"] });
  }, [cardPaymentModalShow]);
  useEffect(() => {
    if (data) {
      setAccountId(data.id);
    }
  }, [data]);
  const handleCopy = (inputId) => {
    const input = document.getElementById(inputId);
    if (input) {
      input.select();
      document.execCommand("copy");
    }
  };
  const handleModalClose = () => {
    setShowWireModal(false);
    setDepositModalOpen(false);
  };

  const handleModalOpen = () => {
    setShowWireModal(true);
  };
  useEffect(() => {
    if (showPaymentMethod === "CARD") {
      setShowAmountAndFee(false);
      let fee = Number(paypalAmount) / (1 - 0.03499);
      setFees(fee - Number(paypalAmount));
      setAmountWithFees(Number(paypalAmount) + fee - Number(paypalAmount));

      setSendAmount(Number(paypalAmount) + fee);
    } else if (showPaymentMethod === "PAYPAL") {
      setShowAmountAndFee(true);
      let fee = (Number(paypalAmount) + 0.49) / (1 - 0.0499);
      setFees(fee - Number(paypalAmount));
      setAmountWithFees(Number(paypalAmount) + fee - Number(paypalAmount));

      setSendAmount(paypalAmount + fee);
    } else if (showPaymentMethod === "WIRE") {
      setShowAmountAndFee(false);
      setFees(0);
      setAmountWithFees(paypalAmount);
      setSendAmount(paypalAmount);
    } else if (showPaymentMethod === "COINBASE") {
      setShowAmountAndFee(false);
      setFees(0);
      setAmountWithFees(paypalAmount);
      setSendAmount(paypalAmount);
    }
  }, [showPaymentMethod, paypalAmount]);

  function handleDepositeModal() {
    setIsConfirmationModalOpen(false);
    if (!showPaymentMethod) {
      setErrorMessage("Please select a payment method.");
      return;
    }

    setErrorMessage("");

    if (showPaymentMethod === "CARD") {
      const newErrors = {};

      if (showPaymentMethod === "CARD") {
        if (!formData.cardNumber || formData.cardNumber.length !== 16) {
          newErrors.cardNumber = "Please enter a valid 16-digit card number";
        }

        if (!formData.cardCode || formData.cardCode.length !== 3) {
          newErrors.cardCode = "Please enter a valid 3-digit card code";
        }

        const isValidMonth =
          !isNaN(formData.expMonth) &&
          formData.expMonth >= 1 &&
          formData.expMonth <= 12;
        const isValidYear = !isNaN(formData.expYear);

        if (!isValidMonth || !isValidYear) {
          newErrors.expDate = "Please enter a valid expiration date";
        }
      }

      setFormErrors(newErrors);

      // If there are no errors, proceed with the form submission logic
      if (Object.keys(newErrors).length === 0) {
        setDepositModalOpen(false);
        const expDate = `${formData.expMonth?.padStart(
          2,
          "0"
        )}${formData.expYear?.slice(-2)}`;
        api
          .post(
            `/authorize-charge
`,
            {
              amount: Number(paypalAmount),
              account_id: accountId,
              card_number: formData.cardNumber,
              card_code: formData.cardCode,
              exp_date: expDate,
            }
          )
          .then((res) => {
            setClientSecret(res?.data?.clientSecret);
            setPaymentIntentId(res?.data?.id);
            setDepositModalOpen(false);
            toast.success("Transaction Successful");

            queryClient.invalidateQueries({ queryKey: ["user_data"] });
          })
          .catch((error) => {
            // Log the error here
            toast.error("Something went wrong!");

            // You can also handle the error further if needed
            // For example, show a user-friendly message or perform other actions
          });
      }

      setDepositModalOpen(true);
    } else if (showPaymentMethod === "PAYPAL") {
      setDepositModalOpen(false);
      setPaypalButton(true);
    } else if (showPaymentMethod === "WIRE") {
      setDepositModalOpen(true);
      api
        .post(`/wire/request`, {
          amount: Number(paypalAmount),
          account_id: accountId,
          currency: selectedCurrency,
        })
        .then((res) => {
          setWireRequestResponse(res.data);
          toast.success("Wire deposit request created successfully");
        });
      handleModalOpen();
    } else if (showPaymentMethod === "COINBASE") {
      setDepositModalOpen(false);
      api
        .post(`/coinbase-charge`, {
          amount: Number(paypalAmount),
          account_id: accountId,
        })
        .then((res) => {
          const url = res?.data.hosted_url;
          if (url) {
            window.location.href = url;
          }
        });
    }
  }

  const { mutate } = useMutation(useSkipDeposit, {
    onSuccess: (data) => {
      toast.success("Step skipped successful");
      queryClient.invalidateQueries({ queryKey: ["user_data"] });
    },
    onError: (data) => {},
  });
  return (
    <div>
      <div>
        <Form>
          {showPaymentMethod === "" || undefined ? (
            <>
              <div className="mb-4 mt-2">
                {!isOnBoarding && (
                  <p className="fs-lg">Select Payment Method</p>
                )}
                <p className="fs-md">Choose how you would like to deposit .</p>
              </div>
              <div className="justify-content-between mt-2 gx-4">
                <Button
                  active={showPaymentMethod === "CARD"}
                  onClick={() => setShowPaymentMethod("CARD")}
                  className="mr-2 mb-2 w-100 justify-content-between text-dark"
                  variant="outline-carre-gray-bg"
                >
                  <IoMdCard size={25} />
                  <span style={{ fontSize: "0.875rem", fontWeight: 500 }}>
                    Debit and Credit Cards
                  </span>
                </Button>

                <Button
                  active={showPaymentMethod === "COINBASE"}
                  onClick={() => setShowPaymentMethod("COINBASE")}
                  className="mr-2 mb-2 w-100 justify-content-between text-dark"
                  variant="outline-carre-gray-bg"
                >
                  <MdCurrencyBitcoin size={25} />
                  <span style={{ fontSize: "0.875rem", fontWeight: 500 }}>
                    Crypto Currency
                  </span>
                </Button>

                <Button
                  active={showPaymentMethod === "PAYPAL"}
                  onClick={() => setShowPaymentMethod("PAYPAL")}
                  className="mr-2 mb-2 w-100 justify-content-between text-dark"
                  variant="outline-carre-gray-bg"
                >
                  <PiPaypalLogo size={25} />
                  <span style={{ fontSize: "0.875rem", fontWeight: 500 }}>
                    Pay Pal{" "}
                  </span>
                </Button>

                <Button
                  active={showPaymentMethod === "WIRE"}
                  onClick={() => setShowPaymentMethod("WIRE")}
                  className="mr-2 mb-2 w-100 justify-content-between text-dark"
                  variant="outline-carre-gray-bg"
                >
                  <CiMoneyBill size={25} />
                  <span style={{ fontSize: "0.875rem", fontWeight: 500 }}>
                    Wire
                  </span>
                </Button>
              </div>
            </>
          ) : (
            <>
              {" "}
              <Button
                onClick={() => {
                  setShowPaymentMethod("");
                  setShowAmountAndFee(false);
                }}
                className="mr-2 mb-2 w-100 text-dark justify-content-between"
                variant="outline-carre-gray-bg"
              >
                {" "}
                <MdCurrencyExchange size={25} />{" "}
                <span style={{ fontSize: "0.875rem", fontWeight: 500 }}>
                  {" "}
                  Change Payment Method{" "}
                </span>
              </Button>
              <div className="fs-sm text-muted text-start w-100">
                {" "}
                Selected Payment Method:{" "}
                <span className="text-dark fs-sm">
                  {" "}
                  {showPaymentMethod === "CARD"
                    ? "Credit Card"
                    : showPaymentMethod === "PAYPAL"
                    ? "PayPal"
                    : showPaymentMethod === "COINBASE"
                    ? "Cryptocurrencies"
                    : showPaymentMethod === "WIRE"
                    ? "Wire"
                    : "Unknown"}
                </span>
              </div>
            </>
          )}

          {(showPaymentMethod === "CARD" ||
            showPaymentMethod === "PAYPAL" ||
            showPaymentMethod === "COINBASE" ||
            showPaymentMethod === "WIRE") && (
            <>
              <label htmlFor="Amount" className="form-label">
                Amount
              </label>
              <InputGroup>
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  onWheel={(e) => e.target.blur()}
                  type="number"
                  className="form-control"
                  id="amount"
                  value={paypalAmount}
                  required
                  onChange={(e) => setPaypalAmount(e.target.value)}
                  placeholder="Enter amount"
                />
              </InputGroup>
            </>
          )}
          {showPaymentMethod === "CARD" && (
            <>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="commission" className="form-label">
                    Credit Card Fees
                  </label>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      type="text"
                      id="commission"
                      name="commission"
                      value={(
                        paypalAmount / (1 - 0.0485) -
                        paypalAmount
                      ).toFixed(2)}
                      readOnly
                    />
                  </InputGroup>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="totalAmount" className="form-label">
                    Total Amount
                  </label>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      type="text"
                      id="totalAmount"
                      name="totalAmount"
                      value={(parseFloat(paypalAmount) / (1 - 0.0485)).toFixed(
                        2
                      )}
                      readOnly
                    />
                  </InputGroup>
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="cardNumber" className="form-label">
                  Card Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="cardNumber"
                  name="cardNumber"
                  value={formData.cardNumber}
                  onChange={handleInputChange}
                />
              </div>

              <div className="row">
                <div className="col-md-4 mb-3">
                  <label htmlFor="expMonth" className="form-label">
                    Expiration Month
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="expMonth"
                    name="expMonth"
                    placeholder="MM"
                    maxLength="2"
                    value={formData.expMonth}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <label htmlFor="expYear" className="form-label">
                    Expiration Year
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="expYear"
                    name="expYear"
                    placeholder="YY"
                    maxLength="2"
                    value={formData.expYear}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-4 mb-3">
                  <label htmlFor="cardCode" className="form-label">
                    CVC
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="cardCode"
                    name="cardCode"
                    value={formData.cardCode}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {Object.keys(formErrors).length > 0 && (
                <div className="alert alert-danger" role="alert">
                  <ul>
                    {Object.values(formErrors).map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}
          {showPaymentMethod === "WIRE" && (
            <>
              <Form.Group>
                <Form.Label className="mt-1">Select Currency</Form.Label>
                <div>
                  <Form.Check
                    type="radio"
                    inline
                    label="USD"
                    value="USD"
                    checked={selectedCurrency === "USD"}
                    onChange={handleCurrencyChange}
                  />
                  <Form.Check
                    type="radio"
                    label="EUR"
                    inline
                    value="EUR"
                    checked={selectedCurrency === "EUR"}
                    onChange={handleCurrencyChange}
                  />
                </div>
              </Form.Group>
            </>
          )}
          {showAmountAndFee && (
            <>
              <label htmlFor="fees" className="form-label">
                Fees
              </label>
              <InputGroup>
                <InputGroup.Text>$</InputGroup.Text>

                <Form.Control
                  size="sm"
                  className="me-auto"
                  placeholder="Amount"
                  value={fees.toFixed(2)}
                  readOnly={true}
                />
              </InputGroup>
              <label htmlFor="netAmount" className="form-label">
                Net Amount{" "}
              </label>
              <InputGroup>
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  size="sm"
                  className="me-auto"
                  placeholder="Amount"
                  value={amountWithFees.toFixed(2)}
                  readOnly={true}
                />
              </InputGroup>
            </>
          )}
        </Form>
        {errorMessage && <p className=" text-carre-red">{errorMessage}</p>}
        {showPaymentMethod !== "WIRE" && (
          <p className="text-muted small">
            The fees charged during transfers are from the vendors, not from
            Carré
          </p>
        )}
        <div className="d-flex justify-content-between">
          {isOnBoarding ? (
            <Button
              variant="secondary 50"
              onClick={() => {
                setDepositModalOpen(false);
                mutate();
              }}
              size="sm"
            >
              Skip for now
            </Button>
          ) : (
            <Button
              variant="secondary w-25"
              onClick={() => {
                setDepositModalOpen(false);
              }}
              size="sm"
            >
              Close
            </Button>
          )}
          <Button
            type={"submit"}
            onClick={(e) => {
              e.preventDefault();
              setIsConfirmationModalOpen(true);
              if (process.env.REACT_APP_IS_PRODUCTION !== "false") {
                setCookies("event_id", uuidv4());
                // eslint-disable-next-line no-undef
                fbq(
                  "track",
                  "AddPaymentInfo",
                  {},
                  {
                    event_id: cookies["event_id"],
                    external_id: JSON.parse(localStorage.getItem("utmParams"))
                      ?.external_id,
                  }
                );
              }
            }}
            size="sm"
            variant="carre-blue w-50 text-white"
            disabled={
              (showPaymentMethod !== "WIRE" &&
                (!paypalAmount || paypalAmount === 0)) ||
              !showPaymentMethod ||
              showPaymentMethod === ""
            }
          >
            {!showPaymentMethod || showPaymentMethod === ""
              ? "Selection Required"
              : showPaymentMethod !== "WIRE" &&
                (!paypalAmount || paypalAmount === 0)
              ? "Amount Required"
              : "Process"}
          </Button>
        </div>
      </div>

      <Modal
        show={showWireModal}
        onHide={handleModalClose}
        centered
        size={"lg"}
        style={{ zIndex: 1110 }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Form>
              <Form.Group>
                <Form.Label className="fw-light">Recipient name:</Form.Label>
                <InputGroup>
                  <Form.Control
                    size="sm"
                    type="text"
                    value="CARRE INC"
                    readOnly
                    id="input1"
                  />
                  <Button
                    style={{ cursor: "pointer" }}
                    onClick={() => handleCopy("input1")}
                  >
                    <BiCopy />
                  </Button>
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <Form.Label className="fw-light">
                  Recipient account number:
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    size="sm"
                    type="text"
                    value={
                      selectedCurrency === "USD"
                        ? "953699870"
                        : "BE92 9678 4484 6623"
                    }
                    readOnly
                    id="input2"
                  />
                  <Button
                    style={{ cursor: "pointer" }}
                    onClick={() => handleCopy("input2")}
                  >
                    <BiCopy />
                  </Button>
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <Form.Label className="fw-light">
                  Recipient bank SWIFT number:
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    size="sm"
                    type="text"
                    value={
                      selectedCurrency === "USD" ? "CHASUS33" : "TRWIBEB1XXX"
                    }
                    readOnly
                    id="input3"
                  />
                  <Button
                    style={{ cursor: "pointer" }}
                    onClick={() => handleCopy("input3")}
                  >
                    <BiCopy />
                  </Button>
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <Form.Label className="fw-light">
                  Recipient mailing address:
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    size="sm"
                    type="text"
                    value={
                      selectedCurrency === "USD"
                        ? "370 NE 75th Street, Unit 126, Miami, Florida, United States 33138"
                        : "Rue du Trône 100, 3rd floor Brussels 1050 Belgium "
                    }
                    readOnly
                    id="input4"
                  />
                  <Button
                    style={{ cursor: "pointer" }}
                    onClick={() => handleCopy("input4")}
                  >
                    <BiCopy />
                  </Button>
                </InputGroup>
              </Form.Group>
            </Form>
          </Row>

          {wireRequestResponse && (
            <>
              <Form.Group>
                <Form.Label className="fw-light">
                  Transaction number:
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    size="sm"
                    type="text"
                    value={wireRequestResponse?.transaction_id}
                    readOnly
                    id="input6"
                  />
                  <Button
                    style={{ cursor: "pointer" }}
                    onClick={() => handleCopy("input6")}
                  >
                    <BiCopy />
                  </Button>
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <Form.Label className="fw-light">Amount :</Form.Label>
                <InputGroup size="sm">
                  <Form.Control
                    size="sm"
                    type="text"
                    value={wireRequestResponse?.amount}
                    readOnly
                    id="input6"
                  />
                  <InputGroup.Text>
                    {wireRequestResponse?.requested_currency}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <div>
                <div className="d-flex mt-2">
                  <Button
                    variant="primary"
                    size="sm"
                    className="mr-3 w-50 fw-medium"
                    onClick={() =>
                      handleButtonClick(wireRequestResponse?.id, "DOWNLOAD")
                    }
                  >
                    <FaFileDownload className="mx-2" size={13} /> Download
                  </Button>
                  <Button
                    style={{ marginLeft: "10px" }}
                    variant="secondary"
                    size="sm"
                    className="fw-medium w-50"
                    onClick={() =>
                      handleButtonClick(wireRequestResponse?.id, "EMAIL")
                    }
                  >
                    <MdEmail className="mx-2" size={13} /> Send to Email
                  </Button>
                </div>
                <Button
                  variant="success"
                  size="sm"
                  className="fw-medium mt-2 w-100"
                  onClick={() =>
                    handleButtonClick(wireRequestResponse?.id, "BOTH")
                  }
                >
                  <TbMailDown className="mx-2" size={13} /> Download & Send to
                  Email
                </Button>
              </div>
            </>
          )}

          {/*<a*/}
          {/*  href={selectedCurrency === "USD" ? ExampleDoc : EuroDoc}*/}
          {/*  download="Carre_wire_transfer"*/}
          {/*  target="_blank"*/}
          {/*  rel="noreferrer"*/}
          {/*>*/}
          {/*  <Button*/}
          {/*    variant="carre-blue"*/}
          {/*    size="sm"*/}
          {/*    className="fw-medium mt-3 w-100"*/}
          {/*  >*/}
          {/*    <FaFileDownload className="mx-2" size={13} /> Download Pdf*/}
          {/*    Document*/}
          {/*  </Button>*/}
          {/*</a>*/}
        </Modal.Body>
      </Modal>
      <Modal
        style={{ zIndex: 9999 }}
        centered
        show={isConfirmationModalOpen}
        onHide={() => setIsConfirmationModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deposit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to deposit ${paypalAmount}
            &nbsp;into the account?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setIsConfirmationModalOpen(false)}
          >
            No
          </Button>
          <Button variant="primary" onClick={() => handleDepositeModal()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
