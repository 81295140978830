import { useQuery } from "react-query";
import api from "../api";

export const useGetUserTransfers = (property_id) => {
  return useQuery(["transfers"], () => {
    if (property_id !== undefined) {
      return api
        .get(`/transfer/${property_id}?for_user=true`)
        .then((res) => res.data);
    } else {
    }
    return api.get("/transfer").then((res) => res.data);
  });
};
