import React, { useState } from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa";

function Wishlist() {
  const [isLiked, setIsLiked] = useState(false);

  return (
    <>
      <h2>Wishlist</h2>
      <div className="p-3 bg-white border rounded border-secondary">
        <div className="row g-0">
          <div className="col-lg-3">
            {/* Image */}
            <div className="">
              <img
                src="https://via.placeholder.com/260x210"
                alt="placeholder"
                className="rounded img-fluid w-100"
              />

              <div className="mt-3 d-md-flex text-purple aligm-items-between justify-content-md-between">
                <div className="me-3">
                  <p className="mb-1 text-muted">Share Price:</p>
                  <p className="text-carre-purple fw-bold">$510.00</p>
                </div>
                <div>
                  <p className="mb-1 text-muted">Expected Share Return:</p>
                  <p className="text-carre-purple fw-bold">$1.93</p>
                </div>
              </div>
            </div>
          </div>
          {/* Card text, name, address */}
          <div className="col-md-9">
            <div className="m-md-3">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h4 className="fw-bold">1510 Castle Hill Ave</h4>
                  <p className="mb-2 card-text text-muted">Bronx, NY, 10462</p>
                </div>
                <button
                  className="heart-button btn btn-link"
                  onClick={() => setIsLiked(!isLiked)}
                  aria-label={isLiked ? "Unlike" : "Like"}
                >
                  {isLiked ? (
                    <FaHeart
                      className="text-carre-purple"
                      size={35}
                      style={{
                        backgroundColor: "white",
                        borderRadius: "50%",
                        padding: 8,
                        boxShadow: "0px 0px 10px #ccc",
                      }}
                    />
                  ) : (
                    <FaRegHeart
                      className="text-carre-purple"
                      size={35}
                      style={{
                        backgroundColor: "white",
                        borderRadius: "50%",
                        padding: 8,
                        boxShadow: "0px 0px 10px #ccc",
                      }}
                    />
                  )}
                </button>
              </div>
              <p className="card-text text-muted mt-md-4 mt-lg-0">
                Lorem tincidunt lectus vitae id vulputate diam quam. Imperdiet
                non scelerisque turpis sed etiam ultrices. Blandit mollis
                dignissim egestas consectetur porttitor. Vulputate dolor
                pretium, dignissim eu augue sit ut convallis. Lectus est, magna
                urna feugiat sed ultricies sed in lacinia. Fusce potenti sit id
                pharetra vel ornare. Vestibulum sed tellus ullamcorper arcu.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Wishlist;
