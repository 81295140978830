import { useQuery } from "react-query";
import api from "../api";

export const useGetVacationDetails = () => {
  return useQuery(["vacation"], () => {
    return api.get("vacation").then((res) => res.data);
  });
};

export const useGetVacationOrderBook = () => {
  return useQuery(["vacation-order-book"], () => {
    return api.get("vacation/order/order-book").then((res) => res.data);
  });
};

export const useGetVacationOrderDetails = () => {
  return useQuery(["vacation-order-details"], () => {
    return api.get("vacation/order/order-detail").then((res) => res.data);
  });
};

export const useCreateVacationOrder = (data) => {
  return api.post(`/vacation/order/create-order`, data).then((res) => res.data);
};

export const useGetVacationTransfers = (for_user = null) => {
  return useQuery(["vacation-transfer", { for_user }], () => {
    const params = for_user ? { for_user } : {};
    return api.get("vacation/transfer", { params }).then((res) => res.data);
  });
};

export const useGetVacationOrders = (status, type) => {
  return useQuery(["vacation-orders", { status, type }], () => {
    const params = {};

    if (status) {
      params.status = status;
    }
    if (type) {
      params.type = type;
    }
    return api.get("vacation/order", { params }).then((res) => res.data);
  });
};

export const useUserCancelVacationOrder = (id) => {
  return api.put(`/vacation/order/${id}/cancel`).then((res) => res.data);
};
