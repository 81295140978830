import { useState } from "react";
import api from "../api";

export const useSendStripePaymentId = () => {
  const [result, setResult] = useState(null);

  const sendStripePaymentId = async (id) => {
    try {
      const response = await api.put(`/account/${id}`);
      const data = await response.data;
      setResult(data);
    } catch (error) {
      // Handle the error if needed
    }
  };

  return { sendStripePaymentId, result };
};
