import { useQuery } from "react-query";
import api from "../api";

export const useGetDividentTransfer = (property_id) => {
  return useQuery(["transfers"], () => {
    if (property_id !== undefined) {
      return api.get(`/dividend-transfer?property_id=${property_id}`).then((res) => res.data);
    } else {
    }
    return api.get("/dividend-transfer").then((res) => res.data);
  });
};


export const useGetVacationDividentTransfer = (property_id) => {
  return useQuery(["vacation-transfers"], () => {
    if (property_id !== undefined) {
      return api.get(`/vacation-token-transfer?property_id=${property_id}`).then((res) => res.data);
    } else {
    }
    return api.get("/vacation-token-transfer").then((res) => res.data);
  });
};



