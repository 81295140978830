import React from "react"

const ICloseIcon = (props) => (
    <svg
        width={16}
        height={14}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.71.293a1 1 0 0 1 0 1.414l-12 12c-.39.391-1.03.391-1.42 0a1 1 0 0 1 0-1.414l12-12a1.006 1.006 0 0 1 1.42 0Z"
            fill="#9691A4"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.29.293a1.006 1.006 0 0 1 1.42 0l12 12a1 1 0 1 1-1.42 1.414l-12-12a1 1 0 0 1 0-1.414Z"
            fill="#9691A4"
        />
    </svg>
)

export default ICloseIcon
