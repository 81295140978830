import React, { useState } from "react";
import Navbar from "../components/NavBar";
import Footer from "../components/Footer";
import { Container, Button, Alert, Accordion } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";

// Import the FAQ data JSON file
import data from "./Property/faqData.json";
import { useTabContext } from "./Dashboard/TabContext";

export default function HelpCenter() {
  const [searchTerm, setSearchTerm] = useState(undefined);
  const [searchResults, setSearchResults] = useState(data);
  const [suggestions, setSuggestions] = useState([]); // State for search suggestions
  const [showSuggestions, setShowSuggestions] = useState(false); // State for suggestion dropdown visibility
  const { setSignUpModalOpen } = useTabContext();
  const [faqData, setFaqData] = useState(data); // Initialize with imported FAQ data

  // Function to handle the search input
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    // Filter FAQ items that match the search term
    const results = Object.keys(faqData).reduce((acc, section) => {
      const sectionResults = faqData[section].filter(
        (item) =>
          item.question.toLowerCase().includes(term) ||
          item.answer.toLowerCase().includes(term)
      );
      if (sectionResults.length > 0) {
        acc[section] = sectionResults;
      }
      return acc;
    }, {});

    setSearchResults(results);

    // Generate search suggestions
    const suggestionList = Object.keys(faqData).reduce((acc, section) => {
      const sectionSuggestions = faqData[section]
        .filter(
          (item) =>
            item.question.toLowerCase().includes(term) ||
            item.answer.toLowerCase().includes(term)
        )
        .map((item) => item.question);

      return [...acc, ...sectionSuggestions];
    }, []);

    setSuggestions(suggestionList);

    // Show or hide the suggestion dropdown based on the search input
    setShowSuggestions(!!term);
  };

  const highlightSearchTerm = (text) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, "gi");
    return text.split(regex).map((chunk, index) =>
      index % 2 === 0 ? (
        chunk
      ) : (
        <span className="bg-carre-blue" key={index}>
          {chunk}
        </span>
      )
    );
  };

  return (
    <>
      <Navbar />
      <Container
        style={{
          overflow: "hidden",
          marginTop: "40px",
        }}
      >
        <div>
          <div className="input-group mt-5">
            <input
              type="text"
              placeholder="What are you looking for?"
              className="form-control shadow mb-4"
              style={{
                borderRadius: "8px",
                padding: "10px",
                backgroundColor: "transparent",
                border: "none",
              }}
              value={searchTerm}
              onChange={handleSearch}
              onClick={() => setShowSuggestions(true)} // Show suggestions on click
              onBlur={() => setShowSuggestions(false)} // Hide suggestions on blur
            />
          </div>
          {/*{showSuggestions && suggestions.length > 0 && (*/}
          {/*  <div className="suggestion-dropdown">*/}
          {/*    <ul>*/}
          {/*      {suggestions.map((suggestion, index) => (*/}
          {/*        <li key={index}>{suggestion}</li>*/}
          {/*      ))}*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*)}*/}
          {Object.keys(searchResults).length > 0 ? (
            Object.keys(searchResults).map((section, index) => (
              <div key={index}>
                <p
                  className="fw-normal fs-4 mx-3
                    text-carre-blue"
                >
                  {section}
                </p>

                <Accordion defaultActiveKey="0" flush>
                  {searchResults[section].map((item, itemIndex) => (
                    <Accordion.Item key={itemIndex} eventKey={itemIndex}>
                      <Accordion.Header className="fs-sm fw-normal">
                        <p
                          className="fw-normal fs-lg"
                          style={{ marginTop: -10, marginBottom: -10 }}
                        >
                          {item.question}
                        </p>
                      </Accordion.Header>
                      <Accordion.Body>
                        {item.isPoint === true ? (
                          <>
                            {item?.answer}
                            <ul>
                              {item?.points?.map((point, index) => (
                                <li key={index}>{point}</li>
                              ))}
                            </ul>
                          </>
                        ) : (
                          highlightSearchTerm(item.answer)
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            ))
          ) : (
            <div>
              <Alert className="text-center fs-sm p-2 fw-semibold">
                No question found for that search.
              </Alert>
            </div>
          )}
        </div>
      </Container>
      <Footer />
    </>
  );
}
