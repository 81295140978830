import { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useAcceptWireDeposit } from "../hooks/useAcceptWireDeposit";
import { toast } from "react-toastify";
import { useGetUserForWire } from "../hooks/useGetUserForWire";

const AdminAcceptWireDeposit = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm();

  const { mutate, isLoading } = useAcceptWireDeposit();

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(null);
  const [isNewOrder, setIsNewOrder] = useState(true);
  const [userId, setUserId] = useState("");
  const [showAllFields, setShowAllFields] = useState(false);

  const { data, isError, error, isSuccess } = useGetUserForWire(
    userId,
    isNewOrder
  );
  useEffect(() => {
    isError && toast.error(error?.response.data.error);
  }, [isError]);
  useEffect(() => {
    if (data && data.deposit_transfer) {
      reset({
        email: email,
        transaction_id: data.deposit_transfer.transaction_id || "",
        commission: data.deposit_transfer.commission || 0,
        amount: data.deposit_transfer.amount || "",
        requested_amount: data.deposit_transfer.requested_amount || "",
        currency: data.deposit_transfer.currency || "",
        exchange_rate: data.deposit_transfer.exchange_rate || "",
      });
    }
  }, [data, reset]);
  const onSubmitUserId = (data) => {
    setUserId(data.userId);
  };
  useEffect(() => {
    if (isSuccess) {
      setShowAllFields(true);
    } else {
      setShowAllFields(false);
    }
  }, [isSuccess]);

  const onSubmit = (data) => {
    setFormData(data);
    setShowModal(true);
  };

  const handleConfirm = () => {
    mutate(
      { ...formData, isNewOrder },
      {
        onSuccess: () => {
          toast.success("Successfully completed");
          reset();
          setShowAllFields(false);
          setUserId("");
        },
        onError: (error) => {
          toast.error(error?.response.data.error);
        },
      }
    );

    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleNewOrder = () => {
    setIsNewOrder(true);
    setUserId("");
    setShowAllFields(false);
  };

  const handleExistingOrder = () => {
    setIsNewOrder(false);
    setUserId("");
    setShowAllFields(false);
  };
  const { email, first_name, last_name, address, tel } =
    data?.user_information || {};

  return (
    <div>
      <div className="mb-4">
        <Button
          variant={isNewOrder ? "primary" : "outline-primary"}
          className="me-2"
          onClick={handleNewOrder}
        >
          New
        </Button>
        <Button
          variant={!isNewOrder ? "primary" : "outline-primary"}
          onClick={handleExistingOrder}
        >
          Existing
        </Button>
      </div>

      <Form onSubmit={handleSubmit(onSubmitUserId)}>
        <Form.Group controlId="formUserId">
          <Form.Label>{isNewOrder ? "User ID" : "Transaction Id"}</Form.Label>
          <Form.Control
            {...register("userId")}
            type="text"
            placeholder="Enter user ID"
          />
          {errors.userId && (
            <Form.Text className="text-danger">User ID is required</Form.Text>
          )}
        </Form.Group>
        <Button variant="primary" type="submit" className="w-100 mt-3">
          Get information
        </Button>
      </Form>

      <div>
        <h1>User Information</h1>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" value={email || ""} readOnly />
        </Form.Group>
        <Form.Group controlId="first_name">
          <Form.Label>First Name</Form.Label>
          <Form.Control type="text" value={first_name || ""} readOnly />
        </Form.Group>
        <Form.Group controlId="last_name">
          <Form.Label>Last Name</Form.Label>
          <Form.Control type="text" value={last_name || ""} readOnly />
        </Form.Group>
        <Form.Group controlId="city_name">
          <Form.Label>City Name</Form.Label>
          <Form.Control type="text" value={address?.city_name || ""} readOnly />
        </Form.Group>
        <Form.Group controlId="tel">
          <Form.Label>Telephone</Form.Label>
          <Form.Control type="tel" value={tel || ""} readOnly />
        </Form.Group>
      </div>
      {showAllFields && (
        <Row className="justify-content-md-center mt-4">
          <Col xs={12} md={12}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId="formTransactionID">
                <Form.Label>Transaction ID</Form.Label>
                <Form.Control
                  {...register("transaction_id", {
                    required: true,
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Only numbers are allowed",
                    },
                  })}
                  type="text"
                  placeholder="Enter transaction ID"
                />
                {errors.transaction_id && (
                  <Form.Text className="text-danger">
                    {errors.transaction_id.message ||
                      "Transaction ID is required"}
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group controlId="formCommission">
                <Form.Label>Commission</Form.Label>
                <Form.Control
                  {...register("commission", {
                    required: true,
                    valueAsNumber: true,

                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Only numbers are allowed",
                    },
                  })}
                  type="text"
                  placeholder="Enter commission"
                />
                {errors.commission && (
                  <Form.Text className="text-danger">
                    {errors.commission.message || "Commission is required"}
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group controlId="formAmount">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  {...register("amount", {
                    required: true,
                    valueAsNumber: true,
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Only numbers are allowed",
                    },
                  })}
                  type="string"
                  placeholder="Enter amount"
                />
                {errors.amount && (
                  <Form.Text className="text-danger">
                    {errors.amount.message || "Amount is required"}
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group controlId="formRequestedAmount">
                <Form.Label>Requested Amount</Form.Label>
                <Form.Control
                  {...register("requested_amount", {
                    required: true,
                    valueAsNumber: true,

                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Only numbers are allowed",
                    },
                    validate: (value) =>
                      parseFloat(value) !== 0 || "Requested Amount cannot be 0",
                  })}
                  type="string"
                  placeholder="Enter requested amount"
                />
                {errors.requested_amount && (
                  <Form.Text className="text-danger">
                    {errors.requested_amount.message}
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group controlId="formCurrency">
                <Form.Label>Currency</Form.Label>
                <div>
                  <Form.Check
                    inline
                    type="radio"
                    label="USD"
                    value="USD"
                    name="currency"
                    {...register("currency")}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="EUR"
                    value="EUR"
                    name="currency"
                    {...register("currency")}
                  />
                </div>
                {errors.currency && (
                  <Form.Text className="text-danger">
                    Currency is required
                  </Form.Text>
                )}
              </Form.Group>
              {isNewOrder && (
                <Form.Group controlId="formCurrency">
                  <Form.Label>Requested Currency</Form.Label>
                  <div>
                    <Form.Check
                      inline
                      type="radio"
                      label="USD"
                      value="USD"
                      name="currency"
                      {...register("requested_currency", { required: true })}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="EUR"
                      value="EUR"
                      name="currency"
                      {...register("requested_currency", { required: true })}
                    />
                  </div>
                  {errors.currency && (
                    <Form.Text className="text-danger">
                      Currency is required
                    </Form.Text>
                  )}
                </Form.Group>
              )}
              <Form.Group controlId="formExchangeRate">
                <Form.Label>Exchange Rate</Form.Label>
                <Form.Control
                  {...register("exchange_rate", {
                    required: true,
                    valueAsNumber: true,

                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Only numbers are allowed",
                    },
                  })}
                  type="string"
                  placeholder="Enter exchange rate"
                />
                {errors.exchange_rate && (
                  <Form.Text className="text-danger">
                    {errors.exchange_rate.message ||
                      "Exchange Rate is required"}
                  </Form.Text>
                )}
              </Form.Group>
              {isNewOrder && (
                <>
                  {" "}
                  <Form.Group controlId="formRequestedAmount">
                    <Form.Label>Requested Amount</Form.Label>
                    <Form.Control
                      {...register("requested_amount", {
                        valueAsNumber: true,
                      })}
                      type="number"
                      placeholder="Enter requested amount"
                    />
                    {errors.requested_amount && (
                      <Form.Text className="text-danger">
                        Requested Amount is required
                      </Form.Text>
                    )}
                  </Form.Group>
                </>
              )}

              <Form.Group controlId="formExchangeRate">
                <Form.Label>Exchange Rate</Form.Label>
                <Form.Control
                  {...register("exchange_rate", {
                    pattern: {
                      value: /^\d*\.?\d*$/,
                      message: "Invalid exchange rate format",
                    },
                    valueAsNumber: true,
                  })}
                  type="text"
                  placeholder="Enter exchange rate"
                />
                {errors.exchange_rate && (
                  <Form.Text className="text-danger">
                    {errors.exchange_rate.message ||
                      "Exchange Rate is required"}
                  </Form.Text>
                )}
              </Form.Group>

              <Button
                variant="primary"
                type="submit"
                className="mt-4 w-100"
                disabled={isLoading}
              >
                {isLoading ? "Please Wait" : "Submit"}
              </Button>
            </Form>
          </Col>
        </Row>
      )}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to deposit this amount {formData?.amount}{" "}
          {formData?.currency} to this account {email} with this transaction id{" "}
          {formData?.transaction_id}?{/* Display confirmation details here */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminAcceptWireDeposit;
