import { useMutation } from "react-query";
import api from "../api";

export const useUploadFile = () => {
  const uploadFile = async ({ file, type }) => {
    const formData = new FormData();
    formData.append("file", file);

    const queryParams = new URLSearchParams({ type });
    const queryParamString = queryParams.toString();

    const response = await api.post(`/user/kyc?${queryParamString}`, formData);
    return response.data;
  };

  return useMutation(uploadFile);
};
