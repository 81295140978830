import React from "react";
import NavBar from "./NavBar";
import { Container } from "react-bootstrap";
import Footer from "./Footer";

const TermsOfService = () => {
  return (
    <div>
      <NavBar />
      <Container className="mt-2">
        <div
          style={{
            color: "",
            fontSize: "14px",
            marginTop: "80px",
          }}
        >
          <span>Carré Inc.&nbsp;</span>
          <br />
          <span>Terms of Service&nbsp;</span>
          <br />
          <span>Updated as of September 24, 2023&nbsp;</span>
          <br />
          <span>Binding Terms of Service and Disclaimer&nbsp;</span>
          <br />
          <span>
            Welcome to this website, which is owned and operated by Carré Inc.,
            a Delaware corporation and its subsidiaries and affiliates
            (collectively, “Carré,” “We,” and/or “Us”). &nbsp;This website
            (https://carre.ai) and any related websites and/or forums owned or
            operated by Carré are referred to collectively as the “Site.”
            &nbsp;The following terms of use (the "Terms" or this “Agreement”)
            set forth the rules that govern your use of the Site. &nbsp;Please
            read these Terms carefully. By accessing or using the Site or using
            services provided by Carré generally described below, including
            investing in Carré shares (the “Services”), you agree that you have
            read, understand, and agree to be bound by the terms and conditions
            described in these Terms and all terms and conditions incorporated
            by reference. &nbsp;If you are accepting these Terms on behalf of an
            entity, you represent and warrant to Carré that you have the
            authority to do so. &nbsp;Carré reserves the right to modify content
            on the Site and these Terms periodically without prior notice,
            provided that registered users will be provided fifteen (15) days’
            prior notice of the new terms. &nbsp;Any changes will become
            effective upon posting the changes, modifications, or revisions to
            the Site, along with the date on which it was most recently updated
            as indicated by the “Updated as of” date set forth above.
            &nbsp;These Terms (as described below) provide that all disputes
            between you and Carré arising out of or relating to these Terms or
            your use of the Site or Services will be resolved by binding
            arbitration, and for such disputes, you waive your right to bring a
            class or representative action and to jury trials.&nbsp;
          </span>
          <br />
          <span>&nbsp;</span>
          <br />
          <span>
            CARRE HAS THE RIGHT, IN ITS SOLE DISCRETION, AND FOR ANY OR NO
            REASON, TO DEACTIVATE AND REVOKE THE ACCESS OF ANY PARTY TO THE SITE
            OR SERVICES.&nbsp;
          </span>
          <br />
          <span>&nbsp;</span>
          <br />
          <span>
            Carré may at any time, for any reason, and without notice or
            liability: (1) modify, suspend, or terminate operation of or access
            to the Site, or any portion thereof; (2) change, revise, or modify
            the Services, Content, or any portion thereof; (3) interrupt the
            operation of the Services, or any portion thereof, as necessary to
            perform routine or non-routine maintenance, error correction, or for
            any other purposes; and/or (4) impose limits on certain programs,
            features, and services, or restrict your access to the Site or its
            Services, in whole or in part.&nbsp;
          </span>
          <br />
          <span>Services&nbsp;</span>
          <br />
          <span>
            Carré offers investment opportunities in real estate properties by
            allowing fractional ownership of properties that are managed by
            Carré. Such fractional ownership refers to a form of shared
            ownership in a property where one or more individuals or entities
            each purchase shares issued by Carré, which reflect a portion of
            shared ownership of real estate managed by Carré. The owners may
            benefit from the respective property’s future appreciation and
            distributions of cash generated form rental income related to such
            property. &nbsp;&nbsp;
          </span>
          <br />
          <span>&nbsp;</span>
          <br />
          <span>
            Investors may invest in properties offered on the Site by purchasing
            shares on the Site for the properties offered therein with a minimum
            investment of $100.00. &nbsp;U.S. Persons (as defined in Regulation
            S under the U.S. Securities Act of 1933) may not invest in Carré
            properties or purchase Carré shares. &nbsp;In order to invest in
            Carré properties or purchase Carré shares, in addition to making
            other representations and signing certain investment documents, you
            will be required (a) to represent that you are not a U.S. Person,
            (b) to acknowledge that neither the offer or sale of the securities
            took place while you were in the United States; and (c) represent
            and warrant that you are or were outside the United States (i) when
            receiving any investing agreement and any other materials provided
            to you by or on behalf of Carré, and (ii) when completing and
            executing any such documents. Notwithstanding the foregoing, these
            Terms and the Site are intended only to provide a general
            description of Carré’s Services and nothing contained within the
            Site or these Terms is a solicitation or offering of any
            investment(s) by Carré or any of its affiliates in any way
            whatsoever. &nbsp;These Terms do not form a contract between the
            User and Carré related to Carré’s Services, and any party
            interesting in participating in a Carré investment should contact
            Carré directly to obtain information, disclosures, and an investment
            agreement regarding such potential investment(s). &nbsp;Carré will
            determine, in its sole discretion the suitability of investors to
            invest in Carré’s properties and shares, and may reject investors
            for any or no reason. &nbsp;
          </span>
          <br />
          <span>&nbsp;</span>
          <br />
          <span>
            If you are interested in investing in Carré properties or shares,
            please reach out to Carré at{" "}
            <a href="mailto:info@carre.ai">info@carre.ai.</a> &nbsp;&nbsp;
          </span>
          <br />
          <span>&nbsp;</span>
          <br />
          <span>
            Any investment materials provided by Carré on the Site or provided
            outside the Site are subject to the following additional terms and
            conditions and limitations:&nbsp;
          </span>
          <br />
          <span>&nbsp;</span>
          <br />
          <span>
            Informational Purposes Only. &nbsp;Such materials may be used for
            informational purposes only. &nbsp;Carré undertakes no obligation to
            update the information contained therein. &nbsp;Carré does not makes
            any representation or warranty, express or implied, as to the
            accuracy or completeness of the information contained therein.
            &nbsp;Nothing in such materials will constitute the provision of any
            tax, accounting, financial, investment, regulatory, legal or other
            advice by Company or its advisors. Such materials may not be
            referenced, quoted or linked by website, in whole or in part, except
            with Carré’s prior written approval.&nbsp;
          </span>
          <br />
          <span>&nbsp;</span>
          <br />
          <span>
            Not an Offer. Such materials will provide only an informational
            overview of Carré and is not intended to be taken by, and should not
            be taken by, any individual recipient as investment advice, a
            recommendation to buy, hold or sell any security, or an offer to
            sell or a solicitation of offers to purchase any security, including
            a security or share in Carré. &nbsp;An offer or solicitation to
            invest in Carré will only occur following further discussions with
            Carré, including an analysis into your investment qualifications, at
            which time, Carré, at its sole discretion, may send formal
            documentation for such an investment in Carré, which investment will
            be subject to the terms and conditions contained in such operative
            documents. The operative documents relating to Carré will contain
            additional information about the investment objective, terms and
            conditions of Carré and its shares, and risk disclosures that should
            be reviewed prior to making an investment decision regarding Carré.
            &nbsp;
          </span>
          <br />
          <span>&nbsp;</span>
          <br />
          <span>
            Forward Looking Statements and Projections. Certain information
            contained therein constitutes “forward-looking statements,” which
            can be identified by the use of terms such as “may”, “will”,
            “should”, “expect”, “anticipate”, “project”, “estimate”, “intend”,
            “continue,” “target” or “believe” (or the negatives thereof) or
            other variations thereon or comparable terminology. Due to various
            risks and uncertainties, actual events or results may differ
            materially from those reflected or contemplated in such
            forward-looking statements. As a result, investors should not rely
            on such forward-looking statements. No representation or warranty is
            made as to future performance or such forward-looking statements.
            Such projections will be prepared and for illustrative purposes
            only, and will not constitute a forecast. They will be prepared
            based on Carré’s current view in relation to future events and
            financial performance of Carré, based on various estimations and
            assumptions made by Carré, including estimations and assumptions
            about events that have not occurred, any of which may prove to be
            inaccurate. &nbsp;Actual results may vary significantly.&nbsp;
          </span>
          <br />
          <span>&nbsp;</span>
          <br />
          <span>
            Associated Risks. An investment in Carré entails a high degree of
            risk and no assurance can be given that investment objective will be
            achieved or that investors will receive a return on their capital
            (or that investors will not lose all or part of their capital
            invested). Each prospective investor should consult its own legal,
            accounting and tax advisors as to the legal, business, tax and
            related matters concerning the information contained in any such
            materials in order to make an independent determination and
            consequences of further discussing a potential investment in Carré,
            including federal, state, local and foreign tax consequences. Past
            performance is not necessarily indicative of future results, and
            there can be no assurance that targeted returns will be achieved,
            that Carré will achieve comparable results, or that Carré will be
            able to implement its investment strategy or achieve its investment
            objective. &nbsp;
          </span>
          <br />
          <span>&nbsp;</span>
          <br />
          <span>
            Unregistered Status. Carré’s investment opportunities and have not
            been registered under the U.S. Securities Act of 1933, as amended,
            the securities laws of any state or the securities laws of any other
            jurisdiction, nor is such registration contemplated. &nbsp;Carré
            shares will be offered and sold only outside the United States under
            exemptions provided under the Securities Act, including Regulation S
            promulgated thereunder. &nbsp;
          </span>
          <br />
          <span>Privacy Policy &nbsp;</span>
          <br />
          <span>
            We collect information and use technological measures to collect
            information about your use of the Site and the Services. By using
            the Site and/or the Services, you consent to the collection, use,
            disclosure, and retention of your personal information by or on
            behalf of Carré as explained in our Privacy Policy
            (https://carre.ai/privacypolicy), as revised from time to time, and
            as otherwise permitted by applicable law. &nbsp;
          </span>
          <br />
          <span>Content Ownership and Licensing&nbsp;</span>
          <br />
          <span>
            The Site and all content, information, and other materials featured,
            displayed, contained, and available on the Site, including, but not
            limited to, all text, images, graphics, designs, illustrations,
            photographs, pictures, audio, software (including object code,
            machine intelligence, and search engines), and video clips, the
            trademark, trade dress, “look and feel”, pages, screens, content
            arrangement, application functionality and computer programs and
            digital media (“Content”) are owned by or licensed to Carré and are
            protected by copyright, trademark, trade dress, patent, and/or other
            intellectual property rights and unfair competition laws under the
            United States and foreign laws. &nbsp;All goodwill generated from
            the use of the Content will inure to our exclusive benefit.
            &nbsp;Content may be subject to patent, copyright, trademark, and
            other intellectual property protection. You may view, download, and
            print Content from the Site subject to the following terms and
            conditions: (a) the Content may be used solely for information
            purposes; and (b) the Content may not be modified or altered in any
            way. &nbsp;You may not republish, distribute, prepare derivative
            works, or otherwise use the Content other than as explicitly
            permitted herein. You may not frame or utilize framing techniques to
            enclose any trademark, logo, or other proprietary information
            (including images, text, page layout, or form) of Carré without the
            express written consent of Carré. You may not use any meta tags or
            any other “hidden text” utilizing Carré’s name or trademarks without
            the express written consent of Carré. You may not use any Carré logo
            or other proprietary graphic or trademark as part of the link
            without express written permission. You may not, in any way,
            otherwise copy, reproduce, distribute, transmit, display, perform,
            reproduce, publish, license, modify, create derivative works from,
            sell, or exploit, in whole or in part, the Site or its Content
            without express written permission. &nbsp;You do not acquire any
            ownership rights to any content in the Site. Any unauthorized use
            terminates the limited license granted by Carré herein.&nbsp;
          </span>
          <br />
          <span>
            Reviews, Comments, Communications, And Other Content &nbsp;
          </span>
          <br />
          <span>
            To the extent that we may offer the ability for any user to add,
            post, or input any content, whether text, audio, video, or
            otherwise, to the Site, you may not use a false e-mail address,
            impersonate any person or entity, or otherwise mislead as to the
            origin of the information. Except for any personally identifiable
            information we may collect from you under the guidelines established
            in our Privacy Policy, any Content you communicate or submit to us
            or through the Site or otherwise will be considered non-confidential
            and non-proprietary. &nbsp;By disclosing any such Content, you grant
            Carré a nonexclusive, world-wide, royalty-free, perpetual,
            irrevocable, and fully sublicensable license to reproduce, use,
            modify, publish, adapt, translate, create derivative works from,
            distribute, and display such Content throughout the Site and the
            world in any media. By disclosing any such Content information, you
            represent and warrant and agree that the Content is accurate, you
            own all rights to or have permission to use the Content that you
            submit, and that use of the content will not cause injury to any
            person or entity. You agree to indemnify, hold harmless and defend
            Carré, and its directors, officers, employees, and agents from and
            against any action, claim, demand, dispute, or liability, including
            reasonable attorney’s fees and costs, arising from or relating to
            any allegation that the Content infringes a third person’s
            copyright, trademark or proprietary or intellectual property right,
            or misappropriates a third person’s trade secrets. &nbsp;
          </span>
          <br />
          <span>&nbsp;</span>
          <br />
          <span>
            Carré does NOT allow users to share profiles. If an account is found
            to be representing more than one individual, the account may be
            removed from the site without notice or any liability of
            Carré.&nbsp;
          </span>
          <br />
          <span>Prohibited Use and Access&nbsp;</span>
          <br />
          <span>
            You are prohibited from using or accessing the Site or its Content
            for any unlawful, obscene, or immoral purpose, or any purpose that
            is likely to cause liability to yourself or others, including
            without limitation:&nbsp;
          </span>
          <br />
          <span>
            to solicit others to perform or participate in any unlawful or
            fraudulent acts;&nbsp;
          </span>
          <br />
          <span>
            to violate any international, federal, or state regulations, rules,
            laws, or local ordinances;&nbsp;
          </span>
          <br />
          <span>
            to infringe upon or violate or misappropriate the intellectual
            property, publicity, privacy, or other proprietary rights of Carré
            or others;&nbsp;
          </span>
          <br />
          <span>
            to post, transmit, submit, or include any unlawful, harmful,
            threatening, abusive, harassing, defamatory, vulgar, obscene,
            sexually explicit, libelous, slanderous, profane, hateful,
            discriminatory, racially, ethnically, or otherwise objectionable
            content, material, or information of any kind, or any content,
            material, or information that may give rise to criminal or civil
            liability, or that is objectionable or which restricts or inhibits
            any other person from using or enjoying the Site;&nbsp;
          </span>
          <br />
          <span>
            to exploit or harm minors, including by exposing them to
            inappropriate content or asking for personally identifiable
            information;&nbsp;
          </span>
          <br />
          <span>
            to impersonate our company, or employees or any other person
            (including, without limitation, by using e-mail addresses or screen
            names associated with any of the foregoing);&nbsp;
          </span>
          <br />
          <span>to submit false or misleading information;&nbsp;</span>
          <br />
          <span>
            to modify, adapt, translate, or reverse engineer any portion of the
            Site or its Content;&nbsp;
          </span>
          <br />
          <span>
            to upload or transmit malicious code or viruses, or use the Site or
            any feature in any way that could interfere with the proper
            functioning or operation of, or disrupt, damage, disable,
            overburden, or impair it or its systems, servers, or networks, or
            attack our Site via a denial-of-service attack or a distributed
            denial-of-service attack;&nbsp;
          </span>
          <br />
          <span>
            to collect or track the personal information of others;&nbsp;
          </span>
          <br />
          <span>
            to use the Site or any feature for any commercial purposes or for
            advertisements (except with our express written permission), chain
            letters, spamming, junk mail, solicitations, promotional materials,
            sales, pyramid or multi-marketing schemes, contests, sweepstakes or
            any other form of solicitation, or harvest or collect email
            addresses or other contact information of other users of the Site by
            electronic or other means (including to spam, phish, pharm, or
            otherwise contact users of the Site);&nbsp;
          </span>
          <br />
          <span>
            to use any “deep-link”, “page-scrape”, “robot”, “spider”, or other
            automatic device, program, algorithm, or methodology, or any similar
            or equivalent manual process, frame or mirror, or scrape the Content
            of the Site; or&nbsp;
          </span>
          <br />
          <span>
            to interfere with or circumvent the security features of the Site,
            other websites, or the Internet.&nbsp;
          </span>
          <br />
          <span>
            Additionally, the systematic retrieval of data or other Content from
            the Site or the Services, whether to create or compile, directly or
            indirectly, a collection, compilation, database or directory, or
            otherwise, is prohibited absent our express prior written
            consent.&nbsp;
          </span>
          <br />
          <span>
            Carré reserves the right to prohibit access, use, conduct,
            communications, or content that we, in our sole discretion, deem to
            be harmful to the Site, Content, users, us, our brand, or any other
            person or entity, or that violates these Terms and/or applicable
            law. &nbsp;We reserve the right to terminate your use of the Site
            and/or Services for using or accessing the Site or Services in our
            sole discretion, including following your violation of the
            prohibitions above or other requirements of these Terms.&nbsp;
          </span>
          <br />
          <span>Site Updates and Accuracy&nbsp;</span>
          <br />
          <span>
            We undertake no obligation to update, amend or clarify information
            on the Site, including, without limitation, pricing or Service
            information, except as required by law. No specified update or
            refresh date applied on the Site should be taken to indicate that
            all information on the Site has been modified or updated. Please
            remember when reviewing information on the Site that such
            information may not represent the complete information available on
            a subject. In addition, subsequent events or changes in
            circumstances may cause existing information on the Site to become
            inaccurate or incomplete.&nbsp;
          </span>
          <br />
          <span>
            On occasion, information on the Site may contain errors, including,
            without limitation, typographical errors, inaccuracies, or omissions
            related to investment opportunities. We reserve the right to, at any
            time and without prior notice, correct any errors, inaccuracies, or
            omissions and to change or update information or retire properties
            if any information on the Site is inaccurate. You agree that all
            agreements, notices, disclosures, and other communications that we
            provide to you electronically satisfy any legal requirement that
            such communications be in writing. You agree that all agreements,
            notices, disclosures, and other communications that we provide to
            you electronically satisfy any legal requirement that such
            communications be in writing. &nbsp;
          </span>
          <br />
          <span>
            This Section is not intended to limit any disclaimer contained in
            these Terms or the Privacy Policy.&nbsp;
          </span>
          <br />
          <span>Fees and Payment&nbsp;</span>
          <br />
          <span>
            (a) General Payment Terms. Certain features of the Services, such as
            purchasing shares with respect to investments opportunities, require
            you to make a payment, including all applicable taxes. Unless
            otherwise agreed, all payments are in U.S. Dollars. Carré reserves
            the right to change the required method of payment at any time, upon
            notice to you. You agree that any investments you make with Carré
            under any investment agreement are neither contingent on the
            delivery of any future functionality or features of the Site or the
            Services nor dependent on any oral or written public comments made
            by us regarding future functionality or features.&nbsp;
          </span>
          <br />
          <span>
            (b) Price. Carré reserves the right to determine pricing for the
            Services. Carré may change the fees for any feature of the Services,
            including additional fees or charges, if Carré gives you advance
            notice of changes before they apply. Carré, at its sole discretion,
            may make promotional offers with different features and different
            pricing to any of Carré customers. These promotional offers, unless
            made directly to you or available to the public at large, will not
            apply to you.&nbsp;
          </span>
          <br />
          <span>Copyright Information &nbsp;</span>
          <br />
          <span>
            All Content of this Web site are the copyright of Carré. All rights
            reserved. The Site and the Content within the Site are the property
            of Carré and is protected by United States copyright laws and
            international treaty provisions. The compilation, organization, and
            display of the Content as well as all software and inventions used
            on and in connection with this Site are the exclusive property of
            Carré. Carré reserves all rights in the Site and its Content not
            specifically granted in any agreements with Carré or in these Terms.
            &nbsp;
          </span>
          <br />
          <span>Trademark Information &nbsp;</span>
          <br />
          <span>
            CARRE and other marks indicated on our site may be registered
            trademarks of Carré in the United States and/or other countries.
            Other Carré marks, graphics, logos, page headers, button icons,
            scripts noted on the Site are Carré’s service marks, trademarks, and
            trade dress and are the sole and exclusive property of Carré.
            &nbsp;Carré’s service marks, trademarks, and trade dress may not be
            used in any manner that is likely to cause confusion among
            customers, in any manner that disparages or discredits Carré and in
            connection with any service or product that is not sponsored,
            endorsed or produced by Carré. &nbsp;All other trademarks not owned
            by Carré its clients, or its subsidiaries that appear on this site
            are the property of their respective owners, who may or may not be
            affiliated with, connected to, or sponsored by Carré or its
            subsidiaries. &nbsp;
          </span>
          <br />
          <span>Third Party Web Sites and Information&nbsp;</span>
          <br />
          <span>
            The Site may provide hyperlinks to third-party Web sites or access
            to third party content. Carré does not control, endorse, or
            guarantee content found in such sites. You agree that Carré is not
            responsible for any content, associated links, resources, or
            services associated with any third-party site. You further agree
            that Carré shall not be liable for any loss or damage of any sort
            associated with your use of third-party content. Links and access to
            these sites are provided for your convenience only. &nbsp;
          </span>
          <br />
          <span>Limitations on Liability&nbsp;</span>
          <br />
          <span>
            YOU ACKNOWLEDGE, BY YOUR USE OF THIS SITE: (1) THAT YOUR USE OF THIS
            SITE IS AT YOUR SOLE RISK; AND (2) THAT WE WILL NOT BE LIABLE FOR
            ANY DAMAGES OF ANY KIND RELATED TO YOUR USE OF OR INABILITY TO USE
            OUR SITE, INCLUDING, WITHOUT LIMITATION, FAILURE OF PERFORMANCE,
            ERROR, OMISSION, INTERRUPTION, COMPUTER VIRUS, OR NETWORK OR
            INTERNET FAILURE, OR INCLUDING, WITHOUT LIMITATION, DIRECT,
            INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, COMPENSATORY OR
            CONSEQUENTIAL DAMAGES, LOST PROFITS OR LOSS OF OR DAMAGE TO PROPERTY
            WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT,
            NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF WE HAVE
            BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL
            OUR TOTAL LIABILITY FOR ANY CLAIMS RELATED TO THIS SITE OR THE
            SERVICES (INCLUDING CLAIMS RELATED TO ANY USER OF OUR SITE OR OTHER
            THIRD PARTY) EXCEED THE GREATER OF THE AMOUNT OF FEES PAID TO CARRE
            IN CONNECTION WITH YOUR USE OF OUR SITE OR THE SERVICES DURING THE
            PRIOR 12 MONTHS, OR $1,000.&nbsp;
          </span>
          <br />
          <span>Disclaimer&nbsp;</span>
          <br />
          <span>
            ALL CONTENT, SERVICES, PRODUCTS, AND SOFTWARE PROVIDED ON THIS SITE
            ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR
            IMPLIED. CARRE DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED
            INCLUDING, WITHOUT LIMITATION, THOSE OF MERCHANTABILITY, FITNESS FOR
            A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. YOU ARE SOLELY
            RESPONSIBLE FOR THE APPROPRIATENESS OF THE SITE, ITS CONTENT, AND
            THE PRODUCTS AND SERVICES OFFERED BY CARRE ON THE SITE FOR YOUR
            INTENDED APPLICATION AND USE. CARRE DOES NOT WARRANT THAT THE SITE,
            ITS CONTENT, OR THE PRODUCTS AND SERVICES IT OFFERS ON, THROUGH OR
            OFF THE SITE MEET YOUR REQUIREMENTS. CARRE SHALL NOT BE LIABLE FOR
            ANY DIRECT, INDIRECT, SPECIAL, CONSEQUENTIAL, INCIDENTAL, OR
            PUNITIVE DAMAGES, EVEN IF CARRE HAS BEEN ADVISED OF THE POSSIBILITY
            OF SUCH DAMAGES.&nbsp;
          </span>
          <br />
          <span>Indemnification &nbsp;</span>
          <br />
          <span>
            You agree to indemnify, hold harmless and defend Carré and its
            managers, members, directors, shareholders, officers, employees, and
            agents from and against any action, claim, demand, dispute, or
            liability, including reasonable attorney’s fees and costs, arising
            from or relating to: (i) your breach of these Terms; (ii) your
            negligence or willful misconduct; or (iii) in connection with your
            use of the Site, any hyperlinked Web site, or the Services. &nbsp;
          </span>
          <br />
          <span>Termination&nbsp;</span>
          <br />
          <span>
            The Terms will remain in effect until you cease to use the Site.
            Notwithstanding the foregoing, all terms and conditions of these
            Terms that may require continued performance, compliance, or effect
            beyond the termination date of the Terms will survive termination of
            these Terms and will be enforceable by the parties. We may also
            terminate or amend these Terms at any time and may do so immediately
            without notice (provided that registered users will be given fifteen
            (15) days’ prior notice of changes to the Terms), and accordingly
            deny you access to the Site and/or the Services, if in our sole
            discretion, you fail to comply with any term or provision of these
            Terms. Upon any termination of these Terms, you must promptly
            destroy all materials downloaded or otherwise obtained from the
            Site, as well as copies of such materials, whether made under these
            Terms or otherwise, unless otherwise permitted by separate written
            agreement with us.&nbsp;
          </span>
          <br />
          <span>Governing Law; Arbitration&nbsp;</span>
          <br />
          <span>
            This Agreement is to be governed by and construed in accordance with
            the laws of the State of Delaware applicable to contracts made and
            to be performed wholly within such State, and without regard to the
            conflicts of laws principles thereof. &nbsp;
          </span>
          <br />
          <span>
            All disputes arising out of or relating to these Terms (including
            its formation, performance, interpretation, applicability,
            enforceability, or alleged breach) or your use of the Site will be
            exclusively resolved under confidential binding arbitration held in
            Broward County, Florida in accordance with the rules and procedures
            of the American Arbitration Association ("AAA"). The parties shall
            request that AAA appoint a single arbitrator. The award rendered by
            the arbitrator will be binding and may be entered as a judgment in,
            and enforced by, any court of competent jurisdiction. To the fullest
            extent permitted by applicable law, no arbitration under these Terms
            will be joined to an arbitration involving any other party subject
            to these Terms, whether through class arbitration proceedings or
            otherwise. Notwithstanding the foregoing, we will have the right to
            seek injunctive or other equitable relief in state or federal courts
            located in Broward County, Florida to enforce these terms or prevent
            an infringement of a third party's rights. In the event equitable
            relief is sought, each party hereby irrevocably submits to the
            personal jurisdiction of such court. &nbsp;&nbsp;
          </span>
          <br />
          <span>Waiver of Jury Trial.&nbsp;</span>
          <br />
          <span>
            EACH OF THE PARTIES HERETO HEREBY WAIVES TO THE FULLEST EXTENT
            PERMITTED BY APPLICABLE LAW ANY RIGHT IT MAY HAVE TO A TRIAL BY JURY
            WITH RESPECT TO ANY LITIGATION DIRECTLY OR INDIRECTLY ARISING OUT
            OF, UNDER OR IN CONNECTION WITH THIS AGREEMENT. EACH OF THE PARTIES
            HERETO HEREBY (I) CERTIFIES THAT NO REPRESENTATIVE, AGENT, OR
            ATTORNEY OF ANY OTHER PARTY HAS REPRESENTED, EXPRESSLY OR OTHERWISE,
            THAT SUCH OTHER PARTY WOULD NOT, IN THE EVENT OF LITIGATION, SEEK TO
            ENFORCE THE FOREGOING WAIVER AND (II) ACKNOWLEDGES THAT IT HAS BEEN
            INDUCED TO ENTER INTO THIS AGREEMENT, BY, AMONG OTHER THINGS, THE
            MUTUAL WAIVERS AND CERTIFICATIONS IN THIS SECTION.&nbsp;
          </span>
          <br />
          <span>Attorneys’ Fees&nbsp;</span>
          <br />
          <span>
            Should any litigation, arbitration, mediation, or other legal
            proceeding be commenced concerning these Terms, the prevailing party
            shall be entitled, in addition to such other relief as may be
            granted, to reimbursement of the prevailing party’s attorney’s fees
            and costs incurred in connection with such proceeding. &nbsp;
          </span>
          <br />
          <span>Entire Terms and Conditions&nbsp;</span>
          <br />
          <span>
            These Terms, together with the Privacy Policy, contain the entire
            agreement between you and Carré with respect to the engagement
            relationship between you and Carré and supersede all prior
            agreements, undertakings, and commitments, whether oral or written.
            &nbsp;We may unilaterally amend these Terms on a go forward basis
            after providing notice to you through the Site. &nbsp;
          </span>
          <br />
          <span>Invalid Provisions&nbsp;</span>
          <br />
          <span>
            The invalidity or unenforceability of any paragraph or provision of
            these Terms shall not affect the validity or enforceability of the
            remainder of these Terms, or the remainder of any paragraph or
            provision. These Terms shall be construed in all respects as if any
            invalid or unenforceable paragraph or provision were omitted.&nbsp;
          </span>
          <br />
          <span>Successors and Assigns&nbsp;</span>
          <br />
          <span>
            These Terms shall be binding upon and shall inure to the benefit of
            the parties hereto and their respective shareholders, partners,
            subscribers, directors, officers, heirs, successors,
            representatives, and assigns. You agree, for yourself and on behalf
            of your successors, heirs, executors, administrators, and any person
            or persons claiming under it, that these Terms and the rights,
            interests, and benefits hereunder cannot be assigned, transferred,
            pledged, or hypothecated in any way and shall not be subject to
            execution, attachment, or similar process. Any attempt to do so,
            contrary to these Terms, shall be null and void and shall relieve
            Carré of any and all obligations or liability hereunder.&nbsp;
          </span>
          <br />
          <span>Effect of Waiver&nbsp;</span>
          <br />
          <span>
            The failure of any party to insist on in any one instance or more
            upon strict performance of any of the terms and conditions hereof,
            or to exercise any right or privilege herein conferred, shall not be
            construed as a waiver of such terms, conditions, rights, or
            privileges, but same shall continue to remain in full force and
            effect. Any waiver by any party of any violation of, breach of or
            default under any provision of these Terms by the other party shall
            not be construed as, or constitute, a continuing waiver of such
            provision, or waiver of any other violation of, breach of or default
            under any other provision of these Terms.&nbsp;
          </span>
          <br />
          <span>Force Majeure&nbsp;</span>
          <br />
          <span>
            Neither party shall be in default by reason of any failure or delay
            in the performance of any obligation under these Terms where such
            failure or delay arises out of any cause beyond the control and
            without the fault or negligence of such party.&nbsp;
          </span>
          <br />
          <span>Binding Effect&nbsp;</span>
          <br />
          <span>
            These Terms shall be binding upon, and inure to the benefit of,
            Carré and its successors, assigns, heirs, legal representatives,
            executors, and administrators. &nbsp;
          </span>
          <br />
          <span>Local Laws and Regulations &nbsp;</span>
          <br />
          <span>
            Users are responsible for adhering to and complying with all
            applicable laws including, but not limited to, permits, licenses,
            taxes, and safety compliance. &nbsp;
          </span>
          <br />
          <span>Contact Information&nbsp;</span>
          <br />
          <span>
            If you have any questions regarding these Terms of Use, please
            contact Carré at <a href="mailto:legal@carre.ai">legal@carre.ai.</a>{" "}
            &nbsp;
          </span>
          <br />
          <span>No Service Guarantee &nbsp;</span>
          <br />
          <span>
            Carré does not guarantee that the Site will be available one hundred
            percent (100%) of the time. If Carré is unable to provide the
            promised level of service for any reason, including, but not limited
            to service outages, pandemic war, acts of God or negligence, neither
            Carré nor its owners or representatives will not be held liable for
            any costs or damages including, but not limited to, indirect,
            special, incidental, exemplary or consequential damages (including,
            without limitation, lost profits) related to these Terms or
            resulting from your use or inability to use the Site, arising from
            any cause of action whatsoever, including contract, warranty, strict
            liability, or negligence, even if Carré has been notified of the
            possibility of such damages. &nbsp;
          </span>
          <br />
          <span>Notice&nbsp;</span>
          <br />
          <span>
            You consent to receive any and all communications from us
            electronically. We will communicate with you by e-mail or by posting
            notices on this Site. You agree that all agreements, notices,
            disclosures and other communications that we provide to you
            electronically satisfy any legal requirement that such
            communications be in writing. You may e-mail notices to:
            <a href="mailto:legal@carre.ai">legal@carre.ai.&nbsp;</a>
          </span>
          <br />
          <span>Spamming Policy&nbsp;</span>
          <br />
          <span>
            Carré does not tolerate the sending of Unsolicited Commercial Email
            (SPAM). Carré reserves the right to cancel any user that mentions
            Carré or any of its associated websites, URLs, or any associated
            Carré email address in any SPAM e-mail message or Usenet posting.
            You hereby agree that, with respect to the foregoing, Carré may
            determine what constitutes SPAM in its sole and absolute discretion.
            &nbsp;
          </span>
          <br />
          <span>International Use&nbsp;</span>
          <br />
          <span>
            The Service is intended solely for visitors located outside the
            United States. United States citizens and residents are prohibiting
            from participating in the Services and no investment is being
            solicited from or offered to any citizens or resident of the United
            States. Access to the Service from countries or territories or by
            individuals where such access is illegal is prohibited.&nbsp;
          </span>
        </div>
      </Container>
      <Footer />{" "}
    </div>
  );
};

export default TermsOfService;
