import { useQuery } from "react-query";
import api from "../api";

export const useGetDataSpikeStatus = (
  id,
  onErrorCallback,
  onSuccessCallback
) => {
  return useQuery(
    ["dataspike_status", id], // Query key
    () => {
      if (id) {
        return api.get(`/dataspike-kyc/${id}`).then((res) => res.data);
      }
    },
    {
      enabled: !!id,
      onSuccess: (res) => {
        if (res?.status === "verified") {
          onSuccessCallback();
        }
      },
      onError: (error) => {
        // Retrieve error message
        const errorMessage =
          error?.response?.data?.error ||
          "An error occurred while fetching the data.";

        // Update parent state with the error
        if (onErrorCallback) {
          onErrorCallback(errorMessage);
        }
      },
    }
  );
};
