import * as React from "react"

const TokenIcon = (props) => (
    <svg
        width={10}
        height={14}
        fill="none"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M1.073 4.735v2.308c0 .956 1.808 1.73 4.039 1.73 2.23 0 4.038-.774 4.038-1.73V4.735"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M1.073 7.043v2.308c0 .956 1.808 1.73 4.039 1.73 2.23 0 4.038-.774 4.038-1.73V7.043M1.073 2.428v2.307c0 .956 1.808 1.731 4.039 1.731 2.23 0 4.038-.775 4.038-1.73V2.427"
            
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <ellipse
            cx={5.112}
            cy={2.428}
            rx={4.039}
            ry={1.731}
            
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default TokenIcon
