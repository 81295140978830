import React, { useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { RiDeleteBinLine } from "react-icons/ri"; // Importing delete icon from react-icons
import api from "../../api";

const AdminManualKycVerify = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [formData, setFormData] = useState({ email: "" });
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [verifying, setVerifying] = useState(false); // State to track verification status

  const handleConfirmationClose = () => setShowConfirmation(false);

  const onSubmit = async (data) => {
    setFormData(data);
    setShowConfirmation(true);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages((prevImages) => [...prevImages, ...files]);
  };

  const handleRemoveImage = (indexToRemove) => {
    setSelectedImages((prevImages) =>
      prevImages.filter((image, index) => index !== indexToRemove)
    );
  };

  const handleShowImageModal = (index) => {
    setSelectedImageIndex(index);
  };

  const handleCloseImageModal = () => {
    setSelectedImageIndex(null);
  };

  const handleVerify = async () => {
    const { email } = formData;

    try {
      setVerifying(true); // Set verifying to true when verification starts

      for (const image of selectedImages) {
        const formData = new FormData();
        formData.append("file", image);
        await api.post(`/kyc/${email}/upload-image`, formData);
      }
      await api.post(`/kyc/${email}/verify`);
      toast.success("User successfully verified");
      reset();
      setShowConfirmation(false);
    } catch (error) {
      toast.error(error?.response.data.error || "Something went wrong");
    } finally {
      setVerifying(false); // Set verifying back to false when verification completes
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Button
          variant="primary"
          type="submit"
          className="w-100 mb-4"
          disabled={verifying}
        >
          {verifying ? "Verifying..." : "Verify user "}
        </Button>
        <Form.Group controlId="email">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            {...register("email", { required: "Email is required" })}
          />{" "}
          {errors?.email && (
            <p className="text-carre-red fs-sm fw-bold">
              {errors.email.message} !
            </p>
          )}
        </Form.Group>

        <Form.Group controlId="images">
          <Form.Label>Images</Form.Label>
          <Form.Control type="file" multiple onChange={handleFileChange} />
          <p>Selected Photos:</p>
          <p className="text-muted">Click on the photo for full screen</p>
          <div className="d-flex flex-wrap">
            {selectedImages.map((image, index) => (
              <div
                key={index}
                className="position-relative border border-gray-400 rounded m-4 "
                style={{
                  width: "300px",
                  height: "300px",
                  padding: "5px",
                  cursor: "pointer",
                }}
                onClick={() => handleShowImageModal(index)} // Open modal when image is clicked
              >
                <img
                  src={URL.createObjectURL(image)}
                  alt={`Selected Image ${index + 1}`}
                  className="w-100 h-100 object-fit-contain card-hover"
                />
                <Button
                  variant="carre-red"
                  className="position-absolute start-50 z-5 mt-3 rounded-circle translate-middle"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent modal from opening when delete button is clicked
                    handleRemoveImage(index);
                  }}
                  style={{ padding: "8px" }}
                >
                  <RiDeleteBinLine />
                </Button>
              </div>
            ))}
          </div>
        </Form.Group>
      </Form>

      <Modal
        show={showConfirmation}
        onHide={handleConfirmationClose}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to verify this user by email{" "}
            <strong>{formData.email}</strong> with these photos?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleConfirmationClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleVerify} disabled={verifying}>
            {verifying ? "Verifying..." : "Verify"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Image Modal */}
      <Modal
        show={selectedImageIndex !== null}
        onHide={handleCloseImageModal}
        fullscreen={true}
      >
        <Modal.Body>
          {selectedImageIndex !== null && (
            <img
              src={URL.createObjectURL(selectedImages[selectedImageIndex])}
              alt={`Selected Image ${selectedImageIndex + 1}`}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseImageModal}
            className={"w-100 "}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminManualKycVerify;
