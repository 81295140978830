import React from 'react';

const IMarketPlace = ({props, color="#A0A0AA"}) => (
    <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g filter="url(#a)">
            <path
                d="M8 13v8"
                stroke={color}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <g filter="url(#b)">
            <path
                d="M16 13v8"
                stroke={color}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <g filter="url(#c)">
            <path
                d="M12 11v10"
                stroke={color}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <g filter="url(#d)">
            <path
                d="M4 21V7h5V3h6v4h5v14"
                stroke={color}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <filter
                id="a"
                x={-3}
                y={2}
                width={22}
                height={30}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation={5}/>
                <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_3_32276"
                />
                <feBlend
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_3_32276"
                    result="shape"
                />
            </filter>
            <filter
                id="b"
                x={5}
                y={2}
                width={22}
                height={30}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation={5}/>
                <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_3_32276"
                />
                <feBlend
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_3_32276"
                    result="shape"
                />
            </filter>
            <filter
                id="c"
                x={1}
                y={0}
                width={22}
                height={32}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation={5}/>
                <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_3_32276"
                />
                <feBlend
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_3_32276"
                    result="shape"
                />
            </filter>
            <filter
                id="d"
                x={-7}
                y={-8}
                width={38}
                height={40}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation={5}/>
                <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_3_32276"
                />
                <feBlend
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_3_32276"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
)

export default IMarketPlace
