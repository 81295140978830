import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

import Modal from "react-bootstrap/Modal";
import ICloseIcon from "../assets/icons/CloseIcon";
import Logo from "../components/Logo";

import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { resendVerification, verifyUser } from "../hooks/verifyUser";

function VerificationUser(props) {
  const [verification, setVerification] = useState("");
  const [cookies, removeCookie] = useCookies();
  const [response, setResponse] = useState({});
  const [validCode, setValidCode] = useState(true);
  const { register, handleSubmit, setValue } = useForm();

  const email = cookies.email;
  const onSubmit = (formData) => {
    setValue("verification", formData.verification);
    doRequest(formData.verification);
  };

  const doRequest = async (code) => {
    setVerification(code);
    if (code.length === 6) {
      const data = await verifyUser(code, email);
      setResponse(data);
      if (data.response !== undefined) {
        if (data.response.status === 400) {
          setValidCode(false);
        }
      } else {
        if (data.status === 200) {
          removeCookie("verified");
          if (props.openLoginFromVerify1) {
            props?.openLoginFromVerify1();
          }
          if (props.isDashboard) {
            props.reload();
          }
        }
      }
    }
    if (code.length !== 6) {
      setValidCode(true);
    }
  };

  let payload = {
    email: cookies.email,
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      aria-hidden="true"
      style={{ zIndex: 99999 }}
    >
      <Modal.Header closeButton={props.onHide}>
        <div className={"d-flex justify-content-between"}>
          <Logo />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="rounded-4">
          <div className="d-flex justify-content-center mt-1 mx-5">
            <div>
              <h2 className={"text-center"}>A Verification Email Was Sent</h2>

              <p className="pt-2 ">
                Help us keep your account safe! We sent an email to{" "}
                {cookies.email} with a code to verify your account. The code
                expires in 24 hours.
              </p>

              <Form onSubmit={handleSubmit(onSubmit)} className="mt-5">
                {validCode ? (
                  <p></p>
                ) : (
                  <p className={"text-carre-red"}>
                    {response.response.data.message}
                  </p>
                )}
                <Form.Group controlId="si-email" className="mb-4">
                  <Form.Control
                    {...register("verification", { required: true })}
                    // onChange={(e) => doRequest(e.target.value)}
                    className={validCode ? "" : "border border-carre-red"}
                    type="number"
                    placeholder="Verification code"
                    required
                  />
                </Form.Group>
                <Button type={"submit"} size="lg" variant="carre-blue w-100">
                  Next
                </Button>
              </Form>
              <p style={{ marginBlock: "60px" }}>
                Didn’t receive an email?{" "}
                <button
                  style={{
                    border: "none",
                    background: "none",
                    textDecoration: "underline",
                  }}
                  className={"text-carre-blue"}
                  onClick={() => resendVerification(payload)}
                >
                  Resend
                </button>{" "}
                email.
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default VerificationUser;
