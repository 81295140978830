import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import NavBar from "../components/NavBar";
import AboutUsPage from "./AboutUsPage";
import Footer from "../components/Footer";

const AboutUsPageHome = () => {
  return (
    <div
      style={{
        overflow: "hidden",
        marginTop: "60px",
      }}
    >
      <NavBar />
      <AboutUsPage />
    </div>
  );
};

export default AboutUsPageHome;
