import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAcceptUserSellRequest } from "../hooks/useAcceptOrRejectUserSellReuqest";

function AdminAcceptSellRequest(props) {
  const {
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {},
  });

  const { mutate, isLoading } = useMutation(useAcceptUserSellRequest, {
    onSuccess: (data) => {
      notifySuccess();
      props.refetch().then(() => {});
      props.onHide();
    },
    onError: (error) => {
      notifyError(error?.response.data.error);
    },
  });
  const notifyError = (error) => toast.error(error);
  const notifySuccess = () =>
    toast.success("Property Share Bought Successfully");
  const onSubmit = (data) => {
    mutate(props.data);
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Accept Sell Request
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <span>
                Are you sure you want to accept the sell request from &nbsp;
                <span className="text-muted">{props.data?.email}</span>
                &nbsp; and amount of &nbsp;
                <span className="text-muted">{props.data?.token_qty}</span>
                &nbsp; shares ?
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="carre-gray-bg" onClick={props.onHide} size="sm">
              Close
            </Button>
            <Button
              type={"submit"}
              size="sm"
              variant="carre-blue w-25"
              disabled={isLoading}
            >
              {!isLoading ? " Accept Sell Request" : "Please wait"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default AdminAcceptSellRequest;
