import { useQuery } from "react-query";
import api from "../api";

export const useGetUserReservedToken = (propertyId) => {
  return useQuery(["reserved_tokens", propertyId], () => {
    const queryParams = propertyId ? { property_id: propertyId } : {};

    return api.get("/reserve-token/property", { params: queryParams }).then((res) => res.data);
  });
};


// {{server}}/reserve-token?property_id=4292be3f-91e0-4029-96ca-e8e456d60d06&status=ALL



export const useGetUserReservedTokenByPropertyId = (propertyId, status) => {
  return useQuery(["reserved_tokens", propertyId, status], () => {
    const queryParams = { property_id: propertyId, status: status };

    return api.get("/reserve-token", { params: queryParams }).then((res) => res.data);
  });
};
