import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useMutation } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import GoBackButton from "../components/GoBackButton";
import { useGetKycByEmail } from "../hooks/useGetKycByEmail";
import { useDeclineUserKyc, useVerifyUserKyc } from "../hooks/useVerifyUserKyc";
import { GetRoleNavigationPath } from "../routeUtils";
import ConfirmModal from "./Dashboard/ConfirmModal";

function UserKyc() {
  const location = useLocation();
  const kyc = location.state;
  const { data, refetch, isLoading } = useGetKycByEmail(kyc?.email);
  const nav = useNavigate();
  const roleUrl = GetRoleNavigationPath();
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

  // Handle opening and closing the ConfirmModal
  const openConfirmModal = () => {
    setConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };
  const { mutate: mutateDecline } = useMutation(useDeclineUserKyc, {
    onSuccess: (data) => {
      nav(`/${roleUrl}#usersubmittingkyc`);
      notifySuccess();
      refetch().then();
    },
    onError: (error) => {
      notifyError(error?.response.data.error);
    },
  });
  // Handler for confirming the token purchase
  const handleDeclineKyc = (data) => {
    closeConfirmModal(); // Close the modal
    mutateDecline({ email: kyc?.email, remark: data });
    // handleSubmit(onSubmit)(); // Trigger the form submission
  };
  const { mutate } = useMutation(useVerifyUserKyc, {
    onSuccess: (data) => {
      notifySuccess();
      nav(`/${roleUrl}#usersubmittingkyc`);
    },
    onError: (error) => {
      notifyError(error?.response.data.error);
    },
  });
  const notifyError = (error) => toast.error(error);
  const notifySuccess = () => toast.success("Account Verified Successfully");

  const onSubmit = () => {
    mutate({ email: kyc?.email, id: kyc.id });
  };
  const recentImages = data?.filter((file) => file.type === "RECENT_IMAGE");
  const pdfFiles = data?.filter((file) => file.type === "PDF");
  const imageFiles = data?.filter((file) => file.type === "IMAGE");
  const openImageModal = (image) => {
    setImageModalOpen(true);
    setSelectedImage(image);
  };

  const closeImageModal = () => {
    setImageModalOpen(false);
    setSelectedImage(null);
  };
  return (
    <div className="mb-5 mx-5">
      <GoBackButton />
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div className="d-flex flex-column">
          <h1 className="me-4 fw-bold">User Kyc</h1>
          <p className="mb-0 fw-bold">First Name :{kyc?.first_name}</p>
          <p className="mb-0 fw-bold">Last Name :{kyc?.last_name}</p>
          <p className="mb-0 fw-bold">Birthday :{kyc?.birthdate}</p>
          <p className="mb-0 fw-bold">Phone number :{kyc?.tel}</p>
          <p className="mb-0 fw-bold">Email: {kyc?.email}</p>

          <div className="mt-2 h-100">
            <span>{kyc?.address.street_name}</span>
            <span className="text-muted">
              {kyc?.address.street_name} {kyc?.address.city_name}
              ,&nbsp;
              {kyc?.address.country_state} {kyc?.address.country_name}
              &nbsp;
              {kyc?.address.zipcode}
            </span>
          </div>
        </div>
        <div>
          <button
            onClick={() => onSubmit()}
            className="btn btn-carre-purple me-3"
          >
            Confirm
          </button>
          <button
            className="btn btn-carre-red"
            onClick={() => setConfirmModalOpen(true)}
          >
            Decline
          </button>
        </div>
      </div>
      {isLoading && <h1>Please Wait</h1>}

      <div>
        <h2>Recent Images</h2>
        <Row>
          {recentImages?.map((file, index) => (
            <Col key={index} md={4} sm={6} xs={12} className="mb-4">
              <strong>File {index + 1}:</strong>
              <p className="text-truncate" style={{ maxWidth: 150 }}>
                {file.name}
              </p>
              {file.image_base64 && (
                <img
                  onClick={() =>
                    openImageModal(`data:image/png;base64,${file.image_base64}`)
                  }
                  src={`data:image/png;base64,${file.image_base64}`}
                  alt=""
                  style={{ width: "100%", height: "200px", objectFit: "cover" }}
                />
              )}
            </Col>
          ))}
        </Row>

        <div>
          <h2 className="m-5">PDFs </h2>
          <Row>
            {pdfFiles?.map((file, index) => (
              <Col key={index} md={4} sm={6} xs={12} className="mb-4">
                <strong>File {index + 1}:</strong>
                <p className="text-truncate" style={{ maxWidth: 150 }}>
                  {file.name}
                </p>
                {file.image_base64 && (
                  <embed
                    src={`data:application/pdf;base64,${file.image_base64}`}
                    type="application/pdf"
                    width="100%"
                    height="200px"
                  />
                )}
              </Col>
            ))}
          </Row>

          <h2 className="m-5">Images</h2>
          <Row>
            {imageFiles?.map((file, index) => (
              <Col key={index} md={4} sm={6} xs={12} className="mb-4">
                <strong>File {index + 1}:</strong>
                <p className="text-truncate" style={{ maxWidth: 150 }}>
                  {file.name}
                </p>
                {file.image_base64 && (
                  <img
                    onClick={() =>
                      openImageModal(
                        `data:image/png;base64,${file.image_base64}`
                      )
                    }
                    src={`data:image/png;base64,${file.image_base64}`}
                    alt=""
                    style={{
                      width: "100%",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                )}
              </Col>
            ))}
          </Row>
        </div>
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          onClose={closeConfirmModal}
          onConfirm={handleDeclineKyc}
          confirmationMessage={`Are you sure you want to cancle users kyc`}
          title={"Order Confirmation"}
          remark
        />
      </div>
      <Modal show={imageModalOpen} onHide={closeImageModal} fullscreen>
        <Modal.Body>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Full-Screen"
              style={{ width: "100%", height: "auto" }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeImageModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UserKyc;
