import React, { useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Card,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import {
  useGetVacationOrders,
  useUserCancelVacationOrder,
} from "../../../hooks/useGetVacationDetails";
import { MdDelete } from "react-icons/md";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import moment from "moment/moment";
import Offcanvas from "react-bootstrap/Offcanvas";
import Col from "react-bootstrap/Col";
import { IoMdFunnel } from "react-icons/io";

const AllUserOrders = () => {
  const [statusFilter, setStatusFilter] = useState(null);
  const [typeFilter, setTypeFilter] = useState(null);

  const { data, refetch } = useGetVacationOrders(statusFilter, typeFilter);
  const { data: filterData, refetch: refetchFilterData } = useGetVacationOrders(
    "PENDING",
    null
  );

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(useUserCancelVacationOrder, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("vacation-order-details");
      queryClient.invalidateQueries("vacation-transfer");
      queryClient.invalidateQueries("vacation-orders");
      queryClient.invalidateQueries("vacation");
      queryClient.invalidateQueries("vacation-order-book");

      refetch();
      refetchFilterData();
    },
    onError: (error) => {
      toast.error(error?.response.data.error);
    },
  });

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);

  const handleCancelOrder = (order) => {
    setOrderToDelete(order);
    setShowConfirmation(true);
  };

  const confirmDeleteOrder = () => {
    if (orderToDelete) {
      mutate(orderToDelete?.id);
      setShowConfirmation(false);
    }
  };
  const clearFilters = () => {
    setStatusFilter();
    setTypeFilter();
  };

  const [showFilters, setShowFilters] = useState(false);

  return (
    <div>
      <p className="fw-medium fs-4 my-4">Open Vacation Orders</p>
      {filterData && filterData.length > 0 ? (
        <>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-2 ">
            {filterData?.map((transaction) => (
              <div className="col" key={transaction?.id}>
                <Card bg="white" text="dark" className="rounded shadow-sm">
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <div className=" fs-sm">
                        ${transaction.total_price.toFixed(2)}{" "}
                      </div>
                      <div className="d-inline-flex align-items-center">
                        <Badge
                          bg="carre-gray"
                          text="black"
                          className="px-2 border-1 py-1 rounded text-xs"
                        >
                          {transaction.status}
                        </Badge>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <div className="fw-normal"> {transaction?.type}</div>
                      <div className="text-muted">
                        {transaction.points}
                        &nbsp;
                        <span className="fs-sm">Vacation Points</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <div className="text-muted">
                        {transaction?.amount?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <Button
                        variant="outline-carre-gray-bg"
                        size="sm"
                        className="fw-light"
                        onClick={() => {
                          handleCancelOrder(transaction);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <div className="text-muted fs-sm">
                        {moment(
                          transaction.token_selling_request?.created_at
                        ).format("MMM D HH:mm")}{" "}
                      </div>
                      <div className="d-inline-flex align-items-center text-muted fs-sm">
                        {transaction.end_period === "END_OF_DAY" ? (
                          <div>Valid : End of day</div>
                        ) : (
                          <div>Valid: Until Completed</div>
                        )}{" "}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </>
      ) : (
        <Alert className="text-center fs-sm p-2 fw-semibold mt-4">
          No data available.
        </Alert>
      )}{" "}
      <p className="fw-medium fs-4 my-4">Vacation Order Historical</p>
      <div className="d-sm-block d-md-none mt-5 mb-3">
        <Button
          variant="primary"
          size="sm"
          onClick={() => setShowFilters(true)}
        >
          <IoMdFunnel /> Filters
        </Button>
      </div>
      <Offcanvas show={showFilters} onHide={() => setShowFilters(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filters</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col lg={6} md={6}>
              <Row>
                <Col sm={12} md={6}>
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    aria-label="Status"
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                    name="statusFilter"
                  >
                    <option value="">ALL</option>
                    <option value="PENDING">Pending</option>
                    <option value="CANCELED">Canceled</option>
                    <option value="IN_PROGRESS">In Progress</option>
                  </Form.Select>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col>
                  <Form.Label>Order Type</Form.Label>
                  <Form.Select
                    aria-label="Type"
                    value={typeFilter}
                    onChange={(e) => setTypeFilter(e.target.value)}
                    name="typeFilter"
                  >
                    <option value="">ALL</option>
                    <option value="BUY">Buy</option>
                    <option value="SELL">Sell</option>
                  </Form.Select>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span className="text-muted">Filters</span>
            <Button variant="link" onClick={clearFilters}>
              Clear All
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <div className="d-none d-md-block mt-2 mb-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <span className="text-muted">Filters</span>
          <Button variant="link" onClick={clearFilters}>
            Clear All
          </Button>
        </div>
        <Row>
          <Col md={6}>
            <Row>
              <Col>
                <Form.Label>Order Type</Form.Label>
                <Form.Select
                  aria-label="Type"
                  value={typeFilter}
                  onChange={(e) => setTypeFilter(e.target.value)}
                  name="typeFilter"
                >
                  <option value="">ALL</option>
                  <option value="BUY">Buy</option>
                  <option value="SELL">Sell</option>
                </Form.Select>
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={6}>
            <Form.Label>Status</Form.Label>
            <Form.Select
              aria-label="Status"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              name="statusFilter"
            >
              <option value="">ALL</option>
              <option value="PENDING">Pending</option>
              <option value="CANCELED">Canceled</option>
              <option value="IN_PROGRESS">In Progress</option>
            </Form.Select>
          </Col>
        </Row>
      </div>
      {data && data.length > 0 ? (
        <>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-2 ">
            {data?.map((transaction) => (
              <div className="col" key={transaction?.id}>
                <Card bg="white" text="dark" className="rounded shadow-sm">
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <div className=" fs-sm">
                        ${transaction.total_price.toFixed(2)}{" "}
                      </div>
                      <div className="d-inline-flex align-items-center">
                        <Badge
                          bg="carre-gray"
                          text="black"
                          className="px-2 border-1 py-1 rounded text-xs"
                        >
                          {transaction.status}
                        </Badge>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <div className="fw-normal"> {transaction?.type}</div>
                      <div className="text-muted">
                        {transaction.points}
                        &nbsp;
                        <span className="fs-sm">Vacation Points</span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <div className="text-muted">
                        {transaction?.amount?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </div>
                    </div>
                    {/*<div className="d-flex justify-content-between align-items-center">*/}
                    {/*  <Button*/}
                    {/*    variant="outline-carre-gray-bg"*/}
                    {/*    size="sm"*/}
                    {/*    className="fw-light"*/}
                    {/*    onClick={() => {*/}
                    {/*      handleCancelOrder(data);*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*    Cancel*/}
                    {/*  </Button>*/}
                    {/*</div>*/}
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <div className="text-muted fs-sm">
                        {moment(
                          transaction.token_selling_request?.created_at
                        ).format("MMM D HH:mm")}{" "}
                      </div>
                      <div className="d-inline-flex align-items-center text-muted fs-sm">
                        {transaction.end_period === "END_OF_DAY" ? (
                          <div>Valid : End of day</div>
                        ) : (
                          <div>Valid: Until Completed</div>
                        )}{" "}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </>
      ) : (
        <Alert className="text-center fs-sm p-2 fw-semibold mt-4">
          No data available.
        </Alert>
      )}
      <Modal
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete your{" "}
          {orderToDelete?.type === "BUY" ? "buy" : "sell"} order for{" "}
          {orderToDelete?.points} vacation points for the price of{" "}
          {orderToDelete?.point_price.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}{" "}
          or {orderToDelete?.type === "BUY" ? "lower" : "higher"} created on{" "}
          {moment(orderToDelete?.created_at).format("MMM D HH:mm")}?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant="carre-gray-bg" onClick={confirmDeleteOrder}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AllUserOrders;
