import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./auth/TokenCreation";
import { useGetUserData } from "./hooks/useGetUserInformation";

export const GetRoleNavigationPath = () => {
    const { data , isError, } = useGetUserData();

   const nav = useNavigate()
   const role = useAuth();

  
    // useEffect(() => {
    //     if (isError === true) {
    //         nav("/")
    //     }
    //   }, [isError]);

     
    if (data?.role === "USER" || data?.role === "MANAGER" || data?.role === "ADMIN" || data?.role === "SUPERADMIN") {
        return data?.role?.toLowerCase();
    }
    else{
        return role?.role?.toLowerCase()
    }
  };