import { useState } from "react";
import api from "../api";

export const useSendCoinbaseId = () => {
  const [result, setResult] = useState(null);

  const sendCoinbaseId = async (id) => {
    try {
      const response = await api.post(`/coinbase-charge/${id}`);
      const data = await response.data;
      setResult(data);
    } catch (error) {
      // Handle the error if needed
    }
  };

  return { sendCoinbaseId, result };
};
