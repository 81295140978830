import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useGetKycUser } from "../hooks/useGetKyc";
import { GetRoleNavigationPath } from "../routeUtils";

function UserKycs() {
  const navigation = useNavigate();
  const { data, isLoading, refetch } = useGetKycUser();
  // Navigate to UserKyc page and pass kyc object as state

  useEffect(() => {
  refetch()
  }, []);
  const roleUrl = GetRoleNavigationPath();
  
      // nav(`/${roleUrl}#usersubmittingkyc`);
  const handleViewKyc = (kyc) => {
    navigation(`/${roleUrl}/user/kyc`, { state: kyc });
  };
  return (
    <div className="">
      <table className="table table-bordered table-sm table-hover">
        <thead>
          <tr>
            <th scope="col">Email</th>
            <th scope="col">Full Name</th>
            <th scope="col">Kyc verified</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map &&
            data.map((kyc) => (
              <tr key={kyc?.id}>
                <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                  {kyc.email}
                </td>
                <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                  {kyc.full_name}
                </td>
                <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                  {kyc.kyc_verify}
                </td>
                <td width="100">
                  <Dropdown className="mx-4">
                    <Dropdown.Toggle variant="carre-purple" id="dropdown-basic">
                      Actions
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleViewKyc(kyc)}>
                        <MdEdit className="mb-1" /> See User Kyc
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
}

export default UserKycs;
