import * as React from "react"

const ListPropertyIcon = ({props,color="#A0A0AA"}) => (
    <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#a)" fill={color}>
            <path
                d="M0 22.594h24V24H0v-1.406ZM24 19.366h-1.614V11.09H24V8.481l-5.253-5.253h-7.658V0H6.455v3.228H5.253L0 8.48v2.608h1.614v8.277H0v1.406h24v-1.406ZM18.164 4.634l4.43 4.43v.62h-5.461l-5.05-5.05h6.081ZM7.862 1.406h1.821v1.822H7.862V1.406ZM1.406 9.683v-.62l4.43-4.43h6.08l-5.049 5.05h-5.46Zm1.614 4.634h1.822v1.821H3.02v-1.821Zm11.505 5.05h-5.05v-5.05h5.05v5.05Zm6.455-3.229h-1.822v-1.821h1.822v1.821Zm0-3.227h-3.228v4.634h3.228v1.821h-5.05v-6.455H8.07v6.455H3.02v-1.821h3.228V12.91H3.02v-1.822h4.43L12 6.54l4.55 4.55h4.43v1.822Z"/>
            <path d="M10.387 9.684h3.227v1.406h-3.227V9.684Z"/>
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h24v24H0z"/>
            </clipPath>
        </defs>
    </svg>
)

export default ListPropertyIcon
