import React, { useEffect, useState } from "react";
// import { useUploadImage } from "../../hooks/useUploadKyc";
import { Form } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useGetUserDataKYC } from "../../hooks/useGetUserInformation";
// import { useGetUserKyc } from "../../hooks/useGetUserKyc";
import { useUpdatedUser } from "../../hooks/useUpdateUser";
import { useUploadFile } from "../../hooks/useUploadKyc";
import { useUploadProfilePicture } from "../../hooks/useUploadProfilePicture";

export default function PersonalInfo() {
  const [cookies] = useCookies();
  // const { data, refetch } = useGetUserKyc();
  const { data: userData, refetch: userRefetch } = useGetUserDataKYC();
  // const { mutate: mutateProfilePicture } = useUploadProfilePicture();
  // const [profilePicture, setProfilePicture] = useState();
  // const [profilePictureUrl, setProfilePictureUrl] = useState();
  // const notifyError = () => toast.error("Something went wrong");
  // const notifySuccess = () =>
  //   toast.success("Kyc picture uploaded successfully ");

  // const [isEditMode, setIsEditMode] = useState(false);
  //
  // const [selectedFiles, setSelectedFiles] = useState([]);
  const { mutate } = useUploadFile();
  // const { data, refetch, isLoading } = useGetUserKyc();
  // const [recentImage, setRecentImage] = useState(null);

  // const handleFileChange = (e) => {
  //   const filesArray = Array.from(e.target.files);
  //   setSelectedFiles([...selectedFiles, ...filesArray]);
  // };

  // const handleUploadAll = () => {
  //   selectedFiles.forEach((file) => {
  //     const type = getFileTypeFromExtension(file.name);
  //     mutate({ file, type });
  //   });
  //
  //   if (recentImage) {
  //     const type = "RECENT_IMAGE";
  //     mutate({ file: recentImage, type });
  //     setRecentImage(null);
  //   }
  //
  //   setSelectedFiles([]);
  //   refetch().then(); // Clear selected files
  // };
  //
  // const getFileTypeFromExtension = (fileName) => {
  //   const extension = fileName.split(".").pop().toLowerCase();
  //   if (
  //     extension === "jpg" ||
  //     extension === "jpeg" ||
  //     extension === "png" ||
  //     extension === "gif"
  //   ) {
  //     return "IMAGE";
  //   } else if (extension === "pdf") {
  //     return "PDF";
  //   } else {
  //     return "OTHER"; // Handle other file types as needed
  //   }
  // };
  //
  // const handleDelete = (index) => {
  //   const updatedFiles = selectedFiles.filter((_, i) => i !== index);
  //   setSelectedFiles(updatedFiles);
  // };
  //
  // const handleRecentImageChange = (e) => {
  //   const file = e.target.files[0];
  //   setRecentImage(file);
  // };

  // const handlProfileImageChange = (event) => {
  //   const file = event.target.files[0];
  //   const imageUrl = URL.createObjectURL(file);
  //   setProfilePictureUrl(imageUrl);
  //   setProfilePicture(file);
  // };
  //
  // const handleProfileImageUpload = () => {
  //   mutateProfilePicture(profilePicture, {
  //     onSuccess: () => {
  //       notifySuccess();
  //       refetch();
  //       userRefetch();
  //     },
  //     onError: (error) => {
  //       notifyError();
  //     },
  //   });
  // };
  //
  const { mutate: personalInfoMutate } = useMutation(useUpdatedUser, {
    onSuccess: (data) => {
      toast.success("Account updated successfully");

      // refetch();
      userRefetch();
    },
    onError: (data) => {},
  });

  useEffect(() => {
    if (userData) {
      // setProfilePictureUrl(userData?.profile_image);
      // setIsEditMode(true);

      reset({
        email: userData.email,
        gender: userData?.gender, // Added gender field
        tel: userData.tel,
        first_name: userData.first_name,
        last_name: userData.last_name,
        birthdate: userData?.birthdate,
        address: {
          number_building: userData?.address?.number_building,
          number_street: userData?.address?.number_street,
          street_name: userData?.address?.street_name,
          city_name: userData?.address?.city_name,
          zipcode: userData?.address?.zipcode,
          country_state: userData?.address?.country_state,
          country_name: userData?.address?.country_name,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const updatedData = {
      address: {
        zipcode: data?.address?.zipcode,
        number_building: data?.address?.number_building,
        number_street: data?.address?.number_street,
        street_name: data?.address?.street_name,
        city_name: data?.address?.city_name,
        country_state: data?.address?.country_state,
        country_name: data?.address?.country_name,
      },
      american_citizen: !data.american_citizen,
      email: data?.email,
      tel: data?.tel,
      first_name: data?.first_name,
      last_name: data?.last_name,
      birthdate: data?.birthdate,
      subscribed: data?.subscribed,
      gender: data?.gender, // Added gender field
    };
    // if (profilePicture) {
    //   handleProfileImageUpload();
    // }

    // handleUploadAll();
    // if (isEditMode) {
    personalInfoMutate(updatedData);
    //   refetch();
    // } else {
    //   if (selectedFiles.length > 0 || recentImage) {
    //     handleUploadAll();
    //     if (profilePicture) {
    //       handleProfileImageUpload();
    //     }
    //     if (data?.length !== 0 || recentImage || data !== null) {
    //       personalInfoMutate(updatedData);
    //       refetch();
    //     }
    //   }

    // }
  };

  return (
    <div className="container-fluid">
      <h2>Personal Info</h2>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            size="sm"
            disabled
            value={cookies?.email}
            {...register("email")}
            type="email"
            className=" mb-2 border-1 form-control text-muted"
          />
        </Form.Group>
        <hr className="bg-secondary" />

        <Form.Group controlId="firstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            size="sm"
            {...register("first_name")}
            type="text"
            className={` mb-2border-1 form-control ${
              errors.first_name ? "border-1 border-danger" : ""
            }`}
          />
          {errors.first_name && (
            <p className="text-danger">This field is required</p>
          )}
        </Form.Group>
        <hr className="bg-secondary" />

        <Form.Group controlId="lastName">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            size="sm"
            {...register("last_name")}
            type="text"
            className={`mb-2 border-1 form-control ${
              errors.last_name ? "border-1 border-danger" : ""
            }`}
          />
          {errors.last_name && (
            <p className="text-danger">This field is required</p>
          )}
        </Form.Group>
        <hr className="bg-secondary" />

        <Form.Group controlId="phoneNumber">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            size="sm"
            type="tel"
            {...register("tel", {
              pattern: {
                value: /^\+?[0-9]*$/,
                message: "Only numbers and '+' are allowed",
              },
            })}
            className={`mb-2 border-1 form-control ${
              errors.tel ? "border-1 border-danger" : ""
            }`}
          />
          {errors.tel && <p className="text-danger">{errors.tel.message}</p>}
        </Form.Group>

        <hr className="bg-secondary" />

        <Form.Group controlId="numberBuilding">
          <Form.Label>Number of Building</Form.Label>
          <Form.Control
            size="sm"
            placeholder="Number of building"
            type="number"
            {...register("address.number_building")}
            className={`mb-2 form-control ${
              errors.address?.number_building
                ? "border-1 border-danger bg-danger"
                : ""
            }`}
          />
          {errors.address?.number_building && (
            <p className="text-danger">This field is required</p>
          )}
        </Form.Group>

        <Form.Group controlId="numberStreet">
          <Form.Label>Street Number</Form.Label>
          <Form.Control
            size="sm"
            type="number"
            {...register("address.number_street", {
              valueAsNumber: true,
              required: "Street number is required",
            })}
            className={`mb-2 form-control mb-2 ${
              errors.address?.number_street
                ? "border-1 border-danger bg-danger"
                : ""
            }`}
            placeholder="Number/Street"
          />
          {errors.address?.number_street && (
            <p className="text-danger">This field is required</p>
          )}
        </Form.Group>

        <Form.Group controlId="streetName">
          <Form.Label>Street Name</Form.Label>
          <Form.Control
            size="sm"
            {...register("address.street_name")}
            type="text"
            className={`mb-2 form-control ${
              errors.address?.street_name
                ? "border-1 border-danger bg-danger"
                : ""
            }`}
            placeholder="Street Name"
          />
          {errors.address?.street_name && (
            <p className="text-danger">This field is required</p>
          )}
        </Form.Group>

        <Form.Group controlId="cityName">
          <Form.Label>City Name</Form.Label>
          <Form.Control
            size="sm"
            {...register("address.city_name")}
            type="text"
            className={`mb-2 form-control ${
              errors.address?.city_name
                ? "border-1 border-danger bg-danger"
                : ""
            }`}
            placeholder="City Name"
          />
          {errors.address?.city_name && (
            <p className="text-danger">This field is required</p>
          )}
        </Form.Group>

        <Form.Group controlId="zipcode">
          <Form.Label>Zipcode</Form.Label>
          <Form.Control
            size="sm"
            {...register("address.zipcode", {
              valueAsNumber: true,
            })}
            type="text"
            className={`mb-2 form-control ${
              errors.address?.zipcode ? "border-1 border-danger bg-danger" : ""
            }`}
            placeholder="Zip Code"
          />
        </Form.Group>

        <Form.Group controlId="countryState">
          <Form.Label>State</Form.Label>
          <Form.Control
            size="sm"
            {...register("address.country_state")}
            type="text"
            className={`mb-2 form-control ${
              errors.address?.country_state
                ? "border-1 border-danger bg-danger"
                : ""
            }`}
            placeholder="Country/State"
          />
        </Form.Group>

        <Form.Group controlId="countryName">
          <Form.Label>Country</Form.Label>
          <Form.Control
            size="sm"
            {...register("address.country_name")}
            type="text"
            className={`mb-2 form-control ${
              errors.address?.country_name
                ? "border-1 border-danger bg-danger"
                : ""
            }`}
            placeholder="Country Name"
          />
          {errors.address?.country_name && (
            <p className="text-danger">This field is required</p>
          )}
        </Form.Group>

        <Form.Group controlId="birthdate">
          <Form.Label>
            Birthdate (as it appears in the official documents)
          </Form.Label>
          <Form.Control
            size="sm"
            format="yyyy-MM-dd"
            required
            {...register("birthdate")}
            type="date"
            className={`form-control ${
              errors.birthdate ? "border-1 border-danger" : ""
            }`}
          />
          {errors.birthdate && (
            <p className="text-danger">This field is required</p>
          )}
        </Form.Group>
        <Form.Group controlId="gender">
          <Form.Label>Gender</Form.Label>
          <Form.Control
            as="select"
            size="sm"
            {...register("gender", { required: "Gender is required" })}
            className="mb-2 form-control"
            placeholder="Select Gender"
          >
            <option value="">Select Gender</option>
            <option value="M">Male</option>
            <option value="F">Female</option>
          </Form.Control>
          {errors.gender && (
            <p className="text-danger">{errors.gender.message}</p>
          )}
        </Form.Group>
      </Form>

      {/*<div className="file-uploader">*/}
      {/*  <Form.Group>*/}
      {/*    <Form.Label>Upload recent photography of yourself</Form.Label>*/}
      {/*    <Form.Control*/}
      {/*      type="file"*/}
      {/*      accept=".jpg,.jpeg,.png,.gif,.pdf"*/}
      {/*      onChange={handleRecentImageChange}*/}
      {/*    />*/}
      {/*  </Form.Group>*/}

      {/*  {recentImage ? (*/}
      {/*    <div className="recent-image-preview">*/}
      {/*      <img*/}
      {/*        src={URL.createObjectURL(recentImage)}*/}
      {/*        alt="Recent Preview"*/}
      {/*        className="recent-image"*/}
      {/*      />*/}
      {/*      <MdDelete*/}
      {/*        size={20}*/}
      {/*        onClick={() => setRecentImage(null)}*/}
      {/*        className="text-carre-red cursor-pointer delete-icon"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  ) : (*/}
      {/*    <div className="recent-image-preview">*/}
      {/*      <img*/}
      {/*        src={`data:application/pdf;base64,${*/}
      {/*          data?.find((image) => image.type === "RECENT_IMAGE")*/}
      {/*            ?.image_base64*/}
      {/*        }`}*/}
      {/*        alt="Recent Preview"*/}
      {/*        className="recent-image"*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  )}*/}
      {/*  <Form className="mt-2">*/}
      {/*    <Form.Group>*/}
      {/*      <Form.Label>*/}
      {/*        Upload your proof of identity{" "}*/}
      {/*        <span className="text-muted">(passport, ID)</span>*/}
      {/*      </Form.Label>*/}
      {/*      <Form.Control*/}
      {/*        type="file"*/}
      {/*        accept=".jpg,.jpeg,.png,.gif,.pdf"*/}
      {/*        multiple*/}
      {/*        onChange={handleFileChange}*/}
      {/*      />*/}
      {/*    </Form.Group>*/}
      {/*  </Form>*/}

      {/*  <Row className="file-list">*/}
      {/*    {selectedFiles?.map((file, index) => (*/}
      {/*      <Col key={index} md={4} sm={6} xs={12} className="file-item">*/}
      {/*        <div className="d-flex justify-content-between file-details align-items-center">*/}
      {/*          <div className="d-flex align-items-center">*/}
      {/*            <strong>File {index + 1}:</strong>*/}
      {/*            <span*/}
      {/*              className="ml-3 text-truncate"*/}
      {/*              style={{ maxWidth: 150 }}*/}
      {/*            >*/}
      {/*              {file?.name}*/}
      {/*            </span>*/}
      {/*          </div>*/}
      {/*          <MdDelete*/}
      {/*            size={20}*/}
      {/*            onClick={() => handleDelete(index)}*/}
      {/*            className="text-carre-red cursor-pointer"*/}
      {/*          />*/}
      {/*        </div>*/}
      {/*        <div className="file-preview">*/}
      {/*          {file?.type.startsWith("image") ? (*/}
      {/*            <img*/}
      {/*              src={URL.createObjectURL(file)}*/}
      {/*              alt={`Preview ${index}`}*/}
      {/*            />*/}
      {/*          ) : (*/}
      {/*            <div className="pdf-preview">*/}
      {/*              <embed*/}
      {/*                src={URL.createObjectURL(file)}*/}
      {/*                className="pdf-embed"*/}
      {/*                type="application/pdf"*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*          )}*/}
      {/*        </div>*/}
      {/*      </Col>*/}
      {/*    ))}*/}
      {/*    {data?.map((file, index) => (*/}
      {/*      <Col key={index} md={4} sm={6} xs={12} className="file-item">*/}
      {/*        <div className="d-flex justify-content-between file-details align-items-center">*/}
      {/*          <div className="d-flex align-items-center">*/}
      {/*            <strong>File {index + 1}:</strong>*/}
      {/*            <span*/}
      {/*              className="ml-3 text-truncate"*/}
      {/*              style={{ maxWidth: 150 }}*/}
      {/*            >*/}
      {/*              {file.name}*/}
      {/*            </span>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*        <div className="file-preview">*/}
      {/*          {file.type === "IMAGE"*/}
      {/*            ? file &&*/}
      {/*              file.image_base64 && (*/}
      {/*                <img*/}
      {/*                  src={`data:image/png;base64,${file.image_base64}`}*/}
      {/*                  alt=""*/}
      {/*                />*/}
      {/*              )*/}
      {/*            : file &&*/}
      {/*              file.image_base64 && (*/}
      {/*                <div className="pdf-preview">*/}
      {/*                  <embed*/}
      {/*                    src={`data:application/pdf;base64,${file?.image_base64}`}*/}
      {/*                    className="pdf-embed"*/}
      {/*                    type="application/pdf"*/}
      {/*                  />*/}
      {/*                </div>*/}
      {/*              )}*/}
      {/*        </div>*/}
      {/*      </Col>*/}
      {/*    ))}*/}
      {/*  </Row>*/}

      {/*  /!* {selectedFiles.length > 0 && (*/}
      {/*  <Button*/}
      {/*    className="mt-3"*/}
      {/*    variant="carre-blue text-white"*/}
      {/*    onClick={handleUploadAll}*/}
      {/*  >*/}
      {/*    Upload All Files*/}
      {/*  </Button>*/}
      {/*)} *!/*/}
      {/*</div>*/}

      <div className="my-5 border container-sm" />
      <Form.Group controlId="formCheckbox" className="mt-2">
        <Form.Check
          {...register("subscribed")}
          type="checkbox"
          defaultChecked={userData?.subscribed}
          label="Subcribed to newsleter"
        />
      </Form.Group>
      <Form.Group controlId="formCheckbox" className="mt-2">
        <Form.Check
          {...register("american_citizen")}
          type="checkbox"
          defaultChecked={!userData?.american_citizen}
          label="I certify that I am not a Citizen or a Resident of the United States of America"
        />
      </Form.Group>
      <div className="my-5 border" />
      {/* Button section */}
      <div className="mb-3 d-flex align-items-center">
        <button
          type={"submit"}
          onClick={handleSubmit(onSubmit)}
          className="text-white me-2 btn btn-carre-purple"
          style={{ padding: "10px 24px" }}
        >
          Save Changes
        </button>
        <div className="ms-auto">
          {/*<button*/}
          {/*  className="btn"*/}
          {/*  style={{*/}
          {/*    backgroundColor: "#F5F4F8",*/}
          {/*    border: "1px solid #D5D2DC",*/}
          {/*    padding: "10px 24px",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Contact US*/}
          {/*</button>*/}
        </div>
      </div>
    </div>
  );
}
