import React, { useEffect, useState } from "react";
import { Tabs, Tab, Container } from "react-bootstrap";
import UserOrderRequest from "../UserOrderRequest";
import AllUserOrders from "./Vacation/AllUserOrders";
import { useParams } from "react-router-dom";

const OrderTabs = () => {
  const [key, setKey] = useState("order");
  const { type } = useParams();
  useEffect(() => {
    if (type === "vacation") {
      setKey("vacation");
    }
  }, [type]);

  return (
    <Container className="mt-4">
      <Tabs
        id="order-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mt-5 mt-sm-0"
        variant="underline" // Use tabs variant
      >
        <Tab eventKey="order" title="Property Order">
          <UserOrderRequest />
        </Tab>
        <Tab eventKey="vacation" title="Vacation Order">
          <AllUserOrders />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default OrderTabs;
