import { useGetUserData } from "./useGetUserInformation";

const useCheckUserSteps = () => {
  const { data, refetch } = useGetUserData();

  if (!data) return false;

  const requiredSteps = ["KYC", "LATER_KYC", "APPLICATION", ""];
  return requiredSteps.includes(data.guide_step);
};

export default useCheckUserSteps;
