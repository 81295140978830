import axios from "axios";
import useGetQueryParam from "./hooks/getQueryParams";
import getQueryParams from "./hooks/getQueryParams";

const cookies = document.cookie.split("; ");
const api = axios.create({
  baseURL: process.env.REACT_APP_API_KEY || "http://localhost:9990",
});

const parseCookie = () => {
  const cookies = document.cookie.split("; ");
  const parsedCookie = {};
  cookies.forEach((pair) => {
    const [key, value] = pair.split("=");
    parsedCookie[key] = value;
  });
  return parsedCookie;
};

api.interceptors.request.use(
  async (req) => {
    const parsedCookie = parseCookie();
    if (parsedCookie?.token) {
      req.headers["Authorization"] = `Bearer ${parsedCookie.token}`;
    }

    if (req.params !== undefined) {
      Object.entries(getQueryParams()).map(
        ([key, value]) => (req.params[key] = value)
      );
    } else {
      req.params = useGetQueryParam();
    }

    if (document.referrer !== "") {
      req.params["user_source"] = document.referrer;
    }

    return req;
  },
  (error) => Promise.reject(error)
);

export default api;
