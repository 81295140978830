/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "./sideBarStyle.css";

import { Dropdown, Modal, Nav, Navbar, Offcanvas } from "react-bootstrap";
import {
  AiOutlineDashboard,
  AiOutlineLogout,
  AiOutlinePieChart,
  AiOutlineSetting,
} from "react-icons/ai";
import { BsWallet2 } from "react-icons/bs";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FiBook, FiInfo } from "react-icons/fi";
import { GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import { MdAccountBalance } from "react-icons/md";
import { PiBuildingsDuotone } from "react-icons/pi";
import { TbBeach, TbHomeQuestion } from "react-icons/tb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SideBaIcon from "../../assets/img/SideBarIcon.png";
import { useAuth } from "../../auth/TokenCreation";
import Logo from "../../components/Logo";
import WhiteLogo from "../../components/WhiteLogo";
import { useGetAccount } from "../../hooks/useGetAccount";
import { useGetUserData } from "../../hooks/useGetUserInformation";
import { GetRoleNavigationPath } from "../../routeUtils";
import PaypalCheckoutForm from "../paypal/PaypalCheckoutForm";
import { PaymentMethod } from "./PaymentMethodeModal";
import { useTabContext } from "./TabContext";
import Onboarding from "./OnBoarding";
import OnboardingModal from "../../components/OnBoardingModal";

const navigation = [
  {
    name: "Dashboard",
    icon: <AiOutlineDashboard className="icon" />,
    to: ``,
  },
  {
    name: "Marketplace",
    icon: <PiBuildingsDuotone className="icon" />,
    to: "marketplace",
  },
  {
    name: "Assets",
    icon: <AiOutlinePieChart className="icon" />,
    to: "assets",
  },
  {
    name: "Account",
    icon: <MdAccountBalance className="icon" />,
    to: "account",
  },
  {
    name: "Vacation Points",
    icon: <TbBeach className="icon" />,
    to: "vacation",
  },
  {
    name: "Wallet",
    icon: <BsWallet2 className="icon" />,
    to: "wallets",
  },

  {
    name: "Order",
    icon: <TbHomeQuestion className="icon" />,
    to: "order",
  },
  {
    name: "Withdraw",
    icon: <GiReceiveMoney className="icon" />,
    to: "withdraw",
  },
  // {
  //   name: "History",
  //   icon: <IOrderHistory />,
  //   iconHover: <IOrderHistory color="#3899da" />,
  //   to: "orderhistory",
  // },
  {
    name: "Learn",
    icon: <FiBook className="icon" />,
    to: "/helpcenter",
  },
  {
    name: "About Us ",
    icon: <FiInfo className="icon" />,
    to: "aboutuspage",
  },
  {
    to: "settings",
  },
];
const userNavigation = [
  {
    name: "Marketplace",
    icon: <PiBuildingsDuotone className="icon" />,
    to: "marketplace",
  },
  {
    name: "Assets",
    icon: <AiOutlinePieChart className="icon" />,
    to: "assets",
  },
  {
    name: "Account",
    icon: <MdAccountBalance className="icon" />,
    to: "account",
  },
  {
    name: "Vacation Points",
    icon: <TbBeach className="icon" />,
    to: "vacation",
  },
  {
    name: "Wallet",
    icon: <BsWallet2 className="icon" />,
    to: "wallets",
  },

  {
    name: "Order",
    icon: <TbHomeQuestion className="icon" />,
    to: "order",
  },
  {
    name: "Withdraw",
    icon: <GiReceiveMoney className="icon" />,
    to: "withdraw",
  },
  {
    name: "Learn",
    icon: <FiBook className="icon" />,
    to: "/helpcenter",
  },
  {
    name: "About Us ",
    icon: <FiInfo className="icon" />,
    to: "aboutuspage",
  },
  {
    to: "settings",
  },
];

function Sidebar({ setShowSidebar, showSidebar }) {
  const { data, refetch } = useGetUserData();
  const { logout } = useAuth();

  const nav = useNavigate();
  const roleUrl = GetRoleNavigationPath();
  const { role } = useAuth();
  const { data: accountData, refetch: refetchData } = useGetAccount();

  const [activeItem, setActiveItem] = useState("marketplace");
  const {
    setDepositModalOpen,
    paypalButton,
    setPaypalButton,
    paypalAmount,
    setShowOffcanvas,
    showOffcanvas,
    depositModalOpen,
  } = useTabContext();
  useEffect(() => {
    refetch();
  }, []);
  const { pathname } = useLocation();

  useEffect(() => {
    const segments = pathname?.split("/");
    const activeNavItem =
      segments.length === 2
        ? (navigation || userNavigation).find(
            (item) => item.to === "/" + segments[1]
          )
        : (navigation || userNavigation).find(
            (item) => item.to === segments[2]
          );
    if (activeNavItem) {
      setActiveItem(activeNavItem.to);
    }
    if (
      pathname === "/manager" ||
      pathname === "/superadmin" ||
      pathname === "/admin"
    ) {
      setActiveItem("Dashboard");
    }
  }, [pathname]);

  const navigationItems =
    role === "ADMIN" || role === "SUPERADMIN" || role === "MANAGER" ? (
      <>
        <header className="d-none d-sm-block mb-4">
          <div className="image-text">
            <span className="image">
              <div className="circular-div">
                {data?.first_name?.charAt(0)}
                {data?.last_name?.charAt(0)}
              </div>
            </span>
            <div className="text logo-text">
              <span className="name">
                {data?.first_name} {data?.last_name}
              </span>
              <span className="profession"> {data?.tel}</span>
            </div>
          </div>
        </header>
        <li className="mb-2">
          <a
            onClick={() => {
              setShowOffcanvas(false);
              setDepositModalOpen(true);
            }}
          >
            <GiPayMoney size={25} className="icon" />
            <span className="text nav-text" style={{ marginLeft: "15px" }}>
              Deposit
            </span>
          </a>
        </li>
        <div className="border-bottom border-2"></div>
        {data?.guide_step !== "FINISH" && (
          <>
            <li className="w-100 mb-2">
              <Onboarding />
            </li>
            <div className="border-bottom border-2"></div>
          </>
        )}

        {navigation
          .filter((item) => item.to !== "settings")
          .map((item, index) => (
            <li
              onClick={() => {
                if (item.to === "/helpcenter") {
                  window.open("https://learn.carre.ai/", "_blank");
                } else {
                  nav(`/${roleUrl}/${item.to}`);
                }
              }}
              key={index}
            >
              <a
                onClick={() => setShowOffcanvas(false)}
                className={activeItem === item.to ? "active" : ""}
              >
                {item.icon}
                <span className="text nav-text">{item.name}</span>
              </a>
            </li>
          ))}
      </>
    ) : (
      <>
        <header className="d-none d-sm-block mb-4">
          <div className="image-text">
            <span className="image">
              <div className="circular-div">
                {data?.first_name?.charAt(0)}
                {data?.last_name?.charAt(0)}
              </div>
            </span>
            <div className="text logo-text">
              <span className="name">
                {data?.first_name} {data?.last_name}
              </span>
              <span className="profession"> {data?.tel}</span>
            </div>
          </div>
        </header>
        <li className="">
          <a
            onClick={() => {
              setShowOffcanvas(false);
              setDepositModalOpen(true);
            }}
          >
            <GiPayMoney size={25} className="icon" />
            <span className="text nav-text" style={{ marginLeft: "15px" }}>
              Deposit
            </span>
          </a>
        </li>
        <div className="border-bottom border-2"></div>
        {data?.guide_step !== "FINISH" && (
          <>
            <li className="w-100 mb-2">
              <Onboarding />
            </li>
            <div className="border-bottom border-2"></div>
          </>
        )}

        {userNavigation
          .filter((item) => item.to !== "settings")
          .map((item, index) => (
            <li
              key={index}
              onClick={() => {
                if (item.to === "/helpcenter") {
                  window.open("https://learn.carre.ai/", "_blank");
                } else {
                  nav(`/${roleUrl}/${item.to}`);
                }
              }}
            >
              <a
                onClick={() => setShowOffcanvas(false)}
                className={activeItem === item.to ? "active" : ""}
              >
                {item.icon}
                <span className="text nav-text">{item.name}</span>
              </a>
            </li>
          ))}
      </>
    );
  return (
    <>
      <Modal
        show={depositModalOpen}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <PaymentMethod />
        </Modal.Body>
      </Modal>
      <OnboardingModal />
      <Modal
        centered
        show={paypalButton}
        onHide={() => setPaypalButton(false)}
        style={{ zIndex: 9999 }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <PaypalCheckoutForm
            amount={Number(paypalAmount)}
            showButton={paypalButton}
            accountId={accountData?.id}
            refetch={refetchData}
            onHide={() => setDepositModalOpen(false)}
            hideButton={() => setPaypalButton(false)}
          />
        </Modal.Body>
      </Modal>
      <div className="d-block d-sm-none">
        <Navbar
          bg="carre-blue"
          expand="true"
          fixed="top"
          className="px-3 text-white"
        >
          <Navbar.Brand as={Link} href="#" className="me-2 me-xl-4 ">
            <WhiteLogo
              src="/images/logo/logo-dark.svg"
              width={116}
              height={39}
              alt="Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="ms-auto d-md-none "
            onClick={() => setShowOffcanvas((prev) => !prev)}
          />
          {/* User account dropdown */}
          <Dropdown className="order-lg-3 ms-2">
            <Dropdown.Toggle
              as={Nav.Link}
              className="dropdown-toggle-flush py-1 px-0"
            >
              <>
                <span className="fw-bold text-white  mx-1 d-none d-sm-inline">
                  {data?.first_name} {data?.last_name}{" "}
                </span>
                <div
                  className="d-inline p-2 text-carre-blue "
                  style={{
                    borderRadius: "50%",
                    backgroundColor: "#ffffff",
                    fontWeight: "bold",
                  }}
                >
                  {data?.first_name?.charAt(0)}
                  {data?.last_name?.charAt(0)}
                </div>
              </>
            </Dropdown.Toggle>
            <Dropdown.Menu renderOnMount align="end">
              <div
                className="d-flex align-items-start border-bottom px-3 py-1 mb-2"
                style={{ width: "16rem" }}
              >
                <div
                  className="d-inline p-2 px-1 text-carre-blue rounded-cirlce"
                  style={{
                    borderRadius: "50%",
                    backgroundColor: "#ffffff",
                    fontWeight: "bold",
                  }}
                >
                  {data?.first_name?.charAt(0)} {data?.last_name?.charAt(0)}
                </div>

                <div className="ps-2">
                  <h6 className="fs-base mb-0">
                    {data?.first_name} {data?.last_name}{" "}
                  </h6>

                  <div className="fs-xs py-2">
                    {data?.tel}
                    <br />
                    {data?.email}
                  </div>
                </div>
              </div>

              <Dropdown.Divider />

              <Dropdown.Item
                onClick={() => {
                  nav(`/${roleUrl}/settings`);
                }}
              >
                Settings
              </Dropdown.Item>

              <Dropdown.Item onClick={logout}>Sign Out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Navbar.Offcanvas
            show={showOffcanvas}
            onHide={() => setShowOffcanvas(false)}
            placement="start"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title className="">
                <Logo />

                {() => {
                  nav(`/${roleUrl}`);
                }}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="sidebar">
              <Nav className="me-auto"></Nav>
              <Nav as="div" className="menu-bar sidebar">
                <Logo className="mx-2" />

                <div className="menu-bar  overflow-auto">
                  <div className="menu">{navigationItems}</div>
                  <div>
                    <li className="bottom-content">
                      <a
                        className={` ${
                          activeItem === "settings" ? "active " : ""
                        }`}
                        onClick={() => {
                          nav(`/${roleUrl}/settings`);
                          setShowOffcanvas(false);
                        }}
                      >
                        <AiOutlineSetting className="icon" />
                        <span className="text nav-text">Settings</span>
                      </a>
                    </li>

                    <li className="">
                      <a onClick={logout}>
                        <AiOutlineLogout className="icon" />
                        <span className="text nav-text">Logout</span>
                      </a>
                    </li>
                  </div>
                </div>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Navbar>
      </div>
      <div
        className={`d-none d-sm-block sidebar ${!showSidebar ? "close" : ""}`}
      >
        <header>
          {!showSidebar ? (
            <img className="mx-2" src={SideBaIcon} alt="Sidebar Icon" />
          ) : (
            <Logo className="mx-2 " />
          )}
          <div
            className={`bx toggle`}
            onClick={() => setShowSidebar(!showSidebar)}
          >
            {showSidebar !== true ? (
              <FaChevronRight style={{ padding: "5px" }} />
            ) : (
              <FaChevronLeft style={{ padding: "5px" }} />
            )}
          </div>
        </header>

        <div className="menu-bar  overflow-auto">
          <div className="menu">{navigationItems}</div>
          <div>
            <li className="bottom-content">
              <a
                className={`nav-link ${
                  activeItem === "settings" ? "active " : ""
                }`}
                onClick={() => {
                  nav(`/${roleUrl}/settings`);
                }}
              >
                <AiOutlineSetting className="icon" />
                <span className="text nav-text">Settings</span>
              </a>
            </li>

            <li className="">
              <a onClick={logout}>
                <AiOutlineLogout className="icon" />
                <span className="text nav-text">Logout</span>
              </a>
            </li>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
