import { useQuery } from "react-query";
import api from "../api";

export const useGetMarketStageById = (id) => {
  return useQuery(["market-stage-by-id"], () => {
    if (id !== undefined) {
      return api.get(`/market-stage/${id}`).then((res) => res.data);
    }
  });
};
