import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";

import Modal from "react-bootstrap/Modal";
import { useAuth } from "../auth/TokenCreation";
import loginImage from "../assets/img/loginImage.png";
import ICloseIcon from "../assets/icons/CloseIcon";
import Logo from "../components/Logo";
import { useCookies } from "react-cookie";
import { AiOutlineGoogle } from "react-icons/ai";

function Login(props) {
  const [cookies] = useCookies();
  const email = cookies?.email;

  const [user, setUser] = useState("");
  useEffect(() => {
    setUser(email);
  }, [email]);

  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const { err } = useAuth();

  const handleLogin = () => {
    login({ user, password });
  };
  let errorMessage;
  useEffect(() => {
    if (err !== undefined) {
      errorMessage = "Your email or password is incorrent";
    }
  }, err);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      aria-hidden="true"
    >
      <Modal.Body>
        <div className="rounded-4">
          <div className="bg-white row">
            <div className="col-5 border-1 d-lg-block d-none">
              <Logo />
              <div className="p-4 m-2 ">
                <h4 className={"fw-bold"}>Hello There!</h4>
                <h4 className={"fw-bold"}>Welcome Back.</h4>
              </div>
              <br />

              <div className="p-2">
                <img
                  alt="info"
                  src={loginImage}
                  width={240}
                  className={"px-3"}
                />
              </div>
              <br />
              <p className={"pt-5 "}>
                Don't have an account?
                <button
                  style={{
                    border: "none",
                    background: "none",
                    textDecoration: "underline",
                  }}
                  className={"text-carre-blue"}
                  onClick={props.openSignUpFromLogin}
                >
                  Sign up here
                </button>
              </p>
            </div>

            <div className="col-12 col-lg-7">
              <div className={"d-flex justify-content-end"}>
                <Button variant="white text-white" onClick={props.onHide}>
                  <ICloseIcon />
                </Button>
              </div>
              <div
                className="d-flex justify-content-center align-items-center mh-100"
                style={{ height: "450px" }}
              >
                <div>
                  <form
                    action={
                      process.env.REACT_APP_API_KEY + "/google-auth/login"
                    }
                    method="get"
                  >
                    <Button
                      type="submit"
                      variant="outline-carre-purple"
                      className={"w-100 mb-3 d-flex justify-content-center"}
                    >
                      <AiOutlineGoogle
                        style={{ width: "20", height: "20" }}
                        className={"m-1"}
                      />
                      <span style={{ marginTop: "1.5px" }}>
                        Sign in with Google
                      </span>
                    </Button>
                  </form>
                  {/*<form*/}
                  {/*  action={*/}
                  {/*    process.env.REACT_APP_API_KEY + "/facebook-auth/login"*/}
                  {/*  }*/}
                  {/*  method="get"*/}
                  {/*>*/}
                  {/*  <Button*/}
                  {/*    type="submit"*/}
                  {/*    variant="outline-carre-purple "*/}
                  {/*    className={"w-100 mb-3 d-flex justify-content-center"}*/}
                  {/*  >*/}
                  {/*    <FaFacebookF*/}
                  {/*      style={{ width: "20", height: "20" }}*/}
                  {/*      className={"m-1"}*/}
                  {/*    />*/}
                  {/*    <span style={{ marginTop: "1.5px" }}>*/}
                  {/*      Sign in with Facebook*/}
                  {/*    </span>*/}
                  {/*  </Button>*/}
                  {/*</form>*/}
                  <div className="py-3 mb-3 d-flex align-items-center">
                    <hr className="w-100" />
                    <div className="px-3">Or</div>
                    <hr className="w-100" />
                  </div>
                  <Form
                    onSubmit={(e) => e.preventDefault(handleLogin())}
                    //noValidate validated={validated} onSubmit={handleSubmit}
                  >
                    <Form.Group controlId="si-email" className="mb-4">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        defaultValue={user}
                        type="email"
                        onChange={(e) => setUser(e.target.value)}
                        placeholder="Enter your email"
                        required
                      />
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <div className={"d-flex justify-content-between mb-2"}>
                        <Form.Label
                          htmlFor="si-password"
                          className="mb-0 d-inline"
                        >
                          Password
                        </Form.Label>
                        <a
                          href="/reset-password"
                          className="fs-sm d-inline text-carre-blue"
                        >
                          Forgot password?
                        </a>
                      </div>
                      <Form.Control
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        placeholder="Enter your password"
                        required
                      />
                    </Form.Group>
                    <p className="text-carre-red">
                      {err !== undefined &&
                        "Your email or password is incorrect !"}
                    </p>
                    <Button type="submit" size="lg" variant="carre-blue w-100">
                      Sign in
                    </Button>
                  </Form>
                  <p className="d-lg-none d-block text-center">
                    Don't have an account?
                    <button
                      style={{
                        border: "none",
                        background: "none",
                        textDecoration: "underline",
                        paddingLeft: "10px",
                      }}
                      className={"text-carre-blue"}
                      onClick={props.openSignUpFromLogin}
                    >
                      Sign up here
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Login;
