import React, { useRef, useState } from "react";
import learncover from "./../assets/img/learn.jpg";
import { IoPlay } from "react-icons/io5";
import video from "./../assets/welcome-to-carre_V2.mp4";
import { FaPlay } from "react-icons/fa";

const LearnVideo = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const iframeRef = useRef(null);

  const handlePlayPause = () => {
    setIsPlaying((prev) => {
      if (prev && iframeRef.current) {
        const iframe = iframeRef.current;
        const iframeSrc = iframe.src;
        iframe.src = iframeSrc; // Refresh the iframe to pause the video
      }
      return !prev;
    });
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsPlaying(true); // Hide the thumbnail and show the video
  };

  return (
    <section className="position-relative py-5">
      <span
        className="position-absolute top-0 start-0 w-100 h-100 d-dark-mode-none"
        style={{
          background: "linear-gradient(360deg, #fff 5.39%, #f6f9fc 78.66%)",
        }}
      />
      <span
        className="position-absolute top-0 start-0 w-100 h-100 d-none d-dark-mode-block"
        style={{
          background:
            "linear-gradient(360deg, #121519 5.39%, rgba(255, 255, 255, .03) 78.66%)",
        }}
      />
      <div className="container position-relative z-2 py-2 py-sm-3 py-sm-4 py-md-5">
        <div className="row align-items-center py-lg-2 py-xl-3 my-xl-1 my-xxl-3">
          <div className="col-md-5 col-xl-4">
            <h2 className="h1 pb-2 pb-sm-3 mb-md-0">
              Learn <span className="text-carre-blue">how Carré works</span> in
              a nutshell. Watch a short introductory video
            </h2>
          </div>

          <div className="col-md-7 offset-xl-1">
            <div>
              {!isPlaying && (
                <div
                  onClick={handleClick}
                  style={{ position: "relative", display: "inline-block" }}
                >
                  <img src={learncover} alt="Video Thumbnail" />
                  <FaPlay
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "3rem",
                      color: "white",
                      cursor: "pointer",
                    }}
                  />
                </div>
              )}
              {isPlaying && (
                <div>
                  <iframe
                    width="1280"
                    height="520"
                    // src="https://www.youtube.com/embed/xEMKyejSURE?autoplay=1"
                    src={`https://www.youtube.com/embed/${"xEMKyejSURE"}?playsinline=1&iv_load_policy=3&rel=0&showinfo=0&controls=1&fs=0&start=${"0:00"}&autoplay=1&enablejsapi=1&widgetid=1&mute=1`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LearnVideo;
