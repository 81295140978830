import { Card, Col, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { FiAlertTriangle } from "react-icons/fi";
import { MdMoreHoriz } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import TokenIcon from "../../assets/icons/TokenIcon";
import { GetRoleNavigationPath } from "../../routeUtils";
import { useTabContext } from "../Dashboard/TabContext";

const AssetsPropertyCard = ({
  images,
  href,
  category,
  location,
  price,
  badges,
  wishlistButton,
  dropdown,
  footer,
  horizontal,
  light,
  className,
  data,
  reserved,
  ...props
}) => {
  const horizontalClass = horizontal ? " card-horizontal" : "",
    extraClass = className ? ` ${className}` : "";
  const image = data?.image != null ? data?.image[0]?.image_url : "";
  const title = data?.property_name;
  const isLongTitle = title?.length > 12;
  const titleFontSize = isLongTitle ? "14px" : "inherit";

  function calculateOwnershipPercentage(ownedTokens, totalTokens) {
    const percentage = (ownedTokens / totalTokens) * 100;
    return percentage;
  }

  const roleUrl = GetRoleNavigationPath();
  const navigate = useNavigate();

  const totalTokenValue = data?.reserve_token_list?.reduce(
    (sum, entry) => sum + entry.token_qty,
    0
  );

  const totalValue = data?.reserve_token_list?.reduce(
    (sum, entry) => sum + entry.token_qty * entry.token_price,
    0 // Provide an initial value of 0
  );
  const { setSelectedTab } = useTabContext();

  const handleOptionChange = (newOption) => {
    setSelectedTab(newOption);
    const newUrl = `/${roleUrl}/marketplace/propdetails/${data.id}}`;
    navigate(newUrl);
  };

  return (
    <div
      {...props}
      className={
        light
          ? ` card card-light card-hover${horizontalClass}${extraClass}`
          : ` card card-hover shadow-md border-0${horizontalClass}${extraClass}`
      }
    >
      {!reserved ? (
        <div
          className="card-img position-relative w-200 md:w-full"
          style={{
            height: "auto", // Set the height of the container
            backgroundImage: `url(${data?.image.image_url})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {reserved && (
            <div
              className="position-absolute text-center"
              style={{ right: "20px", bottom: "0", padding: "5px" }}
            >
              <p
                className="text-uppercase fw-bold text-white bg-carre-blue p-2 rounded-pill mt-1"
                style={{ fontSize: "12px" }}
              >
                Reserved
              </p>
            </div>
          )}
        </div>
      ) : (
        <div
          className="card-img position-relative w-200 md:w-full"
          style={{
            height: "auto", // Set the height of the container
            backgroundImage: `url(${data?.default_image?.image_url})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {reserved && (
            <div
              className="position-absolute text-center"
              style={{ right: "20px", bottom: "0", padding: "5px" }}
            >
              <p
                className="text-uppercase fw-bold text-white bg-carre-blue p-2 rounded-pill mt-1"
                style={{ fontSize: "12px" }}
              >
                Reserved
              </p>
            </div>
          )}
        </div>
      )}

      <div
        className="card-body position-relative pb-2 "
        onClick={(event) => {
          navigate(`/user/marketplace/propdetails/${data.id}`);
        }}
      >
        {category && (
          <div className="mb-1 fs-xs text-uppercase text-carre-blue">
            {category}
          </div>
        )}

        {title && (
          <Row>
            <Col>
              <p
                className="h4"
                style={{
                  //  fontSize: titleFontSize,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {title}
              </p>
            </Col>

            <Col
              md={3}
              className="d-flex justify-content-end d-none d-md-block "
            >
              <Dropdown onClick={(e) => e.stopPropagation()}>
                <Dropdown.Toggle variant="carre-blue w-100">
                  Details <MdMoreHoriz />
                </Dropdown.Toggle>
                <Dropdown.Menu className="my-1 w-100">
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedTab("RESERVETOKEN");
                      navigate(
                        `/${roleUrl}/marketplace/propdetails/${data.id}`
                      );
                    }}
                  >
                    Reserve Shares
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedTab("SELLTOKEN");
                      navigate(
                        `/${roleUrl}/marketplace/propdetails/${data.id}`
                      );
                    }}
                  >
                    Sell Share Request
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedTab("ORDER");
                      navigate(
                        `/${roleUrl}/marketplace/propdetails/${data.id}`
                      );
                    }}
                  >
                    Order
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedTab("BALANCE");
                      navigate(
                        `/${roleUrl}/marketplace/propdetails/${data.id}`
                      );
                    }}
                  >
                    Balance
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedTab("TRANSFERS");
                      navigate(
                        `/${roleUrl}/marketplace/propdetails/${data.id}`
                      );
                    }}
                  >
                    Transfers
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        )}

        {/* <p className={`mb-4 fs-sm`} style={{ maxHeight: "20px" }}>
          {`
            ${data?.address?.street_name}, ${data?.address?.city_name}, ${
            data?.address?.zipcode ? data.address.zipcode + ", " : ""
          }${
            data?.address?.country_state
              ? data.address.country_state + ", "
              : ""
          }${data?.address?.country_name}`}
        </p> */}

        {reserved ? (
          <>
            <div className="d-flex fw-bold align-items-center justify-content-between mt-2">
              <p className="fs-sm  mb-0 me-2">Reserved Shares </p>
              <p className="text-carre-gray-bg mb-0">
                <span className="mx-1 fs-sm text-carre-blue float-end">
                  {totalTokenValue}(
                  {calculateOwnershipPercentage(
                    totalTokenValue,
                    data.number_of_token
                  ).toFixed(2)}
                  %) <TokenIcon />
                </span>
              </p>
            </div>
            <div className="d-flex fw-bold align-items-center justify-content-between mt-2">
              <p className="fs-sm  mb-0 me-2">Reserved Value</p>
              <p className="text-carre-gray-bg mb-0">
                <span className="mx-1 fs-sm text-carre-blue float-end">
                  {totalValue?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </span>
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex fw-bold align-items-center justify-content-between mt-2">
              <p className="fs-sm  mb-0 me-2">Shares</p>
              <p className="text-carre-gray-bg mb-0">
                <span className="mx-1 fs-sm text-carre-blue float-end">
                  {totalTokenValue}
                  {data.owned_token} (
                  {calculateOwnershipPercentage(
                    data.owned_token,
                    data.number_of_token
                  ).toFixed(2)}
                  %) <TokenIcon />
                </span>
              </p>
            </div>
            <div className="d-flex fw-bold align-items-center justify-content-between mt-2">
              <p className="fs-sm  mb-0 me-2">Value</p>
              <p className="text-carre-gray-bg mb-0">
                <span className="mx-1 fs-sm text-carre-blue float-end">
                  {(data.owned_token * data.token_price)?.toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                    }
                  )}
                </span>
              </p>
            </div>
            <div className="d-flex fw-bold align-items-center justify-content-between mt-2">
              <p className="fs-sm  mb-0 me-2">Rental Income</p>
              <p className="text-carre-gray-bg mb-0">
                <span className="mx-1 fs-sm text-carre-blue float-end">
                  {data.dividend_sum?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </span>
              </p>
            </div>

            <div className="d-flex fw-bold align-items-center justify-content-between mt-2">
              <p className="fs-sm  mb-0 me-2">Vacation Income</p>
              <p className="text-carre-gray-bg mb-0">
                <span className="mx-1 fs-sm text-carre-blue float-end">
                  {data.vacation_token_sum?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </span>
              </p>
            </div>
          </>
        )}
        <div className="mt-2 d-flex align-items-center justify-content-center text-nowrap d-block d-md-none">
          <Dropdown onClick={(e) => e.stopPropagation()} className="w-100 mt-1">
            <Dropdown.Toggle variant="carre-blue w-100">
              Details <MdMoreHoriz className=" me-2" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="my-1 w-100">
              <Dropdown.Item
                onClick={() => {
                  setSelectedTab("RESERVETOKEN");
                  navigate(`/${roleUrl}/marketplace/propdetails/${data.id}`);
                }}
              >
                Reserve Shares
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setSelectedTab("SELLTOKEN");
                  navigate(`/${roleUrl}/marketplace/propdetails/${data.id}`);
                }}
              >
                Sell Share Request
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setSelectedTab("ORDER");
                  navigate(`/${roleUrl}/marketplace/propdetails/${data.id}`);
                }}
              >
                Order
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setSelectedTab("BALANCE");
                  navigate(`/${roleUrl}/marketplace/propdetails/${data.id}`);
                }}
              >
                Balance
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setSelectedTab("TRANSFERS");
                  navigate(`/${roleUrl}/marketplace/propdetails/${data.id}`);
                }}
              >
                Transfers
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <div className="dropdown">
        <button
          className="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Options
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <button
            className={`dropdown-item`}
            onClick={() => handleOptionChange(1)}
          >
            Option 1
          </button>
          <button
            className={`dropdown-item`}
            onClick={() => handleOptionChange(2)}
          >
            Option 2
          </button>
          <button
            className={`dropdown-item`}
            onClick={() => handleOptionChange(3)}
          >
            Option 3
          </button>
          <button
            className={`dropdown-item`}
            onClick={() => handleOptionChange(4)}
          >
            Option 4
          </button>
        </div>
      </div> */}
        </div>
      </div>
    </div>
  );
};

export default AssetsPropertyCard;
