import { useQuery } from "react-query";
import api from "../api";

export const useGetProperty = ({ market, location, type, isAdmin, limit }) => {
  return useQuery(["property", market, location, type, isAdmin, limit], () => {
    const endpoint = isAdmin ? `admin/property` : "property";
    return api
      .get(endpoint, {
        params: {
          market,
          location,
          type,
          limit,
        },
      })
      .then((res) => res.data);
  });
};

export const useGetPropertyPublic = ({ market, location, type }) => {
  return useQuery(["property-public", market, location, type], () => {
    return api
      .get(`property`, {
        params: {
          market,
          location,
          type,
        },
      })
      .then((res) => res.data);
  });
};
export const useGetPropertyComment = (propertyId) => {
  return useQuery(["comment", { propertyId }], () => {
    return api.get(`property/${propertyId}/comment`).then((res) => res.data);
  });
};
