import React, { useState } from "react";
import { Table, Form, Button, Modal, Dropdown } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  usePropertyHistorical,
  useCreatePropertyHistorical,
  useEditPropertyHistorical,
  useDeletePropertyHistorical,
} from "../../hooks/useCreatePropertyHistroical";
import { useParams } from "react-router-dom";
import moment from "moment";

const PropertyHistorical = () => {
  const params = useParams();
  const propertyId = params.property_id;
  const {
    data: propertyHistorical,
    isLoading,
    isError,
    refetch,
  } = usePropertyHistorical(propertyId);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [editMode, setEditMode] = useState(false);
  const [editingHistoricalId, setEditingHistoricalId] = useState(null);
  const [editDate, setEditDate] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const createPropertyHistorical = useCreatePropertyHistorical();
  const editPropertyHistorical = useEditPropertyHistorical();
  const deletePropertyHistorical = useDeletePropertyHistorical();

  const onSubmit = async (formData) => {
    const newData = {
      property_id: propertyId,
      ...formData,
    };
    if (editMode) {
      await editPropertyHistorical.mutateAsync({
        propertyId,
        historicalId: editingHistoricalId,
        data: newData,
      });
    } else {
      await createPropertyHistorical.mutateAsync(newData);
    }
    await refetch();
    setEditMode(false);
    setEditingHistoricalId(null);
    setValue("income", "");
    setValue("gross_income", "");
    setValue("cost", "");
    setValue("return", "");
    // setValue("date", "");
    setShowModal(false);
  };

  const handleEdit = (historicalData) => {
    setEditMode(true);
    setEditingHistoricalId(historicalData.id);
    setValue("income", historicalData.income);
    setValue("gross_income", historicalData.gross_income);
    setValue("cost", historicalData.cost);
    setValue("return", historicalData.return);

    // setValue("date", formattedDate);
    setEditDate(historicalData.date);
    setShowModal(true);
  };

  const handleDelete = async (historicalId) => {
    await deletePropertyHistorical.mutateAsync({
      propertyId,
      historicalId,
    });
    await refetch();
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error: {isError.message}</div>;

  return (
    <div>
      <h2>Property Historical</h2>

      <Button
        variant="primary"
        className="w-100"
        onClick={() => setShowModal(true)}
      >
        Add new data
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>Income</th>
            <th>Gross Income</th>
            <th>Cost</th>
            <th>Return</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {propertyHistorical.map((historicalData, index) => (
            <tr key={index}>
              <td>
                {new Date(historicalData?.date).toISOString().substring(0, 10)}
              </td>
              <td>{historicalData.income}</td>
              <td>{historicalData.gross_income}</td>
              <td>{historicalData.cost}</td>
              <td>{historicalData.return}</td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    Actions
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleEdit(historicalData)}>
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleDelete(historicalData.id)}
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editMode ? "Edit Historical Data" : "Add Historical Data"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="income">
              <Form.Label>Income</Form.Label>
              <Form.Control
                type="string"
                {...register("income", {
                  pattern: {
                    value: /^\d*\.?\d*$/,
                    message: "Invalid exchange rate format",
                  },
                  valueAsNumber: true,
                  required: "Income is required",
                })}
              />
              {errors.income && <p>{errors.income.message}</p>}
            </Form.Group>
            <Form.Group controlId="grossIncome">
              <Form.Label>Gross Income</Form.Label>
              <Form.Control
                type="string"
                {...register("gross_income", {
                  pattern: {
                    value: /^\d*\.?\d*$/,
                    message: "Invalid exchange rate format",
                  },
                  valueAsNumber: true,
                  required: "Gross Income is required",
                })}
              />
              {errors.gross_income && <p>{errors.gross_income.message}</p>}
            </Form.Group>
            <Form.Group controlId="cost">
              <Form.Label>Cost</Form.Label>
              <Form.Control
                type="string"
                {...register("cost", {
                  pattern: {
                    value: /^\d*\.?\d*$/,
                    message: "Invalid exchange rate format",
                  },
                  valueAsNumber: true,
                  required: "Cost is required",
                })}
              />
              {errors.cost && <p>{errors.cost.message}</p>}
            </Form.Group>
            <Form.Group controlId="return">
              <Form.Label>Return</Form.Label>
              <Form.Control
                type="string"
                {...register("return", {
                  pattern: {
                    value: /^\d*\.?\d*$/,
                    message: "Invalid exchange rate format",
                  },
                  valueAsNumber: true,
                  required: "Return is required",
                })}
              />
              {errors.return && <p>{errors.return.message}</p>}
            </Form.Group>
            <Form.Group controlId="date">
              <Form.Label>Date</Form.Label>
              <Form.Control
                defaultValue={new Date(editDate).toISOString().substring(0, 10)}
                type="date"
                {...register("date", {
                  required: "Date is required",
                  valueAsDate: true,
                })}
              />
              {errors.date && <p>{errors.date.message}</p>}
            </Form.Group>
            <Button variant="primary" type="submit">
              {editMode ? "Update" : "Add"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PropertyHistorical;
