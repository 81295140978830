import { useQuery } from "react-query";
import api from "../api";

export const useGetTransfersForOrderBook = (id) => {
  return useQuery(["transfer-for-book", id], () => {
    // Add the parameters for_user, limit, and offset to the API request
    const params = {
      for_user: false,
      limit: 10,
      offset: 0,
    };

    return api.get(`/transfer/${id}`, { params }).then((res) => res.data);
  });
};
