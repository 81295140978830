import React, { useEffect, useState } from "react";
import { useGetUserData } from "../../hooks/useGetUserInformation";
import { useTabContext } from "./TabContext";
import { BsCircleFill } from "react-icons/bs";
import {
  RiHomeFill,
  RiMapPin2Fill,
  RiCurrencyFill,
  RiShoppingCartFill,
  RiCheckFill,
  RiRidingFill,
} from "react-icons/ri";
import { AiOutlineHome, AiOutlineIdcard, AiOutlineUser } from "react-icons/ai";
import { FaGlobeAmericas } from "react-icons/fa";

const Onboarding = () => {
  const { setShowOnboarding } = useTabContext();

  const { data } = useGetUserData();

  const [currentStep, setCurrentStep] = useState(null);
  const setStepBasedOnGuideStep = () => {
    const guideStep = data?.guide_step ? data?.guide_step.toUpperCase() : "";

    switch (guideStep) {
      case "":
        setCurrentStep(1);
        break;
      case "AMERICAN_CITIZEN":
        setCurrentStep(1);
        break;

      case "LATER_KYC":
        setCurrentStep(1);
        break;
      case "APPLICATION":
        setCurrentStep(2);
        break;
      case "ADDRESS":
        setCurrentStep(3);
        break;
      case "KYC":
        setCurrentStep(4);
        break;
      case "DEPOSIT":
        setCurrentStep(5);
        break;
      case "BUY":
        setCurrentStep(6);
        break;
      case "FINISH":
        setCurrentStep(7);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setStepBasedOnGuideStep();
  }, [data]);

  const handleShow = () => {
    setShowOnboarding(true);
  };

  const stepIcons = [
    <FaGlobeAmericas size="25" />,
    <AiOutlineUser size="25" />,
    <AiOutlineHome size="25" />,
    <AiOutlineIdcard size="25" />,
    <RiCurrencyFill size="25" />,
    <RiShoppingCartFill size="25" />,
    <RiCheckFill size="25" />,
  ];

  const stepNames = [
    "Complete profile",
    "Personal info",
    "Address",
    "Kyc verification",
    "Deposit",
    "Buy share",
    "Finish",
  ];

  return (
    <>
      <li className="custom-hover" onClick={handleShow}>
        <div className="icon">
          {/*{stepIcons[currentStep - 1] ? (*/}
          {/*  stepIcons[currentStep - 1]*/}
          {/*) : (*/}
          {/*  <BsCircleFill />*/}
          {/*)}*/}
          <AiOutlineIdcard size="25" />
        </div>
        <div className="text nav-text">
          {/*{stepNames[currentStep - 1]}*/}
          Complete profile
        </div>
      </li>
    </>
  );
};

export default Onboarding;
