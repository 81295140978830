import { useQuery } from "react-query";
import api from "../api";

export const useGetOrderBookById = (id) => {
  return useQuery(["order-book", id], () => {
    if (id !== undefined) {
      return api.get(`/property/${id}/order-book`).then((res) => res.data);
    }
  });
};
