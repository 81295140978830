import api from "../api";

export const useUserRemoveSellRequest = (data) => {
  const newData = {
    status: "CANCELED",
  };
  return api
    .post(`/request/cancel/${data.id}`, newData)
    .then((res) => res.data);
};
