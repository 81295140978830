import React, { createContext, useContext, useState } from "react";

const TabContext = createContext();

export const useTabContext = () => {
  return useContext(TabContext);
};

export const TabProvider = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState("DETAILS");
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  const [depositModalOpen, setDepositModalOpen] = useState(false);
  const [paypalButton, setPaypalButton] = useState(false);
  const [paypalAmount, setPaypalAmount] = useState(0);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState("");

  return (
    <TabContext.Provider
      value={{
        setShowOffcanvas,
        showOffcanvas,
        selectedTab,
        setSelectedTab,
        loginModalOpen,
        setLoginModalOpen,
        signUpModalOpen,
        setSignUpModalOpen,
        depositModalOpen,
        setDepositModalOpen,
        paypalButton,
        setPaypalButton,
        setPaypalAmount,
        paypalAmount,
        setShowOnboarding,
        showOnboarding,
        selectedPdf,
        setSelectedPdf,
      }}
    >
      {children}
    </TabContext.Provider>
  );
};
