import React from "react";
import { Table } from "react-bootstrap";
import { BiBath, BiBed } from "react-icons/bi";
import { FaCarSide } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUserAsset } from "../hooks/useGetUserAsset";
import GoBackButton from "./GoBackButton";

const TransferTable = () => {
  const params = useParams();
  const id = params.id;
  const { data } = useGetUserAsset();
  const navigate = useNavigate();

  const property = data?.find((obj) => obj.id === parseInt(id));

  return (
    <>
      <GoBackButton />
      <div>
        <table className="table table-bordered table-sm table-hover">
          <thead>
            <tr>
              <th scope="col">Property Img</th>
              <th scope="col">Property Details</th>
            </tr>
          </thead>
          <tbody>
            {property && (
              <tr
                key={property?.id}
                onClick={() =>
                  navigate(`/admin/marketplace/propdetails/${property.id}`)
                }
              >
                <td width="240px">
                  <div className="w-100 h-100">
                    <img
                      alt="property"
                      src={property?.image.image_url}
                      className="img-fluid  "
                      style={{
                        objectFit: "cover",
                        maxHeight: "200px",
                        maxWidth: "240px",
                        width: "240px",
                        height: "100px",
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div className="">
                    <div>
                      <p className="fw-bold mb-1">Id: {property?.id}</p>
                      <p className="fw-bold mb-1">{property?.property_name}</p>
                      <p className="fw-bold mb-1">
                        {property?.property_area} sq
                      </p>
                      <div className="d-flex gap-2">
                        <span className="fw-bold mb-1">
                          <BiBath /> {property?.bathrooms}
                        </span>
                        <span className="fw-bold mb-1">
                          <BiBed /> {property?.bedroom_count}
                        </span>
                        <span className="fw-bold mb-1">
                          <FaCarSide /> {property?.parking}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Table  bordered hover>
        <thead>
          <tr>
            <th>From</th>
            <th>To</th>
            <th>Amount</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {property?.transfer?.map((item, index) => (
            <tr key={index}>
              <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                {item?.from}
              </td>
              <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                {item?.to}
              </td>
              <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                {item?.amount}
              </td>
              <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                {new Date(item?.created_at).toLocaleDateString()}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default TransferTable;
