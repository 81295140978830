import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { Button, Dropdown, Form, Row } from "react-bootstrap";
import {
  useGetPropertyById,
  useGetPropertyDocumentByPropertyId,
} from "../../hooks/useGetPropertyById";
import { BiBath, BiBed } from "react-icons/bi";
import { FaCarSide } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import api from "../../api";
import { IoMdDocument } from "react-icons/io";
import axios from "axios";
import { useCookies } from "react-cookie";
import { AiOutlineFilePdf } from "react-icons/ai";
import { GetRoleNavigationPath } from "../../routeUtils";

function ListDocument() {
  const params = useParams();
  let navigate = useNavigate();

  const [response, setResponse] = useState();
  const roleUrl = GetRoleNavigationPath();
  const cookies = useCookies();

  const { data: property } = useGetPropertyById(params.property_id);

  const {
    data: propertyDocument,
    isLoading: propertyDocumentIsLoading,
    refetch: propertyDocumentRefetch,
  } = useGetPropertyDocumentByPropertyId(params.property_id);

  function uploadDocument(file) {
    const document = file;
    const bodyFormData = new FormData();
    bodyFormData.append("file", document);
    axios({
      method: "POST",
      url:
        process.env.REACT_APP_API_KEY +
        "/property/" +
        params.property_id +
        "/upload-document",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${cookies[0].token}`,
      },
    })
      .then((res) => {
        propertyDocumentRefetch();
        notifySuccessUpload();
      })
      .catch((err) => {
        setResponse(err);
        propertyDocumentRefetch();
        notifyError();
      });
  }

  const notifyError = () =>
    toast.error(response?.response?.data?.error || "Something went wrong ");

  const notifySuccessUpload = () =>
    toast.success("Document Upload Successfully");

  const notifySuccessOnDelete = () =>
    toast.success("Comment Deleted Successfully");

  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (data) => {
    for (let i = 0; i < data?.document.length; i++) {
      uploadDocument(data?.document[i]);
    }
    reset();
    propertyDocumentRefetch();
  };

  const deleteDocument = (id, documentId) => {
    api
      .delete(`/property/${id}/delete-document/${documentId}`)
      .then((res) => {
        notifySuccessOnDelete();
        propertyDocumentRefetch().then();
      })
      .catch((err) => {
        notifyError();
        propertyDocumentRefetch().then();
      });
  };

  useEffect(() => {
    propertyDocumentRefetch().then();
  }, [params.property_id, reset]);

  return (
    <div>
      <div className="">
        <table className="table table-bordered table-sm table-hover">
          <thead>
            <tr>
              <th scope="col">Property Img</th>
              <th scope="col">Property Details</th>
              <th scope="col">Address</th>
              <th scope="col">Market</th>
            </tr>
          </thead>
          <tbody>
            {property && (
              <tr
                key={property?.id}
                onClick={() =>
                  navigate(`/${roleUrl}/marketplace/propdetails/${property.id}`)
                }
              >
                <td width="240px">
                  <div className="w-100 h-100">
                    <img
                      alt="property"
                      src={
                        property?.image != null
                          ? property?.image.find((x) => x.is_default === true)
                              ?.image_url
                          : ""
                      }
                      className="img-fluid  "
                      style={{
                        objectFit: "cover",
                        maxHeight: "200px",
                        maxWidth: "240px",
                        width: "240px",
                        height: "100px",
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div className="">
                    <div>
                      <p className="fw-bold mb-1">Id: {property?.id}</p>
                      <p className="fw-bold mb-1">{property?.property_name}</p>
                      <p className="fw-bold mb-1">
                        {property?.property_area} sq
                      </p>
                      <div className="d-flex gap-2">
                        <span className="fw-bold mb-1">
                          <BiBath /> {property?.bathrooms}
                        </span>
                        <span className="fw-bold mb-1">
                          <BiBed /> {property?.bedroom_count}
                        </span>
                        <span className="fw-bold mb-1">
                          <FaCarSide /> {property?.parking}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="">
                    <div>
                      <span className="fw-bold mb-1">
                        {property?.address?.country_name}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="">
                    <div>
                      <span className="text-muted mb-0">
                        {property?.market_stage === 1 && "Submitted"}
                        {property?.market_stage === 2 && "Upcomming"}
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between align-items-center align-content-center  mx-5">
        <button
          onClick={() => navigate(`/${roleUrl}/listproperty`)}
          replace={true}
          className={
            "bg-carre-purple text-white text-decoration-none d-flex align-items-center justify-content-center border-0 rounded-3 w-25"
          }
          style={{ height: "50px" }}
        >
          Go Back
        </button>
      </div>

      <Form onSubmit={handleSubmit(onSubmit)} id="document">
        <div className="rounded-2 shadow-lg p-3 mb-5 p-3 mt-4 my-2 ">
          <Row>
            <div>
              <IoMdDocument
                style={{ width: "20px", height: "20px" }}
                className="text-carre-purple  d-inline mb-2 mx-1"
              />
              <p className="h4 font-weight-bold d-inline "> Attach Document </p>
              <Form.Group as={Col}>
                <input
                  className="form-control form-control-lg"
                  id="document"
                  type="file"
                  {...register("document", {
                    required: true,
                  })}
                  accept="application/pdf"
                  multiple={true}
                />
              </Form.Group>
            </div>
          </Row>
        </div>

        <Button
          type={"submit"}
          size="lg"
          variant="carre-purple w-100"
          disabled={propertyDocumentIsLoading}
        >
          {propertyDocumentIsLoading ? "Please wait" : "Upload Document"}
        </Button>
      </Form>

      {
        <div
          className={"mt-5"}
          style={{
            position: "relative",
            overflow: "auto",
            minHeight: "500px",
            maxHeight: "500px",
          }}
        >
          <div className="m-4">
            <div>
              <div>
                <div className="overflow-hidden pb-5">
                  <table className="table table-bordered table-sm table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Id</th>
                        <th scope="col">File</th>
                        <th scope="col">Created At</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {propertyDocument &&
                        propertyDocument?.map &&
                        propertyDocument?.map((item) => (
                          <tr key={item?.id}>
                            <td width="240px">{item.id}</td>
                            <td>
                              <div className={"d-flex h-100"}>
                                <AiOutlineFilePdf
                                  size={30}
                                  className={"pe-2"}
                                />{" "}
                                {
                                  <a
                                    href={item.document_url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {item.document_display_name}
                                  </a>
                                }
                              </div>
                            </td>
                            <td
                              style={{ maxWidth: "350px", maxHeight: "220px" }}
                            >
                              {item.created_at}
                            </td>

                            <td width="100">
                              <Dropdown className="mx-4 mt-1 ">
                                <Dropdown.Toggle
                                  variant="carre-purple"
                                  id="dropdown-basic"
                                >
                                  Actions
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() =>
                                      deleteDocument(property.id, item.id)
                                    }
                                  >
                                    <MdDelete /> Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
}

export default ListDocument;
