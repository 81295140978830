import api from "../api";

export const createProperty = (data) => {
    if (data.id) {
        return api.put(`/property/${data.id}`, data).then((res) => res.data);
    } else {
        return api.post("/property", data).then((res) => res.data);
    }
}

export const createPropertyComment = (data) => {
    api.post(`/property/${data?.property_id}/comment`, data).then((res) => res.data)
};

export const updatePropertyComment = (data) => {
    api.put(`/property/${data?.property_id}/comment/${data?.id}`, data).then((res) => res.data)
};