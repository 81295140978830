import React, { useState } from "react";
import { Button, Card, Dropdown, Table } from "react-bootstrap";
import { BiBath, BiBed } from "react-icons/bi";
import { FaCarSide } from "react-icons/fa";

import { useNavigate, useParams } from "react-router-dom";
import { useGetUserReservedToken } from "../../hooks/useGetUserReservedTokens";
import { GetRoleNavigationPath } from "../../routeUtils";
import UserCancelReservedTokenModal from "./UserCancelReservedTokenModal";

function UserReserveToken() {
  const [showModal, setShowModal] = useState(false);
  const [withdrawalToCancel, setWithdrawalToCancel] = useState(null);
  const roleUrl = GetRoleNavigationPath();
  const nav = useNavigate();
  const params = useParams();
  const id = params.id;
  const { data, refetch } = useGetUserReservedToken();
  const filteredData = id
    ? data?.filter((property) => property.id === id)
    : data;

  return (
    <>
      <div>
        {filteredData?.map((property) => (
          <Card key={property.id} className="mb-4">
            <Card.Header className="fs-6 fw-bold">
              {property.property_name}
            </Card.Header>
            <Card.Body>
              <div className="row">
                <div className="col-12 col-md-4">
                  <img
                    alt="property"
                    src={property.default_image?.image_url}
                    className="img-fluid border-sm mb-3"
                    style={{
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="col-12 col-md-8">
                  <div className="row">
                    <div className="col-6">
                      <p className="fs-sm">
                        <strong>Property Area:</strong>{" "}
                        {property?.property_area} sq
                      </p>
                    </div>
                    <div className="col-6">
                      <p className="fs-sm">
                        <strong>Available Shares Price:</strong>{" "}
                        {property?.vacation_token_price?.toLocaleString(
                          "en-US",
                          { style: "currency", currency: "USD" }
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6">
                      <p className="fs-sm">
                        <strong>Total Tokens:</strong>{" "}
                        {property?.number_of_token}
                      </p>
                      <p className="fs-sm">
                        <strong>Monthly Rental Income:</strong>{" "}
                        {property?.monthly_rental_income?.toLocaleString(
                          "en-US",
                          { style: "currency", currency: "USD" }
                        )}
                      </p>
                    </div>
                    <div className="col-6">
                      <p className="fs-sm">
                        <strong>Net Expected Monthly:</strong>{" "}
                        {property?.net_expected_monthly?.toLocaleString(
                          "en-US",
                          { style: "currency", currency: "USD" }
                        )}
                      </p>
                      <p className="fs-sm">
                        <strong>Net Expected Cash Return:</strong>{" "}
                        {(property?.net_expected_cash_return * 100).toFixed(2)}%
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <p className="fs-sm">
                        <strong>Net Expected Total Returns:</strong>{" "}
                        {(property?.net_expected_total_returns * 100).toFixed(
                          2
                        )}
                        %
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>

            <Card.Footer>
              <h5>Transfers</h5>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Amount</th>
                    <th>Share Quantity</th>
                    <th>Share Price</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {property?.reserve_token_list?.map((transfer) => (
                    <tr key={transfer.id}>
                      <td>{transfer.amount}</td>
                      <td>{transfer.token_qty}</td>
                      <td>{transfer.token_price}</td>
                      <td>{transfer.status}</td>

                      <td className="align-middle text-center">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="carre-blue"
                            id="reservation-dropdown"
                          >
                            Actions
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              disabled={transfer?.status === "CANCELED"}
                              onClick={() => {
                                setShowModal(true);
                                setWithdrawalToCancel(transfer);
                              }}
                            >
                              Cancel Reservation
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                nav(
                                  `/${roleUrl}/marketplace/propdetails/${data?.property_id}`
                                );
                              }}
                            >
                              Preview Property
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Footer>
          </Card>
        ))}
      </div>
      <div className="container mt-4">
        <table className="table table-bordered table-sm table-hover ">
          <thead>
            <tr>
              <th scope="col">Property Image</th>
              <th scope="col">Property Details</th>
              <th scope="col">Quantity</th>
              <th scope="col">Status</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data?.map((data, index) => (
              <tr key={index}>
                <td width="240px">
                  <div className="w-100 h-100">
                    <img
                      alt="property"
                      src={data.property?.default_image?.image_url}
                      className="img-fluid  "
                      style={{
                        objectFit: "cover",
                        maxHeight: "200px",
                        maxWidth: "240px",
                        width: "240px",
                        height: "80px",
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div>
                    <div>
                      <p className="fw-bold mb-1">
                        {data?.property?.property_name}
                      </p>
                      <p className="fw-bold mb-1">
                        {data?.property?.property_area} sq
                      </p>
                      <div className="d-flex gap-2">
                        <span className="fw-bold mb-1">
                          <BiBath /> {data?.property?.bathrooms}
                        </span>
                        <span className="fw-bold mb-1">
                          <BiBed /> {data?.property?.bedroom_count}
                        </span>
                        <span className="fw-bold mb-1">
                          <FaCarSide /> {data?.property?.parking}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="align-middle justify-content-center align-items-center text-center">
                  <p className="fw-bold">{data?.token_qty}&nbsp;SHARES</p>
                </td>
                <td
                  className="align-middle justify-content-center align-items-center text-center"
                  style={{
                    color:
                      data?.status === "PENDING"
                        ? "blue"
                        : data?.status === "CANCELED"
                        ? "red"
                        : "green",
                  }}
                >
                  <p className="fw-bold">{data?.status}</p>
                </td>
                <td className="align-middle text-center">
                  <div className="d-flex justify-content-center">
                    <Button
                      className="align-middle fw-bold text-center"
                      variant="carre-red text-white"
                      disabled={data?.status === "CANCELED"}
                      onClick={() => {
                        setShowModal(true);
                        setWithdrawalToCancel(data);
                      }}
                    >
                      Cancel Reservation
                    </Button>
                  </div>
                  <div className="d-flex justify-content-center mt-2">
                    <Button
                      className="align-middle fw-bold text-center"
                      variant="carre-blue text-white"
                      onClick={() => {
                        nav(
                          `/${roleUrl}/marketplace/propdetails/${data?.property_id}`
                        );
                      }}
                    >
                      Preview property
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <UserCancelReservedTokenModal
        refetch={refetch}
        show={showModal}
        data={withdrawalToCancel}
        onHide={() => setShowModal(false)}
      />
    </>
  );
}

export default UserReserveToken;
