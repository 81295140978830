import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React from "react";
import moment from "moment";
import { BsCheckCircle } from "react-icons/bs";
import { RiExchangeDollarLine } from "react-icons/ri";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { PiArrowsLeftRightBold } from "react-icons/pi";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";

const TransferModal = ({
  property,
  transferData,
  show,
  handleClose,
  isSecondary,
  isSell,
}) => {
  const navigate = useNavigate();

  const handleGoToOrders = () => {
    navigate("/user/order");
  };

  const handleCancel = () => {
    navigate(`/user/marketplace/propdetails/${property?.id}`);
  };

  const formatSuccessMessage = () => {
    return (
      <div>
        <p className="fw-bold">
          {isSell
            ? transferData?.status !== "COMPLETED"
              ? "Selling"
              : "Sold"
            : isSecondary
            ? "Buying"
            : "Bought"}{" "}
          {transferData?.transfer?.token_qty || transferData?.token_qty} shares
          at{" "}
          {transferData?.transfer?.token_price.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }) ||
            transferData.token_price?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}{" "}
          per share in {property?.property_name}
        </p>

        {/*<p className="text-muted">*/}
        {/*  Transfer ID: {transferData?.transfer?.id || transferData?.id}*/}
        {/*</p>*/}
        <div className="mt-4 d-flex align-items-center">
          <div className="border bg-success rounded-circle p-1 me-2 mt-2">
            <IoMdCheckmark size={35} />
          </div>
          <div>
            <p className="fw-bold mb-0">Success! Your order is in</p>
            <p className="mb-0 fs-sm mt-0">just now</p>
          </div>
        </div>
      </div>
    );
  };

  const renderSecondaryMarketSteps = () => {
    if (isSecondary) {
      return (
        <>
          <div className="mt-4 d-flex align-items-center">
            <div
              className={`border ${
                transferData?.status === "COMPLETED"
                  ? "bg-success"
                  : "bg-carre-gray"
              } rounded-circle p-1 me-2 mt-2`}
            >
              <PiArrowsLeftRightBold size={35} />
            </div>
            <div className="d-flex flex-column justify-content-center">
              <p className="fw-bold mb-0">Trade executed</p>
              <p className="mb-0 fs-sm mt-0">
                {transferData?.status === "COMPLETED"
                  ? "completed"
                  : "if the limited price is reached"}
              </p>
            </div>
          </div>

          {transferData?.status !== "COMPLETED" && (
            <div className="mt-4 d-flex align-items-center">
              <div className="border bg-carre-gray rounded-circle p-1 me-2 mt-2">
                <IoMdClose size={35} />
              </div>
              <div className="d-flex flex-column justify-content-center">
                <p className="fw-bold mb-0">Order will cancel</p>
                <p className="mb-0 fs-sm mt-0">
                  {transferData?.end_period === "END_OF_DAY"
                    ? " if the price is not reached at the end of the day"
                    : " after completion"}
                </p>
              </div>
            </div>
          )}
        </>
      );
    }
    return null;
  };

  const renderSellMarketSteps = () => {
    if (isSell) {
      return (
        <>
          <div className="mt-4 d-flex align-items-center">
            <div
              className={`border ${
                transferData?.status === "COMPLETED"
                  ? "bg-success"
                  : "bg-carre-gray"
              } rounded-circle p-1 me-2 mt-2`}
            >
              <PiArrowsLeftRightBold size={35} />
            </div>
            <div className="d-flex flex-column justify-content-center">
              <p className="fw-bold mb-0">Trade executed</p>
              <p className="mb-0 fs-sm mt-0">
                {transferData?.status === "COMPLETED"
                  ? "completed"
                  : isSecondary
                  ? "if the limited price is reached"
                  : "if accepted by Carré"}
              </p>
            </div>
          </div>

          {transferData?.status !== "COMPLETED" && (
            <div className="mt-4 d-flex align-items-center">
              <div className="border bg-carre-gray rounded-circle p-1 me-2 mt-2">
                <IoMdClose size={35} />
              </div>
              <div className="d-flex flex-column justify-content-center">
                <p className="fw-bold mb-0">Order will cancel</p>
                <p className="mb-0 fs-sm mt-0">
                  {isSecondary
                    ? transferData?.end_period === "END_OF_DAY"
                      ? " end of day"
                      : " until completion"
                    : "until completion or canceled by your request"}
                </p>
              </div>
            </div>
          )}
        </>
      );
    }
    return null;
  };

  return (
    <Modal show={show} onHide={handleClose} fullscreen>
      <Modal.Header closeButton>
        <Modal.Title>Transfer Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {formatSuccessMessage()}
        {isSell ? renderSellMarketSteps() : renderSecondaryMarketSteps()}
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button variant="secondary" onClick={handleGoToOrders}>
          Go to Orders
        </Button>
        <Button variant="primary" onClick={handleCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TransferModal;
