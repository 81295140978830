import React from "react";
import { Button, Dropdown, Form, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast, ToastContainer } from "react-toastify";
import { GiHouseKeys } from "react-icons/gi";
import { UseCreatePropertyType } from "../hooks/useCreatePropertyType";
import { useGetPropertyType } from "../hooks/useGetPropertyType";
import { MdDelete, MdEdit } from "react-icons/md";

function PropertyType() {
  const { data, isLoading: typeLoading, refetch } = useGetPropertyType();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const { mutate, error, isLoading } = useMutation(UseCreatePropertyType, {
    onSuccess: (data) => {
      notifySuccess();
      refetch().then(() => {});
      reset();
    },
    onError: (error) => {
      notifyError(error?.response.data.error);
    },
  });
  const notifyError = (error) => toast.error(error);
  const notifySuccess = () => toast.success("Property Type Added Successfully");
  const onSubmit = (data) => {
    mutate(data);
  };

  return (
    <div className="m-4">
      <div className="table-responsive">
        <table className="table table-bordered table-sm table-hover ">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Name</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((types) => (
              <tr key={types.id}>
                <td>{types.id}</td>
                <td>{types.name}</td>
                <td width="100">
                  <Dropdown className="mx-4 my-2">
                    <Dropdown.Toggle variant="carre-blue" id="dropdown-basic">
                      Actions
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">
                        <MdEdit /> Edit
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        {" "}
                        <MdDelete /> Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="rounded-2 shadow-lg p-3 mb-5 p-3 my-2 ">
              <GiHouseKeys
                style={{ width: "20px", height: "20px" }}
                className="text-carre-purple  d-inline mb-2 mx-1"
              />
              <p className="h4 font-weight-bold d-inline ">
                {" "}
                Property type details
              </p>
              <Row>
                <Form.Group as={Col}>
                  <label> Property type name</label>
                  <input
                    className={
                      errors.name
                        ? "border-carre-red form-control"
                        : " form-control false"
                    }
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <p className="text-carre-red">This field is required</p>
                  )}
                </Form.Group>
              </Row>
            </div>

            <Button
              type={"submit"}
              size="lg"
              variant="carre-purple w-100"
              disabled={isLoading}
            >
              {!isLoading ? "Create Property Type" : "Please wait"}
            </Button>
          </Form>
        </div>
      </div>

      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
}

export default PropertyType;
