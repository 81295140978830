import React from "react"; // noinspection ES6UnusedImports
import styles from "./FAQ.css";

export const FAQ = () => {
  return (
    <div className="macbook-pro">
      <div className="div">
        <div className="overlap">
          <img
            className="layer"
            alt="Layer"
            src="https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/layer-1.svg"
          />
          <p className="lorem-ipsum-has-been">
            Lorem Ipsum has been the industry&#39;s standard dummy text ever
            since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book.
          </p>
          <div className="text-wrapper">Join Our Team</div>
          <div className="button">
            <div className="text-wrapper-2">Join Here</div>
          </div>
        </div>
        <div className="overlap-group">
          <img
            className="image"
            alt="Image"
            src="https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/image-57-1@2x.png"
          />
          <div className="group">
            <p className="p">Founder / CEO / Head of Business Development</p>
            <p className="text-wrapper-3">
              Serial Enterprenuer. 25+ years in Telecom, IT, Construction
            </p>
            <div className="text-wrapper-4">I. Bici</div>
          </div>
        </div>
        <div className="overlap-2">
          <div className="group-2">
            <div className="text-wrapper-5">CTO</div>
            <p className="text-wrapper-6">20+ years in software development.</p>
            <div className="text-wrapper-7">A. Ademi</div>
          </div>
          <img
            className="img"
            alt="Image"
            src="https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/image-60-1@2x.png"
          />
        </div>
        <div className="nav">
          <div className="div-container">
            <img
              className="link-SVG"
              alt="Link SVG"
              src="https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/link---svg.svg"
            />
            <div className="div-basic-navbar-nav">
              <div className="div-mx-auto">
                <div className="link-home">Home</div>
                <div className="link-marketplace">Marketplace</div>
                <div className="link-about-us">About Us</div>
                <div className="link-help-center">Help Center</div>
              </div>
              <div className="div-mt">
                <div className="group-wrapper">
                  <div className="group-3">
                    <img
                      className="SVG"
                      alt="Svg"
                      src="https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/svg-1.svg"
                    />
                    <div className="text-wrapper-8">Login</div>
                  </div>
                </div>
                <div className="div-wrapper">
                  <div className="text-wrapper-9">+ Sign Up</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="heading-how-real">Our Story</div>
        <div className="heading-how-real-2">Our Mission</div>
        <div className="heading-how-real-3">Our Leadership and Team</div>
        <div className="at-carre-we-ve-wrapper">
          <p className="at-carre-we-ve">
            At Carré, we&#39;ve experienced firsthand the challenges and
            limitations of traditional property ownership. It&#39;s tough and
            often inaccessible, especially for the younger generation. Rising
            house prices, hefty expenses, slow transactions, and a lack of
            information make it difficult to enter the market.
            <br />
            That&#39;s why we created Carré. We believe everyone should have the
            opportunity to invest in real estate and benefit from its potential.
            Our innovative platform offers a human-centered approach, allowing
            fractional ownership and leveraging technology to make the process
            transparent and accessible.
            <br />
            Our mission is simple: to revolutionize real estate investment,
            break down barriers, and empower individuals to build wealth through
            property ownership. With Carré, you can overcome financial hurdles,
            access valuable deals, and enjoy the benefits of real estate in a
            hassle-free way. Welcome to a new era of real estate investment.
          </p>
        </div>
        <div
          className="overlap-3"
          style={{
            backgroundImage:
              "url(https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/image-59@2x.png)",
          }}
        />
        <div
          className="overlap-4"
          style={{
            backgroundImage:
              "url(https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/image-62@2x.png)",
          }}
        />
        <div className="ellipse" />
        <div
          className="overlap-5"
          style={{
            backgroundImage:
              "url(https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/image-63@2x.png)",
          }}
        />
        <div className="overlap-6">
          <div className="ellipse-2" />
          <img
            className="image-2"
            alt="Image"
            src="https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/image-56@2x.png"
          />
        </div>
        <div className="image-wrapper">
          <img
            className="image-3"
            alt="Image"
            src="https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/image-57@2x.png"
          />
        </div>
        <div className="overlap-7">
          <div className="ellipse-3" />
          <img
            className="image-4"
            alt="Image"
            src="https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/image-60@2x.png"
          />
        </div>
        <div
          className="overlap-8"
          style={{
            backgroundImage:
              "url(https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/image-61@2x.png)",
          }}
        />
        <div className="ellipse-4" />
        <div className="group-4">
          <div className="text-wrapper-10">A. Amzaj</div>
          <div className="text-wrapper-11">Front-End Developer</div>
        </div>
        <div className="group-5">
          <div className="text-wrapper-12">M. Ademi</div>
          <div className="text-wrapper-13">Back-End, Blockchain Developer</div>
        </div>
        <div className="group-6">
          <div className="text-wrapper-14">TBD</div>
          <div className="text-wrapper-15">Marketing Director</div>
        </div>
        <div className="group-7">
          <div className="text-wrapper-16">E. Kaiku</div>
          <div className="text-wrapper-17">Creative Director</div>
        </div>
        <div className="group-8">
          <div className="text-wrapper-18">D. Sadikaj</div>
          <div className="text-wrapper-19">Dev Ops</div>
        </div>
        <div className="group-9">
          <div className="text-wrapper-20">S. Gvilava</div>
          <div className="text-wrapper-21">Designer</div>
        </div>
        <div className="group-10">
          <div className="text-wrapper-22">P. Lluka</div>
          <div className="text-wrapper-23">CFO</div>
        </div>
        <div className="group-11">
          <div className="text-wrapper-24">A. Jupolli</div>
          <div className="text-wrapper-25">Legal</div>
        </div>
        <div className="group-12">
          <div className="text-wrapper-26">TBD</div>
          <div className="text-wrapper-27">Realtor Adviser</div>
        </div>
        <div className="div-w">
          <div className="div-d-sm-inline">
            <div className="div-hd-bd">
              <img
                className="SVG-2"
                alt="Svg"
                src="https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/svg.svg"
              />
              <div className="div-my">
                <img
                  className="email"
                  alt="Email"
                  src="https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/email-bbcccc8bbf74f1777c5b0ca44b079273-svg.svg"
                />
                <div className="text-wrapper-28">info@carre.ai</div>
              </div>
              <img
                className="div-my-2"
                alt="Div my"
                src="https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/div-my-4.svg"
              />
            </div>
            <div className="div-my-3">
              <div className="heading-quick">Quick Links</div>
              <div className="list">
                <div className="item-link-calculate">
                  Calculate your property
                </div>
                <div className="item-link-top-offers">Top offers</div>
                <div className="item-link-top-cities">Top cities</div>
              </div>
            </div>
            <div className="div-my-4">
              <div className="heading-about">About</div>
              <div className="list-2">
                <div className="item-link-about-us">About Us</div>
                <div className="item-link-help">Help &amp; support</div>
                <div className="item-link-contacts">Contacts</div>
                <div className="item-link-news">News</div>
              </div>
            </div>
            <div className="div-hd-cfdb">
              <div className="heading-featured">Featured News</div>
              <div className="div-d-flex">
                <div
                  className="footerhero"
                  style={{
                    backgroundImage:
                      "url(https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/footerhero-b3321aebb8c637d064c8-png-1@2x.png)",
                  }}
                />
                <div className="div-hd-bea">
                  <div className="home-improvement">HOME IMPROVEMENT</div>
                  <p className="text-wrapper-29">
                    Your Guide to a Smart Apartment Searching
                  </p>
                  <div className="p-text-muted">
                    <p className="mi-justo-varius">
                      Mi justo, varius vitae cursus ipsum sem
                      <br />
                      massa amet pellentesque. Ipsum enim
                      <br />
                      sit nulla ridiculus semper nam...
                    </p>
                  </div>
                  <div className="div-d-flex-2">
                    <div className="p-mb">
                      <img
                        className="calendar"
                        alt="Calendar"
                        src="https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/calendar-26a29772c2b3882e0dd45401be472d4f-svg-1.svg"
                      />
                      <div className="text-wrapper-30">Dec 4</div>
                    </div>
                    <div className="p-mb-2">
                      <img
                        className="message"
                        alt="Message"
                        src="https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/message-fadc76ce9ff3be1a4c598c08a3c7380a-svg-1.svg"
                      />
                      <div className="text-wrapper-31">2 comments</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="separator" />
              <div className="div-d-flex-3">
                <div
                  className="footerhero-2"
                  style={{
                    backgroundImage:
                      "url(https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/footerhero-b3321aebb8c637d064c8-png@2x.png)",
                  }}
                />
                <div className="div-hd-cabc">
                  <div className="tips-advice">TIPS &amp; ADVICE</div>
                  <p className="text-wrapper-32">
                    Top 10 Ways to Refresh Your Space
                  </p>
                  <div className="volutpat-orci-vitae-wrapper">
                    <p className="volutpat-orci-vitae">
                      Volutpat, orci, vitae arcu feugiat
                      <br />
                      vestibulum ultricies nisi, aenean eget.
                      <br />
                      Vitae enim, tellus tempor consequat mi
                      <br />
                      vitae...
                    </p>
                  </div>
                  <div className="div-d-flex-4">
                    <div className="p-mb-3">
                      <img
                        className="calendar-2"
                        alt="Calendar"
                        src="https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/calendar-26a29772c2b3882e0dd45401be472d4f-svg.svg"
                      />
                      <div className="text-wrapper-33">Nov 24</div>
                    </div>
                    <div className="p-mb-4">
                      <img
                        className="message-2"
                        alt="Message"
                        src="https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/message-fadc76ce9ff3be1a4c598c08a3c7380a-svg.svg"
                      />
                      <div className="text-wrapper-34">No comments</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="group-13">
          <p className="text-wrapper-35">
            Democratize real estate: Making property ownership accessible to
            everyone, regardless of financial barriers or market complexities.
          </p>
          <p className="text-wrapper-36">
            Empower investors: Providing individuals with the tools and
            opportunities to build wealth through fractional ownership and
            transparent investment processes.
          </p>
          <p className="text-wrapper-37">
            Revolutionize the market: Disrupting traditional real estate
            practices with innovative technologies and streamlined experiences.
          </p>
          <p className="text-wrapper-38">
            Foster financial growth: Enabling individuals to unlock the
            potential of real estate as a means to achieve long-term financial
            stability and prosperity.
          </p>
          <div className="ellipse-5" />
          <div className="ellipse-6" />
          <div className="ellipse-7" />
          <div className="ellipse-8" />
        </div>
        <div className="rocket">
          <img
            className="rocket-2"
            alt="Rocket"
            src="https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/rocket.svg"
          />
        </div>
        <img
          className="illustration"
          alt="Illustration"
          src="https://generation-sessions.s3.amazonaws.com/33aefc1b866fc091c4b5b3634501e564/img/illustration.svg"
        />
        <h1 className="h-1">Revolutionizing real estate investment for all.</h1>
        <div className="heading-how-real-4">Our Values</div>
        <div className="overlap-group-wrapper">
          <div className="overlap-group-2">
            <p className="text-wrapper-39">
              We believe in open and honest communication, providing our
              investors with clear and comprehensive information about our
              processes, properties, and investment opportunities.
            </p>
            <div className="text-wrapper-40">Transparency</div>
          </div>
        </div>
        <div className="overlap-wrapper">
          <div className="overlap-9">
            <p className="text-wrapper-41">
              We strive to make real estate investment accessible to all,
              breaking down barriers and creating inclusive opportunities for
              individuals to participate in wealth-building through fractional
              ownership.
            </p>
            <div className="text-wrapper-42">Accessibility</div>
          </div>
        </div>
        <div className="group-14">
          <div className="overlap-10">
            <p className="text-wrapper-43">
              We embrace innovation and leverage cutting-edge technologies to
              revolutionize the real estate investment landscape, continuously
              seeking new ways to enhance the investor experience and drive
              efficiency.
            </p>
            <div className="text-wrapper-44">Innovation</div>
          </div>
        </div>
        <div className="group-15">
          <div className="overlap-11">
            <p className="text-wrapper-45">
              We foster a collaborative environment, working closely with our
              partners, investors, and stakeholders to achieve shared goals and
              create mutually beneficial outcomes.
            </p>
            <div className="text-wrapper-46">Collaboration</div>
          </div>
        </div>
        <div className="group-16">
          <div className="overlap-12">
            <p className="text-wrapper-47">
              We empower our investors by providing them with the knowledge,
              tools, and resources necessary to make informed investment
              decisions and take control of their financial future.
            </p>
            <div className="text-wrapper-48">Empowerment</div>
          </div>
        </div>
        <div className="group-17">
          <div className="overlap-13">
            <p className="text-wrapper-49">
              We prioritize sustainable practices in real estate investment,
              considering environmental, social, and governance factors to
              create long-term value and contribute to a more sustainable
              future.
            </p>
            <div className="text-wrapper-50">Sustainability</div>
          </div>
        </div>
        <div className="group-18">
          <div className="overlap-14">
            <p className="text-wrapper-51">
              We put our investors at the center of everything we do, striving
              to exceed their expectations, provide exceptional customer
              service, and deliver a seamless and rewarding investment
              experience.
            </p>
            <div className="text-wrapper-52">Customer-centricity:</div>
          </div>
        </div>
        <div className="group-19">
          <div className="overlap-15">
            <p className="we-uphold-the">
              We uphold the highest standards of integrity in our operations,
              ensuring that our actions align with our investors&#39; best
              interests and maintaining trust and confidence in our platform.
            </p>
            <div className="text-wrapper-53">Integrity</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FAQ;
