import React from 'react';
import './unauth.css';

const UnauthorizedPage = () => {
  return (
    <div className="unauthorized-container">
      <div className="unauthorized-content">
        <h1>Unauthorized Access</h1>
        <p>You do not have permission to access this page.</p>
        <p>Please contact the administrator for further assistance.</p>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
