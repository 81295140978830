import { useQuery } from "react-query";
import api from "../api";

export const useGetUserForWire = (id, isNew) => {
  return useQuery(
    ["user_for_deposit", id, isNew],
    () => {
      if (!id) return;
      return api.get(`/wire/${id}?is_new=${isNew}`).then((res) => res.data);
    },
    {
      enabled: !!id,
    }
  );
};
