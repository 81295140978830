import React from "react";

function CarreToken() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 40 39"
    >
      <ellipse cx="20" cy="19.5" fill="#3899DA" rx="20" ry="19.5"></ellipse>
      <path
        fill="#fff"
        stroke="#000"
        strokeOpacity="0.05"
        d="M26.668 28.476c-1.575-.354-5.13-2.557-5.13-4.493-.39-6.512 3.45-6.688 3.45-11.545-.008-1.406-.457-2.796-1.32-4.078C22.807 7.08 21.55 5.934 19.984 5c-1.57.94-2.825 2.092-3.683 3.381-.857 1.29-1.297 2.686-1.29 4.097 0 4.84 3.814 5.003 3.45 11.544 0 1.924-3.553 4.137-5.129 4.491-1.576.354 2.875.877 4.865 0 0 0 .087 3.588 1.817 4.487 1.726-.9 1.818-4.487 1.818-4.487 1.943.843 6.418.316 4.837-.037z"
      ></path>
      <path
        fill="#fff"
        stroke="#000"
        strokeOpacity="0.05"
        d="M12.719 23.316s-7.517-2.514-3.576-7.206c2.45-2.915 8.526-.085 9.168 8.462h-1.644s.092-3.437-2.108-4.272c-2.201-.834-2.39 1.174-1.84 3.016zm14.563 0s7.516-2.514 3.575-7.206c-2.45-2.915-8.526-.085-9.167 8.462h1.648s-.09-3.437 2.107-4.272c2.198-.834 2.386 1.174 1.837 3.016zm-3.695 1.257h-7.244a.836.836 0 00-.298.054.783.783 0 00-.252.154.71.71 0 00-.17.231.66.66 0 00-.058.273v.003c0 .189.082.37.227.503a.814.814 0 00.55.209h7.245a.814.814 0 00.549-.209.683.683 0 00.227-.503v-.003a.683.683 0 00-.227-.503.814.814 0 00-.55-.209z"
      ></path>
    </svg>
  );
}

export default CarreToken;
