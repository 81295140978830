import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useMutation } from "react-query";
import { ToastContainer, toast } from "react-toastify";
import { useUserPasswordChange } from "../../hooks/useUserPasswordChange";

export default function PasswordSecurity() {
  const [response, setResponse] = useState();
  const [newPassword, setNewPassword] = useState("");
  const [confirmationPassowrd, setConfirmationPassword] = useState("");
  const [match, setMatch] = useState(false);
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm();
  const { mutate, error, isLoading } = useMutation(useUserPasswordChange, {
    onSuccess: (data) => {
      notifySuccess();
      reset();
    },
    onError: (error) => {
      notifyError(error?.response.data.error);
    },
  });
  const notifyError = (error) => toast.error(error);

  const newPw = watch("new_password");
  const confirmPw = watch("confirmation_password");

  useEffect(() => {
    setMatch(newPw === confirmPw);
  }, [newPw, confirmPw]);

  const onSubmit = (data) => {
    mutate(data);
  };

  const toggleCurrentPasswordVisibility = () => {
    setCurrentPasswordVisible(!currentPasswordVisible);
  };

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const notifySuccess = () => toast.success("Password Changed Successfully");

  return (
    <div className="container-sm">
      <h2>Password & Security</h2>
      <p className="text-muted text-sm text-lg">
        Manage your password settings and secure your account.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group d-flex flex-column flex-lg-row">
          {/* Current Pass */}
          <div>
            <label htmlFor="currentPassword">Current Password</label>
            <div className="password-input">
              <input
                onChange={(event) => setNewPassword(event.target.value)}
                type={currentPasswordVisible ? "text" : "password"}
                className={`${
                  errors.current_password ? "border-carre-red" : "rounded"
                } d-inline`}
                style={{ height: "45px", display: "flex" }}
                {...register("current_password", { required: true })}
              />
              <div
                className="password-toggle d-inline"
                onClick={toggleCurrentPasswordVisibility}
              >
                {currentPasswordVisible ? (
                  <AiFillEyeInvisible size={25} className="mx-2 mb-2" />
                ) : (
                  <AiFillEye size={25} className="mx-2 mb-2" />
                )}
              </div>
            </div>
            {errors.current_password && (
              <p className="text-carre-red">This field is required</p>
            )}
          </div>
          <small className="form-text text-muted mt-lg-5 ms-lg-3">
            <a
              href="/reset-password"
              className="text-decoration-underline text-carre-purple"
            >
              Forgot password?
            </a>
          </small>
        </div>
        {/* New Confirm Pass */}
        <div className="d-flex flex-column flex-lg-row mt-lg-3">
          <div>
            <label htmlFor="newPassword">New Password</label>
            <div className="password-input">
              <input
                type={newPasswordVisible ? "text" : "password"}
                {...register("new_password", { required: true })}
                className={`${
                  errors.new_password ? "border-carre-red" : "rounded"
                } d-inline`}
                style={{ height: "45px", display: "flex" }}
              />
              <div
                className="password-toggle d-inline"
                onClick={toggleNewPasswordVisibility}
              >
                {newPasswordVisible ? (
                  <AiFillEyeInvisible size={25} className="mx-2 mb-2" />
                ) : (
                  <AiFillEye size={25} className="mx-2 mb-2" />
                )}
              </div>
            </div>
            {errors.new_password && (
              <p className="text-carre-red">This field is required</p>
            )}
          </div>

          <div className="mt-3 mt-lg-0 ms-lg-3">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <div className="password-input">
              <input
                {...register("confirmation_password", { required: true })}
                type={confirmPasswordVisible ? "text" : "password"}
                className="d-inline rounded"
                style={{ height: "45px", display: "flex" }}
              />
              <div
                className="password-toggle d-inline"
                onClick={toggleConfirmPasswordVisibility}
              >
                {confirmPasswordVisible ? (
                  <AiFillEyeInvisible size={25} className="mx-2 mb-2" />
                ) : (
                  <AiFillEye size={25} className="mx-2 mb-2" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <Button
            type={"submit"}
            size="sm"
            variant="carre-purple"
            style={{ height: "45px" }}
            disabled={!match}
          >
            {match ? "Change Password" : "Password doesn't match"}
          </Button>
        </div>
      </form>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
}
