import React, { useEffect } from "react";
import { Button, Form, InputGroup, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { GiHouseKeys } from "react-icons/gi";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useUpdatePropertyOccupancyStatus } from "../hooks/useUpdatePropertyOccupancyStatus";

function OccupancyStatusModal(props) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      end_lease: new Date(),
      occupied_until: new Date(),
    },
  });

  useEffect(() => {
    if (props.data) {
      reset({
        rental_type: props?.data.rental_type,
        rental_type_status: props?.data.rental_type_status,
        monthly_rental_income: props?.data.monthly_rental_income,
        daily_rental_income: props?.data.daily_rental_income,
        expected_number_of_rented_days:
          props?.data.expected_number_of_rented_days,
        id: props?.data.id,
      });
    }
  }, [props.data]);
  const occupancyStatus = watch("rental_type");
  const occupancyType = watch("rental_type_status");

  const { mutate, isLoading } = useMutation(useUpdatePropertyOccupancyStatus, {
    onSuccess: (data) => {
      notifySuccess();
      props.refetch().then(() => {});
      props.onHide();
    },
    onError: () => {
      notifyError();
    },
  });
  const notifyError = () =>
    toast.error(errors?.response?.data?.error || "Something went wrong ");
  const notifySuccess = () =>
    toast.success("Property Status Updated Successfully");
  const onSubmit = (data) => {
    mutate(data);
  };
  useEffect(() => {
    if (occupancyStatus === "SHORT_TERM") {
      setValue("occupied_until", new Date());
      setValue("end_lease", new Date());
    }
  });
  useEffect(() => {
    if (occupancyStatus === "SHORT_TERM" && occupancyType === "OCCUPIED") {
      setValue("occupied_until", new Date());
      setValue("end_lease", new Date());
    }
  });
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Change Occupancy Status
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="rounded-2 shadow-lg p-3  p-3 my-2 ">
              <GiHouseKeys
                style={{ width: "20px", height: "20px" }}
                className="text-carre-purple  d-inline mb-2 mx-1"
              />
              <p className="h4 font-weight-bold d-inline ">STATUS</p>
              <Row>
                <Form.Group as={Col}>
                  <label>Rental type</label>
                  <Form.Select
                    className={
                      errors.rental_type
                        ? "text-carre-purple form-control border-carre-red mb-2"
                        : "mb-2 form-control false"
                    }
                    {...register("rental_type")}
                  >
                    <option value={""}>Please Select</option>
                    <option key={1} value="LONG_TERM">
                      Long Term
                    </option>
                    <option key={2} value="SHORT_TERM">
                      Short Term
                    </option>
                  </Form.Select>
                  {errors.rental_type && (
                    <p className="text-carre-red">This field is required</p>
                  )}
                </Form.Group>
              </Row>
              <div>
                <Row>
                  <Form.Group as={Col}>
                    <label>Rental type status</label>
                    <Form.Select
                      className={
                        errors.rental_type_status
                          ? "text-carre-purple form-control border-carre-red mb-2"
                          : " form-control false"
                      }
                      {...register("rental_type_status", {})}
                    >
                      <option value={""}>Please Select</option>
                      <option key={1} value="OCCUPIED">
                        Occupied
                      </option>
                      <option key={2} value="VACANT">
                        Vacant
                      </option>
                    </Form.Select>
                    {errors.rental_type_status && (
                      <p className="text-carre-red">This field is required</p>
                    )}
                  </Form.Group>
                </Row>
              </div>
              {occupancyStatus === "LONG_TERM" &&
                occupancyType === "OCCUPIED" && (
                  <div>
                    <Row>
                      <Form.Group as={Col}>
                        <label>End Lease Date</label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            defaultValue={
                              props.data?.end_lease &&
                              new Date(props.data?.end_lease)
                                .toISOString()
                                .substring(0, 10)
                            }
                            className={
                              errors.end_lease
                                ? " form-control border-4 border-carre-red"
                                : " form-control false"
                            }
                            {...register("end_lease", {
                              required: true,
                            })}
                            type="date"
                          />
                        </InputGroup>
                        {errors.end_lease && (
                          <p className="text-carre-red">
                            This field is required{" "}
                          </p>
                        )}
                      </Form.Group>
                      <Form.Group as={Col}>
                        <label>Occupied until</label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            defaultValue={
                              props.data?.occupied_until &&
                              new Date(props.data?.occupied_until)
                                .toISOString()
                                .substring(0, 10)
                            }
                            className={
                              errors.occupied_until
                                ? " form-control border-4 border-carre-red"
                                : " form-control false"
                            }
                            {...register("occupied_until", {
                              required: true,
                            })}
                            type="date"
                          />
                        </InputGroup>
                        {errors.occupied_until && (
                          <p className="text-carre-red">
                            This field is required to move to market
                          </p>
                        )}
                      </Form.Group>
                      <Form.Group as={Col}>
                        <label>Monthly rental income </label>
                        <input
                          type="number"
                          className={
                            errors.monthly_rental_income
                              ? "border-carre-red form-control"
                              : " form-control false"
                          }
                          {...register("monthly_rental_income", {
                            valueAsNumber: true,
                            required: true,
                          })}
                        />
                        {errors.monthly_rental_income && (
                          <p className="text-carre-red">
                            This field is required
                          </p>
                        )}
                      </Form.Group>
                    </Row>
                  </div>
                )}

              {occupancyStatus === "SHORT_TERM" &&
                occupancyType === "OCCUPIED" && (
                  <div>
                    <Row>
                      <Form.Group as={Col}>
                        <label>Daily rental income </label>
                        <input
                          type="number"
                          className={
                            errors.daily_rental_income
                              ? "border-carre-red form-control"
                              : " form-control false"
                          }
                          {...register("daily_rental_income", {
                            valueAsNumber: true,
                            required: true,
                          })}
                        />
                        {errors.daily_rental_income && (
                          <p className="text-carre-red">
                            This field is required
                          </p>
                        )}
                      </Form.Group>
                      <Form.Group as={Col}>
                        <label>Expected rented day </label>
                        <input
                          type="number"
                          className={
                            errors.expected_number_of_rented_days
                              ? "border-carre-red form-control"
                              : " form-control false"
                          }
                          {...register("expected_number_of_rented_days", {
                            valueAsNumber: true,
                            required: true,
                          })}
                        />
                        {errors.expected_number_of_rented_days && (
                          <p className="text-carre-red">
                            This field is required
                          </p>
                        )}
                      </Form.Group>
                    </Row>
                  </div>
                )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
            <Button
              type={"submit"}
              size="sm"
              variant="carre-purple w-25"
              disabled={isLoading}
            >
              {!isLoading ? "Change Status" : "Please wait"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default OccupancyStatusModal;
