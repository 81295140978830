import { useQuery } from "react-query";
import api from "../api";
import { useAuth } from "../auth/TokenCreation";

export const useGetUserData = () => {
  const { cookies } = useAuth();

  return useQuery(
    ["user_data"],
    () => {
      if (cookies.token) {
        return api.get("user/user-information").then((res) => res.data);
      } else {
      }
    },
    {}
  );
};

export const useGetUserDataKYC = () => {
  return useQuery(["user_data_kyc"], () => {
    return api.get("user/user-information").then((res) => res.data);
  });
};
