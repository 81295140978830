import React, { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  useRequestPasswordResetCode,
  useResetPassword,
  useVerifyRequestCodeForPasswordReset,
} from "../hooks/useRequestPaswordResetCode";
import { useTabContext } from "./Dashboard/TabContext";
import SignUp from "./SignUp";
import Login from "./Login";
// import "./ForgetPasword.css";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1);
  const [success, setSuccess] = useState(false);

  const { mutate } = useMutation(useRequestPasswordResetCode, {
    onSuccess: (data) => {
      // Handle success response or move to the next step
      setStep(2);
      toast.success("A verification code has been send to your email!");
    },
    onError: (error) => {
      toast.error(error?.response?.data?.error || error?.message);
    },
  });

  const { mutate: mutateCode } = useMutation(
    useVerifyRequestCodeForPasswordReset,
    {
      onSuccess: (data) => {
        // Handle success response or move to the next step
        toast.success("Correct code!");
        setStep(3);
      },
      onError: (error) => {
        toast.error(error?.message);
      },
    }
  );
  const nav = useNavigate();
  const {
    loginModalOpen,
    setLoginModalOpen,
    signUpModalOpen,
    setSignUpModalOpen,
  } = useTabContext();

  const openLoginFromSignUp = () => {
    setSignUpModalOpen(false);
    setLoginModalOpen(true);
  };

  const openSignUpFromLogin = () => {
    setLoginModalOpen(false);
    setSignUpModalOpen(true);
  };
  const { mutate: mutateNewPassword } = useMutation(useResetPassword, {
    onSuccess: (data) => {
      // Handle success response or move to the next step
      setSuccess(true);
      toast.success("Password successfully reset!");
      nav("/");
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (step === 1) {
      mutate({ email });
    } else if (step === 2) {
      mutateCode({ email, code });
    } else if (step === 3) {
      mutateNewPassword({ email, code, new_password: newPassword });
    }
  };

  const renderForm = () => {
    if (step === 1) {
      return (
        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
      );
    } else if (step === 2) {
      return (
        <Form.Group controlId="formCode" className="w-100">
          <Form.Label>Verification Code</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter the verification code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
        </Form.Group>
      );
    } else if (step === 3) {
      return (
        <>
          <Form.Group controlId="formNewPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter your new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formConfirmPassword">
            <Form.Label>Confirm New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm your new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </Form.Group>
        </>
      );
    }
  };

  return (
    <Container className="d-flex align-items-center justify-content-center vh-100 container">
      <div>
        <h1 className="text-center text-carre-blue mb-4">Reset Password</h1>
        <Form onSubmit={handleSubmit}>
          {renderForm()}

          {success ? (
            <Button
              variant="outline-carre-blue"
              type="submit"
              className="w-100 mt-2"
              disabled
            >
              Password successfully reset!
            </Button>
          ) : (
            <Button variant="carre-blue " type="submit" className="w-100 mt-2">
              {step === 3 ? "Reset Password" : "Continue"}
            </Button>
          )}
        </Form>
        <Button
          className="w-100 mt-2 text-center"
          variant="outline-carre-blue"
          onClick={() => setSignUpModalOpen(true)}
        >
          Not registered? Sign up here
        </Button>
      </div>

      <Login
        show={loginModalOpen}
        onHide={() => setLoginModalOpen(false)}
        openSignUpFromLogin={openSignUpFromLogin}
      />

      <SignUp
        show={signUpModalOpen}
        onHide={() => setSignUpModalOpen(false)}
        openLoginFromSignUp={openLoginFromSignUp}
      />
    </Container>
  );
};

export default ForgetPassword;
