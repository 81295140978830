import { useQuery } from "react-query";
import api from "../api";

export const useGetSellRequestForAdmin = (data) => {
  return useQuery(["sell_request_for_admin"], () => {
    if (data) {
      return api.get("/request?status=PENDING").then((res) => res.data);
    } else {
      return api.get("/request").then((res) => res.data);
    }
  });
};
