import Dropdown from "react-bootstrap/Dropdown";
import { BiBath, BiBed } from "react-icons/bi";
import { FaCarSide } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import TokenIcon from "../../assets/icons/TokenIcon";
import CarreToken from "../../assets/icons/CarreToken";

const FinderPropertyCard = ({
  images,
  href,
  category,
  location,
  price,
  badges,
  wishlistButton,
  dropdown,
  footer,
  horizontal,
  light,
  className,
  data,
  ...props
}) => {
  const horizontalClass = horizontal ? " card-horizontal" : "",
    extraClass = className ? ` ${className}` : "";
  const image = data?.image != null ? data?.image[0]?.image_url : "";
  const title = data.property_name;
  const isLongTitle = title.length > 15;
  const titleFontSize = isLongTitle ? "14px" : "inherit";

  return (
    <div
      {...props}
      className={
        light
          ? ` card card-light custom-hover `
          : ` card card-hover custom-hover shadow-md border-0${horizontalClass}${extraClass}`
      }
    >
      {horizontal ? (
        <div
          className="card-img position-relative w-200 md:w-full"
          style={{
            height: "249px", // Set the height of the container
            backgroundImage: `url(${data?.image.image_url})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div
            className="position-absolute text-center"
            style={{ right: "20px", bottom: "0", padding: "5px" }}
          >
            <p
              className="text-uppercase fw-bold text-white bg-carre-blue p-2 rounded-pill mt-1"
              style={{ fontSize: "12px" }}
            >
              sadasdsa
            </p>
          </div>
        </div>
      ) : (
        <div
          className="card-img position-relative"
          style={{ width: "100%", height: "300px" }}
        >
          <img
            loading="lazy"
            className="card-img-top"
            alt=""
            src={image}
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
            }}
          />
          <div
            className="position-absolute text-center"
            style={{ right: "20px", bottom: "0", padding: "5px" }}
          >
            <p
              className="text-uppercase fs-small text-white bg-carre-blue p-2 rounded-pill mt-1"
              style={{ fontSize: "12px" }}
            >
              {data?.market_stage_name}
            </p>
          </div>
          <div
            className="position-absolute text-center"
            style={{ right: "20px", bottom: "0", padding: "5px" }}
          >
            <p
              className="text-uppercase fs-small text-white bg-carre-blue p-2 rounded-pill mt-1"
              style={{ fontSize: "12px" }}
            >
              {data?.market_stage_name}
            </p>
          </div>
          {data?.id === "56996ca0-dd3e-4301-845a-286e6303be5a" && (
            <div
              className="position-absolute text-center"
              style={{ right: "20px", top: "0", padding: "5px" }}
            >
              <p
                className="text-uppercase fs-md text-white bg-warning p-2 rounded-pill  mt-1 px-3"
                style={{ fontSize: "12px" }}
              >
                SOLD
              </p>
            </div>
          )}
        </div>
      )}
      <div className="card-body position-relative pb-3 ">
        {dropdown && (
          <Dropdown className="dropdown position-absolute z index-5 top-0 end-0 mt-3 me-3">
            <Dropdown.Toggle
              variant={`${
                light ? "translucent-light" : "light shadow-sm"
              } btn-icon btn-xs rounded-circle`}
            >
              <FiAlertTriangle className="text-info" />
            </Dropdown.Toggle>
            <Dropdown.Menu variant={light ? "dark" : ""} className="my-1">
              {dropdown.map((item, indx) => {
                if (item.href) {
                  return (
                    <Dropdown.Item
                      key={indx}
                      as={href}
                      href={item.href}
                      {...item.props}
                    >
                      <i
                        className={`${item.icon}${
                          light ? "" : " opacity-60"
                        } me-2`}
                      ></i>
                      {item.label}
                    </Dropdown.Item>
                  );
                } else {
                  return (
                    <Dropdown.Item key={indx} as="button" {...item.props}>
                      <i
                        className={`${item.icon}${
                          light ? "" : " opacity-60"
                        } me-2`}
                      ></i>
                      {item.label}
                    </Dropdown.Item>
                  );
                }
              })}
            </Dropdown.Menu>
          </Dropdown>
        )}
        {category && (
          <div className="mb-1 fs-xs text-uppercase text-carre-blue">
            {category}
          </div>
        )}

        {/* <Card.Title
            className="h5 mt-1 text-carre-black fw-bold"
            style={{
              fontSize: titleFontSize,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </Card.Title> */}
        {title && (
          <h3 className=" mb-2 fs-base">
            <p
              style={{
                fontSize: titleFontSize,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              className={light ? "text-light" : ""}
            >
              {title}
            </p>
          </h3>
        )}
        <p
          className={`mb-4 fs-sm`}
          style={{
            maxHeight: "20px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {`
    ${data?.address?.street_name}, ${data?.address?.city_name}, ${
            data?.address?.zipcode ? data.address.zipcode + ", " : ""
          }${
            data?.address?.country_state
              ? data.address.country_state + ", "
              : ""
          }${data?.address?.country_name}`}
        </p>
        {data?.market_stage_name !== "Secondary" ? (
          <div className="d-flex fw-bold align-items-center justify-content-between mt-2">
            <p className="fs-sm  mb-0 me-2">Available Shares</p>
            <p className="text-carre-gray-bg mb-0">
              <span className="mx-1 fs-sm text-carre-blue float-end">
                {price} <TokenIcon />
              </span>
            </p>
          </div>
        ) : (
          <div style={{ height: 27 }}></div>
        )}
        {price && (
          <div className="d-flex fw-bold align-items-center justify-content-between mt-2">
            <p className="fs-sm  mb-0 me-2">Expected Total Return</p>
            <p className="text-carre-gray-bg mb-0">
              <span className="mx-1 fs-sm text-carre-blue float-end">
                {(
                  data?.net_expected_cash_return +
                  data?.vacation_token_exceptions +
                  data?.net_appreciation
                ).toFixed(2)}{" "}
                %
              </span>
            </p>
          </div>
        )}
        {horizontal && (
          <>
            {footer && (
              <div
                className={`d-flex align-items-center justify-content-center justify-content-sm-start border-top${
                  light ? " border-light" : ""
                } pt-3 pb-2 mt-3 text-nowrap`}
              >
                {footer.map((item, indx) => {
                  return (
                    <span key={indx} className="d-inline-block me-4 fs-sm">
                      {item[1]}
                      <i
                        className={`${item[0]} ms-1 mt-n1 fs-lg text-muted`}
                      ></i>
                    </span>
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>
      {!horizontal && (
        <>
          {footer && (
            <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap">
              <span className="d-inline-block mx-1 px-2 fs-sm">
                {data.bedroom_count}
                <BiBed />
              </span>
              <span className="d-inline-block mx-1 px-2 fs-sm">
                {data.bathrooms}
                <BiBath />
              </span>
              <span className="d-inline-block mx-1 px-2 fs-sm">
                {data.parking}
                <FaCarSide />
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FinderPropertyCard;
