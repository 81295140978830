import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Modal, Button, Form } from "react-bootstrap";

import {
  useCreateRateCalendar,
  useGetRateCalendar,
  useUpdateRateCalendar,
  useDeleteRateCalendar,
} from "../../hooks/useRateCalendar";
import { useGetProperty } from "../../hooks/useGetProperty";

const localizer = momentLocalizer(moment);

const MyCalendar = () => {
  const [selectedRange, setSelectedRange] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [price, setPrice] = useState(0);
  const [minimumStay, setMinimumStay] = useState(1);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { data: properties, isLoading: isPropertiesLoading } = useGetProperty({
    isAdmin: true,
  });

  const { refetch, data: rateCalendar } =
    useGetRateCalendar(selectedPropertyId);

  const handlePropertyChange = (e) => {
    setSelectedPropertyId(e.target.value);
    refetch();
  };

  const handleSelectSlot = (slotInfo) => {
    const { slots } = slotInfo;
    const currentDate = new Date();
    if (slots[0] < currentDate) {
      toast.error("You cannot select past dates.");
      return;
    }
    const addHours = (dateString, hours) => {
      const date = new Date(dateString);
      date.setHours(date.getHours() + hours);
      return date;
    };

    // const start = addHours(slots[0], 2);
    // const end = addHours(slots[slots.length - 1], 2);
    const start = moment.utc(slots[0]).toDate();
    const end = moment.utc(slots[slots.length - 1]).toDate();

    setSelectedRange({ start, end });
    setSelectedEvent(null);
    setPrice(0);
    setMinimumStay(1);
    setShowModal(true);
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setSelectedRange({
      start: event.start,
      end: event.end,
    });
    setPrice(event.price);
    setMinimumStay(event.minimumStay);
    setShowModal(true);
  };

  const createMutation = useMutation(useCreateRateCalendar, {
    onSuccess: (data) => {
      toast.success(data.message);
      refetch();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.error);
    },
  });

  const updateMutation = useMutation(useUpdateRateCalendar, {
    onSuccess: (data) => {
      toast.success(data.message);
      refetch();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.error);
    },
  });

  const deleteMutation = useMutation(useDeleteRateCalendar, {
    onSuccess: (data) => {
      toast.success(data.message);
      refetch();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.error);
    },
  });

  const handleSubmit = () => {
    if (selectedEvent) {
      const data = {
        id: selectedPropertyId,
        rate_id: selectedEvent.rateId,
        price,
        minimum_stay: minimumStay,
      };
      updateMutation.mutate(data);
    } else {
      const entries = [];
      let currentDate = moment(selectedRange.start);

      while (currentDate <= moment(selectedRange.end)) {
        entries.push({
          // date: moment.utc(currentDate.clone()),
          date: currentDate.format("YYYY-MM-DD") + "T00:00:00.000Z",
          price,
          minimum_stay: minimumStay,
        });
        currentDate = currentDate.add(1, "day");
      }

      const data = { id: selectedPropertyId, entries };
      createMutation.mutate(data);
    }

    setShowModal(false);
  };

  const handleDelete = () => {
    if (selectedEvent) {
      const data = {
        id: selectedPropertyId,
        rate_id: selectedEvent.rateId,
      };
      deleteMutation.mutate(data);
    }
    setShowModal(false);
  };

  const events = rateCalendar
    ? rateCalendar.map((entry) => ({
        title: `Price: $${entry.price}, Min Stay: ${entry.minimum_stay} day(s)`,
        start: new Date(entry.date),
        end: new Date(entry.date),
        allDay: true,
        price: entry.price,
        minimumStay: entry.minimum_stay,
        rateId: entry.id,
      }))
    : [];

  const formatDate = (date) => moment(date).format("YYYY-MM-DD");

  return (
    <div>
      <Form.Group controlId="propertySelect" className="mb-5">
        <Form.Label>Select Property</Form.Label>
        <Form.Control
          as="select"
          value={selectedPropertyId || ""}
          onChange={handlePropertyChange}
          disabled={isPropertiesLoading}
        >
          <option value="" disabled>
            Select a property...
          </option>
          {properties &&
            properties.map((property) => (
              <option key={property.id} value={property.id}>
                {property.property_name}
              </option>
            ))}
        </Form.Control>
      </Form.Group>

      {selectedPropertyId && (
        <Calendar
          localizer={localizer}
          events={events}
          selectable
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleSelectEvent}
          style={{ height: 600 }}
          views={["month", "agenda"]}
        />
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedEvent
              ? "Edit Price and Minimum Stay"
              : "Set Price Per Day"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Selected Range:</p>
          <p>
            Start: {selectedRange?.start && formatDate(selectedRange.start)}
          </p>
          <p>End: {selectedRange?.end && formatDate(selectedRange.end)}</p>
          <Form>
            <Form.Group>
              <Form.Label>Price per day:</Form.Label>
              <Form.Control
                type="number"
                value={price}
                onChange={(e) => setPrice(parseFloat(e.target.value))}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Minimum Stay (days):</Form.Label>
              <Form.Control
                type="number"
                value={minimumStay}
                onChange={(e) => setMinimumStay(parseInt(e.target.value))}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {selectedEvent && (
            <Button variant="danger" onClick={handleDelete}>
              Delete
            </Button>
          )}
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MyCalendar;
