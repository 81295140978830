import { Button, Col, Container, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useUpdateSteps } from "../hooks/useUpdateSteps";
import VerificationUser from "../pages/VerificationUser";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Testimonials from "./Testimonials";

function AmericanCitizen() {
  const { control, handleSubmit, watch } = useForm();
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookie] = useCookies();
  const queryClient = useQueryClient();

  const notifyError = () => toast.error("Something went wrong");
  const notifySuccess = () =>
    toast.success("Application created successfully ");

  const { mutate: updateStep } = useMutation(useUpdateSteps, {
    onSettled: (data, error) => {
      navigate("/user/marketplace");
      queryClient.invalidateQueries({ queryKey: ["user_data"] });
      removeCookie("guide_step");

      if (error) {
        notifyError(`Error updating step "${data}"`);
      } else {
        // notifySuccess(`Step updated successfully`);
      }
    },
  });

  const [verificationModal, setVerificationModal] = useState();
  useEffect(() => {
    if (cookies?.verified === "false" || cookies?.verified === false) {
      setVerificationModal(true);
    } else {
      setVerificationModal(false);
    }
  }, [cookies]);

  return (
    <Container className="d-flex justify-content-center align-items-center min-vh-100">
      <VerificationUser
        show={verificationModal}
        onHide={() => setVerificationModal(false)}
        openLoginFromVerify1={undefined}
      />
      <Container className="h-100 mb-5 mt-3">
        <div className="text-end">
          <FaTimes
            size="25"
            onClick={() => {
              updateStep("AMERICAN_CITIZEN");
            }}
          />
        </div>
        <Row className="justify-content-center">
          <Col
            lg={4}
            className="d-flex flex-column align-items-center text-center text-lg-start"
          >
            <p className="fs-1 fw-semibold text-black ">Welcome to Carré</p>
            <p>
              Get started today and unlock the vast potential that real estate
              investment offers!
            </p>
            <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center w-100">
              <Button
                size="sm"
                onClick={() => updateStep("LATER_KYC")}
                className="mb-2 mb-lg-0 w-100 w-lg-auto"
              >
                Explore Properties
              </Button>
              <Button
                variant="warning"
                className="mx-2 mx-lg-4 w-100 w-lg-auto"
                size="sm"
                onClick={() => updateStep("AMERICAN_CITIZEN")}
              >
                Complete your profile
              </Button>
            </div>
            <div className="mt-3">
              <p className="d-flex align-items-center mb-2">
                <FaCheck className="me-2 text-success" />
                Start investing with as little as $100
              </p>
              <p className="text-dark d-flex align-items-center mb-2">
                <FaCheck className="me-2 text-success" />
                Join a +3000 investors community
              </p>
              <p className="text-dark d-flex align-items-center">
                <FaCheck className="me-2 text-success" />
                Complete your profile in less than 2 min
              </p>
            </div>
          </Col>
          <Col lg={8} className="mt-5 mt-lg-0">
            <Testimonials />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default AmericanCitizen;
