import React, { useEffect } from "react";
import { Card, Col, Container, Placeholder, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useGetUserAsset } from "../../hooks/useGetUserAsset";
import { useGetUserReservedToken } from "../../hooks/useGetUserReservedTokens";
import { GetRoleNavigationPath } from "../../routeUtils";
import AssetsPropertyCard from "../Property/AssetsPropertyCard";
import "./assets.css";
import Footer from "../../components/Footer";

const Assets = () => {
  const { data, refetch, isLoading: userAssetsLoading } = useGetUserAsset();
  const { data: reservedData, isLoading: UserReservedTokenLoading } =
    useGetUserReservedToken();
  const roleUrl = GetRoleNavigationPath();

  useEffect(() => {
    refetch();
  }, []);
  let tokens = 0;
  let totalApp = 0;
  let vacationTokens = 0;
  let tokenRent = 0;
  let reservedTokens = 0;

  if (data) {
    for (let i = 0; i <= data?.length - 1; i++) {
      // eslint-disable-next-line
      tokens += data[i].owned_token;
      totalApp += data[i].net_appreciation;
      vacationTokens += data[i].vacation_token_sum;
      tokenRent += data[i].dividend_sum;
    }
  }

  if (reservedData) {
    for (let i = 0; i <= reservedData?.length - 1; i++) {
      for (
        let j = 0;
        j <= reservedData[i].reserve_token_list?.length - 1;
        j++
      ) {
        reservedTokens += reservedData[i].reserve_token_list[j].token_qty;
      }
    }
  }
  const navigate = useNavigate();

  const cards = [
    {
      title: `Total Income`,
      style: "fs-lg text-carre-blue",

      cardTitleValue: ` ${(vacationTokens + tokenRent).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}`,

      values: [
        ` ${tokenRent?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}`,
        `${vacationTokens?.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}`,
        `$0.00`,
      ],
      text: ["Income", "Vacation Points", "Share appreciation"],
    },
    {
      title: `Total Value`,
      style: "fs-lg text-carre-blue",

      cardTitleValue: `${(tokens * 100)?.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })}`,
      values: [`${tokens}`, `${data?.length}`, `${reservedTokens}`],
      text: [
        "Number of shares",
        "Number of assets",
        `Number of reserved shares`,
      ],
    },
  ];

  // const renderTooltip = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     {props.street_name}, {props.city_name}, {props.country_state},
  //     {props.country_name}, {props.zipcode}
  //   </Tooltip>
  // );

  const isLoading = userAssetsLoading || UserReservedTokenLoading;

  const getRandomValueInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  if (isLoading) {
    return (
      <>
        <div className="row">
          {[1, 2].map((card, index) => (
            <div className="col-lg-6 col-md-6 col-12 mb-3" key={index}>
              <Card>
                <Card.Body>
                  <Row className="d-flex align-items-center mb-2">
                    <Col xs={9}>
                      <Placeholder
                        as={Card.Title}
                        animation="glow"
                        className="gap-2 d-flex"
                      >
                        <Placeholder xs={1} />
                        <Placeholder xs={7} />
                      </Placeholder>
                    </Col>
                    <Col xs={3}>
                      <Placeholder
                        as={Card.Title}
                        animation="glow"
                        className="gap-2 d-flex"
                      >
                        <Placeholder xs={12} />
                      </Placeholder>
                    </Col>
                  </Row>

                  {[1, 2, 3].map((item, index) => (
                    <Row className="d-flex align-items-center" key={index}>
                      <Col xs={9}>
                        <Placeholder
                          as={Card.Title}
                          animation="glow"
                          className="gap-2 d-flex"
                        >
                          <Placeholder xs={1} />
                          <Placeholder xs={getRandomValueInRange(6, 11)} />
                        </Placeholder>
                      </Col>
                      <Col xs={3}>
                        <Placeholder
                          as={Card.Title}
                          animation="glow"
                          className="gap-2 d-flex justify-content-end"
                        >
                          <Placeholder xs={getRandomValueInRange(7, 12)} />
                        </Placeholder>
                      </Col>
                    </Row>
                  ))}
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
        {[1, 2, 3].map((card, index) => (
          <div key={index}>
            <div
              className="d-flex flex-column flex-md-row"
              style={{ width: "100%", height: "auto" }}
            >
              <div
                className="d-none d-sm-block" // Hides this on small screens
                style={{ width: "100%", maxWidth: "30%", padding: "10px" }}
              >
                <Placeholder
                  as="div"
                  animation="glow"
                  style={{
                    height: "100%",
                    borderRadius: "5%",
                    overflow: "hidden",
                  }}
                >
                  <Placeholder xs={12} style={{ height: "100%" }} />
                </Placeholder>
              </div>

              <div
                className="w-100 d-block d-sm-none rounded-2" // 100% width
                style={{
                  height: "250px", // Fixed height
                }}
              >
                <Placeholder
                  as="div"
                  animation="glow"
                  style={{
                    height: "100%",
                    borderRadius: "5%",
                    overflow: "hidden",
                  }}
                >
                  <Placeholder xs={12} style={{ height: "100%" }} />
                </Placeholder>
              </div>
              <div className="flex-grow-1" style={{ padding: "10px" }}>
                <Row
                  className="align-items-center mb-2"
                  style={{ height: "40px" }}
                >
                  <Col xs={8}>
                    <Placeholder xs={6} /> {/* Title placeholder */}
                  </Col>
                  <Col xs={4} className="text-end d-flex justify-content-end">
                    <Placeholder.Button
                      variant="primary"
                      xs={4}
                      className="ms-2 d-none d-md-block w-100"
                      style={{
                        padding: "0.5rem 1rem",
                      }}
                    />{" "}
                    {/* Bigger Button */}
                  </Col>
                </Row>

                {[
                  { label: "Shares", value: "" },
                  { label: "Rental Income", value: "" },
                  { label: "Vacation Income", value: "" },
                  { label: "Other Income", value: "" }, // Added additional row
                ].map((row, index) => (
                  <Row
                    key={index}
                    className="align-items-center mb-2"
                    style={{ height: "40px" }}
                  >
                    <Col xs={6}>
                      <p className="fs-md fw-semibold">{row.label}</p>
                      {/* Row label placeholder */}
                    </Col>
                    <Col xs={6} className="text-end">
                      <Placeholder
                        as={Card.Title}
                        animation="glow"
                        className="gap-2 d-flex justify-content-end"
                      >
                        <Placeholder xs={getRandomValueInRange(7, 12)} />
                      </Placeholder>
                    </Col>
                  </Row>
                ))}

                <Row className="mt-auto">
                  <Col className="text-end">
                    <Placeholder.Button
                      variant="primary"
                      xs={8}
                      className="d-md-none w-100"
                      style={{
                        height: "40px",
                        fontSize: "1.25rem",
                        padding: "0.5rem 1rem",
                      }}
                    />{" "}
                    {/* Bigger Button for mobile */}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }

  if (data?.length === undefined || data?.length === 0) {
    return (
      <>
        {" "}
        <Container fluid>
          <Row className="my-2 shadow-sm rounded">
            <Col xs={12} md={12}>
              <p className="text-carre-blue h5 fw-bold text-center">
                You currently do not own any asset. Please go to the{" "}
                <Link to={`/${roleUrl}/marketplace`}>marketplace</Link> to
                review properties and buy tokens
              </p>
            </Col>
          </Row>
        </Container>
      </>
    );
  } else {
    return (
      <div className="container">
        <div className="d-flex flex-wrap ">
          {cards.map((card, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-6 pb-2 pt-2">
              <Card
                className={`shadow ${
                  card?.bg === "bg-carre-blue" ? "bg-carre-blue text-white" : ""
                }  h-100 ${card?.class} mr-`}
              >
                <Card.Body className="d-flex flex-column">
                  <Card.Title
                    className={`${card?.style} d-flex justify-content-between fs-lg fw-medium`}
                  >
                    <span>{card?.title}</span>
                    <span>{card?.cardTitleValue}</span> {/* New value */}
                  </Card.Title>
                  <div className="mt-auto my-2">
                    {card?.bigValue ? (
                      <div className="d-flex justify-content-top h2">
                        <span>{card?.bigValue}</span>
                      </div>
                    ) : (
                      card?.values?.map((value, idx) => (
                        <div
                          key={idx}
                          className={`d-flex justify-content-between fs-md  ${
                            card?.bg === "bg-carre-blue"
                              ? " text-white"
                              : "text-black"
                          }`}
                        >
                          <span className="text-truncate fw-normal">
                            {card?.text[idx] !== "undefined" ? (
                              card?.text[idx]
                            ) : (
                              <span className="text-white">.</span>
                            )}
                          </span>
                          <span className="text-carre-blue fw-normal">
                            {value}
                          </span>
                        </div>
                      ))
                    )}
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>

        {data?.map((p, index) => (
          <div key={index} className="custom-hover">
            <AssetsPropertyCard
              horizontal
              price={
                p?.market_stage_name === "Upcoming"
                  ? p?.allowed_token_to_reserve - p?.reserved_token
                  : p?.available_token?.toLocaleString("en-US")
              }
              className="mx-auto shadow my-2 w-100"
              data={p}
            />
          </div>
        ))}
        {reservedData?.map((p, index) => (
          <div key={index} className="custom-hover">
            <AssetsPropertyCard
              reserved={true}
              horizontal
              price={
                p?.market_stage_name === "Upcoming"
                  ? p?.allowed_token_to_reserve - p?.reserved_token
                  : p?.available_token?.toLocaleString("en-US")
              }
              className="mx-auto shadow my-2 w-100 "
              data={p}
            />
          </div>
        ))}
        <Footer />
      </div>
    );
  }
};

export default Assets;
