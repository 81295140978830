import React, { createContext, useContext, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { QueryCache } from "react-query";
import { toast } from "react-toastify";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const queryCache = new QueryCache();
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookie] = useCookies();
  const [err, setErr] = useState();
  const [role, setRole] = useState(localStorage.getItem("role") || "");
  const auth_api = axios.create({
    baseURL: process.env.REACT_APP_API_KEY || "http://localhost:3000",
  });

  const login = async ({ user, password }) => {
    const res = await auth_api
      .post("/user/login", {
        email: user,
        password: password,
      })
      .then(setErr(undefined))

      .catch(function (error) {
        if (error.response) {
          setErr(error);
          toast.error(error.response.data.error);
        }
      });
    setCookies("token", res.data.access_token, {
      expires: new Date(Date.now() + 6000000),
    }); // your token
    setCookies("name", res.data.user.first_name, {
      expires: new Date(Date.now() + 6000000),
    });
    setCookies("last_name", res.data.user.last_name, {
      expires: new Date(Date.now() + 6000000),
    }); // optional data
    setCookies("email", res.data.user.email, {
      expires: new Date(Date.now() + 6000000),
    });
    setCookies("american_citizen", res.data.user.american_citizen_int, {
      expires: new Date(Date.now() + 6000000),
    });

    setCookies("guide_step", res.data.user.guide_step, {
      expires: new Date(Date.now() + 6000000),
    });
    setCookies("verified", res.data.user.verified, {
      expires: new Date(Date.now() + 6000000),
    });
    setRole(res?.data?.user?.role);
    localStorage.setItem("role", res?.data?.user?.role);
    const lowercaseRole = res?.data?.user?.role?.toLowerCase();
    // if (res.data.user.american_citizen_int === null) {
    //   navigate("/american-citizen");
    // } else
    if (lowercaseRole === "admin") {
      navigate("/admin");
    } else if (lowercaseRole === "manager") {
      navigate("/manager");
    } else if (lowercaseRole === "superadmin") {
      navigate("/superadmin");
    } else if (lowercaseRole === "user") {
      navigate("/user/marketplace");
    } else {
      navigate("/user");
    }

    setTimeout(() => {
      ["token", "name", "email", "verified"].forEach((obj) =>
        removeCookie(obj)
      );
    }, 6000000);
  };

  const logout = () => {
    queryCache.clear();
    removeCookie("token");
    removeCookie("name");
    removeCookie("email");
    removeCookie("verified");
    removeCookie("american_citizen");
    removeCookie("guide_step");

    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie =
      "verified=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // ["token", "name", "email", "verified"].forEach((obj) => removeCookie(obj)); // remove data save in cookies
    setRole(null);
    navigate("/");
  };

  const value = useMemo(
    () => ({
      cookies,
      login,
      logout,
      setRole,
      role,
      err,
    }),
    [cookies, err]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useAuth = () => {
  return useContext(UserContext);
};
