import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/TokenCreation";

const FacebookAuth = () => {
  const [setCookies] = useCookies();
  const { setRole } = useAuth();

  const nav = useNavigate();
  useEffect(() => {
    const currentUrl = window.location.href;

    const queryParams = currentUrl.split("?")[1];

    if (queryParams) {
      const getRequestUrl =
        process.env.REACT_APP_API_KEY +
        `/facebook-auth/callback?${queryParams}`;

      // Perform your logic with the GET request data as needed
      fetch(getRequestUrl)
        .then((response) => response.json())

        .then((data) => {
          if (data.access_token !== undefined) {
            setCookies("token", data.access_token, {
              expires: new Date(Date.now() + 6000000),
            });
          }
          setCookies("name", data.user.first_name, {
            expires: new Date(Date.now() + 6000000),
          });
          setCookies("last_name", data.user.last_name, {
            expires: new Date(Date.now() + 6000000),
          }); // optional data
          setCookies("email", data.user.email, {
            expires: new Date(Date.now() + 6000000),
          });
          setCookies("verified", data.user.verified, {
            expires: new Date(Date.now() + 6000000),
          });
          setRole(data?.user?.role);
          localStorage.setItem("role", data?.user?.role);

          // Handle the response data as needed
          nav("/user");
        })

        .catch((error) => {
          // console.error("Error making GET request:", error);
        });
    }
  }, []); // Run the effect only once when the component mounts

  return <div></div>;
};
export default FacebookAuth;
