import { useQuery } from "react-query";
import api from "../api";

export const useGetSellRequestForUser = (propertyId, status) => {
  return useQuery(["sell-request-for-user"], () => {
    const queryParams = { property_id: propertyId };
    
    if (status !== "ALL") {
      queryParams.status = status;
    }

    return api.get("user/sell-request", { params: queryParams }).then((res) => res.data);

  });
};
