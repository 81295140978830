import React from 'react';

const IAssets = ({props, color = "#A0A0AA"}) => (
    <svg
        width={24}
        height={24}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g filter="url(#a)">
            <path
                d="M10 21a7 7 0 0 0 7-7h-7V7a7 7 0 1 0 0 14Z"
                stroke={color}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <g filter="url(#b)">
            <path
                d="M14 10V3c5.133.933 6.806 5.056 7 7h-7Z"
                stroke={color}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <filter
                id="a"
                x={-8}
                y={-4}
                width={36}
                height={36}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation={5}/>
                <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_3_32272"
                />
                <feBlend
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_3_32272"
                    result="shape"
                />
            </filter>
            <filter
                id="b"
                x={3}
                y={-8}
                width={29}
                height={29}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation={5}/>
                <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_3_32272"
                />
                <feBlend
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_3_32272"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
)

export default IAssets
