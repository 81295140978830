import React, { useEffect, useState } from "react";
import { useGetProperty } from "../../hooks/useGetProperty";
import { Link, useNavigate } from "react-router-dom";
import { BiBath, BiBed } from "react-icons/bi";
import { FaCarSide } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import { MdComment, MdDelete, MdEdit, MdHouse } from "react-icons/md";
import { IoMdDocument } from "react-icons/io";
import OccupancyStatusModal from "../../components/OccupancyStatusModal";
import DeletePropertyConfirmationModal from "../../components/DeletePropertyConfirmationModal";
import { GetRoleNavigationPath } from "../../routeUtils";
import { IoCalendarOutline } from "react-icons/io5";
import GoBackButton from "../../components/GoBackButton";

function ListProperty() {
  const navigation = useNavigate();

  const { data, isLoading, refetch } = useGetProperty({
    isAdmin: true,
  });

  const [modalShow, setModalShow] = useState(false);
  const [propertyId, setPropertyId] = useState();
  const [property, setProperty] = useState();
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const roleUrl = GetRoleNavigationPath();

  function handleOccupancyModal(data) {
    setModalShow(true);
    setPropertyId(data.id);
    setProperty(data);
  }

  function handleDeleteModal(data) {
    setDeleteModalShow(true);
    setPropertyId(data.id);
    setProperty(data);
  }

  useEffect(() => {
    refetch().then();
  }, [data]);
  return (
    <>
      <div>
        <div className="d-flex justify-content-end align-items-center align-content-center m-4">
          <Link
            to={`/${roleUrl}/addproperty`}
            replace={true}
            className={
              "bg-carre-blue text-white text-decoration-none d-flex align-items-center justify-content-center border-0 rounded-3 w-25"
            }
            style={{ height: "50px" }}
          >
            Add Property
          </Link>
        </div>
        {isLoading ? (
          <h2>Loading</h2>
        ) : (
          <div>
            <div className="m-4">
              <div>
                <div>
                  <div className="">
                    <table className="table table-bordered table-sm table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Property Img</th>
                          <th scope="col">Property Details</th>
                          <th scope="col">Address</th>
                          <th scope="col">Market</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.map &&
                          data.map((property) => (
                            <tr key={property?.id}>
                              <td width="240px">
                                <div className="w-100 h-100">
                                  <img
                                    alt="property"
                                    src={
                                      property.image != null
                                        ? property.image[0].image_url
                                        : ""
                                    }
                                    className="img-fluid  "
                                    style={{
                                      objectFit: "cover",
                                      maxHeight: "200px",
                                      maxWidth: "240px",
                                      width: "240px",
                                      height: "100px",
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className="">
                                  <div>
                                    <p className="fw-bold mb-1">
                                      {property?.property_name}
                                    </p>
                                    <p className="fw-bold mb-1">
                                      {property?.property_area} sq
                                    </p>
                                    <div className="d-flex gap-2">
                                      <span className="fw-bold mb-1">
                                        <BiBath /> {property?.bathrooms}
                                      </span>
                                      <span className="fw-bold mb-1">
                                        <BiBed /> {property?.bedroom_count}
                                      </span>
                                      <span className="fw-bold mb-1">
                                        <FaCarSide /> {property?.parking}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                                {property?.address?.country_name}
                              </td>
                              <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                                <div>
                                  <span>{property?.market_stage_name}</span>
                                </div>
                              </td>
                              <td width="100">
                                <Dropdown className="mx-4 mt-4">
                                  <Dropdown.Toggle
                                    variant="carre-blue text-white"
                                    id="dropdown-basic"
                                  >
                                    Actions
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() =>
                                        navigation(
                                          `/${roleUrl}/property/${property.id}/edit`
                                        )
                                      }
                                    >
                                      <MdEdit className="mb-1" /> Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        navigation(
                                          `/${roleUrl}/property/${property.id}/rc`
                                        )
                                      }
                                    >
                                      <IoCalendarOutline className="mb-1" />{" "}
                                      Rate Calendar
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        navigation(
                                          `/${roleUrl}/property/${property.id}/comment`
                                        )
                                      }
                                    >
                                      <MdComment className="mb-1" /> Comment
                                      List
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        navigation(
                                          `/${roleUrl}/property/${property.id}/document`
                                        )
                                      }
                                    >
                                      <IoMdDocument className="mb-1" /> Document
                                      List
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      onClick={() =>
                                        navigation(
                                          `/${roleUrl}/property/${property.id}/historical`
                                        )
                                      }
                                    >
                                      <IoMdDocument className="mb-1" /> Property
                                      Historical
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      onClick={() =>
                                        handleDeleteModal(property)
                                      }
                                      href="#/action-2"
                                    >
                                      {" "}
                                      <MdDelete className="mb-1" /> Delete
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleOccupancyModal(property)
                                      }
                                    >
                                      <MdHouse className="mb-1" /> Change
                                      Occupancy Status
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <DeletePropertyConfirmationModal
        show={deleteModalShow}
        id={propertyId}
        refetch={refetch}
        data={property}
        onHide={() => setDeleteModalShow(false)}
      />
      <OccupancyStatusModal
        refetch={refetch}
        show={modalShow}
        id={propertyId}
        data={property}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default ListProperty;
