import React, { useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import UserOrderRequest from "../UserOrderRequest";
import UserReserveToken from "./UserReserveToken";
import UserWithdrawRequest from "./UserWithdrawRequest";
import Footer from "../../components/Footer";

const Request = () => {
  const [activeTab, setActiveTab] = useState("token");

  const handleNavClick = (tab) => {
    setActiveTab(tab);
  };

  let pageContent;
  switch (activeTab) {
    case "token":
      pageContent = <UserOrderRequest />;
      break;
    case "withdraw":
      pageContent = <UserWithdrawRequest />;
      break;
    case "reservation":
      pageContent = <UserReserveToken />;
      break;
    default:
      pageContent = <UserOrderRequest />;
      break;
  }

  return (
    <>
      <div className="container mt-5 mt-md-0">
        <div className="mt-4">
          <UserWithdrawRequest />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Request;
