import React, { useState, useEffect } from "react";
import { useGetUserSellRequest } from "../../hooks/useGetUserSellRequest";
import { Card, Alert, Table, Row, Col } from "react-bootstrap";
import { FaCalendar, FaCoins, FaHashtag } from "react-icons/fa";
import CustomCard from "./CostumeCard";

function OrderPage({ id, viewMode }) {
  const { data, refetch, error } = useGetUserSellRequest(id);
  useEffect(() => {
    refetch().then();
  }, [id]);

  const renderOrders = () => {
    if (data?.length === 0) {
      return (
        <Alert className="text-center fs-sm p-2 fw-semibold">
          No orders found for this property.
        </Alert>
      );
    }

    if (viewMode !== "table") {
      return renderOrdersAsCards();
    } else {
      return renderOrdersAsTable();
    }
  };
  const renderOrdersAsCards = () => {
    return data?.map((transfer) => (
      <Row>
        {data?.map((order) => (
          <Col key={order.id} xs={12} sm={6} md={4} lg={4}>
            <CustomCard
              data={[
                {
                  icon: <FaCalendar className="icon" />,
                  text: "Created At",
                  value: new Date(order.created_at).toLocaleDateString("en-US"),
                },
                {
                  icon: <FaCoins className="icon" />,
                  text: "Number of Share",
                  value: order.token_qty,
                },
                {
                  icon: <FaHashtag className="icon" />,
                  text: "Status",
                  value: order.status,
                },
                {
                  icon: <FaCoins className="icon" />,
                  text: "Share",
                  value: order.token_price,
                },
                {
                  icon: <FaCoins className="icon" />,
                  text: "Amount",
                  value: order.total_price,
                },
              ]}
            />
          </Col>
        ))}
      </Row>
    ));
  };

  const renderOrdersAsTable = () => {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Status</th>
            <th>Share Quantity</th>
            <th>Share Price</th>
            <th>Amount</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((transfer) => (
            <tr key={transfer.id}>
              <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                {transfer.status}
              </td>
              <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                {transfer.token_qty}
              </td>

              <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                {transfer.token_price}
              </td>
              <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                {transfer.total_price}
              </td>
              <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                {new Date(transfer.created_at).toLocaleDateString("en-US")}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <div style={{ minHeight: "400px" }}>
      {error?.response?.status === 401 && (
        <Alert className="text-center fs-sm p-2 fw-semibold">
          Log in to see more data
        </Alert>
      )}
      {renderOrders()}
    </div>
  );
}

export default OrderPage;
