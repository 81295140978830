import { useEffect } from "react";
import {
  PayPalButtons,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import api from "../../api";
import { useQueryClient } from "react-query";

const currency = "USD";
const style = { layout: "horizontal" };

const ButtonWrapper = ({
  currency,
  showSpinner,
  amount,
  accountId,
  refetch,
  onHide,
  hideButton,
}) => {
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: currency,
      },
    });
  }, [amount, currency, showSpinner]);
  const queryClient = useQueryClient();

  return (
    <>
      <div className="spinner" />
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[amount, currency, style]}
        fundingSource={undefined}
        createOrder={(data, actions) => {
          return api
            .post("/paypal-charge", { amount: amount, account_id: accountId })
            .then((orderId) => {
              queryClient.invalidateQueries({ queryKey: ["user_data"] });

              return orderId?.data;
            });
        }}
        onApprove={function (data, actions) {
          return api.post(`/paypal-charge/${data?.orderID}`).then((orderId) => {
            onHide();
            hideButton();
            refetch();
            queryClient.invalidateQueries({ queryKey: ["user_data"] });
            return orderId?.data;
          });
        }}
      />
    </>
  );
};

export default function PaypalCheckoutForm({
  amount,
  showButton,
  accountId,
  refetch,
  onHide,
  hideButton,
}) {
  return (
    <div>
      <PayPalScriptProvider
        options={{
          "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
          components: "buttons",
          currency: "USD",
        }}
      >
        {/* {showButton && ( */}
        <ButtonWrapper
          currency={currency}
          amount={amount}
          showSpinner={false}
          accountId={accountId}
          onHide={onHide}
          refetch={refetch}
          hideButton={hideButton}
        />
        {/* )} */}
      </PayPalScriptProvider>
    </div>
  );
}
