// GoBackButton.js
import React from "react";
import { Button } from "react-bootstrap";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

const GoBackButton = ({ className }) => {
  const handleButtonClick = () => {
    window.history.back();
  };

  return (
    <Button
      size="sm"
      variant="carre-blue"
      className={`mb-3 mt-4 ${className} text-white fw-semibold`}
      onClick={handleButtonClick}
    >
      <MdOutlineKeyboardBackspace className="mx-1" />
      Go back
    </Button>
  );
};

export default GoBackButton;
