import React from "react";

import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./TokenCreation";

export const ProtectRoutes = () => {
  let { cookies } = useAuth();
  if (cookies.token) {
    if (cookies?.guide_step === "") {
      return <Navigate to="/welcome" />;
    } else {
      return <Outlet />;
    }
  } else {
    return <Navigate to="/" exact />;
  }
};
export const AdminRoutes = () => {
  let { cookies } = useAuth();
  const { role } = useAuth();

  if (role === "ADMIN" && cookies.token) {
    if (cookies?.guide_step === "") {
      return <Navigate to="/welcome" />;
    } else {
      return <Outlet />;
    }
  } else {
    return <Navigate to="/" exact />;
  }
};

export const SuperAdminRoutes = () => {
  const { role, cookies } = useAuth();

  if (role === "SUPERADMIN" && cookies.token) {
    if (cookies?.guide_step === "") {
      return <Navigate to="/welcome" />;
    } else {
      return <Outlet />;
    }
  } else {
    return <Navigate to="/" exact />;
  }
};

export const ManagerRoutes = () => {
  const { role } = useAuth();
  let { cookies } = useAuth();

  if (role === "MANAGER" && cookies.token) {
    if (cookies?.guide_step === "") {
      return <Navigate to="/welcome" />;
    } else {
      return <Outlet />;
    }
  } else {
    return <Navigate to="/" exact />;
  }
};

export const AmericanCitizenRoute = () => {
  let { cookies } = useAuth();
  if (cookies?.guide_step === "") {
    return <Outlet />;
  } else if (cookies.token) {
    return <Navigate to="/user" />;
  } else {
    return <Navigate to="/" exact />;
  }
};
