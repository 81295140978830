import React from 'react';
import { Card } from 'react-bootstrap';

const CustomCard = ({ data, button }) => {
  return (
    <Card className="mb-3" style={{ marginBottom: '15px' }}>
      <Card.Body>
        {data.map((item, index) => (
          <div key={index} className="d-flex justify-content-between align-items-center mb-2">
            <div className="striped-text">
              {item.icon}{" "}
              <span className="text fs-sm">{item.text}</span>
            </div>
            <span className='fs-sm text-carre-blue'>{item.value}</span>
          </div>
        ))}
        {button && (
          <div className="d-flex justify-content-end mt-3 fs-sm">
            {button}
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default CustomCard;
