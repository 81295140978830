import React, { useState } from "react";
import { Button, Dropdown, Form, Table } from "react-bootstrap";

import { useGetWithdrawRequestForAdmin } from "../../hooks/useGetWithdrawReuqestForAdmin";
import AdminUpdateWithdrawRequest from "./AdminUpdateWithdrawRequest";
import Modal from "react-bootstrap/Modal";

function formatCreatedAt(createdAt) {
  const date = new Date(createdAt);
  const formattedDate = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  return formattedDate;
}

function AdminWithdrawRequestOfUser() {
  const [showModal, setShowModal] = useState(false);
  const [withdrawalToCancel, setWithdrawalToCancel] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const { data, refetch } = useGetWithdrawRequestForAdmin(selectedStatus);
  const [wireData, setWireData] = useState();
  const [showWireDataModal, setShowWireDataModal] = useState(false);

  const handleShowModal = () => {
    setShowWireDataModal(true);
  };

  const handleCloseModal = () => {
    setShowWireDataModal(false);
  };
  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };

  const handleCancelRequest = (withdrawalId) => {
    // Implement the cancellation logic here.
    // You can call an API or update the status locally as needed.
    // After the cancellation, close the modal.
    setShowModal(false);
  };

  return (
    <>
      <div className="container mt-4">
        <div>
          <Form.Label>Status</Form.Label>
          <Form.Select
            className="mb-4"
            as="select"
            value={selectedStatus}
            onChange={(e) => handleStatusChange(e.target.value)}
          >
            <option value="ALL">All</option>
            <option value="COMPLETED">Completed</option>
            <option value="REJECTED">Rejected</option>
            <option value="REQUEST">Request</option>
            <option value="IN_PROGRESS">In Progress</option>
            <option value="CANCELED">Canceled</option>
          </Form.Select>
        </div>
        <Table bordered hover responsive>
          <thead>
            <tr>
              <th>Created At</th>
              <th>Amount</th>
              <th>Email</th>
              <th>Type</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((withdrawal, index) => (
              <tr key={index}>
                <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                  {formatCreatedAt(withdrawal?.created_at)}
                </td>
                <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                  {withdrawal?.amount}
                </td>
                <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                  {withdrawal?.email}
                </td>
                <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                  {withdrawal?.type}
                  {withdrawal?.type === "WIRE" && (
                    <>
                      <Button
                        onClick={() => {
                          setShowWireDataModal(true);
                          setWireData(withdrawal);
                        }}
                      >
                        Show More Data
                      </Button>
                    </>
                  )}
                </td>
                <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                  {withdrawal?.status}
                </td>
                {/* <td className="text-center">
                  {(withdrawal?.status !== "CANCELED" && withdrawal?.status !== "REJECTED" ) && (
                    <Form.Select
                      className="mb-3"
                      as="select"
                      onChange={(e) => {
                        setShowModal(true);
                        setWithdrawalToCancel({
                          ...withdrawal,
                          status: e.target.value,
                        });
                      }}

                    >
                      <option value="COMPLETED">Completed</option>
                      <option value="REJECTED">Rejected</option>
                      <option value="IN_PROGRESS">In Progress</option>
                      <option value="CANCELED">Cancel</option>
                    </Form.Select>
                  )}
                </td> */}
                <td width="100">
                  {withdrawal?.status !== "CANCELED" &&
                    withdrawal?.status !== "COMPLETED" &&
                    withdrawal?.status !== "REJECTED" && (
                      <Dropdown className="mx-4 my-2">
                        <Dropdown.Toggle
                          variant="carre-blue text-white"
                          id="dropdown-basic"
                        >
                          ACTION
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            disabled={withdrawal?.status === "COMPLETED"}
                            onClick={() => {
                              setShowModal(true);
                              setWithdrawalToCancel({
                                ...withdrawal,
                                status: "COMPLETED",
                              });
                            }}
                          >
                            Complete Request
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={withdrawal?.status === "REJECTED"}
                            onClick={() => {
                              setShowModal(true);
                              setWithdrawalToCancel({
                                ...withdrawal,
                                status: "REJECTED",
                              });
                            }}
                          >
                            Rejected Request
                          </Dropdown.Item>

                          <Dropdown.Item
                            disabled={withdrawal?.status === "IN_PROGRESS"}
                            onClick={() => {
                              setShowModal(true);
                              setWithdrawalToCancel({
                                ...withdrawal,
                                status: "IN_PROGRESS",
                              });
                            }}
                          >
                            In progress Request
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal show={showWireDataModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Withdrawal Details</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>

        <p>Amount: {wireData?.amount}</p>
        <p>Type: {wireData?.type}</p>
        <p>Status: {wireData?.status}</p>
        <p>Recipient Name: {wireData?.recipient_name}</p>
        <p>Recipient Account Number: {wireData?.recipient_account_number}</p>
        <p>Recipient Bank Swift: {wireData?.recipient_bank_swift}</p>
        <p>Recipient Mailing Address: {wireData?.recipient_mailing_address}</p>
        <p>Note: {wireData?.note}</p>
        <p>Bank Name: {wireData?.bank_name}</p>
        <p>Currency: {wireData?.currency}</p>

        <p>IBAN: {wireData?.iban}</p>
        <p>Bank Address: {wireData?.bank_address}</p>
        <p>City: {wireData?.city}</p>
        <p>Country: {wireData?.country}</p>
      </Modal>
      <AdminUpdateWithdrawRequest
        refetch={refetch}
        status={withdrawalToCancel?.status}
        show={showModal}
        data={withdrawalToCancel}
        onHide={() => setShowModal(false)}
        onCancelRequest={() => handleCancelRequest(withdrawalToCancel?.id)}
      />
    </>
  );
}

export default AdminWithdrawRequestOfUser;
