import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import React  from 'react';
const NoPageFound = () => {
  return (
    <>
      {/* Page content */}
      <main className="page-wrapper">
        <section className="d-flex align-items-center min-vh-100  bg-success">
          <Container className="d-flex justify-content-center text-center">
            <Col xs={12} md={10} lg={8} className="px-0">
              <div className="ratio ratio-16x9 mb-lg-5 mb-4"></div>
              <h1 className="h3 pt-lg-4">This page dose not exist </h1>
              <p className="lead mb-5 pb-lg-2">
                Either it was removed, or you mistyped the link.
              </p>
              <Button
                href="/"
                size="lg"
                variant="primary rounded-pill w-sm-auto w-100 mb-3"
              >
                Go to homepage
              </Button>
            </Col>
          </Container>
        </section>
      </main>
    </>
  );
};

export default NoPageFound;
