import { useQuery } from "react-query";
import api from "../api";

export const useGetPropertyForBuySellRequest = (id, orderType) => {
  return useQuery(["property_data"], () => {
    return api
      .get(`/property/${id}/buy`, { params: { order_type: orderType } })
      .then((res) => res.data);
  });
};
