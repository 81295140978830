import api from "../api";
import { useQuery } from "react-query";

export const createUser = (newUser) =>
  api.post("/user", newUser).then((res) => res.data);
export const AmericanCitizen = (data) =>
  api.post("/user/american-citizen", data).then((res) => res.data);

// export const verifyUser = (email, code) =>
//     api.get(`/verify/${email}/${code}`).then((res) => res.data).catch((error) => error.data);
