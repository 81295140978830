import * as React from "react"

const YesIcon = (props) => (
    <svg
        width="1.3em"
        height="1.3em"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 3.334a6.667 6.667 0 1 0 0 13.333 6.667 6.667 0 0 0 0-13.333ZM1.667 10a8.333 8.333 0 1 1 16.667 0 8.333 8.333 0 0 1-16.667 0ZM13.09 7.744a.833.833 0 0 1 0 1.179l-3.334 3.333a.833.833 0 0 1-1.178 0L6.91 10.59A.833.833 0 0 1 8.09 9.41l1.077 1.078 2.744-2.745a.833.833 0 0 1 1.179 0Z"
            fill="#FD5631"
        />
    </svg>
)

export default YesIcon
