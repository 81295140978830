import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useMutation } from "react-query";

import { toast } from "react-toastify";
import { Modal, Button, Form } from "react-bootstrap";
import {
  useCreateRateCalendar,
  useGetRateCalendar,
} from "../../../hooks/useRateCalendar";

const localizer = momentLocalizer(moment);

const MyCalendar = () => {
  const [selectedRange, setSelectedRange] = useState(null);
  const [propertyId, setPropertyId] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [price, setPrice] = useState(0);

  const {
    refetch,
    data: rateCalendar,
    isLoading,
    isError,
  } = useGetRateCalendar(propertyId);
  const handleSelectSlot = (slotInfo) => {
    const start = moment(slotInfo.start);
    const end = moment(slotInfo.end).subtract(2, "day");

    // If start and end are the same day, set end to start
    const adjustedEnd = start.isSame(end, "day") ? start : end;

    setSelectedRange({
      start: start.toDate(),
      end: adjustedEnd.toDate(),
    });
    setShowModal(true);
  };

  const createMutation = useMutation(useCreateRateCalendar, {
    onSuccess: (data) => {
      toast.success(data.message);
      refetch();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.error);
    },
  });

  const handleSubmit = () => {
    const entries = [];
    let currentDate = moment(selectedRange.start);
    while (currentDate <= moment(selectedRange.end)) {
      entries.push({
        date: currentDate.toISOString(),
        price: price,
      });
      currentDate = currentDate.add(1, "day");
    }

    const data = { id: propertyId, entries };
    createMutation.mutate(data);
    setShowModal(false);
  };

  const events = rateCalendar
    ? rateCalendar.map((entry) => ({
        title: `Price: $${entry.price}`,
        start: new Date(entry.date),
        end: new Date(entry.date),
        allDay: true,
      }))
    : [];

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        selectable
        onSelectSlot={handleSelectSlot}
        style={{ height: 600 }}
      />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Set Price Per Day</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Selected Range:</p>
          <p>Start: {selectedRange?.start.toString()}</p>
          <p>End: {selectedRange?.end.toString()}</p>
          <Form>
            <Form.Group>
              <Form.Label>Price per day:</Form.Label>
              <Form.Control
                type="number"
                value={price}
                onChange={(e) => setPrice(parseFloat(e.target.value))}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MyCalendar;
