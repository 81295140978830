// src/components/SEO.js
import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title, description, keywords, url }) => {
  return (
    <Helmet>
      <title>{title || "Invest in Real Estate Easily | Carré"}</title>
      <meta
        name="description"
        content={
          description ||
          "Join Carré and invest in premium fractional real estate for as little as $100. Earn passive income and enjoy hassle-free property ownership."
        }
      />
      <meta
        name="keywords"
        content={
          keywords ||
          "real estate investment, fractional ownership, passive income, property investment, Carré, affordable real estate, rental properties, real estate crowdfunding, diversified investments, low-cost investment"
        }
      />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={url || "https://carre.ai/"} />
    </Helmet>
  );
};

export default SEO;
