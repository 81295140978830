import api from "../api";

export const useAcceptUserSellRequest = (data) => {
  const newData = {
    status: "ACCEPT",
    email: data.email,
  };
  return api
    .post(`/request/process-request/${data.id}`, newData)
    .then((res) => res.data);
};
export const useRejectUserSellRequest = (data) => {
  const newData = {
    status: "REJECTED",
    email: data.email,
  };
  return api
    .post(`/request/process-request/${data.id}`, newData)
    .then((res) => res.data);
};
