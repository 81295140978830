import React, { useEffect, useState } from "react";
import { useTabContext } from "./TabContext";
import { pdfjs } from "react-pdf";
import { Viewer, Worker } from "@react-pdf-viewer/core";
const CustomWatermarkLayer = () => {
  // Customize your watermark text and styles here
  const watermarkText = "Carré Carré Carré Carré";
  const watermarkStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000, // Make sure it's above the PDF content
    fontSize: "124px",
    color: "rgba(0, 0, 0, 0.3)", // Adjust the color and opacity
    opacity: "10%",
    pointerEvents: "none", // Make sure the watermark doesn't interfere with user interactions
    transform: "rotate(25deg)", // Rotate the watermark diagonally
  };

  return <div style={watermarkStyle}>{watermarkText}</div>;
};
const PdfViewer = () => {
  const { selectedPdf } = useTabContext();

  const pdfLink = selectedPdf;

  const [pdfContainerHeight, setPdfContainerHeight] = useState(1500);

  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      clearTimeout(timeoutId);

      // Add space as the user scrolls
      const additionalSpace = window.scrollY * 2; // You can adjust the multiplier as needed

      // Debounce the setPdfContainerHeight call
      timeoutId = setTimeout(() => {
        setPdfContainerHeight(1500 + additionalSpace);
      }, 200); // Adjust the debounce delay as needed (in milliseconds)
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
    return () => {
      // Cleanup event listener on component unmount
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      // Prevent Ctrl + P
      if (e.ctrlKey && e.key === "p") {
        e.preventDefault();
      }
    };

    // Add event listener when component mounts
    document.addEventListener("keydown", handleKeyDown);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="mx-2">
      <Worker
        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
      >
        <Viewer renderProtectedView fileUrl={pdfLink} />{" "}
        <CustomWatermarkLayer />
      </Worker>
    </div>

    // <div>
    //   {/* Overlay */}
    //   <div
    //     id="overlay"
    //     style={{
    //       position: "fixed",
    //       top: 0,
    //       left: 0,
    //       width: "100%",
    //       height: "100vh",
    //       // overflowY: "auto",
    //       backgroundColor: "rgba(255, 255, 255, 0.5)",
    //       zIndex: 1,
    //     }}
    //   >
    //     {/* Overlay content goes here */}
    //     <p className="text-carre-bg fs-5 opacity-25">
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré Carré
    //       Carré Carré Carré Carré Carré Carré
    //     </p>
    //   </div>
    //
    //   <div
    //     style={{
    //       minHeight: "100vh",
    //       height: `${pdfContainerHeight}px`, // Dynamically set height
    //       // overflowY: "auto",
    //       position: "relative",
    //       marginLeft: "auto",
    //       marginRight: "auto",
    //     }}
    //   >
    //     <object
    //       id="pdfIframe"
    //       title="PDF Viewer"
    //       data={pdfLink}
    //       style={{
    //         width: "100%",
    //         overflow: "auto",
    //         height: "100%",
    //         border: "none",
    //       }}
    //     />
    //   </div>
    // </div>
  );
};

export default PdfViewer;
