import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useUserRemoveSellRequest } from "../hooks/useUserRemoveTokenSellRequest";

function UserRejectSellTokenRequest(props) {
  const {
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {},
  });

  const { mutate, isLoading } = useMutation(useUserRemoveSellRequest, {
    onSuccess: (data) => {
      notifySuccess();
      props.refetch().then(() => {});
      props.onHide();
    },
    onError: (error) => {
      notifyError(error?.response.data.error);
    },
  });
  const notifyError = (error) => toast.error(error);
  const notifySuccess = () => toast.success("Order cancelled successfully");
  const onSubmit = (data) => {
    mutate(props.data);
  };

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter fw-medium">
              Cancel Order Request
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <span>
                Are you sure you want to cancel the share order request from !
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide} size="sm">
              Close
            </Button>
            <Button
              type={"submit"}
              size="sm"
              variant="carre-blue w-50"
              disabled={isLoading}
            >
              {!isLoading ? " Cancel Order Request" : "Please wait"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default UserRejectSellTokenRequest;
