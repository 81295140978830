import { useQuery, useMutation, queryCache, useQueryClient } from "react-query";
import api from "../api";

const getPropertyHistorical = async (propertyId) => {
  const response = await api.get(`/property/${propertyId}/historical`);
  return response.data;
};

export const usePropertyHistorical = (propertyId) => {
  return useQuery(["propertyHistorical", propertyId], () =>
    getPropertyHistorical(propertyId)
  );
};

const createPropertyHistorical = async (data) => {
  const response = await api.post(
    `/property/${data.property_id}/historical`,
    data
  );
  return response.data;
};

export const useCreatePropertyHistorical = () => {
  const queryClient = useQueryClient();

  return useMutation(createPropertyHistorical, {
    onSuccess: () => {
      queryClient.invalidateQueries("propertyHistorical");
    },
  });
};

const editPropertyHistorical = async ({ propertyId, historicalId, data }) => {
  const response = await api.put(
    `/property/${propertyId}/historical/${historicalId}`,
    data
  );
  return response.data;
};

export const useEditPropertyHistorical = () => {
  const queryClient = useQueryClient();

  return useMutation(editPropertyHistorical, {
    onSuccess: () => {
      queryClient.invalidateQueries("propertyHistorical");
    },
  });
};

const deletePropertyHistorical = async ({ propertyId, historicalId }) => {
  const response = await api.delete(
    `/property/${propertyId}/historical/${historicalId}`
  );
  return response.data;
};

export const useDeletePropertyHistorical = () => {
  const queryClient = useQueryClient();

  return useMutation(deletePropertyHistorical, {
    onSuccess: () => {
      queryClient.invalidateQueries("propertyHistorical");
    },
  });
};
