import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdComment, MdDelete, MdEdit } from "react-icons/md";
import { Button, Dropdown, Form, Row } from "react-bootstrap";
import {
  useGetPropertyById,
  useGetPropertyCommentById,
} from "../../hooks/useGetPropertyById";
import { BiBath, BiBed } from "react-icons/bi";
import { FaCarSide } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast, ToastContainer } from "react-toastify";
import {
  createPropertyComment,
  updatePropertyComment,
} from "../../hooks/useCreateProperty";
import api from "../../api";
import { GetRoleNavigationPath } from "../../routeUtils";

function ListComment() {
  const params = useParams();
  let navigate = useNavigate();
  const roleUrl = GetRoleNavigationPath();
  const [editData, setEditData] = useState();

  const {
    data: property,
    isLoading: propertyIsLoading,
    refetch: propertyRefetch,
  } = useGetPropertyById(params.property_id);

  const { data: comment, isLoading: commentIsLoading } =
    useGetPropertyCommentById(params.property_id, params.id);

  const notifySuccess = () => toast.success("Comment Added Successfully");

  const notifySuccessUpdate = () =>
    toast.success("Comment Updated Successfully");

  const notifySuccessOnDelete = () =>
    toast.success("Comment Deleted Successfully");

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm(
    params.id !== undefined && {
      defaultValues: {
        title: comment?.title,
        description: comment?.description,
      },
    }
  );

  const [showInsert, setShowInsert] = useState(
    !commentIsLoading ? true : false
  );

  const { mutate, error, isLoading } = useMutation(createPropertyComment, {
    onSuccess: () => {
      reset();
      setShowInsert(false);
      setTimeout(() => {
        propertyRefetch();
        notifySuccess();
      }, 300);
    },
    onError: (error) => {
      notifyError(error?.response.data.error);
    },
  });
  const notifyError = (error) => toast.error(error);

  const { mutate: update } = useMutation(updatePropertyComment, {
    onSuccess: () => {
      setShowInsert(false);
      setTimeout(() => {
        notifySuccessUpdate();
        navigate(`/${roleUrl}/property/${params.property_id}/comment`);
        setEditData();
        reset({
          title: "",
          description: "",
        });
      }, 200);
    },
    onError: (error) => {
      notifyError(error?.response.data.error);
    },
  });

  const onSubmit = (data) => {
    if (params.id === undefined) {
      mutate(data);
    } else {
      update(data);
    }
  };

  async function componentDidMount(id) {
    // GET request using axios with async/await
    const response = await api
      .get(`property/${params.property_id}/comment/${id}`)
      .then((res) => {
        return res?.data;
      });
    setEditData(response);
  }

  const handleEdit = (id) => {
    navigate(`/${roleUrl}/property/${params.property_id}/comment/${id}`);
    componentDidMount(id);
    setShowInsert(true);
  };

  const deleteComment = (id, commentId) => {
    api
      .delete(`/property/${id}/comment/${commentId}`)
      .then((res) => {
        propertyRefetch().then();
        notifySuccessOnDelete();
      })
      .catch((err) => {
        notifyError();
      });
  };

  useEffect(() => {
    if (params.id !== undefined) {
      reset({
        title: editData?.title,
        description: editData?.description,
      });
    }
    propertyRefetch();
  }, [reset, editData, showInsert]);

  const returnTitle = (item) => {
    return <div dangerouslySetInnerHTML={{ __html: item?.title }} />;
  };

  const returnDescription = (item) => {
    return (
      <p
        className=" collapse show overflow-hidden "
        style={{ wordWrap: "break-word" }}
        id="collapseSummary"
        dangerouslySetInnerHTML={{ __html: item?.description }}
      ></p>
    );
  };

  return (
    <div>
      <div className="">
        <table className="table table-bordered table-sm table-hover">
          <thead>
            <tr>
              <th scope="col">Property Img</th>
              <th scope="col">Property Details</th>
              <th scope="col">Address</th>
              <th scope="col">Market</th>
            </tr>
          </thead>
          <tbody>
            {property && (
              <tr
                key={property?.id}
                onClick={() =>
                  navigate(`/${roleUrl}/marketplace/propdetails/${property.id}`)
                }
              >
                <td width="240px">
                  <div className="w-100 h-100">
                    <img
                      alt="property"
                      src={
                        property?.image != null
                          ? property?.image.find((x) => x.is_default === true)
                              ?.image_url
                          : ""
                      }
                      className="img-fluid  "
                      style={{
                        objectFit: "cover",
                        maxHeight: "200px",
                        maxWidth: "240px",
                        width: "240px",
                        height: "100px",
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div className="">
                    <div>
                      <p className="fw-bold mb-1">Id: {property?.id}</p>
                      <p className="fw-bold mb-1">{property?.property_name}</p>
                      <p className="fw-bold mb-1">
                        {property?.property_area} sq
                      </p>
                      <div className="d-flex gap-2">
                        <span className="fw-bold mb-1">
                          <BiBath /> {property?.bathrooms}
                        </span>
                        <span className="fw-bold mb-1">
                          <BiBed /> {property?.bedroom_count}
                        </span>
                        <span className="fw-bold mb-1">
                          <FaCarSide /> {property?.parking}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="">
                    <div>
                      <span className="fw-bold mb-1">
                        {property?.address?.country_name}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="">
                    <div>
                      <span className="fw-bold mb-0">
                        {property?.market_stage === 1 && "Submitted"}
                        {property?.market_stage === 2 && "Upcomming"}
                        {property?.market_stage === 3 && "Primary"}
                        {property?.market_stage === 4 && "Secondary"}
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between align-items-center align-content-center  mx-5">
        <button
          onClick={() => navigate(`/${roleUrl}/listproperty`)}
          replace={true}
          className={
            "bg-carre-purple text-white text-decoration-none d-flex align-items-center justify-content-center border-0 rounded-3 w-25"
          }
          style={{ height: "50px" }}
        >
          Go Back
        </button>
        <button
          onClick={() => {
            if (showInsert === true) {
              navigate(`/${roleUrl}/property/${params.property_id}/comment`);
              setEditData();
              reset({
                title: "",
                description: "",
              });
            }
            setShowInsert(!showInsert);
          }}
          replace={true}
          className={
            "bg-carre-purple text-white text-decoration-none d-flex align-items-center justify-content-center border-0 rounded-3 w-25"
          }
          style={{ height: "50px" }}
        >
          {showInsert && params.id === undefined ? "Close Add Comment" : ""}
          {showInsert && params.id !== undefined ? "Close Update Comment" : ""}

          {!showInsert ? "Add Comment" : ""}
        </button>
      </div>
      {showInsert && (
        <Form onSubmit={handleSubmit(onSubmit)} id="comment">
          <div className="rounded-2 shadow-lg p-3 mb-5 p-3 my-2 ">
            <MdComment
              style={{ width: "20px", height: "20px" }}
              className="text-carre-purple  d-inline mb-2 mx-1"
            />
            <p className="h4 font-weight-bold d-inline "> Comment </p>
            <Row>
              <Form.Group as={Col}>
                <label>Title</label>
                <input
                  className={
                    errors.title
                      ? "border-carre-red form-control"
                      : " form-control false"
                  }
                  {...register("title", { required: true })}
                />
                {errors.title && (
                  <p className="text-carre-red">This field is required</p>
                )}
                <input
                  hidden={true}
                  value={params.property_id}
                  {...register("property_id", {
                    required: true,
                  })}
                />
                {params.id !== undefined ? (
                  <input
                    hidden={true}
                    value={params.id}
                    {...register("id", {
                      required: true,
                    })}
                  />
                ) : (
                  ""
                )}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col}>
                <label>Description</label>
                <textarea
                  form={"comment"}
                  style={{ height: "300px" }}
                  className={
                    errors.description
                      ? "border-carre-red form-control"
                      : " form-control false"
                  }
                  {...register("description", { required: true })}
                />
                {errors.description && (
                  <p className="text-carre-red">This field is required</p>
                )}
              </Form.Group>
            </Row>
          </div>

          <Button
            type={"submit"}
            size="lg"
            variant="carre-purple w-100"
            disabled={isLoading}
          >
            {params.id === undefined ? "Insert Comment" : "Update Comment"}
            {isLoading ? "Please wait" : ""}
          </Button>
        </Form>
      )}

      {propertyIsLoading ? (
        <h2>Loading</h2>
      ) : (
        <div
          className={"mt-5"}
          style={{
            position: "relative",
            overflow: "auto",
            minHeight: "500px",
            maxHeight: "500px",
          }}
        >
          <div className="m-4">
            <div>
              <div>
                <div className="overflow-hidden pb-5">
                  <table className="table table-bordered table-sm table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Id</th>
                        <th scope="col">Title</th>
                        <th scope="col">Description</th>
                        <th scope="col">Created At</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {property &&
                        property?.property_comment?.map &&
                        property?.property_comment?.map((item) => (
                          <tr key={item?.id}>
                            <td width="240px">{item.id}</td>
                            <td width="240px">{returnTitle(item)}</td>
                            <td
                              style={{ maxWidth: "350px", maxHeight: "220px" }}
                            >
                              {returnDescription(item)}
                            </td>
                            <td width="240px">{item.created_at}</td>

                            <td width="100">
                              <Dropdown className="mx-4 mt-1 ">
                                <Dropdown.Toggle
                                  variant="carre-purple"
                                  id="dropdown-basic"
                                >
                                  Actions
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => handleEdit(item.id)}
                                  >
                                    <MdEdit /> Edit
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    onClick={() =>
                                      deleteComment(property.id, item.id)
                                    }
                                  >
                                    <MdDelete /> Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
}

export default ListComment;
