import React, { useState } from "react";
import { Button, Row, Col, Modal } from "react-bootstrap";
import moment from "moment";
import { BiCross, BiTrash } from "react-icons/bi";
import { useMutation, useQueryClient } from "react-query";
import {
  useGetVacationOrders,
  useUserCancelVacationOrder,
} from "../../../hooks/useGetVacationDetails";
import { toast } from "react-toastify";
import { RxCross1 } from "react-icons/rx";

const UserOrders = () => {
  const { data: buyOrders, refetch: buyRefetch } = useGetVacationOrders(
    "PENDING,IN_PROGRESS",
    "BUY"
  );
  const { data: sellOrders, refetch: sellRefetch } = useGetVacationOrders(
    "PENDING,IN_PROGRESS",
    "SELL"
  );
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(useUserCancelVacationOrder, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("vacation-order-details");
      queryClient.invalidateQueries("vacation-transfer");
      queryClient.invalidateQueries("vacation-orders");
      queryClient.invalidateQueries("vacation");
      queryClient.invalidateQueries("vacation-order-book");

      sellRefetch();
      buyRefetch();
    },
    onError: (error) => {
      toast.error(error?.response.data.error);
    },
  });

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);
  const handleCancelOrder = (order) => {
    setOrderToDelete(order);
    setShowConfirmation(true);
  };
  const confirmDeleteOrder = () => {
    if (orderToDelete) {
      mutate(orderToDelete?.id);
      setShowConfirmation(false);
    }
  };

  return (
    <>
      <Row className="mb-5">
        {buyOrders?.length > 0 && (
          <Col md={6} className="px-4">
            <p className="fw-medium fs-4 my-4">Open Buy Orders</p>

            <Row className="mt-3">
              <Col xs={4} className="text-black fw-medium fs-sm">
                <span>Price per point ($)</span>
              </Col>
              <Col xs={3} className="fw-medium text-black fs-sm">
                <span>Quantity</span>
              </Col>
              <Col xs={3} className="text-end text-black fw-medium fs-sm">
                {" "}
                <span>Time (UTC)</span>
              </Col>
              <Col xs={3} className="text-end text-black fw-medium fs-sm"></Col>
            </Row>
            {buyOrders &&
              buyOrders.map((order, index) => (
                <Row
                  key={index}
                  className={index % 2 === 0 ? "bg-carre-gray mt-1" : "mt-1"}
                >
                  <Col xs={3} className="d-flex align-items-center">
                    <span className="d-block text-left">
                      {order?.point_price}
                    </span>
                  </Col>
                  <Col
                    xs={3}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <span>{order?.available_point.toLocaleString()}</span>
                  </Col>
                  <Col xs={4} className="text-end">
                    <span className="d-block text-right">
                      {moment(order.created_at).format("MMM D HH:mm")}
                    </span>
                  </Col>
                  <Col
                    xs={2}
                    className=" d-flex align-items-center justify-content-end"
                  >
                    <Button
                      className="p-1"
                      size="sm"
                      variant="outline-carre-gray-bg"
                      onClick={() => handleCancelOrder(order)}
                    >
                      <RxCross1 />
                    </Button>
                  </Col>
                </Row>
              ))}
          </Col>
        )}
        {sellOrders?.length > 0 && (
          <Col md={6} className="px-4">
            <p className="fw-medium fs-4 my-4">Open Sell Orders</p>

            <Row className="mt-3">
              <Col xs={4} className="text-black fw-medium fs-sm">
                {" "}
                <span>Price per point ($)</span>
              </Col>
              <Col xs={3} className="fw-medium text-black fs-sm">
                <span>Quantity</span>
              </Col>
              <Col xs={3} className="text-end text-black fw-medium fs-sm">
                <span>Time (UTC)</span>
              </Col>
              <Col xs={3} className="text-end text-black fw-medium fs-sm"></Col>
            </Row>
            {sellOrders &&
              sellOrders.map((order, index) => (
                <Row
                  key={index}
                  className={index % 2 === 0 ? "bg-carre-gray" : ""}
                >
                  <Col xs={3} className="d-flex align-items-center">
                    <span className="d-block text-left ">
                      {order?.point_price}
                    </span>
                  </Col>
                  <Col
                    xs={3}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <span>{order?.available_point.toLocaleString()}</span>
                  </Col>
                  <Col xs={4} className="text-end">
                    <span className="d-block text-right">
                      {moment(order.created_at).format("MMM D HH:mm")}
                    </span>
                  </Col>
                  <Col
                    xs={2}
                    className=" d-flex align-items-center justify-content-end"
                  >
                    <Button
                      className="p-1"
                      size="sm"
                      variant="outline-carre-gray-bg"
                      onClick={() => handleCancelOrder(order)}
                    >
                      <RxCross1 />
                    </Button>
                  </Col>
                </Row>
              ))}
          </Col>
        )}
      </Row>

      {/* Confirmation Modal */}
      <Modal
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete your{" "}
          {orderToDelete?.type === "BUY" ? "buy" : "sell"} order for{" "}
          {orderToDelete?.available_point} vacation points for the price of{" "}
          {orderToDelete?.point_price.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}{" "}
          or {orderToDelete?.type === "BUY" ? "lower" : "higher"} created on{" "}
          {moment(orderToDelete?.created_at).format("MMM D HH:mm")}?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant="carre-red" onClick={confirmDeleteOrder}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserOrders;
