import { useMutation } from "react-query";
import api from "../api";

const acceptWireDepositMutation = async (data) => {
  const { isNewOrder, ...restData } = data;
  const response = await api.post("/wire/accept", restData, {
    params: { is_new: isNewOrder },
  });
  return response.data;
};

export const useAcceptWireDeposit = () => {
  return useMutation(acceptWireDepositMutation);
};