import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
import React from "react";
import {useForm} from "react-hook-form";
import {useMutation} from "react-query";
import {toast, ToastContainer} from "react-toastify";
import {GiHouseKeys} from "react-icons/gi";
import {useDeleteProperty} from "../hooks/useDeleteProperty";
import {AiOutlineWarning} from "react-icons/ai";

function DeletePropertyConfirmationModal(props) {

    const {
        handleSubmit,
    } = useForm()


    const {mutate, isLoading} = useMutation(useDeleteProperty, {
        onSuccess: (data) => {
            notifySuccess()
            props.refetch().then(() => {
            })
            props.onHide()

        },
        onError: () => {
            notifyError()
        }
    });
    const notifyError = () => toast.error('Something went wrong ');
    const notifySuccess = () => toast.success("Property Deleted Successfully");
    const onSubmit = () => {
        mutate(props?.id);
    };

    return (
        <div>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Delete Property <AiOutlineWarning className="text-carre-red"/>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="rounded-2 shadow-lg p-3  p-3 my-2 ">
                            <GiHouseKeys style={{width: "20px", height: "20px"}}
                                         className="text-carre-purple  d-inline mb-2 mx-1"/>
                            <span className="h5 font-weight-bold d-inline  ">
                               Do you want to delete property &nbsp;
                                {props?.data?.property_name}&nbsp;?
                            </span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="carre-purple w-25" size="sm" onClick={props.onHide}>Close</Button>
                        <Button
                            type={"submit"}
                            size="sm"
                            variant="carre-red w-25"
                            disabled={isLoading}
                        >
                            {!isLoading ? "Delete" : "Please wait"}
                        </Button>
                    </Modal.Footer>
                </form>

            </Modal>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"/>
        </div>
    );
}

export default DeletePropertyConfirmationModal