import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { Container } from "react-bootstrap";

function PrivacyPolicy() {
  return (
    <div>
      <NavBar />
      <Container className="mt-5">
        <div
          style={{
            fontSize: "14px",
            marginTop: "80px",
          }}
        >
          <span>Scope&nbsp;</span>
          <br />
          <span>
            This privacy policy (this “Privacy Policy”) describes how we, Carré
            Inc., a Delaware corporation, (“Carré,” “we,” or “us”) handle your
            personal information related to your use of the Carré webpage and
            other related websites where this policy appears in the footer of
            the page (collectively, the “Site” or the “Sites”) and your
            participation in any investment(s) offered by Carré (which shall be
            subject to the entry of an agreement specifically related to such
            investment(s)) (the “Services”). The policy that applies on any of
            our domains or subdomains is always the policy that appears in the
            footer of each website.&nbsp;
          </span>
          <br />
          <span>
            By accepting this Privacy Policy and our Terms of Service by way of
            your use of the Site, you hereby consent to the collection, storage,
            use, and disclosure of your personal information as described in
            this Privacy Policy. Carré reserves the right to modify content on
            the Site and this Privacy Policy periodically without prior notice.
            &nbsp;Any changes will become effective upon posting the changes,
            modifications, or revisions to the Site, along with the date on
            which it was most recently updated as indicated by the “Updated as
            of” date set forth above. &nbsp;&nbsp;
          </span>
          <br />
          <span>Collection&nbsp;</span>
          <br />
          <span>
            You can browse the Site without disclosing your identity to Carré or
            revealing any personal information about yourself. Once you give us
            your personal information, you are not anonymous to us. If you
            choose to provide personal information to us, or anywhere on the
            Site, including third parties, you consent to the transfer and
            storage of that information to our servers .&nbsp;
          </span>
          <br />
          <span>
            We may collect and store the following personal information:&nbsp;
          </span>
          <br />
          <span>
            Contact information and other identifiers, including your email
            address, first and last name, phone number, address, birthdate,
            country of citizenship, selfie picture and a picture of your
            passport;&nbsp;
          </span>
          <br />
          <span>Correspondence sent to us; &nbsp;</span>
          <br />
          <span>
            Transactional information based on your activities on the
            Sites;&nbsp;
          </span>
          <br />
          <span>
            Information you provide to use our Site or any service(s) we
            provide; &nbsp;
          </span>
          <br />
          <span>Information you provide for investment purposes; &nbsp;</span>
          <br />
          <span>
            Computer sign-on data, statistics on page views, traffic to and from
            the Site, and ad data; &nbsp;
          </span>
          <br />
          <span>
            Information about your Internet service provider, your operating
            system, browser type, domain name, Internet protocol (IP) address,
            your location and access times, the Website that referred you to us,
            the web pages you request, and the date and time of those requests,
            and standard web log information, and&nbsp;
          </span>
          <br />
          <span>
            Images you submit to the Site could include metadata that indicates
            when, where, how and who created such materials and the way they are
            formatted.&nbsp;
          </span>
          <br />
          <span>Marketing&nbsp;</span>
          <br />
          <span>
            We do not share, sell, or rent your personal information to third
            parties for their marketing purposes without your consent.
            &nbsp;However, please note that information you voluntarily provide
            when using our Site, our product or service, will be used for its
            intended purpose. &nbsp;&nbsp;
          </span>
          <br />
          <span>
            If you no longer wish to receive our communications, you may opt-out
            of receiving them by using the link included in each email
            communication, by adjusting the settings on your account or by
            contacting&nbsp;
          </span>
          <a href="mailto:legal@carre.ai">legal@carre.ai</a>
          <br />
          <span>Use&nbsp;</span>
          <br />
          <span>
            Our primary purpose in collecting personal information is to provide
            you with a safe, smooth, efficient, and customized experience. You
            agree that we may use your personal information to:&nbsp;
          </span>
          <br />
          <span>Comply with Know Your Client (KYC) requirements;&nbsp;</span>
          <br />
          <span>
            Provide the Services and products and customer support you request;
            &nbsp;
          </span>
          <br />
          <span>Resolve disputes and troubleshoot problems; &nbsp;</span>
          <br />
          <span>
            Prevent potentially prohibited or illegal activities and enforce our
            Terms of Service; &nbsp;
          </span>
          <br />
          <span>
            Customize, measure, and improve our Services and the Sites’ content
            and layout;&nbsp;
          </span>
          <br />
          <span>
            For security and to prevent fraud and monitor against theft; &nbsp;
          </span>
          <br />
          <span>
            Conduct research and help us learn more about your preferences and
            enhance your experience;&nbsp;
          </span>
          <br />
          <span>
            To maintain and administer your account, establish your identity and
            perform other business activities as needed or described elsewhere
            in this Policy; and&nbsp;
          </span>
          <br />
          <span>
            For use in performing various types of analytics. &nbsp;For example,
            we use information to analyze how users interact with our Site,
            where visitors to our Site arrive from and exit to, and gaining
            insights into the types of users that may be interested in our
            Services. &nbsp;
          </span>
          <br />
          <span>Our Disclosure of Your Information&nbsp;</span>
          <br />
          <span>
            We may disclose personal information to respond to legal
            requirements, enforce our policies, respond to claims, or protect
            anyone’s rights, property, or safety.&nbsp;
          </span>
          <br />
          <span>We may also share your personal information with:&nbsp;</span>
          <br />
          <span>
            Members of our corporate family to help detect and prevent
            potentially illegal acts and provide joint services; &nbsp;
          </span>
          <br />
          <span>
            Service providers under contract who help with our business
            operations (such as fraud investigations, bill collection, and/or
            affiliates); &nbsp;
          </span>
          <br />
          <span>
            Law enforcement or other governmental officials or third parties, in
            response to a request or subpoena relating to a criminal
            investigation or alleged illegal activity, or when we believe in
            good faith that the disclosure of information is necessary to
            prevent imminent physical harm or financial loss or to report
            suspected illegal activity; &nbsp;
          </span>
          <br />
          <span>
            Other business entities, should we plan to merge with or be acquired
            by that business entity.&nbsp;
          </span>
          <br />
          <span>
            We may also share information about our customers with affiliated
            companies, vendors, business partners, and other organizations to
            provide better service to you. These companies need information
            about you to perform their service function (such as to verify your
            information, assist us with providing any of our Services, and to
            protect you and/or us from fraud). We also share your information
            with specially chosen companies that help us with marketing
            functions (such as to maintain and manage our customer information
            and market our Site and Services). We may engage vendors to serve
            advertisements on our behalf across the Internet and to provide
            analytics services. These entities may collect certain information
            from you (e.g. click stream information, browser type, time and
            date, hardware/software information, cookie ID, IP address, etc.)
            when you visit our Site and they use that information to provide
            advertisements about goods and services that are deemed to be of
            greater interest to you.&nbsp;
          </span>
          <br />
          <span>Cookies, local storage, and web beacons&nbsp;</span>
          <br />
          <span>
            We may use cookies and browser local storage (small files placed on
            your hard drive) on certain pages of the Site to help analyze our
            web page flow, customize our Services, measure promotional
            effectiveness, improve functionality, and preserve and track
            transaction history and information between sessions. We link the
            information we store in such files to personally identifiable
            information you submit while on our site in order to customize the
            user experience. &nbsp;Cookies may track your browser type, your
            internet service provider, the time and length of your visit, the
            pages you view and order you view them, and the site you visit
            before and/or after visiting our Site.&nbsp;
          </span>
          <br />
          <span>
            A few important things you should know about cookies and browser
            local storage are that:&nbsp;
          </span>
          <br />
          <span>
            We offer certain features that are only available through the use of
            cookies and/or local storage;&nbsp;
          </span>
          <br />
          <span>
            We use cookies and/or browser local storage to help identify you and
            maintain your signed-in status;&nbsp;
          </span>
          <br />
          <span>
            You are always free to decline our cookies and local storage if your
            browser permits, although doing so may interfere with your use of
            the Sites; and &nbsp;
          </span>
          <br />
          <span>
            You may erase our cookies and browser local storage files.&nbsp;
          </span>
          <br />
          <span>
            We may also allow other third parties (e.g., ad networks and ad
            servers such as Google Analytics, DoubleClick, Facebook, and others)
            to serve tailored marketing to you and to access their own cookies
            or other tracking technologies on your computer, mobile phone, or
            other device you use to access the Site. We neither have access to,
            nor does this Privacy Policy govern, the use of cookies or other
            tracking technologies that may be placed on your computer, mobile
            phone, or other device you use to access the Site by non-affiliated,
            third-party ad technology, ad servers, ad networks or any other
            non-affiliated third parties. Those parties that use these
            technologies may offer you a way to opt out of targeted advertising
            as described below. You may receive tailored advertising on your
            computer through a web browser. Cookies may be associated with
            de-identified data linked to or derived from data you voluntarily
            have submitted to us (e.g., your email address) that we may share
            with a service provider in hashed, non-human-readable form.&nbsp;
          </span>
          <br />
          <span>
            Most web browser applications have features that can notify you when
            you receive a cookie or prevent cookies from being sent. If you
            disable cookies or other device tracking features, however, you may
            not be able to use certain personalized functions of the Site.&nbsp;
          </span>
          <br />
          <span>Children’s Privacy&nbsp;</span>
          <br />
          <span>
            Our Site is directed toward and designed for use by persons aged 18
            or older. We will not approve applications of, or establish or
            maintain registrations for any child whom we know to be under the
            age of 18. We do not solicit or knowingly collect personally
            identifiable information from children under the age of 18. If we
            nevertheless discover that we have received personally identifiable
            information from an individual who indicates that he or she is, or
            whom we otherwise has reason to believe is, under the age of 16, we
            will delete such information from our systems. Additionally, a
            child’s parent or legal guardian may request that the child’s
            information be corrected or deleted from our files by requesting
            this via our Contact Us page.&nbsp;
          </span>
          <br />
          <span>Account Protection&nbsp;</span>
          <br />
          <span>
            Your password is the key to your account. Use unique numbers,
            letters, and special characters and do not disclose your Carré
            password to anyone. If you do share your password or your personal
            information with others, remember that you are responsible for all
            actions taken in the name of your account. If you lose control of
            your password, you may lose substantial control over your personal
            information and may be subject to legally binding actions taken on
            your behalf. Therefore, if your password has been compromised for
            any reason, you should immediately notify Carré and change your
            password.&nbsp;
          </span>
          <br />
          <span>
            Accessing, Reviewing and Changing Your Personal Information&nbsp;
          </span>
          <br />
          <span>
            You can see, review, correct, and change most of your personal
            information by logging into your account at Carré. You must promptly
            update your personal information if it changes or is inaccurate.
            Upon your request, we will close your account and remove your
            personal information as soon as reasonably possible, based on your
            account activity and in accordance with applicable law. We retain
            personal information from closed accounts in order to comply with
            law, prevent fraud, collect any fees owed, resolve disputes,
            troubleshoot problems, assist with any investigations, enforce our
            Terms of Service, and take other actions otherwise permitted by
            law.&nbsp;
          </span>
          <br />
          <span>Security&nbsp;</span>
          <br />
          <span>
            The security of your personal information is important to us. When
            you enter sensitive information on our registration or other forms,
            we encrypt that information using secure socket layer technology
            (SSL) and Base64 encoding. &nbsp;
          </span>
          <br />
          <span>
            We treat data as an asset that must be protected and use many tools
            (encryption, passwords, physical security, etc.) to protect your
            personal information against unauthorized access and disclosure.
            However, as you probably know, third parties may unlawfully
            intercept, or access transmissions or private communications.
            Therefore, although we work hard to protect your privacy, we cannot
            guarantee that your personal information or private communications
            will always remain private.&nbsp;
          </span>
          <br />
          <span>
            Your information is stored on our servers . You consent to the
            transfer of all such information to our servers, which may not offer
            a level of protection equivalent to that required in certain other
            countries, and to the processing of that information as described in
            this Privacy Policy. &nbsp;&nbsp;
          </span>
          <br />
          <span>Third Parties&nbsp;</span>
          <br />
          <span>
            Except as otherwise expressly included in this Privacy Policy, this
            document addresses only the use and disclosure of information we
            collect from you. If you disclose your information to other sites
            throughout the Internet, different privacy policies or rules may
            apply to their use or disclosure of the information you disclose to
            them. This site may contain links to other Web sites. Please note
            that when you click on one of these links, you are entering another
            site. Carré is not responsible for the privacy policies or the
            content of such Web sites. Since Carré does not control the privacy
            policies of third parties, you are subject to the privacy policies,
            and to the actions, of those third parties. We encourage you to ask
            questions and take precautions before you disclose your personal
            information to others. &nbsp;We are in no way responsible for the
            actions or policies of our users or third parties. &nbsp;&nbsp;
          </span>
          <br />
          <span>EU and EEA Users and Customers&nbsp;</span>
          <br />
          <span>
            Personal information. References to “personal information” in this
            Privacy Policy are equivalent to “personal data” governed by
            European data protection legislation.&nbsp;
          </span>
          <br />
          <span>
            Controller, Data Protection Officer, and EU Representative. Carré is
            the controller of your personal information covered by this Privacy
            Policy for purposes of European data protection legislation and you
            can contact our Data Protection Officer at&nbsp;
          </span>

          <a href="mailto:dataprotectionofficer@carre.ai">
            dataprotectionofficer@carre.ai
          </a>

          <br />
          <br />
          <span>Legal bases for processing.&nbsp;</span>
          <br />
          <span>
            The legal bases on which we process your personal information as
            described in this Privacy Policy will depend on the type of personal
            information and the specific context in which we process it.
            However, the legal bases we typically rely on are set out in the
            table below. We rely on our legitimate interests as our legal basis
            only where those interests are not overridden by the impact on you
            (unless we have your consent or our processing is otherwise required
            or permitted by law). If you have questions about the legal basis of
            how we process your personal information, contact us at 
          </span>
          <a href="mailto:legal@carre.ai">legal@carre.ai</a>

          <br />
          <span>Processing purpose&nbsp;</span>
          <br />
          <span>Legal Basis&nbsp;</span>
          <br />
          <span>Service delivery &nbsp;</span>
          <span>Site operation&nbsp;</span>
          <br />
          <span>
            Processing is necessary to perform the contract governing our
            operation of the Site or the provision of the Services, or to take
            steps that you request prior to engaging our Services. Where we
            cannot process your personal data as required to operate the Site or
            Services on the grounds of contractual necessity, we process your
            personal information for this purpose based on our legitimate
            interest in providing you with the Services you access and
            request.&nbsp;
          </span>
          <br />
          <span>Marketing&nbsp;</span>
          <br />
          <span>
            Processing is based on your consent where that consent is required
            by applicable law. Where we rely on your consent you have the right
            to withdraw it any time in the manner indicated when you consent or
            in the Service. Where such consent is not required by applicable
            law, we process your personal information for these purposes based
            on our legitimate interests in promoting our business.&nbsp;
          </span>
          <br />
          <span>Compliance &amp; Protection&nbsp;</span>
          <br />
          <span>
            Processing is necessary to comply with our legal obligations or
            based on our legitimate interests in protecting our or others’
            rights, privacy, safety, or property.&nbsp;
          </span>
          <br />
          <span>Other purposes with your consent&nbsp;</span>
          <br />
          <span>
            Processing is based on your consent. Where we rely on your consent
            you have the right to withdraw it anytime in the manner indicated
            when you consent or by contacting us at&nbsp;
          </span>
          <a href="mailto:legal@carre.ai">legal@carre.ai</a>

          <br />
          <span>Use for new purposes.&nbsp;</span>
          <br />
          <span>
            We may use your personal information for reasons not described in
            this Privacy Policy where permitted by law and the reason is
            compatible with the purpose for which we collected it. If we need to
            use your personal information for an unrelated purpose, we will
            notify you and explain the applicable legal basis.&nbsp;
          </span>
          <br />
          <span>Retention.&nbsp;</span>
          <br />
          <span>
            We will only retain your personal information for as long as
            necessary to fulfill the purposes we collected it for, including for
            the purposes of satisfying any legal, accounting, or reporting
            requirements.&nbsp;
          </span>
          <br />
          <span>
            To determine the appropriate retention period for personal
            information, we consider the amount, nature, and sensitivity of the
            personal information, the potential risk of harm from unauthorized
            use or disclosure of your personal information, the purposes for
            which we process your personal information and whether we can
            achieve those purposes through other means, and the applicable legal
            requirements.&nbsp;
          </span>
          <br />
          <span>YOUR RIGHTS&nbsp;</span>
          <br />
          <span>&nbsp;</span>
          <br />
          <span>
            Where the European Union’s General Data Protection Regulation
            2016/679, or GDPR, applies, in certain circumstances and subject to
            data processing agreements, you have rights in relation to the
            personal information we hold about you. We set out below an outline
            of those rights and how to exercise those rights. Please note that
            we will require you to verify your identity before responding to any
            requests to exercise your rights by providing details only known to
            the account holder. To exercise any of your rights, please contact
            us at&nbsp;
          </span>
          <a href="mailto:legal@carre.ai">legal@carre.ai</a>

          <span>
            &nbsp; Please note that for each of the rights below we may have
            valid legal reasons to refuse your request, in such instances we
            will let you know if that is the case.&nbsp;
          </span>
          <br />
          <span>&nbsp;</span>
          <br />
          <span>Right to Access&nbsp;</span>
          <br />
          <span>&nbsp;</span>
          <br />
          <span>
            You have the right to know whether we process personal information
            about you, and if we do, to access data we hold about you and
            certain information about how we use it and who we share it
            with.&nbsp;
          </span>
          <br />
          <span>&nbsp;</span>
          <br />
          <span>Right to Correct&nbsp;</span>
          <br />
          <span>&nbsp;</span>
          <br />
          <span>
            You have the right to require us to correct any personal information
            held about you that is inaccurate and have incomplete data
            completed. Where you request correction, please explain in detail
            why you believe the personal information we hold about you to be
            inaccurate or incomplete so that we can assess whether a correction
            is required. Please note that while we assess whether the personal
            information we hold about you is inaccurate or incomplete, you may
            exercise your right to restrict our processing of the applicable
            data as described below.&nbsp;
          </span>
          <br />
          <span>&nbsp;</span>
          <br />
          <span>Right to Erase&nbsp;</span>
          <br />
          <span>&nbsp;</span>
          <br />
          <span>
            You may request that we erase the personal information we hold about
            you in the following circumstances:&nbsp;
          </span>
          <br />
          <span>
            where you believe it is no longer necessary for us to hold the
            personal information;&nbsp;
          </span>
          <br />
          <span>
            we are processing it on the basis of your consent and you wish to
            withdraw your consent;&nbsp;
          </span>
          <br />
          <span>
            we are processing your data on the basis of our legitimate interest
            and you object to such processing;&nbsp;
          </span>
          <br />
          <span>
            you no longer wish us to use your data to send you marketing;
            or&nbsp;
          </span>
          <br />
          <span>you believe we are unlawfully processing your data.&nbsp;</span>
          <br />
          <span>
            Please provide as much detail as possible on your reasons for the
            request to assist us in determining whether you have a valid basis
            for erasure.&nbsp;
          </span>
          <br />
          <span>Right to Restrict&nbsp;</span>
          <br />
          <span>
            You have a right to require us to stop processing the personal
            information we hold about you other than for storage purposes in the
            following circumstances:&nbsp;
          </span>
          <br />
          <span>
            You have objected to us processing personal information we hold
            about you on the basis that it is inaccurate and you wish us to
            temporarily cease processing your data while we investigate the
            objection;&nbsp;
          </span>
          <br />
          <span>
            You have objected to us processing personal information we hold
            about you on the basis of our legitimate interest and you wish us to
            stop processing the personal information while we determine whether
            there is an overriding interest in us retaining such personal
            information;&nbsp;
          </span>
          <br />
          <span>
            We wish to erase the personal information as the processing we are
            doing is unlawful under applicable data protection laws such as
            GDPR, but you want us to simply restrict the use of that data;
            and&nbsp;
          </span>
          <br />
          <span>
            We no longer need the personal information for the purposes of the
            processing, but you require us to retain the data for the
            establishment, exercise, or defense of legal claims.&nbsp;
          </span>
          <br />
          <span>Right to Port Data&nbsp;</span>
          <br />
          <span>
            You have the right to request your Personal Information be ported
            over to another controller. &nbsp;
          </span>
          <br />
          <span>Right to Object&nbsp;</span>
          <br />
          <span>
            You have the right to object to our processing of data about you and
            we will consider your request. Please provide us with detail as to
            your reasoning so that we can assess whether there is a compelling
            overriding interest in us continuing to process such data or we need
            to process it in relation to legal claims.&nbsp;
          </span>
          <br />
          <span>
            You also have the right, at any time, to object to our processing of
            data about you in order to send you marketing, including where we
            build profiles for such purposes and we will stop processing the
            data for that purpose.&nbsp;
          </span>
          <br />
          <span>Withdrawal of Consent&nbsp;</span>
          <br />
          <span>
            Where you have provided your consent to us processing your personal
            data, you can withdraw your consent at any time by contacting us
            at&nbsp;
          </span>
          <a href="mailto:legal@carre.ai">legal@carre.ai</a>

          <br />
          <br />
          <span>Complaints&nbsp;</span>
          <br />
          <span>
            In the event that you wish to make a complaint about how we process
            your personal information, please contact our Data Protection and
            Privacy Officer at&nbsp;
          </span>
          <a href="mailto:dataprotectionofficer@carre.ai">
            dataprotectionofficer@carre.ai
          </a>

          <span>&nbsp;and we will try to address with your request.&nbsp;</span>
          <br />
          <span>Cross-Border Data Transfer&nbsp;</span>
          <br />
          <span>
            If we transfer your personal information out of the European
            Economic Area or Switzerland and are required to apply additional
            safeguards to your personal information under European data
            protection legislation, we will do so. Please contact us at&nbsp;
          </span>
          <a href="mailto:legal@carre.ai">legal@carre.ai</a>

          <span>
            &nbsp;for further information about any such transfers or the
            specific safeguards applied.&nbsp;
          </span>
          <br />
          <span>&nbsp;</span>
          <br />
          <span>Changes in this Privacy Statement&nbsp;</span>
          <br />
          <span>
            We may amend this Privacy Policy at any time by posting the amended
            terms on the Site. We reserve the right to modify this privacy
            statement at any time, so please review it frequently. If we make
            material changes to this policy, we will notify you here, by email,
            or by means of a notice on our home page.&nbsp;
          </span>
          <br />
          <span>
            If you have any questions you can answer them online, you may write
            to us at 
          </span>
          <a href="mailto:legal@carre.ai">legal@carre.ai</a>

          <br />
          <span>Questions about this policy?&nbsp;</span>
          <br />
          <span>
            If you have any questions or concerns regarding our privacy
            policies, please send us a detailed message to
          </span>
          <a href="mailto:legal@carre.ai">legal@carre.ai</a>

          <span>&nbsp;and we will try to resolve your concerns.&nbsp;</span>
        </div>
      </Container>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
