import api from "../api";

export const useRequestPasswordResetCode = (data) => {
  // Call the API with the "data2" object, which does not include the "id" property
  return api.post(`user/forget-password`, data).then((res) => res.data);
};

export const useVerifyRequestCodeForPasswordReset = (data) => {
  // Call the API with the "data2" object, which does not include the "id" property
  return api
    .post(`user/forget-password/verify-code`, data)
    .then((res) => res.data);
};
export const useResetPassword = (data) => {
  // Call the API with the "data2" object, which does not include the "id" property
  return api
    .post(`user/forget-password/change-password`, data)
    .then((res) => res.data);
};
