import React, { useState } from "react";
import { Alert, Badge, Button, Card, Form } from "react-bootstrap";

import { useGetWithdrawRequestForUser } from "../../hooks/useGetWithdrawRequestForUser";
import UserCancelWithdrawReuqest from "./UserCancelWithdrawRequest";
import moment from "moment/moment";

// function formatCreatedAt(createdAt) {
//   const date = new Date(createdAt);
//   const formattedDate = date.toLocaleDateString(); // Change toLocaleDateString to other format if needed
//   return formattedDate;
// }
function formatCreatedAt(createdAt) {
  const date = new Date(createdAt);
  const formattedDate = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  return formattedDate;
}

function UserWithdrawRequest() {
  const [showModal, setShowModal] = useState(false);
  const [withdrawalToCancel, setWithdrawalToCancel] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState("ALL"); // Default to "ALL" status
  const { data, refetch } = useGetWithdrawRequestForUser(selectedStatus);
  const { data: openData } = useGetWithdrawRequestForUser("REQUEST");

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "COMPLETED":
        return "#4CAF50"; // Green
      case "REJECTED":
        return "#F44336"; // Red
      case "REQUEST":
        return "#2196F3"; // Blue
      case "IN_PROGRESS":
        return "#FFC107"; // Yellow
      case "CANCELED":
        return "#9E9E9E"; // Gray
      default:
        return "#FFFFFF"; // Default color (white)
    }
  };
  return (
    <>
      <div>
        <p className="fw-medium fs-4 my-4">Open Withdrawal</p>
        {openData?.length > 0 ? (
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-2 ">
            {openData?.map((transaction) => (
              <div className="col">
                <Card bg="white" text="dark" className="rounded shadow-sm">
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <div className="text-muted fs-sm">
                        {" "}
                        {moment(transaction.created_at).format("MMM D HH:mm")}
                      </div>
                      <div className="d-inline-flex align-items-center">
                        <div
                          className="rounded-circle me-2"
                          style={{
                            width: "12px",
                            height: "12px",
                            backgroundColor: getStatusColor(
                              transaction?.status
                            ),
                          }}
                        />
                        <Badge
                          bg="carre-gray"
                          text="black"
                          className="px-2 border-1 py-1 rounded text-xs"
                        >
                          {transaction?.status}
                        </Badge>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="fw-medium"> {transaction?.type}</div>
                      <div className="text-muted">
                        {transaction?.amount?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <Button
                        variant="outline-carre-gray-bg"
                        size="sm"
                        className="fw-light"
                        onClick={() => {
                          setShowModal(true);
                          setWithdrawalToCancel(transaction);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        ) : (
          <Alert className="text-center fs-sm p-2 fw-semibold mt-4">
            No data available.
          </Alert>
        )}
      </div>
      <div className="mt-4">
        <p className="fw-medium fs-4 my-4">Withdrawal Historical</p>

        <div>
          <Form.Label>Status</Form.Label>
          <Form.Select
            className="mb-4"
            as="select"
            size="sm"
            value={selectedStatus}
            onChange={(e) => handleStatusChange(e.target.value)}
          >
            <option value="ALL">All</option>
            <option value="COMPLETED">Completed</option>
            <option value="REJECTED">Rejected</option>
            <option value="IN_PROGRESS">In Progress</option>
            <option value="CANCELED">Canceled</option>
          </Form.Select>
        </div>

        <div>
          {data?.length > 0 ? (
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-2">
              {data?.map((transaction) => (
                <div className="col">
                  <Card bg="white" text="dark" className="rounded shadow-sm">
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className="text-muted fs-sm">
                          {" "}
                          {moment(transaction.created_at).format("MMM D HH:mm")}
                        </div>
                        <div className="d-inline-flex align-items-center">
                          <div
                            className="rounded-circle me-2"
                            style={{
                              width: "12px",
                              height: "12px",
                              backgroundColor: getStatusColor(
                                transaction?.status
                              ),
                            }}
                          />
                          <Badge
                            bg="carre-gray"
                            text="black"
                            className="px-2 border-1 py-1 rounded text-xs"
                          >
                            {transaction?.status}
                          </Badge>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center ">
                        <div className="fw-medium"> {transaction?.type}</div>
                        <div className="text-muted">
                          {" "}
                          {transaction?.amount?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
          ) : (
            <Alert className="text-center fs-sm p-2 fw-semibold mt-4">
              No data available.
            </Alert>
          )}
        </div>
      </div>

      <UserCancelWithdrawReuqest
        refetch={refetch}
        show={showModal}
        data={withdrawalToCancel}
        onHide={() => setShowModal(false)}
      />
    </>
  );
}

export default UserWithdrawRequest;
