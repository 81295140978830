import React, { useEffect, useState } from "react";
import { Alert, Table } from "react-bootstrap";
import { useGetUserSellRequest } from "../../hooks/useGetUserSellRequest";

const OrderHistory = () => {
  const { data: orders } = useGetUserSellRequest();
  const [selectedData, setSelectedData] = useState(orders); // Initialize with orders
  const [selected, setSelected] = useState("data2");
  const [isData1Selected, setIsData1Selected] = useState(true);

  function formatCreatedAt(createdAt) {
    const date = new Date(createdAt);
    const formattedDate = date.toLocaleDateString(); // Change toLocaleDateString to other format if needed
    return formattedDate;
  }

  if (orders?.length === 0) {
    return (
      <Alert className="text-center fs-sm p-2 fw-semibold">
        No orders found for this property.
      </Alert>
    );
  }

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Status</th>
          <th>Share Quantity</th>
          <th>Share Price</th>
          <th>Amount</th>
          <th>Created At</th>
        </tr>
      </thead>
      <tbody>
        {orders?.map((transfer) => (
          <tr key={transfer.id}>
            <td className="align-middle fw-bold justify-content-center align-items-center text-center">
              {transfer.token_selling_request.status}
            </td>
            <td className="align-middle fw-bold justify-content-center align-items-center text-center">
              {transfer.token_selling_request.token_qty}
            </td>

            <td className="align-middle fw-bold justify-content-center align-items-center text-center">
              {transfer.token_selling_request.token_price}
            </td>
            <td className="align-middle fw-bold justify-content-center align-items-center text-center">
              {transfer.token_selling_request.total_price}
            </td>
            <td className="align-middle fw-bold justify-content-center align-items-center text-center">
              {new Date(
                transfer.token_selling_request.created_at
              ).toLocaleDateString("en-US")}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default OrderHistory;
