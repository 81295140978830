import { DocsVerificationWidget } from "@dataspike/docver-widget";

export const renderWidget = async (props) => {
  const { verificationId, setShow } = props;

  const id = verificationId;
  // ReactDOM.unmountComponentAtNode(document.getElementById("root"));
  const container = document?.getElementById("test1");
  // container.innerHtml = "";
  if (container) {
    DocsVerificationWidget({
      // onInit: () => {
      //   // setShow(false);
      // },
      id,
      elementId: "test1",
      apiUrl: "https://api.dataspike.io",
      onFinish: () => {
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      },
    });
  }
};
