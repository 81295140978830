import { useGetUserData } from "./useGetUserInformation";

const useCheckUserStepIsBuy = () => {
  const { data } = useGetUserData();

  if (!data) return false;

  return data.guide_step === "BUY";
};

export default useCheckUserStepIsBuy;
