import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import Lightbox from "react-image-lightbox";
import "./Lightbox.css";
import CarreToken from "../assets/icons/CarreToken";

const LightboxPage = ({ data }) => {
  const imageLink = [];
  let index = 0;
  const [currentIndex, setCurrentIndex] = useState(index);

  let def;

  for (let i = 0; i < (data?.image?.length || 0); i++) {
    if (data?.image[i]?.is_default) {
      def = data?.image[i]?.image_url;
      index = i;
    } else {
      imageLink.push(data?.image[i]?.image_url);
    }
  }
  const [a, b] = imageLink;

  useEffect(() => {
    setCurrentIndex(index);
  }, [index]);

  const prevImage = () => {
    const index = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(index);
  };

  const nextImage = () => {
    const index = (currentIndex + 1) % images.length;

    setCurrentIndex(index);
  };

  const [photoIndex, setPhotoIndex] = useState(0);

  const images = imageLink.concat(def);

  const [isOpen, setIsOpen] = useState(false);
  const openLightBox = (val) => {
    setPhotoIndex(val);
    setIsOpen(true);
  };
  const styles = {
    containerStyles: {
      position: "relative",
      width: "100%",
      height: "415px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    imgStyles: {
      objectFit: "cover",
      height: "100%",
      width: "100%",
      // padding: "10px",
    },
    buttonStyles: {
      position: "absolute",
      top: "calc(50% - 24px)",
      cursor: "pointer",
    },
  };

  return (
    <div className="container mt-2">
      <div className="row light-box-container">
        <div
          onClick={() => openLightBox(data?.image.length - 2)}
          className="col-md-8 col-lg-9 px-0 gx-1 mb-3 d-none d-md-block "
        >
          <div className="position-relative">
            <img
              src={def}
              alt="Default"
              style={{
                objectFit: "cover",
                maxHeight: "415px",
                minHeight: "415px",
              }}
              className="w-100 h-100 rounded-2"
            />
            {data?.property_owned_token !== null && (
              <div
                className="position-absolute text-center"
                style={{ left: "0px", top: "0", padding: "5px" }}
              >
                <p
                  className="text-uppercase fs-md text-carre-blue  bg-carre-gray p-2 rounded-pill mt-1"
                  style={{ fontSize: "14px" }}
                >
                  <span className="mt-2">
                    {data?.property_owned_token?.token_qty}
                  </span>{" "}
                  <CarreToken />
                </p>
              </div>
            )}
            <div className="position-absolute translate-middle-y pb-5">
              <span className="badge text-white bg-carre-blue rounded-4 fw-light fs-6 ms-2 pb-2">
                {data?.market_stage_name}
              </span>
            </div>
            <div
              className="position-absolute translate-middle-y pb-5"
              style={{ marginLeft: 140 }}
            ></div>
          </div>
        </div>
        <div
          style={{ position: "relative", width: "100%" }}
          className="d-block d-md-none"
        >
          <div style={styles.containerStyles}>
            <Button
              variant="carre-gray-bg text-white"
              className="opacity-75"
              style={{ ...styles.buttonStyles, left: "-27px" }}
              onClick={prevImage}
            >
              <MdNavigateBefore size="30px" />
            </Button>

            <img
              onClick={() => openLightBox(photoIndex)}
              src={data?.image[currentIndex]?.image_url}
              alt="Default"
              style={styles.imgStyles}
              className="rounded-4"
            />

            <Button
              variant="carre-gray-bg text-white"
              className="opacity-75"
              style={{ ...styles.buttonStyles, right: "-27px" }}
              onClick={nextImage}
            >
              <MdNavigateNext size="30px" />
            </Button>
          </div>
        </div>
        <style>
          {`
          @media (min-width: 992px) {
            .pl-md-4 {
              padding-left: 1rem !important;
            }
          }

          @media (max-width: 991.98px) {
            .col-md-8, .col-lg-9, .col-md-4, .col-lg-3 {
              width: 100%;
            }
          }
        `}
        </style>
        <div className="col-md-4 col-lg-3 mb-3 p-0 pl-md-4">
          <div
            onClick={() => openLightBox(-1)}
            className="mb-3 rounded-3"
            style={{ maxHeight: "200px" }}
          >
            <img
              src={a}
              alt="Second"
              style={{
                borderRadius: "5",
                objectFit: "cover",
                maxWidth: "100%",
                maxHeight: "200px",
                minHeight: "200px",
              }}
              className={
                a === undefined
                  ? "rounded-2  d-none d-md-block"
                  : "w-100 h-100 mb-2 rounded-2  d-none d-md-block"
              }
            />
          </div>
          <div
            onClick={() => openLightBox(0)}
            className="rounded-2 position-relative d-none d-md-block"
            style={{ maxHeight: "200px" }}
          >
            {data?.image?.length > 3 ? (
              <div
                className="position-absolute w-100 text-center"
                style={{
                  bottom: "40%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  zIndex: "1",
                }}
              >
                <div
                  className="position-relative bg-black text-center rounded-2 d-inline-block"
                  style={{ width: "100px", height: "30px" }}
                >
                  <p
                    className="fw-normal text-white mt-1 mx-2"
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "20px",
                    }}
                  >
                    {data?.image?.length - 2 + " more images "}
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
            <img
              src={b}
              alt="Third"
              style={{
                objectFit: "cover",
                maxWidth: "100%",
                maxHeight: "200px",
                minHeight: "200px",
              }}
              className={`${
                data?.image.length > 3
                  ? "w-100 rounded-2 opacity-25 d-none d-md-block"
                  : "w-100 rounded-2  d-none d-md-block"
              } `}
            />
          </div>
        </div>
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          imageTitle={photoIndex + 1 + "/" + images.length}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
          onAfterOpen={() => setPhotoIndex(photoIndex + 1)}
        />
      )}
    </div>
  );
};

export default LightboxPage;
