import React, { useState } from "react";
import { Button, Card, Modal, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const ReservationPropertyCard = ({
  property,
  startDate,
  endDate,
  isOptional,
}) => {
  const numberOfStays = property?.total_amount / property?.price_avg;
  const totalPrice = property.price_avg * numberOfStays;
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowModal(true);
  };
  const navigate = useNavigate();

  const handleCardClick = (e) => {
    const effectiveStartDate = startDate || property.date_from;
    navigate(
      `/user/book-details/${
        property.property_id
      }?start_date=${effectiveStartDate}${
        !isOptional ? `&end_date=${endDate}` : ""
      }`
    );
  };

  return (
    <>
      <Card
        style={{ marginBottom: "20px", border: "none" }}
        className="custom-hover"
        onClick={handleCardClick}
      >
        <Card.Img
          variant="top"
          src={property.property_image_url}
          style={{
            width: "100%",
            height: "auto",
            aspectRatio: "1 / 1",
            objectFit: "cover",
            borderRadius: "15px",
          }}
        />
        <Card.Body className="p-0 mt-2">
          <p
            className="fs-lg text-black fw-medium"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {property.property_name}
          </p>
          <p style={{ marginTop: "-12px" }}>
            {property.city_name}, {property.country_name}
          </p>
          <p className="text-sm" style={{ marginTop: "-12px" }}>
            {!isOptional && `${property.number_of_stay} night`}
            {property.number_of_stay > 1 && "s"}{" "}
            {moment(property.date_from).format("D MMM")}
            {isOptional !== true && " • "}
            {property.date_to}
          </p>
          <Card.Text>
            {isOptional ? (
              <p
                className="text-decoration-underline text-medium fw-medium"
                style={{ cursor: "pointer" }}
              >
                VP {property?.price_avg?.toFixed(2)} average / day
              </p>
            ) : (
              <p
                className="text-decoration-underline text-medium fw-medium"
                style={{ cursor: "pointer" }}
                onClick={handleShow}
              >
                <strong>{totalPrice}</strong> vacation point total
              </p>
            )}
          </Card.Text>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="fw-medium fs-lg">Price Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="text-black fs-md">
            <Col xs={6}>
              {property.price_avg} VP x {property.number_of_stay} night
              {property.number_of_stay > 1 && "s"}
            </Col>
            <Col xs={6} className="text-end">
              {totalPrice} VP
            </Col>
          </Row>
          <hr />
          <Row className="text-black fs-md">
            <Col xs={6}>Total</Col>
            <Col xs={6} className="text-end">
              {totalPrice} vacation point
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReservationPropertyCard;
