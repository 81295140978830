// GlobalLoadingIndicator.js
import React from "react";
import { Spinner } from "react-bootstrap";
import { useIsFetching } from "react-query";
import { useAuth } from "../auth/TokenCreation";

function GlobalLoadingIndicator() {
  const isFetching = useIsFetching();
  const { cookies } = useAuth();

  if (!isFetching) {
    return null;
  }

  if (isFetching && cookies.token) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // background: 'rgba(0, 0, 0, 0.05)', // Opacity background color
        }}
      >
        <Spinner animation="border" variant="light" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
}

export default GlobalLoadingIndicator;
