import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import {
  FaChartLine,
  FaDollarSign,
  FaGlobe,
  FaLaptop,
  FaLock,
  FaMoneyBillWave,
  FaPlane,
  FaTools,
} from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";

const FeatureSection = () => {
  const features = [
    {
      icon: <FaMoneyBillWave size={40} />,
      title: "Fractional Ownership",
      description:
        "Invest in real estate by purchasing shares, making property investment accessible with minimal capital.",
    },
    {
      icon: <FaDollarSign size={40} />,
      title: "Daily Rental Income",
      description:
        "Earn rental income daily from the properties you invest in, providing a consistent revenue stream.",
    },
    {
      icon: <FaChartLine size={40} />,
      title: "Property Appreciation",
      description:
        "Benefit from the appreciation of property values over time, growing your wealth with each investment.",
    },
    {
      icon: <FaPlane size={40} />,
      title: "Vacation Points",
      description:
        "Redeem vacation points earned from your investments for stays at premium properties, offering a unique perk.",
    },
    {
      icon: <FaDollarSign size={40} />,
      title: "Low Entry Costs",
      description:
        "Start investing in real estate with as little as $100, breaking down barriers to entry.",
    },
    {
      icon: <FaGlobe size={40} />,
      title: "Global Property Portfolio",
      description:
        "Access a diverse portfolio of pre-vetted properties located in various high-potential markets around the world.",
    },
    {
      icon: <FaLaptop size={40} />,
      title: "User-Friendly Platform",
      description:
        "An intuitive online platform that simplifies the process of browsing, purchasing, and managing real estate investments.",
    },
    {
      icon: <FaTools size={40} />,
      title: "Hassle-Free Management",
      description:
        "Care handles all property management tasks, from maintenance to tenant relations, ensuring a seamless investment experience.",
    },
    {
      icon: <FaLock size={40} />,
      title: "Secure Transactions",
      description:
        "Enjoy peace of mind with secure transactions and compliance with regulatory standards, ensuring the safety of your investments.",
    },
  ];

  return (
    <Container className="my-5">
      <h2 className="text-center mb-4 text-3xl fw-semibold mb-4">
        Discover what sets us apart in fractional real estate investment{" "}
      </h2>
      <div className="my-5 py-1 pb-5 container d-block d-sm-none">

        <Swiper
          slidesPerView={1.2}
          spaceBetween={24}
          loop={true}
          pagination={{ el: "#property-bullets", clickable: true }}
          breakpoints={{
            560: { slidesPerView: 2.2 }, // Adjust accordingly
            960: { slidesPerView: 3.2 }, // Adjust accordingly
          }}
        >
          {features?.map((feature, i) => (
            <SwiperSlide>
              <Card
                className="h-auto text-start border-0 rounded-4"
                S
                style={{ backgroundColor: "#f3f2f6", minHeight: "330px" }}
              >
                <Card.Body className="p-4 ">
                  <div
                    className="h4 fw-semibold mb-4"
                    style={{ color: "rgba(44,138,197,0.76)" }}
                  >
                    {feature.icon}
                  </div>

                  <Card.Title>
                    <p className="h4 fw-semibold">{feature.title}</p>
                  </Card.Title>
                  <Card.Text>{feature.description}</Card.Text>
                </Card.Body>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="d-none d-sm-block">
        <Row>
          {features.map((feature, index) => (
            <Col md={4} className="mb-4" key={index}>
              <Card
                className="h-100 text-start border-0 rounded-4"
                S
                style={{ backgroundColor: "#f3f2f6" }}
              >
                <Card.Body className="p-5">
                  <div
                    className="h4 fw-semibold mb-5"
                    style={{ color: "rgba(44,138,197,0.76)" }}
                  >
                    {feature.icon}
                  </div>

                  <Card.Title>
                    <p className="h4 fw-semibold">{feature.title}</p>
                  </Card.Title>
                  <Card.Text>{feature.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </Container>
  );
};

export default FeatureSection;
