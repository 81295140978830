import React, { useState } from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { Button, Dropdown, Form, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";

import { UseCreateDao } from "../hooks/useCreateDao";
import { toast, ToastContainer } from "react-toastify";

import { GiHouseKeys } from "react-icons/gi";
import { MdDelete, MdEdit } from "react-icons/md";
import { useGetDao } from "../hooks/useGetDao";
import CCdata from "./Property/CitiAndCountry.json";

function Dao() {
  const { data, refetch } = useGetDao();

  const country = CCdata.map((data) => data.country);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm();
  const { mutate, isLoading } = useMutation(UseCreateDao, {
    onSuccess: (data) => {
      notifySuccess();
      refetch().then(() => {});
      reset();
    },
    onError: (error) => {
      notifyError(error?.response.data.error);
    },
  });
  const notifyError = (error) => toast.error(error);
  const countryname = watch("address.country_name");
  const filteredCities =
    countryname !== undefined
      ? CCdata.find((item) => item.country === countryname).city
      : [];
  const notifySuccess = () => toast.success("DAO Added Successfully");
  const onSubmit = (data) => {
    mutate(data);
  };
  return (
    <div className="m-4">
      <div className="table-responsive">
        <table className="table table-bordered table-sm table-hover ">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((dao) => (
              <tr key={dao.id}>
                <td>{dao.dao_name}</td>
                <td width="100">
                  <Dropdown className="mx-4 my-2">
                    <Dropdown.Toggle
                      variant="carre-blue text-white"
                      id="dropdown-basic"
                    >
                      Actions
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">
                        <MdEdit /> Edit
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        {" "}
                        <MdDelete /> Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="container">
        <div className="row justify-content-center">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="rounded-2 shadow-lg p-3 mb-5 p-3 my-2 ">
              <GiHouseKeys
                style={{ width: "20px", height: "20px" }}
                className="text-carre-blue  d-inline mb-2 mx-1"
              />
              <p className="h4 font-weight-bold d-inline "> Dao</p>
              <Row>
                <Form.Group as={Col}>
                  <label> Dao name</label>
                  <input
                    className={
                      errors.dao_name
                        ? "border-carre-red form-control"
                        : " form-control false"
                    }
                    {...register("dao_name", { required: true })}
                  />
                  {errors.dao_name && (
                    <p className="text-carre-red">This field is required</p>
                  )}
                </Form.Group>
              </Row>
            </div>

            <div className="rounded-2 shadow-lg p-3 mb-5 p-3 my-2 ">
              <HiOutlineLocationMarker
                style={{ width: "20px", height: "20px" }}
                className="text-carre-blue  d-inline mb-2 mx-1"
              />
              <p className="h4 font-weight-bold d-inline "> Location</p>
              <Row>
                <Form.Group as={Col}>
                  <label>Country</label>
                  <Form.Select
                    className={
                      errors?.address?.country_name
                        ? "text-carre-blue form-control border-carre-red"
                        : " form-control false"
                    }
                    {...register("address.country_name", {
                      required: true,
                    })}
                  >
                    {country?.map((count) => (
                      <option key={count.id} value={count.id}>
                        {count}
                      </option>
                    ))}
                  </Form.Select>
                  {errors?.address?.country_name && (
                    <p className="text-carre-red">This field is required</p>
                  )}
                </Form.Group>

                <Form.Group as={Col}>
                  <label> City*</label>
                  <Form.Select
                    className={
                      errors?.address?.country_name
                        ? "text-carre-blue form-control border-carre-red"
                        : " form-control false"
                    }
                    {...register("address.city_name", {
                      required: true,
                    })}
                  >
                    {filteredCities?.map((count) => (
                      <option key={count.id} value={count.id}>
                        {count}
                      </option>
                    ))}
                    {errors.address?.city_name && (
                      <p className="text-carre-red">This field is required</p>
                    )}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  <label> District</label>
                  <input
                    className={
                      errors.address?.country_name
                        ? "border-carre-red form-control"
                        : " form-control false"
                    }
                    {...register("address.country_state", { required: true })}
                  />
                  {errors.address?.country_name && (
                    <p className="text-carre-red">This field is required</p>
                  )}
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <label> Zip code*</label>
                  <input
                    className={
                      errors.address?.zipcode
                        ? "border-carre-red form-control"
                        : " form-control false"
                    }
                    {...register("address.zipcode", {
                      required: true,
                    })}
                  />
                  {errors.address?.zipcode && (
                    <p className="text-carre-red">This field is required</p>
                  )}
                </Form.Group>
                <Form.Group as={Col}>
                  <label> Street address*</label>
                  <input
                    className={
                      errors.address?.street_name
                        ? "border-carre-red form-control"
                        : " form-control false"
                    }
                    {...register("address.street_name", {
                      required: true,
                    })}
                  />
                  {errors.address?.street_name && (
                    <p className="text-carre-red">This field is required</p>
                  )}
                </Form.Group>
              </Row>
            </div>
            <Button
              type={"submit"}
              size="lg"
              variant="carre-blue w-100 text-white"
              disabled={isLoading}
            >
              {!isLoading ? "Create DAO" : "Please wait"}
            </Button>
          </Form>
        </div>
      </div>

      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
}

export default Dao;
