import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast, ToastContainer } from "react-toastify";

import { useUserCancelWithdrawReuqest } from "../../hooks/useUserCancelWithdrawRequest";

function UserCancelWithdrawReuqest(props) {
  const {
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {},
  });

  const { mutate, isLoading } = useMutation(useUserCancelWithdrawReuqest, {
    onSuccess: (data) => {
      notifySuccess();
      props.refetch().then(() => {});
      props.onHide();
    },
    onError: (error) => {
      notifyError(error?.response.data.error);
    },
  });
  const notifyError = (error) => toast.error(error);
  const notifySuccess = () =>
    toast.success("Withdraw request succesfull canceled");
  const onSubmit = (data) => {
    mutate(props.data.id);
  };

  function formatCreatedAt(createdAt) {
    const date = new Date(createdAt);
    const formattedDate = date.toLocaleDateString(); // Change toLocaleDateString to other format if needed
    return formattedDate;
  }

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Cancellation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {props?.data && (
              <>
                Are you sure you want to cancel the withdraw request created at{" "}
                <strong>{formatCreatedAt(props.data.created_at)}</strong> and
                amount <strong>{props.data.amount}</strong>?
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide} size="sm">
              Close
            </Button>
            <Button
              type={"submit"}
              size="sm"
              variant="carre-purple w-25"
              disabled={isLoading}
            >
              {!isLoading ? "Cancel Request" : "Please wait"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default UserCancelWithdrawReuqest;
