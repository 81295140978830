import { useQuery } from "react-query";
import api from "../api";

export const useGetPropertyForReservation = (
  dateFrom,
  dateTo,
  propertyId,
  options = {}
) => {
  return useQuery(
    ["reservation/search", dateFrom, dateTo, propertyId, options],
    () => {
      const queryParams = { date_from: dateFrom, date_to: dateTo };

      if (propertyId) {
        queryParams.type = "PROPERTY";
        queryParams.value = propertyId;
      }

      if (options.value) {
        queryParams.value = options.value;
      }
      if (options.key) {
        queryParams.key = options.key;
      }
      if (options.type) {
        queryParams.type = options.type;
      }
      return api
        .get("/reservation/search", { params: queryParams })
        .then((res) => res.data);
    }
  );
};

export const useGetReservationSearch = (value, options = {}) => {
  return useQuery(["reservation-search", value, options], () => {
    const queryParams = {
      value, // Added value first
    };

    if (options.type) {
      queryParams.type = options.type;
    }
    if (options.key) {
      queryParams.key = options.key;
    }

    return api
      .get(`/reservation/search/prepare`, { params: queryParams })
      .then((res) => res.data);
  });
};
export const fetchWalletStats = async (dateFrom, dateTo) => {
  const queryParams = { date_from: dateFrom, date_to: dateTo };
  const response = await api.get("/wallet-stats", { params: queryParams });
  return response.data;
};
export const fetchVacationStats = async (dateFrom, dateTo) => {
  const queryParams = { date_from: dateFrom, date_to: dateTo };
  const response = await api.get("/vacation/stats", { params: queryParams });
  return response.data;
};
