import React from "react";
import ReactDOM from "react-dom/client";
// import "./custom.scss";
// import "./scss/theme.scss"
import "./scss/theme.scss";

import App from "./App";

// import 'bootstrap/dist/css/bootstrap.min.css';
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import AppProvider from "./auth/AppProvider";
import GlobalLoadingIndicator from "./pages/GlobalLoadingIndicator";
import { ToastContainer } from "react-toastify";
import { TabProvider } from "./pages/Dashboard/TabContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 300000,
      // staleTime: 1000 * 10,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppProvider>
        <TabProvider>
          <QueryClientProvider client={queryClient}>
            <App />
            <GlobalLoadingIndicator />
          </QueryClientProvider>
        </TabProvider>
      </AppProvider>
    </BrowserRouter>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  </React.StrictMode>
);
