import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import PreviewProperty from "./Dashboard/PreviewProperty";

function PreviewPropertyHome() {
  return (
    <div
      style={{
        overflow: "hidden",
        marginTop: "90px",
      }}
    >
      <NavBar />
      <PreviewProperty />
    </div>
  );
}

export default PreviewPropertyHome;
