export function checkPlural(value, singularWord, pluralWord) {
  if (value === 1) {
    return `${value} ${singularWord}`;
  } else if (value > 1) {
    return `${value} ${pluralWord}`;
  } else {
    return "Invalid value";
  }
}

// (checkPlural(1, 'Bathroom', 'Bathrooms')