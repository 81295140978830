import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import IAssets from "../../assets/icons/AssestIcon";
import DashboardIcon from "../../assets/icons/DashboardIcon";
import ListPropertyIcon from "../../assets/icons/ListPropertyIcon";
import IMarketPlace from "../../assets/icons/MarketPlaceIcon";
import IWallet from "../../assets/icons/WalletIcon";
// noinspection ES6UnusedImports
import styles from "./../Dashboard/styles.css";

import { useCookies } from "react-cookie";
import { FiAlertTriangle, FiBook, FiInfo } from "react-icons/fi";
import { useAuth } from "../../auth/TokenCreation"; // noinspection ES6UnusedImports
import { useGetUserData } from "../../hooks/useGetUserInformation";
import { GetRoleNavigationPath } from "../../routeUtils";
import VerificationUser from "../VerificationUser";
import Sidebar from "./SideBar";
import { useTabContext } from "./TabContext";
import PdfViewer from "./PdfViewer";

// const role2 = GetRoleNavigationPath();
const navigation = [
  {
    name: "Dashboard",
    icon: <DashboardIcon />,
    iconHover: <DashboardIcon color="#3899da" />,
    to: `/admin`,
  },
  {
    name: "Marketplace",
    icon: <IMarketPlace />,
    iconHover: <IMarketPlace color="#3899da" />,
    to: "marketplace",
  },
  {
    name: "Assets",
    icon: <IAssets />,
    iconHover: <IAssets color="#3899da" />,
    to: "assets",
  },
  {
    name: "Account/Wallet",
    icon: <IWallet />,
    iconHover: <IWallet color="#3899da" />,
    to: "wallets",
  },
  {
    name: "Request",
    icon: <ListPropertyIcon />,
    iconHover: <ListPropertyIcon color="#3899da" />,
    to: "order",
  },
  // {
  //   name: "History",
  //   icon: <IOrderHistory />,
  //   iconHover: <IOrderHistory color="#3899da" />,
  //   to: "orderhistory",
  // },
  {
    name: "Learn",
    icon: <FiBook />,
    iconHover: <FiBook color="#3899da" />,
    to: "/helpcenter",
  },
  {
    name: "About Us ",
    icon: <FiInfo />,
    iconHover: <FiInfo color="#3899da" />,
    to: "aboutuspage",
  },
];
const userNavigation = [
  {
    name: "Marketplace",
    icon: <IMarketPlace />,
    iconHover: <IMarketPlace color="#3899da" />,
    to: "marketplace",
  },
  {
    name: "Assets",
    icon: <IAssets />,
    iconHover: <IAssets color="#3899da" />,
    to: "assets",
  },
  {
    name: "Account/Wallet",
    icon: <IWallet />,
    iconHover: <IWallet color="#3899da" />,
    to: "wallets",
  },
  {
    name: "Request",
    icon: <ListPropertyIcon />,
    iconHover: <ListPropertyIcon color="#3899da" />,
    to: "order",
  },
  // {
  //   name: "History",
  //   icon: <IOrderHistory />,
  //   iconHover: <IOrderHistory color="#3899da" />,
  //   to: "orderhistory",
  // },
  {
    name: "Learn",
    icon: <FiBook color="#707070" />,
    iconHover: <FiBook color="#3899da" />,
    to: "/helpcenter",
  },
  {
    name: "About Us ",
    icon: <FiInfo color="#707070" />,
    iconHover: <FiInfo color="#3899da" />,
    to: "aboutuspage",
  },
];

const Layout = () => {
  const { pathname } = useLocation();
  const [showSidebar, setShowSidebar] = useState(window.innerWidth >= 1200);
  const { data, refetch } = useGetUserData();
  const [activeItem, setActiveItem] = useState("");
  const { setShowOnboarding } = useTabContext();

  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    const segments = pathname?.split("/");
    const activeNavItem =
      segments.length === 2
        ? (navigation || userNavigation).find(
            (item) => item.to === "/" + segments[1]
          )
        : (navigation || userNavigation).find(
            (item) => item.to === segments[2]
          );

    if (activeNavItem) {
      setActiveItem(activeNavItem.name);
    }

    // Check if the pathname is "/manager" or "/superadmin" or "/admin" and set active item to "dashboard"
    if (
      pathname === "/manager" ||
      pathname === "/superadmin" ||
      pathname === "/admin"
    ) {
      setActiveItem("Dashboard");
    }
  }, [pathname]);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth < 1200 && windowWidth >= 200) {
        // MD and SM
        setShowSidebar(false);
      } else {
        setShowSidebar(true);
      }
    };
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [cookies] = useCookies();

  const name = cookies?.name;

  const [verificationModal, setVerificationModal] = useState();
  useEffect(() => {
    if (cookies?.verified === "false" || cookies?.verified === false) {
      setVerificationModal(true);
    } else {
      setVerificationModal(false);
    }
  }, [cookies]);

  return (
    <div>
      <Sidebar setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
      <VerificationUser
        show={verificationModal}
        onHide={() => setVerificationModal(false)}
        openLoginFromVerify1={undefined}
      />
      <div className="d-none d-sm-block ">
        <div className={`main-content  ${showSidebar ? "open" : "closed "}`}>
          <div
            style={{
              marginTop: "10px",
            }}
          >
            <div className="container-fluid ">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-auto d-sm-none d-block mt-5 ">
        <div className="container" style={{ marginTop: "20px" }}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
