import {useQuery} from "react-query";
import api from "../api";

export const useVerifyUser = (code, email) => {
    return useQuery(["verify"], () => {
        return api.get(`verify/${email}/${code}`).then((res) => res.data);
    });
};


export const verifyUser = async (code, email) => {
    return await api.get(`verify/${email}/${code}`).then((res) => res).catch((error) => error);
};

export const resendVerification = (data) =>
    api.post("/resend-verification", data).then((res) => res.data);
