import { Splide, SplideSlide } from "@splidejs/react-splide";
import {
  Button,
  Col,
  Nav,
  NavItem,
  OverlayTrigger,
  Row,
  Tab,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import { BiBath, BiBed, BiCar, BiSolidArea } from "react-icons/bi";
import LightboxPage from "../../components/LightBox";
import { useNavigate, useParams } from "react-router-dom";
import { useGetPropertyById } from "../../hooks/useGetPropertyById";
import { GetRoleNavigationPath } from "../../routeUtils";
import { useEffect, useMemo, useRef, useState } from "react";
import ReserveTokenPage from "./ReserveTokenPage";
import SellTokenPage from "./SellTokenPage";
import OrderPage from "./OrderPage";
import TransfersPage from "./TransferPage";
import BalancePage from "./BalancePage";

function PropertyDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const id = params.id;
  const roleUrl = GetRoleNavigationPath();
  const { data, refetch } = useGetPropertyById(id);
  const navRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState("RESERVETOKEN");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [viewMode, setViewMode] = useState("card"); // New state for view mode
  useEffect(() => {
    window.scrollTo(0, 0);
    refetch();
  }, [id, data, refetch]);

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  useEffect(() => {
    if (navRef.current) {
      const containerWidth = navRef.current.offsetWidth;
      const selectedTabElement = navRef.current.querySelector(".active");
      if (selectedTabElement) {
        const tabWidth = selectedTabElement.offsetWidth;
        const tabLeftOffset = selectedTabElement.offsetLeft;
        let tabCenterOffset = tabLeftOffset + tabWidth / 2 - containerWidth / 2;
        tabCenterOffset = Math.max(tabCenterOffset, 0);
        setScrollPosition(tabCenterOffset);
      }
    }
  }, [selectedTab]);

  const renderComponent = () => {
    switch (selectedTab) {
      case "RESERVETOKEN":
        return <ReserveTokenPage id={data?.id} viewMode={viewMode} />;
      case "SELLTOKEN":
        return <SellTokenPage id={data?.id} viewMode={viewMode} />;
      case "ORDER":
        return <OrderPage id={data?.id} viewMode={viewMode} />;
      case "TRANSFERS":
        return <TransfersPage id={data?.id} viewMode={viewMode} />;
      case "BALANCE":
        return <BalancePage id={data?.id} viewMode={viewMode} />;
      default:
        return null;
    }
  };

  const navbarContainerStyle = {
    overflowX: "auto",
    scrollBehavior: "smooth",
  };

  const tabsWidth = useMemo(() => {
    if (navRef.current) {
      return Array.from(navRef.current.children).reduce(
        (totalWidth, child) => totalWidth + child.offsetWidth,
        0
      );
    }
    return 0;
  }, [navRef.current]);

  const containerWidth = navRef.current ? navRef.current.offsetWidth : 0;

  const navScrollableStyle = {
    display: "flex",
    flexWrap: "nowrap",
    ...(tabsWidth > containerWidth
      ? {
          transform: `translateX(-${scrollPosition}px)`, // Center the selected tab
        }
      : {}),
  };

  const navItemStyle = {
    padding: "0 10px",
  };
  return (
    <div>
      <div className="d-block d-lg-none mb-3 mx-3">
        <Splide
          className=""
          options={{
            perPage: 1,
            height: "25rem",
            rewind: true,
            gap: "1rem",
          }}
          aria-labelledby="basic-example-heading"
        >
          {data?.image?.map((slide) => (
            <SplideSlide key={slide.image_url}>
              <img
                src={slide.image_url}
                alt="Default"
                style={{
                  objectFit: "cover",
                }}
                className="w-100 h-100 rounded-2"
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>
      <div className=" d-none d-lg-block container">
        <LightboxPage data={data} />
      </div>
      <div className="container d-flex gap-4 align-items-center ">
        <div className="d-flex align-items-center">
          <div className="fs-3 fw-bold">{data?.bedroom_count}</div>

          <BiBed size={25} />
        </div>

        <div className="d-flex align-items-center">
          <div className="fs-3 fw-bold">{data?.bathrooms}</div>
          <BiBath size={25} />
        </div>

        <div className="d-flex align-items-center">
          <div className="fs-3 fw-bold">{data?.parking}</div>
          <BiCar size={25} />
        </div>

        <div className="d-flex align-items-center">
          <div className="fs-3 fw-bold">{data?.property_area}</div>
          <BiSolidArea size={25} />
        </div>
      </div>
      <div className=" container">
        <Row>
          <Col>
            <div className="mt-2 h-100">
              <h1 className="text-carre-black">{data?.property_name}</h1>
              <span>{data?.address.street_name}</span>
              <span className="text-muted">
                {data?.description.street_name} {data?.address.city_name}
                ,&nbsp;
                {data?.address.country_state} {data?.address.country_name}
                &nbsp;
                {data?.address.zipcode}
              </span>
            </div>
          </Col>

          <Col
            className="p-3 overflow-visible h-50  d-none d-md-block"
            style={{ maxHeight: "10px" }}
          >
            <div className="d-flex flex-column align-items-end justify-content-end h-100">
              <Row>
                <Col className="w-100">
                  <Button
                    onClick={() => {
                      if (data?.market_stage_name === "Upcoming") {
                        navigate(`/${roleUrl}/buy/${data?.id}`);
                      } else {
                        navigate(`/${roleUrl}/buy/${data?.id}`);
                      }
                    }}
                    variant={
                      data?.market_stage_name === "Upcoming"
                        ? "carre-blue text-white"
                        : "carre-blue text-white"
                    }
                    sm
                    size="md"
                  >
                    {data?.market_stage_name === "Upcoming" ? "Reserve" : "Buy"}
                  </Button>
                </Col>
                {data?.market_stage_name !== "Upcoming" && (
                  <Col>
                    <Button
                      onClick={() => {
                        navigate(`/${roleUrl}/sell/${data?.id}`);
                      }}
                      variant="carre-red text-white"
                      className="mb-3"
                      size="md"
                    >
                      Sell
                    </Button>
                  </Col>
                )}
              </Row>

              <div className="text-center h6">
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      This is the sum of cash on cash return + appreciaton +
                      vacation points
                    </Tooltip>
                  }
                >
                  <div className="text-muted">
                    <span className=" float-start">Expected Total Return</span>
                    <span className="mx-1 text-carre-blue float-end">
                      {(
                        data?.net_expected_cash_return * 2 +
                        data?.net_appreciation
                      ).toFixed(2)}
                      %
                    </span>
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          </Col>
        </Row>
        <div style={navbarContainerStyle} className="mt-4">
          <Nav
            justify
            variant="tabs"
            ref={navRef}
            style={navScrollableStyle}
            className="gap-3 mt-4"
          >
            <NavItem>
              <Nav.Link
                active={selectedTab === "TRANSFERS"}
                onClick={() => handleTabClick("TRANSFERS")}
                style={{
                  ...navItemStyle,
                  color: selectedTab === "TRANSFERS" && "#3899da",
                }}
              >
                Transfers
              </Nav.Link>
            </NavItem>

            <NavItem>
              <Nav.Link
                active={selectedTab === "BALANCE"}
                onClick={() => handleTabClick("BALANCE")}
                style={{
                  ...navItemStyle,
                  color: selectedTab === "BALANCE" && "#3899da",
                }}
              >
                Balance
              </Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link
                active={selectedTab === "RESERVETOKEN"}
                onClick={() => handleTabClick("RESERVETOKEN")}
                className=""
                style={{
                  ...navItemStyle,
                  color: selectedTab === "#000000" && "#3899da",

                  whiteSpace: "nowrap",
                }}
              >
                Reserve Share
              </Nav.Link>
            </NavItem>

            <NavItem>
              <Nav.Link
                active={selectedTab === "ORDER"}
                onClick={() => handleTabClick("ORDER")}
                style={{
                  ...navItemStyle,
                  color: selectedTab === "ORDER" && "#3899da",
                }}
              >
                Order
              </Nav.Link>
            </NavItem>

            <NavItem>
              <Nav.Link
                active={selectedTab === "SELLTOKEN"}
                onClick={() => handleTabClick("SELLTOKEN")}
                style={{
                  ...navItemStyle,
                  color: selectedTab === "SELLTOKEN" && "#3899da",
                  whiteSpace: "nowrap",
                }}
              >
                Sell Share Request
              </Nav.Link>
            </NavItem>
          </Nav>
          <hr />
        </div>
        <div className="d-flex justify-content-end mb-3">
          <Button
            variant="outline-primary"
            className="mx-2"
            onClick={() => setViewMode("table")}
            disabled={viewMode === "table"}
          >
            Table View
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => setViewMode("card")}
            disabled={viewMode === "card"}
          >
            Card View
          </Button>
        </div>
        <div> {renderComponent()}</div>
      </div>
    </div>
  );
}

export default PropertyDetails;
