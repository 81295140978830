import { useQuery } from "react-query";
import api from "../api";

export const useGetUserSellRequest = (
  property_id = "",
  market_stage,
  type,
  status,
  additionalParam2
) => {
  const queryParams = {};

  return useQuery(
    ["sell-request", property_id, market_stage, type, status, additionalParam2],

    () => {
      if (status !== "ALL") {
        queryParams.status = status;
      }

      return api
        .get("/user/sell-request", {
          params: {
            property_id,
            market_stage,
            type,
            status,
            limit: 100,
          },
        })
        .then((res) => res.data);
    }
  );
};
