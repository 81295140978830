import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { MdDelete, MdEdit } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import { useGetSellRequestForAdmin } from "../../hooks/useGetSellRequestForAdmin";
import AdminAcceptSellReuqest from "../../components/AdminAcceptSellRequest";
import AdminRejectSellReuquest from "../../components/AdminRejectSellRequest";
import { BiBath, BiBed } from "react-icons/bi";
import { FaCarSide } from "react-icons/fa";
import moment from "moment";

function AdminSellRequest() {
  const [status, setStatus] = useState(true);
  const { data, refetch } = useGetSellRequestForAdmin(status);

  useEffect(() => {
    refetch().then();
  }, [status]);
  const [modalShow, setModalShow] = useState(false);

  const [property, setProperty] = useState();
  const [rejectModalShow, setRejectedModalShow] = useState(false);

  function handleAcceptSellModal(data) {
    setModalShow(true);
    setProperty(data.token_selling_request);
  }

  function handleRejectSellModal(data) {
    setRejectedModalShow(true);
    setProperty(data?.token_selling_request);
  }

  return (
    <div className="">
      <div className="m-4">
        <div className="d-flex justify-content-between mb-4">
          <Button
            disabled="true"
            variant="outline-carre-gray-bg"
            className="text-black"
          >
            Status
          </Button>

          <hr className="w-100 mx-2" />
          <div className="d-flex gap-2 ">
            <div>
              <select
                className="form-select w-auto"
                aria-label="Default select example"
                onChange={(e) => setStatus(!status)}
              >
                <option>Pending</option>
                <option>All</option>
              </select>
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table table-bordered table-sm table-hover ">
            <thead>
              <tr>
                <th scope="col">Info</th>
                <th scope="col">Property Image</th>
                <th scope="col">Property Details</th>
                <th scope="col">Quantity</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((data) => (
                <tr key={data?.token_selling_request?.id}>
                  <td>
                    created by
                    <p className="fw-semibold">
                      {data?.token_selling_request?.email}{" "}
                    </p>
                    on{" "}
                    <p className="fw-semibold">
                      {" "}
                      {moment(data?.token_selling_request?.created_at).format(
                        "MMMM Do YYYY"
                      )}
                    </p>
                  </td>
                  <td width="240px">
                    <div className="w-100 h-100">
                      <img
                        alt="property"
                        src={
                          data?.property?.image != null
                            ? data.property.image?.image_url
                            : ""
                        }
                        className="img-fluid  "
                        style={{
                          objectFit: "cover",
                          maxHeight: "200px",
                          maxWidth: "240px",
                          width: "240px",
                          height: "100px",
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="">
                      <div>
                        <p className="fw-bold mb-1">
                          {data?.property?.property_name}
                        </p>
                        <p className="fw-bold mb-1">
                          {data?.property?.property_area} sq
                        </p>
                        <div className="d-flex gap-2">
                          <span className="fw-bold mb-1">
                            <BiBath /> {data?.property?.bathrooms}
                          </span>
                          <span className="fw-bold mb-1">
                            <BiBed /> {data?.property?.bedroom_count}
                          </span>
                          <span className="fw-bold mb-1">
                            <FaCarSide /> {data?.property?.parking}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="align-middle fw-bold justify-content-center align-items-center text-center">
                    {data?.token_selling_request?.token_qty}
                  </td>
                  <td
                    className="align-middle fw-bold justify-content-center align-items-center text-center"
                    style={{
                      color:
                        data?.token_selling_request?.status === "PENDING"
                          ? "blue"
                          : data?.token_selling_request?.status === "CANCELED"
                          ? "red"
                          : "green",
                    }}
                  >
                    {data?.token_selling_request?.status}
                  </td>
                  <td width="100">
                    <Dropdown className="mx-4 my-2">
                      <Dropdown.Toggle
                        variant="carre-blue text-white"
                        id="dropdown-basic"
                      >
                        Actions
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleAcceptSellModal(data)}
                        >
                          <MdEdit /> Accept Request
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleRejectSellModal(data)}
                        >
                          {" "}
                          <MdDelete /> Reject Request
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </div>
      <AdminAcceptSellReuqest
        refetch={refetch}
        show={modalShow}
        data={property}
        onHide={() => setModalShow(false)}
      />

      <AdminRejectSellReuquest
        refetch={refetch}
        show={rejectModalShow}
        data={property}
        onHide={() => setRejectedModalShow(false)}
      />
    </div>
  );
}

export default AdminSellRequest;
