import React, { useEffect, useState } from "react";
import { Tabs, Tab, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { FaCalendarAlt, FaMapMarkedAlt, FaExchangeAlt } from "react-icons/fa";
import RangeDatePicker from "./RangeDatePicker";
import BookingTable from "./BookingTable";
import VacationPage from "../pages/Dashboard/Vacation/VacationPage";
import { useGetVacationDetails } from "../hooks/useGetVacationDetails"; // Importing icons

const VacationTabs = () => {
  const [key, setKey] = useState("vacation");
  const { type } = useParams();

  useEffect(() => {
    if (type === "reservation") {
      setKey("reservation");
    } else if (type === "order") {
      setKey("order");
    }
  }, [type]);

  const tabStyle = {
    color: "#6c757d", // Inactive tab text color
    fontWeight: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const activeTabStyle = {
    color: "#000", // Active tab text color
    fontWeight: "semibold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const iconStyle = {
    marginBottom: "4px", // Adjust the space between icon and text
  };

  const mobileTabStyle = {
    display: "flex",
    flexDirection: "column", // Stack icon and text vertically on mobile
    alignItems: "center",
  };
  const { data: vacationData } = useGetVacationDetails();

  return (
    <div className="mt-md-0 mt-5 container-sm">
      <div></div>
      <Tabs
        id="order-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        variant="tabs"
        className="bg-carre-gray p-1 gap-2 rounded-2 mt-2"
        justify
      >
        <Tab
          eventKey="vacation"
          title={
            <span
              style={
                key === "vacation"
                  ? { ...activeTabStyle, ...mobileTabStyle }
                  : { ...tabStyle, ...mobileTabStyle }
              }
            >
              <FaCalendarAlt style={iconStyle} /> Book
            </span>
          }
        >
          <p className="text-medium fw-medium">
            <strong>
              {" "}
              {Number(vacationData?.balance?.toFixed(2)).toLocaleString()}
            </strong>{" "}
            vacation points available
          </p>
          <RangeDatePicker />
        </Tab>
        <Tab
          eventKey="order"
          title={
            <span
              style={
                key === "order"
                  ? { ...activeTabStyle, ...mobileTabStyle }
                  : { ...tabStyle, ...mobileTabStyle }
              }
            >
              <FaExchangeAlt style={iconStyle} /> Trade
            </span>
          }
        >
          <VacationPage />
        </Tab>

        <Tab
          eventKey="reservation"
          title={
            <span
              style={
                key === "reservation"
                  ? { ...activeTabStyle, ...mobileTabStyle }
                  : { ...tabStyle, ...mobileTabStyle }
              }
            >
              <FaMapMarkedAlt style={iconStyle} /> My trips
            </span>
          }
        >
          <p className="text-medium fw-medium">
            <strong>
              {Number(vacationData?.balance?.toFixed(2)).toLocaleString()}
            </strong>{" "}
            vacation points available
          </p>
          <BookingTable />
        </Tab>
      </Tabs>
    </div>
  );
};

export default VacationTabs;
