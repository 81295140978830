import { useGetProperty } from "../../hooks/useGetProperty";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetPropertyType } from "../../hooks/useGetPropertyType";
import { useGetPropertyCountries } from "../../hooks/useGetPropertyCountries";
import { useGetMarketPlace } from "../../hooks/useGetMarketPlace";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { MdOutlineFilterAlt } from "react-icons/md";
import Col from "react-bootstrap/Col";
import { Alert, Card, Placeholder, Row } from "react-bootstrap";
import { GetRoleNavigationPath } from "../../routeUtils";
import { BiReset } from "react-icons/bi";
import { useQueryClient } from "react-query";
import FinderPropertyCard from "../Property/FinderPropertyCard";
import { useTabContext } from "./TabContext";
import Footer from "../../components/Footer";
import SEO from "../../components/SEO";

function MarketPlace() {
  const [market, setMarket] = useState("");
  const [location, setLocation] = useState("");
  const [type, setType] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const roleUrl = GetRoleNavigationPath();
  const { data, isLoading, refetch } = useGetProperty({
    market,
    location,
    type,
    isAdmin:
      roleUrl === "superadmin" || roleUrl === "admin" || roleUrl === "manager",
  });
  const { data: propertyType, isLoading: isLoadingPropertyType } =
    useGetPropertyType();
  const { data: country, isLoading: isLoadingCountry } =
    useGetPropertyCountries();
  const { data: marketPlace, isLoading: isLoadingMarketPlace } =
    useGetMarketPlace();

  const filterMarket = marketPlace?.find((obj) => obj.id === parseInt(market));
  const filterType = propertyType?.find((obj) => obj.id === parseInt(type));
  const nav = useNavigate();

  const navigate = (id) => {
    nav("propdetails/" + id);
  };
  const { setSelectedTab } = useTabContext();

  useEffect(() => {
    refetch().then();
  }, [data]);

  const typePrimaryItems = [];
  const typeUpcomingItems = [];
  const typeSecondaryItems = [];
  const typeOtherItems = [];

  data?.forEach((item) => {
    if (item.market_stage_name === "Primary") typePrimaryItems.push(item);
    else if (item.market_stage_name === "Upcoming")
      typeUpcomingItems.push(item);
    else if (item.market_stage_name === "Secondary")
      typeSecondaryItems.push(item);
    else typeOtherItems.push(item);
  });

  // Combine the arrays in the desired order
  const combinedData = [
    ...typeSecondaryItems,
    ...typePrimaryItems,
    ...typeUpcomingItems,

    ...typeOtherItems,
  ];
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleResetFilters = () => {
    setMarket("");
    setLocation("");
    setType("");
  };

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries("property");
  }, [market, location, type, queryClient]);
  return (
    <>
      <SEO
        title="Discover Premium Real Estate Investments | Carré Marketplace"
        description="Explore the Carré Marketplace for exclusive fractional real estate investment opportunities. Join today and invest in high-quality rental properties with as little as $100."
        keywords="real estate marketplace, fractional investments, property investment, Carré marketplace, rental properties, investment opportunities, affordable real estate, passive income"
        url="https://carre.ai/marketplace"
      />
      <div className="my-3 container mt-5 mt-md-0">
        <Offcanvas
          show={show}
          onHide={handleClose}
          responsive={false}
          className=""
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title as="h5">Filters</Offcanvas.Title>
          </Offcanvas.Header>

          <Offcanvas.Body className="py-lg-4 ">
            <div className="pb-4 mb-2 ">
              <h3 className="h6">Market Stages</h3>
              <div className="flex space-x-2 mb-4">
                <Button
                  variant="outline-carre-blue w-100"
                  size="sm"
                  className={`${
                    market === "" ? "bg-carre-blue text-white" : ""
                  } px-4 py-2 rounded-lg hover:bg-blue-500 hover:text-white transition-all duration-300 mt-2 mx-1`}
                  onClick={() => setMarket("")}
                >
                  All Properties
                </Button>

                {marketPlace?.map((stage) =>
                  stage.name !== "Submitted" ? (
                    <Button
                      variant="outline-carre-blue w-100"
                      size="sm"
                      key={stage.id}
                      className={`${
                        stage.id === market ? "bg-carre-blue text-white" : ""
                      } px-4 py-2 rounded-lg hover:bg-blue-500 hover:text-white transition-all duration-300 mx-1 mt-2`}
                      onClick={() => setMarket(stage.id)}
                    >
                      {stage.name}
                    </Button>
                  ) : null
                )}
              </div>
            </div>
            <div className="pb-4 mb-2 ">
              <h3 className="h6">Location</h3>
              <select
                className=" form-select"
                aria-label="Default select example"
                onChange={(e) => setLocation(e.target.value)}
              >
                <option selected value={""}>
                  Location
                </option>
                {country?.map((country) => (
                  <option key={country.name} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="pb-4 mb-2">
              <h3 className="h6">Property type</h3>
              <select
                className=" form-select"
                aria-label="Default select example"
                onChange={(e) => setType(e.target.value)}
              >
                <option selected value={""}>
                  Property Type
                </option>
                {propertyType?.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="border-top py-4">
              <Button
                variant="outline-carre-blue w-100"
                onClick={handleResetFilters}
              >
                <BiReset className="fi-rotate-right me-2"></BiReset>
                Reset filters
              </Button>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        {/* Filter Tab  */}
        {/* <div className="d-lg-none mb-4 container mx-5 ">
        <Button
          style={{ marginLeft: "10px" }}
          variant="carre-blue text-white"
          onClick={() => setShowFilters(!showFilters)}
        >
          <MdOutlineFilterAlt /> Filter
        </Button>
      </div> */}
        {/* lg/xl screens */}

        {/* md/sm screen  */}
        {/*  */}
        {/* Property Listing */}

        {isLoading ? (
          <div className="row">
            {[1, 2, 3, 4, 5, 6].map((p) => (
              <Col
                key={p}
                sm={12}
                md={6}
                lg={6}
                xl={4}
                style={{ marginBottom: "20px" }}
              >
                <Card>
                  <div
                    style={{
                      width: "100%",
                      height: "300px",
                      backgroundColor: "#e0e0e0",
                      marginBottom: "20px",
                      borderTopLeftRadius: 12,
                      borderTopRightRadius: 12,
                    }}
                  />
                  <Card.Body>
                    <Placeholder as={Card.Title} animation="glow">
                      <Placeholder xs={8} />
                    </Placeholder>
                    <Placeholder
                      as={Card.Text}
                      animation="glow"
                      className="mb-4"
                    >
                      <Placeholder xs={12} />
                    </Placeholder>
                    <Placeholder
                      as={Card.Text}
                      animation="glow"
                      className="justify-content-between d-flex "
                    >
                      <Placeholder xs={5} /> <Placeholder xs={5} />
                    </Placeholder>
                    <Placeholder
                      as={Card.Text}
                      animation="glow"
                      className="justify-content-between d-flex"
                    >
                      <Placeholder xs={6} /> <Placeholder xs={4} />
                    </Placeholder>
                  </Card.Body>
                  <Card.Footer>
                    <Placeholder
                      animation="glow"
                      className="d-flex justify-content-around align-content-center"
                    >
                      <Placeholder xs={2} />
                      <Placeholder xs={2} />
                      <Placeholder xs={2} />
                    </Placeholder>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </div>
        ) : (
          <div style={{ minHeight: "100vh" }}>
            <Row>
              {data && data.length > 0 ? (
                data.map((p) => (
                  <Col
                    onClick={() => {
                      navigate(p.id);
                      setSelectedTab("DETAILS");
                    }}
                    key={p.id}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={4}
                    xxl={4}
                    style={{ marginBottom: "20px" }}
                  >
                    <FinderPropertyCard
                      // title="3-bed Apartment | 67 sq.m"
                      // category={
                      //   p?.market_stage_name === "Upcoming"
                      //     ? "To Reserve"
                      //     : "To Buy"
                      // }
                      price={
                        p?.market_stage_name === "Upcoming"
                          ? p?.allowed_token_to_reserve - p?.reserved_token
                          : p?.available_token?.toLocaleString("en-US")
                      }
                      badges={[
                        ["success", "Verified"],
                        ["info", "New"],
                      ]}
                      footer={[
                        ["fi-bed", 3],
                        ["fi-bath", 1],
                        ["fi-car", 2],
                      ]}
                      // light // Optionally pass light prop so the card works well on dark backgrounds
                      className="mx-auto shadow"
                      style={{ maxWidth: "800px" }}
                      data={p}
                    />
                  </Col>
                ))
              ) : (
                <Alert className="text-center fs-sm p-2 fw-semibold">
                  No data is found!
                </Alert>
              )}
            </Row>
          </div>
        )}
        <div
          size="sm"
          variant="carre-blue "
          className="w-100 rounded-0 z-1 text-center fixed-bottom bg-carre-blue p-2 text-white"
          onClick={handleShow}
        >
          <MdOutlineFilterAlt className="fi-rotate-right me-2"></MdOutlineFilterAlt>
          Filters
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MarketPlace;
