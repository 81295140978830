import React from "react";
import { UserProvider } from "./TokenCreation";

const AppProvider = ({ children }) => (
  <>
    <UserProvider>{children}</UserProvider>
  </>
);

export default AppProvider;
